import React from 'react';
import PropTypes from 'prop-types';
import { Lecture } from '../Lecture';
import { Spinner } from '../../Common/Spinner';
import './embed.scss';

const EmbedLecture = ({
 lecture, profile, track, examLecture, setExamLecture, submitExam, isFetchingGradeExam,
 isErrorGradeExam, isSuccessGradeExam, module
}) => {
  const renderContent = () => (
    <div className="columns">
      <div className="is-8">
        <div className="container">
          {lecture
            ? (
              <Lecture
                module={module}
                lecture={lecture}
                activeProgram={profile.activeProgram}
                track={track}
                examLecture={examLecture}
                setExamLecture={setExamLecture}
                submitExam={submitExam}
                isFetchingGradeExam={isFetchingGradeExam}
                isErrorGradeExam={isErrorGradeExam}
                isSuccessGradeExam={isSuccessGradeExam}
              />
            ) : (
              <section className="loading-lecture">
                <Spinner />
              </section>
            )}
        </div>
      </div>
    </div>
  );

  return (
    renderContent()
  );
};

EmbedLecture.defaultProps = {
  profile: {}
};

EmbedLecture.propTypes = {
  lecture: PropTypes.shape().isRequired,
  profile: PropTypes.shape(),
  track: PropTypes.func.isRequired,
  module: PropTypes.string.isRequired,
};

export default EmbedLecture;
