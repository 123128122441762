export default {
  title: 'Ejercicio 41',
  description: `
  A partir del siguiente arreglo de mascotas:
  <br>
  <code>
    const mascotas = [
        { nombre: "Bimbo",    especie: "Hamster", comidasFavoritas: ["Semillas", "Nueces"] },
        { nombre: "Ludovico", especie: "Perro",   comidasFavoritas: ["Huesos", "Jamon"] },
        { nombre: "Pavlov",   especie: "Perro",   comidasFavoritas: ["Croquetas", "Pollo"] },
        { nombre: "Chancla",  especie: "Gato",    comidasFavoritas: ["Atun", "Pescado"] },
        { nombre: "Don Pepe", especie: "Perico",  comidasFavoritas: ["Semillas"] }
    ];
    </code>
    <br>
    Genera un nuevo arreglo llamado <strong>nombresMascotas</strong> que contenga los nombres de todas 
    las mascotas. `,
  seed: `//type your code here 
    const mascotas = [
        { nombre: "Bimbo",    especie: "Hamster", comidasFavoritas: ["Semillas", "Nueces"] },
        { nombre: "Ludovico", especie: "Perro",   comidasFavoritas: ["Huesos", "Jamon"] },
        { nombre: "Pavlov",   especie: "Perro",   comidasFavoritas: ["Croquetas", "Pollo"] },
        { nombre: "Chancla",  especie: "Gato",    comidasFavoritas: ["Atun", "Pescado"] },
        { nombre: "Don Pepe", especie: "Perico",  comidasFavoritas: ["Semillas"] }
      ];`,
  test: [
    `test('Prueba 1', function (t) {
      t.equal(nombresMascotas.length,5,"Son 5 mascotas");
      t.end()
    });`,
    `test('Prueba 2', function (t) {
      t.equal(nombresMascotas[0],"Bimbo","primera mascota correcta");
      t.end()
    });`,
  ]
};
