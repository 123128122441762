/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../MyCourse/mycourse.scss';
import PropTypes from 'prop-types';
import book from './book.svg';
// import { Spinner } from '../../Common/Spinner';
import ReadOnly from '../../Common/Content/ReadOnly';
import { SELF_OPEN_LESSON } from '../../utils/tracks';
import { ProjectRateForm } from '../../Components/ProjectRateForm';
import RateProjectTable from '../../Components/RateProjectTable';
import './selfLecture.scss';
import Exam from './Exam/Exam';
// import { CardVideo } from '../../Common/CardVideo';

function SelfLecture({
  activeProgram, lecture, exam, activeModule, topic,
  // isFetch,
  track, submitExam, nextLesson, lastLesson, nextProject,
  activeProject, contentType, alumniProject, saveAlumniProject,
 }) {
  const module = activeProgram.modules.find((_m) => _m.slug === activeModule);
  const tracker = module.selfpaced_tracker
    .find((st) => st.topic_slug === topic) ?? { lessons: [], projects: [] };
  
    const allExams = tracker.lessons
    .find((leasson) => leasson.lecture === lecture._id)?.exam ?? [];
  const trackerExam = allExams.length > 0 ? allExams[allExams.length - 1] : null;

  const sendProject = contentType === 'selfProject' && tracker.projects
    .find(
      (p) => activeProject && p.slug === activeProject.slug 
      && (alumniProject ? alumniProject.points <= 0 : true)
   );

   const doneProject = contentType === 'selfProject' && tracker.projects
    .find(
      (p) => activeProject && p.slug === activeProject.slug 
      && (alumniProject && alumniProject.points > 0)
    );

  useEffect(() => {
    track(SELF_OPEN_LESSON, { lecture_id: lecture._id, module: activeModule });
  }, [lecture]);

  return (
    <section className="selfPaced-lecture-container">
      <>
        {lecture && contentType === 'self' && (
        <>
          <div className="markdown" id="lectureContainer">
            <ReadOnly
              module={activeModule}
              data={lecture}
              activeProgram={activeProgram}
              track={() => {}}
              program={activeProgram.program}
              lectures={activeProgram.tracking.lectures}
              type="selfPaced"
            />
          </div>
          <section className="selfPaced-question-container">
            <Exam
              score={trackerExam?.score}
              exam={trackerExam?.responses || exam}
              submitExam={(newResponses) => {
                  submitExam(newResponses);
                }}
            />
          </section>
          <div style={{ marginBottom: '3rem', display: 'flex', alignItems: 'center' }}>
            {lastLesson && lastLesson.id && (
            <Link to={`/alumni/self/lectures/${activeModule}/${lastLesson.id}?type=self&topic=${topic}`}>
              <button className="btn__alt-trasnparent" type="button">{`Lección anterior < ${lastLesson.tema}`}</button>
            </Link>
                )}
            {nextLesson && nextLesson.id && (
            <Link to={`/alumni/self/lectures/${activeModule}/${nextLesson.id}?type=self&topic=${topic}`}>
              <button className="btn__alt-default-white" type="button">{`Siguiente lección > ${nextLesson.tema}`}</button>
            </Link>
                )}
            {nextProject && nextProject._id && (
            <Link to={`/alumni/self/lectures/${activeModule}/${nextProject.slug}?type=selfProject&topic=${topic}`}>
              <button className="btn__alt-default-white" type="button">{`Proyecto de clase: ${nextProject.title}`}</button>
            </Link>
                )}
          </div>
        </>
          ) }

        {lecture && contentType === 'selfProject' && (
        <>
          <div className="row mt-4">
            <div className="col-6 d-flex flex-row justify-content-between">
              <div className="ribbon__lecture bg__brand-plasma">
                <img src={book} alt="lecture ribbon" />
                <p className="text__white">Proyecto</p>
              </div>
            </div>
          </div>
          {/* Proyecto enviado */}
          {sendProject && (
          <div className="m-5 project_send text__white">
            <h5>¡Felicidades por tu esfuerzo!</h5>
            <p>
              Tú senseí calificará tu proyecto y una vez calificado 
              podrás visualizar en esta sección tu revisión.
            </p>
            {alumniProject ? (
              <>
                <p>{`Título: ${alumniProject?.title}`}</p>
                <p>{`Descripción: ${alumniProject?.description}`}</p>
                <p>Links: </p>
                <ul>
                  {alumniProject?.links && alumniProject?.links.map((e) => <li key={e}><a href={e} target="_blanck">{e}</a></li>)}
                </ul>
              </>
                ) : <></>}
          </div>
          )}

          {/* Proyecto calificado */}
          {doneProject && (
          <>
            <h4 className="my-5 text__white">
              Tu calificación:
            </h4>
            <RateProjectTable
              alumniProject={alumniProject} 
              testPasses={doneProject.testPasses} 
              testTotal={doneProject.testTotal} 
              testFailures={doneProject.testFailures}
            />
          </>
            )}
          {/* Vista del proyecto */}
          <div className="markdown lectures-scroll" id="lectureContainer">
            {activeProject && (
            <>
              <ReadOnly
                module={activeModule}
                data={activeProject}
                activeProgram="Master en Code"
                track={() => {}}
                program={activeProgram.program}
                type="selfPaced"
              />
              <ProjectRateForm 
                submitProject={saveAlumniProject} 
                alumniProject={alumniProject}
              />
            </>
            )}
          </div>
        </>
          )}
      </>
    </section>
  );
}

SelfLecture.defaultProps = {
  alumniProject: {
    links: [''],
    points: -1,
    description: '',
  },
  lastLesson: {},
  nextProject: {},
  activeProject: null
};

SelfLecture.propTypes = {
  activeProgram: PropTypes.shape().isRequired,
  lecture: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  exam: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  activeModule: PropTypes.string.isRequired,
  // isFetch: PropTypes.bool.isRequired,
  // isFetchingGradeExam: PropTypes.bool.isRequired,
  track: PropTypes.func.isRequired,
  topic: PropTypes.string.isRequired,
  submitExam: PropTypes.func.isRequired,
  // history: PropTypes.shape().isRequired,
  activeProject: PropTypes.shape(),
  contentType: PropTypes.string.isRequired,
  alumniProject: PropTypes.shape({
    title: PropTypes.string,
    points: PropTypes.number,
    links: PropTypes.arrayOf(PropTypes.string).isRequired,
    description: PropTypes.string,
  }),
  saveAlumniProject: PropTypes.func.isRequired,
  nextLesson: PropTypes.shape().isRequired,
  lastLesson: PropTypes.shape(),
  nextProject: PropTypes.shape(),
};
export default SelfLecture;
