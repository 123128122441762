import React from 'react';
import profile from './professional-profile.json';

export default (ProfessionalProfile) => function ProfessionalProfileHoc() {
  return (
    <ProfessionalProfile
      profile={profile}
    />
  );
};
