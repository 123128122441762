/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Footer } from '../../Common/Footer';
import Recovery from '../../Components/Recovery';
import checkBrowser from '../../utils/checkBrowser';

const RecoverPassword = (props) => (
  <>
    <Recovery {...props} />
    <Footer />
  </>
);

export default checkBrowser(RecoverPassword);
