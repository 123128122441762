export default {
    title: 'Ejercicio 14',
    description: `
    Crea una función llamada <code>whichQuadrant(x,y)</code> 
    que reciba la coordenada de un punto en el plano cartesiano; es decir, recibe dos valores enteros x e y.
    Evalúe en qué cuadrante se ubica dicho punto.
    <ul>
    <li>Retorne <code>Cuadrante I  </code> X > 0 , Y > 0</li> 
    <li>Retorne <code>Cuadrante II </code> X < 0 , Y > 0</li> 
    <li>Retorne <code>Cuadrante III</code> X < 0 , Y < 0</li> 
    <li>Retorne <code>Cuadrante IV </code> X > 0 , Y < 0</li>
    </ul>
    `,
    seed: '// Escribe tu código aquí',
    test: [
      `test('Probar si 2, 3', function (t) {
        t.equal(whichQuadrant(2, 3), 'Cuadrante I','Esto debe retornar Cuadrante I');
        t.end()
      });`,
      `test('Probar si -2, 3', function (t) {
        t.equal(whichQuadrant(-2, 3), 'Cuadrante II','Esto debe retornar Cuadrante II');
        t.end()
      });`,
      `test('Probar si -2, -3', function (t) {
        t.equal(whichQuadrant(-2, -3), 'Cuadrante III','Esto debe retornar Cuadrante III');
        t.end()
      });`,
      `test('Probar si 2, -3', function (t) {
        t.equal(whichQuadrant(2, -3), 'Cuadrante IV','Esto debe retornar Cuadrante IV');
        t.end()
      });`,
    ]
  };
