/* eslint-disable react/destructuring-assignment */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import api from './api';
import Mixpanel from '../utils/Mixpanel';
// import { CONTROL_GROUP } from '../utils/const';

const AuthContext = React.createContext(undefined);

const AuthProvider = withRouter(({ children, history, setRole }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState('');
  const [recoverSuccess, setRecoverSuccess] = useState(false);

  const onLogin = async (email, password) => {
    setIsFetching(true);
    setIsError('');

    const currentLogin = (response) => {
      localStorage.setItem('TOKEN', response.token);
      localStorage.setItem('ROLE', response.role);
      setRole(response.role);
      // eslint-disable-next-line camelcase
      Mixpanel.track('CampusAuthContext-LoginSuccessfull');
      setTimeout(() => {
        history.push(`/${response.uri}/course`);
        // if (CONTROL_GROUP.indexOf(response.batch) !== -1) {
        //   history.push(`/${response.uri}/course`);
        // } else {
        //   history.push(`/${response.uri}/profile`);
        // }
        // window.location.reload();
      }, [700]);
    };
    try {
      const response = await api.Auth.login(email, password); 
      currentLogin(response);
    } catch (error) {
      switch (error.response.status) {
        case 500:
          setIsError('Ocurrió un error en el servidor');
          break;
        case 400:
          setIsError('Contraseña incorrecta.');
          break;
        default:
          setIsError(error.response.data.message);
          break;
      }
    }
    setIsFetching(false);
  };

  const onRegister = async (email, password, confirmPassword, handleAuthOption) => {
    setIsFetching(true);
    setIsError('');
    if (password === confirmPassword) {
      try {
        await api.Auth.register(email, password);
        handleAuthOption('login');
      } catch (error) {
        if (error.response) {
          setIsError('No pudimos completar tu registro, asegúrate de ingresar el correo que proporcionaste al momento de tu inscripción. Si el error persiste, envíanos un mensaje dentro del bot');
          setIsFetching(false);
        } else if (error.request) {
          setIsError('Petición bloqueada por CORS.');
          setIsFetching(false);
        } else if (error.status) {
          setIsError(`Error ${error.message}`);
          setIsFetching(false);
        } else {
          setIsError(`Error ${error.message}`);
          setIsFetching(false);
        }
      }
      setIsFetching(false);
    } else {
      setIsError('Asegúrate de que las contraseñas coincidan.');
      setIsFetching(false);
    }
  };

  const onRecover = async (recoverEmail) => {
    setIsFetching(true);
    setIsError('');
    try {
      await api.Auth.recover(recoverEmail);
      setRecoverSuccess(true);
    } catch (error) {
      switch (error.response.status) {
        case 500:
          setIsError('Ocurrió un error en el servidor');
          setIsFetching(false);
          break;
        default:
          setIsError(error.response.data.message);
          setIsFetching(false);
          break;
      }
    }
    setIsFetching(false);
  };

  const onSetNewPassword = async (newPassword, confirmPassword, code) => {
    setIsFetching(true);
    setIsError('');
    if (newPassword === confirmPassword) {
      try {
        await api.Auth.setNewPassword(newPassword, code);
        history.push('/');
      } catch (error) {
        if (error.response) {
          setIsError('No se encontró el código de recuperación o ha caducado.');
          setIsFetching(false);
        } else if (error.request) {
          setIsError('Petición bloqueada por CORS.');
          setIsFetching(false);
        } else {
          setIsError(`Error ${error.message}`);
          setIsFetching(false);
        }
      }
    } else {
      setIsError('Asegúrate de que las contraseñas coincidan.');
      setIsFetching(false);
    }
  };

  const state = [
    {
      isFetching, isError, recoverSuccess
    },
    {
      onLogin, onRegister, onRecover, onSetNewPassword
    }
  ];

  return (
    <AuthContext.Provider value={state}>
      {children}
    </AuthContext.Provider>
  );
});

AuthProvider.propTypes = {
  children: PropTypes.shape().isRequired
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth can only be used inside AuthProvider');
  }
  return context;
};

export {
  AuthProvider,
  useAuth
};
