export default {
  title: 'Ejercicio 24',
  description: `
  Crea una función llamada <code>farenheitToCelsius()</code> que reciba 
  como parametro los grados farenheit y los convierta a grados centígrados.
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba 25 grados', function (t) {
      t.equal(farenheitToCelsius(77), 25, "Conversión correcta");
      t.end()
    });`,
    `test('Prueba 40 grados', function (t) {
      t.equal(farenheitToCelsius(104), 40, "Conversión correcta");
      t.end()
    });`,
    `test('Prueba 36 grados', function (t) {
      t.equal(farenheitToCelsius(96.8), 36, "Conversión correcta");
      t.end()
    });`
  ]
};
