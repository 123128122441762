import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import api from './api';

const ModulesContext = React.createContext(undefined);

const ModulesProvider = ({ children }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [module, setModule] = useState(null);
  const [selfPaced, setSelfPaced] = useState(null);

  const [projectFetching, setProjectFetching] = useState(false);
  const [errorProject, setErrorProject] = useState(false);
  const [project, setProject] = useState();

  const getCompleteModule = async (slugModule) => {
    if (module?.slug === slugModule) return;
    setIsFetching(true);
    setIsError(false);
    try {
      const response = await api.Lectures.getLectureByModules(slugModule);
      setModule(response);
    } catch (error) {
      switch (error.response.status) {
        case 500:
          setIsError('Ocurrió un error en el servidor');
          break;
        default:
          setIsError(error.response.data.message);
          break;
      }
    } finally {
      setIsFetching(false);
    }
  };

  const getCompleteModuleSelfPaced = async (slugModule) => {
    if (module?.slug === slugModule) return;
    setIsFetching(true);
    setIsError(false);
    try {
      const response = await api.Lectures.getLectureByModulesSelfPaced(slugModule);
      setSelfPaced(response);
    } catch (error) {
      switch (error.response.status) {
        case 500:
          setIsError('Ocurrió un error en el servidor');
          break;
        default:
          setIsError(error.response.data.message);
          break;
      }
    } finally {
      setIsFetching(false);
    }
  };

  const sendAlumniProyect = async (idModule, idProject, body, cb) => {
    try {
      setProjectFetching(true);
      const newProject = await api.Project.sendAlumniProyect(idModule, idProject, body);
      setProject(newProject);
      cb(null, newProject);
    } catch (error) {
        setErrorProject(error);
        cb(error, null);
    } finally {
      setProjectFetching(false);
    }
  };

  const sendAlumniProyectSelfPaced = async (idModule, idProject, topic, body, cb) => {
    try {
      setProjectFetching(true);
      // eslint-disable-next-line max-len
      const newProject = await api.Project.sendAlumniProyectSelfPaced(idModule, idProject, topic, body);
      setProject(newProject);
      cb(null, newProject);
    } catch (error) {
        setErrorProject(error);
        cb(error, null);
    } finally {
      setProjectFetching(false);
    }
  };
  
  const state = [
    {
      isFetching,
      isError,
      module,
      selfPaced,
      projectFetching,
      errorProject,
      project,
    }, 
    { 
      getCompleteModule, 
      sendAlumniProyect, 
      getCompleteModuleSelfPaced, 
      sendAlumniProyectSelfPaced 
    }

  ];
  return (
    <ModulesContext.Provider value={state}>
      {children}
    </ModulesContext.Provider>
  );
};

ModulesProvider.propTypes = {
  children: PropTypes.shape(),
};

ModulesProvider.defaultProps = {
  children: [],
};

const useModules = () => {
  const context = useContext(ModulesContext);
  if (context === undefined) {
    throw new Error('useModules can only be used inside ModulesContext');
  }
  return context;
};

export {
  ModulesProvider,
  useModules
};
