/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import List from './List';
import Embed from './Embed';
import Code from './Code';
import ImageEditor from './ImageEditor';
import Paragraph from './Paragraph';
import ReadingProgressBar from '../ReadingProgressBar/ReadingProgressBar';
import { LECTURE_FINISHED, OPEN_LECTURE, READ_MORE_CLICKED } from '../../utils/tracks';
import './readOnly.scss';

const ReadOnly = ({
  data, track, program, module, type
}) => {
  const [scroll, setScroll] = useState();
  const [lectureContainer] = useState(React.createRef());
  const mountedRef = useRef(true);
  
  const getDocHeight = () => {
    if (lectureContainer.current) {
      const containerOffsetHeight = lectureContainer.current.offsetHeight;
      return containerOffsetHeight;
    }
    return null;
  };

  let finishedScroll = false;
  const calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset;
    const winHeight = window.innerHeight;
    const docHeight = getDocHeight();
    const totalDocScrollLength = docHeight - winHeight;
    const scrollPosition = Math.floor((scrollTop / totalDocScrollLength) * 100);
    setScroll(scrollPosition);
    if (localStorage.getItem('ROLE') === 'ALUMNI') {
      if (scrollPosition >= 100 && !finishedScroll) {
        track(LECTURE_FINISHED, { event_type: 'lecture_finished', lecture_id: data._id, program });
        finishedScroll = true;
      }
    }
  };

  const listenToScrollEvent = () => {
    document.addEventListener('scroll', () => {
      requestAnimationFrame(() => {
        calculateScrollDistance();
      });
    });
  };

  useEffect(() => {
    track(OPEN_LECTURE, { program, lecture_id: data._id, module });
    listenToScrollEvent();
    return () => {
      setScroll(); 
    };
  }, []);

  const renderBlock = (block, i) => {
    switch (block.type) {
      case 'header':
        return <Header data={block.data} key={i} />;
      case 'list':
        return <List data={block.data} key={i} />;
      case 'embed':
        return (
          <Embed
            data={block.data}
            lectureId={data._id}
            lectureProgram={program}
            key={i}
            track={track}
            type={type}
          />
        );
      case 'code':
        return <Code data={block.data} key={i} />;
      case 'image':
        return <ImageEditor data={block.data} key={i} />;
      default:
        return (
          <Paragraph
            data={block.data}
            lectureId={data._id}
            lectureProgram={program}
            key={i}
            track={track}
          />
        );
    }
  };

  const handleClick = (readmoreLink) => {
      track(READ_MORE_CLICKED,
        { url_readmore: readmoreLink, lecture_id: data._id, program, });
  };

  const renderGraphData = (og) => (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a data-testid="a-readonly-opengraph_link" className="readOnly__link col-11 col-md-5 bg__gray3 d-flex align-items-center mt-5" href={og.ogUrl} target="_blank" onClick={() => handleClick(og.ogUrl)}>
      <div className="row">
        <div className="col-4 px-0">
          <img className={og.ogImage && 'readOnly__link-img'} src={og.ogImage ? og.ogImage.url : 'https://devf-website.s3.amazonaws.com/static/assets/img/logo-devf-white.svg'} alt="Imagen" style={{ height: '100%' }} />
        </div>
        <div className="col-8 d-flex flex-column justify-content-between">
          <h6 className="text__white ">{og.ogTitle}</h6>
          <caption className="text__gray6 mb-2" style={{ textAlign: 'start' }}>{og.ogDescription ? `${og.ogDescription.slice(0, 50)}...` : ''}</caption>
          <small className="text__brand-pluto mb-2">{og.ogUrl.slice(0, 40)}</small>
        </div>
      </div>
    </a>
    );

  useEffect(() => () => {
    mountedRef.current = false;
  }, []);

  return (
    <div ref={lectureContainer} data-testid="div-readonly-container">
      <>
        <ReadingProgressBar scroll={`${scroll}%`} track={track} data-testid="div-readonlyprogressbar" />
        {data.blocks.map((block, i) => renderBlock(block, i))}
        {/* <h4 className="text__white">Quiero saber más.</h4> */}
        <div className="row d-flex justify-content-around">
          {
            data.openGraph 
            && data.openGraph.length > 0
            && data.openGraph.map((og) => renderGraphData(og))
          }
        </div>
      </>
    </div>
  );
};

ReadOnly.propTypes = {
  module: PropTypes.string.isRequired,
  activeProgram: PropTypes.shape({
    infoProgram: PropTypes.shape({
      lectures: PropTypes.arrayOf(PropTypes.shape()),
    }),
  }).isRequired,
  data: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    blocks: PropTypes.arrayOf(PropTypes.shape()),
    reading_time: PropTypes.number.isRequired,
    openGraph: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }),
  track: PropTypes.func.isRequired,
  program: PropTypes.string,
  type: PropTypes.string,
};

ReadOnly.defaultProps = {
  data: {
    _id: '',
    blocks: [],
    reading_time: 0,
  },
  program: '',
  type: '',
};

export default ReadOnly;
