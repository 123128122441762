/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  User,
  Unlock,
  Inbox,
  // Sliders,
  ChevronRight,
} from 'react-feather';
import { Spinner } from '../Spinner';
import detectImage from '../../utils/detectImage';
import './sidebarProfile.scss';

const SidebarProfile = ({ profile }) => {
  const {
    profile_pic, first_name, last_name, cv_url, github, linkedin
  } = profile;
  return (
    <aside className="m-sidebarProfile campus-container text__white pr-4">
      <div className="row align-items-center mb-4">
        <div className="col-4">
          {profile_pic ? <img src={detectImage(profile_pic) ? profile_pic : `data:image/png;base64, ${profile_pic}`} style={{ width: 'inherit' }} alt="Imagen de perfil" /> : <Spinner />}
        </div>
        <div className="col-8">
          <h6>{`${first_name} ${last_name}`}</h6>
          {/* <p>alumna desde marzo 2021</p> */}
        </div>
      </div>
      <>
        {!cv_url && !github && !linkedin
          && (
            <div className="m-info-complete">
              <h6>Completa tu información</h6>
              <p>Complementa tu información para personalizar tu experiencia en dev.f</p>
            </div>
          )}
      </>
      <NavLink to="/me" className="m-sidebarProfile__card row col-12 align-items-center" activeClassName="--active">
        <div className="col-2 py-3">
          <User />
        </div>
        <div className="col-9">
          <p className="my-0">Información básica</p>
        </div>
        <div className="col-1">
          <ChevronRight />
        </div>
      </NavLink>
      <NavLink to="/account" className="m-sidebarProfile__card row col-12 align-items-center" activeClassName="--active">
        <div className="col-2 py-3">
          <Unlock />
        </div>
        <div className="col-9">
          <p className="my-0">Cuenta y seguridad</p>
        </div>
        <div className="col-1">
          <ChevronRight />
        </div>
      </NavLink>
      <a href={`https://devf.typeform.com/to/SmyxurE2?email=${profile.email}`} target="_blank" rel="noreferrer" className="m-sidebarProfile__card row col-12 align-items-center" activeClassName="--active">
        <>
          {/* <NavLink to="/invoice" className="m-sidebarProfile__card row
          // col-12 align-items-center" activeClassName="--active"> */}
          <div className="col-2 py-3">
            <Inbox />
          </div>
          <div className="col-9">
            <p className="my-0">Datos de facturación (Sólo México)</p>
          </div>
          <div className="col-1">
            <ChevronRight />
          </div>
          {/* </NavLink> */}
        </>
      </a>
      {/* <NavLink 
        to="/" 
        className="m-sidebarProfile__card row col-12 align-items-center" 
        activeClassName="--active">
        <div className="col-2 py-3">
          <Sliders />
        </div>
        <div className="col-9">
          <p className="my-0">Control escolar</p>
          <small>Administra tus becas, suspensiones, bajas y más servicios</small>
        </div>
        <div className="col-1">
          <ChevronRight />
        </div>
      </NavLink> */}
    </aside>
  );
};

SidebarProfile.propTypes = {
  profile: PropTypes.shape().isRequired
};

export default SidebarProfile;
