/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-danger */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { devfApi } from '../../utils/http';
import Mixpanel from '../../utils/Mixpanel';
import { TestRunner } from '../../Components/TestRunner';
import { programs } from '../../utils/programs';
import exercises from '../../exercises';
import Editor from '../../Components/Editor/Editor';
import KataCard from '../../Components/KataCard/KataCard';
import './workspace.scss';

class Workspace extends Component {
  constructor(props) {
    super(props);
    const { program, id, activeProgram } = props;
    this.state = {
      code: exercises[program][id].seed,
      test: exercises[program][id].test,
      descritpion: exercises[program][id].description,
      title: exercises[program][id].title,
      id,
      activeProgram,
      cinta: program,
      saveMsg: '',
      saveClases: '',
      assignment_id: null,
      correct: false,
    };
    this.testRun = React.createRef();
    this.EditorWork = React.createRef();
  }

  createMarkup = () => ({ __html: this.state.description });

  retrieveCode = (cinta, id) => devfApi.get(`/api/v1/campus/assignment/${cinta}/${id}`)

  async componentDidMount() {
    const { program, id } = this.props;
    Mixpanel.track('CampusWorkspace-OpenKata');
    const resp = await this.retrieveCode(program, id);
    if (resp) {
      const { code, _id } = resp.data;
      if (resp.status === 200) {
        this.setState({
          code,
          assignment_id: _id,
        });
      }
      this.testRun?.current?.setSavedTest(resp.data);
      if (this.testRun?.current?.state.message === 'Correcto') {
        this.setState({ correct: true });
      } else {
        this.setState({ correct: false });
      }
    }
  }

  async componentDidUpdate(prevProps) {
    const { program, id, timestamp } = this.props;

    if (prevProps.timestamp !== timestamp) {
      this.testRun.current.clearOutput();
      this.retrieveCode(program, id).then((resp) => {
        const { code, _id } = resp.data;
        if (resp.status === 200) {
          this.setState({
            code,
            assignment_id: _id,
            test: exercises[program][id].test,
            descritpion: exercises[program][id].description,
            title: exercises[program][id].title,
            id,
            cinta: program,
          });
          this.testRun.current.setSavedTest(resp.data);
        } else {
          this.setState({
            code: exercises[program][id].seed,
            test: exercises[program][id].test,
            assignment_id: null,
            descritpion: exercises[program][id].description,
            title: exercises[program][id].title,
            id,
            cinta: program
          });
        }
        if (this.testRun.current.state.message === 'Correcto') {
          this.setState({ correct: true });
        } else {
          this.setState({ correct: false });
        }
      }).catch(() => {
        this.setState({
          code: exercises[program][id].seed,
          test: exercises[program][id].test,
          assignment_id: null,
          descritpion: exercises[program][id].description,
          title: exercises[program][id].title,
          id,
          cinta: program
        });
        if (this.testRun.current.state.message === 'Correcto') {
          this.setState({ correct: true });
        } else {
          this.setState({ correct: false });
        }
      });
    }
  }

  trackSuccess = (id) => {
    const { cinta } = this.state;
    devfApi.post('/api/v1/campus/tracking', {
      event_type: 'success_kata',
      kata_id: id,
      kata_program: cinta,
      program: this.state.cinta,
    });
    this.setState({ correct: true });
  }

  catchData = async (e) => {
    const { cinta, id } = this.state;
    devfApi.post('/api/v1/campus/tracking', {
      event_type: 'run_kata',
      kata_id: id,
      program: this.state.cinta,
    });
    const code = this.EditorWork.current.getValue();
    const save = e.target.id === 'save';
    this.testRun.current.runTest(code, save, cinta, id);
    this.setState({ code });
    Mixpanel.track('CampusWorkspace-TestKata');
    ReactGA.event({
      category: 'Katas',
      action: 'Resolve Kata',
      label: `Kata: ${this.state.title}`
    });
    // this.saveAssigment();
  }

  buildAssigment = (test) => {
    const assingment = {
      assignment_id: test.id,
      program: this.state.cinta,
      code: test.code,
      results: {
        count: test.results.count,
        fail: test.results.fail,
        pass: test.results.pass
      },
      tests: test.tests.map((currentTest) => ({ ok: currentTest._ok, name: currentTest.name })),
      tap: test.tap
    };
    this.saveAssigment(assingment);
  }

  quitMessage = (vm) => {
    setTimeout(() => {
      vm.setState({
        saveMsg: ''
      });
    }, 3000);
  }

  sendAssigment = (testData) => devfApi.post('/api/v1/campus/assignment', testData);

  updateAssigment = (testData) => devfApi.patch('/api/v1/campus/assignment', testData);

  showMessage = (error) => {
    this.setState({
      saveMsg: (error) ? 'Guardado' : 'Ocurrió un problema al guardar',
      saveClases: (error) ? 'bg__success text__white p-3' : 'bg__error text__white p-3'
    });
    this.quitMessage(this);
  }

  saveAssigment = async (testData) => {
    // const { id } = this.props;
    // devfApi.post('/api/v1/campus/tracking', {
    //   event_type: 'save_kata',
    //   kata_id: id,
    //   program: this.state.cinta,
    // });
    const { assignment_id } = this.state;
    const assigment = (assignment_id)
      ? await this.updateAssigment(testData)
      : await this.sendAssigment(testData);
    if (assigment.status === 200 || assigment.status === 201) {
      this.showMessage(true);
    } else {
      this.showMessage(false);
    }
  }

  reset = () => {
    const { id } = this.props;
    devfApi.post('/api/v1/campus/tracking', {
      event_type: 'reset_kata',
      kata_id: id,
      program: this.state.cinta,
    });
    this.EditorWork.current.setValue();
    this.testRun.current.clearOutput();
  }

  render() {
    const {
      descritpion, code, test, title, saveMsg, id, saveClases, activeProgram
    } = this.state;
    return (
      <section className="row bg__gray2">
        <div className="col-lg-3 bg__gray2">
          <KataCard program={programs[activeProgram] || {
            name: 'Coding', slug: 'code', color: '#1D1033', fontColor: '#FFFFFF', acknowledgment: 'Por definir'
          }}
          />
        </div>
        <div className="col-lg-9 bg__gray3">
          <div className="col-12">
            <h4 className="text__white" style={{ marginBottom: 'auto', marginTop: `${1}rem` }}>{title}</h4>
            <div className="m_campus-card bg__gray6" style={{ height: 'auto' }}>
              <div className="p-3" dangerouslySetInnerHTML={{ __html: descritpion }} />
            </div>
          </div>
          <div className="col-12">
            <div className={(saveMsg === '') ? 'd-none' : 'd-inline'}>
              <p className={saveClases} style={{ margin: 0 }}>
                {saveMsg}
              </p>
            </div>
            <Editor
              code={code}
              height="350"
              ref={this.EditorWork}
            />
            <div className="Workspace--controls">
              <button type="button" style={{ width: '100%', height: '3rem' }} className="btn__tool" onClick={this.catchData}>Probar mi código</button>
            </div>
            <TestRunner
              success={this.trackSuccess}
              id={id}
              code={code}
              test={test}
              ref={this.testRun}
              buildAssigment={this.buildAssigment}
            />
          </div>
        </div>
      </section>
    );
  }
}

Workspace.propTypes = {
  timestamp: PropTypes.string.isRequired,
  program: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  activeProgram: PropTypes.string.isRequired
};

export default Workspace;
