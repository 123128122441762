/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import isAuthenticated from '../utils/isAuthenticated';
import { SenseiProvider } from '../Context/SenseiContext';
import { LecturesProvider } from '../Context/LecturesContext';
import { SenseiLectureProvider } from '../Context/SenseiLectureContext';
import { SenseiAdminModuleProvider } from '../Context/SenseiAdminModuleContext';
import SenseiAdminModule from '../views/Sensei/SenseiAdminModule';
import ProfileSensei from '../views/Sensei/Profile';
import CreateLecture from '../views/Sensei/CreateLecture';
import VideosOnboarding from '../views/Sensei/VideoOnboarding';
import Lectures from '../views/Sensei/Lectures';
import EditLecture from '../views/Sensei/EditLecture';
import SenseiNavbar from '../Common/SenseiNavbar';
import Sidebar from '../views/Sensei/Sidebar';
import { VideoProvider } from '../Context/VideoContext';
import { ModulesProvider } from '../Context/ModuleContext';

const MyCourse = React.lazy(() => import('../views/MyCourse/sensei'));
const AlumniProfile = React.lazy(() => import('../views/Sensei/AumniProfile'));

const PrivateSenseiRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) => (
        isAuthenticated() && localStorage.getItem('ROLE') === 'SENSEI_MASTER' ? <Component {...props} /> : <Redirect to="/" />
      )
    }
  />
);

PrivateSenseiRoute.defaultProps = {
  component: React.createElement('div')
};

PrivateSenseiRoute.propTypes = {
  component: PropTypes.element
};

function SenseiRoute() {
  return (
    <SenseiProvider>
      <SenseiNavbar />
      <main style={{ paddingTop: '64px' }}>
        <div className="row" style={{ margin: 'auto' }}>
          <Switch>
            <Suspense fallback={<div>Loading...</div>}>
              <PrivateSenseiRoute
                path="/sensei/course"
                key={0}
              >
                <ModulesProvider>
                  <VideoProvider>
                    <MyCourse />
                  </VideoProvider>
                </ModulesProvider>
              </PrivateSenseiRoute>
              <PrivateSenseiRoute exact path="/sensei/profile" key={1}>
                <>
                  <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                    <Sidebar />
                  </div>
                  <div id="o-sensei__content" className="col-lg-10 col-12 d-flex p-0">
                    <ProfileSensei />
                  </div>
                </>
              </PrivateSenseiRoute>
              <PrivateSenseiRoute exact path="/sensei/alumni/profile/:alumniId/:idCampusProgram" key={2}>
                <AlumniProfile />
              </PrivateSenseiRoute>
              <PrivateSenseiRoute exact path="/sensei/create/lecture" key={3}>
                {localStorage.getItem('ROLE') === 'SENSEI_MASTER' && ( 
                  <>
                    <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                      <Sidebar />
                    </div>
                    <div id="o-sensei__content" className="col-lg-10 col-12 d-flex p-0">
                      <LecturesProvider>
                        <CreateLecture />
                      </LecturesProvider>
                    </div>
                  </>
                )}
              </PrivateSenseiRoute>
              <PrivateSenseiRoute exact path="/sensei/onboarding" key={4}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <div id="o-sensei__content" className="col-lg-10 col-12 d-flex p-0">
                  <VideoProvider>
                    <VideosOnboarding />
                  </VideoProvider>
                </div>
              </PrivateSenseiRoute>
              <PrivateSenseiRoute exact path="/sensei/lecture/:lecture" key={5}>
                {localStorage.getItem('ROLE') === 'SENSEI_MASTER' && (
                <>
                  <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                    <Sidebar />
                  </div>
                  <div id="o-sensei__content" className="col-lg-10 col-12 d-flex p-0">
                    <SenseiLectureProvider>
                      <EditLecture />
                    </SenseiLectureProvider>
                  </div>
                </>
                  )}
              </PrivateSenseiRoute>
              <PrivateSenseiRoute exact path="/sensei/lectures/:module/:lecture" key={6}>
                <ModulesProvider>
                  <LecturesProvider>
                    <Lectures />
                  </LecturesProvider>
                </ModulesProvider>
              </PrivateSenseiRoute>
              <PrivateSenseiRoute exact path="/sensei/modules" key={7}>
                {localStorage.getItem('ROLE') === 'SENSEI_MASTER' && (
                <>
                  <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                    <Sidebar />
                  </div>
                  <div id="o-sensei__content" className="col-lg-10 col-12 d-flex p-0">
                    <SenseiAdminModuleProvider>
                      <SenseiAdminModule />
                    </SenseiAdminModuleProvider>
                  </div>
                </>
                  )}
              </PrivateSenseiRoute>
              <PrivateSenseiRoute exact path="/sensei/alumni/profile/self/:alumniId/:idCampusProgram" key={8}>
                <AlumniProfile />
              </PrivateSenseiRoute>
            </Suspense>
          </Switch>
        </div>
      </main>
    </SenseiProvider>
  );
}

export default SenseiRoute;
