import React from 'react';
import PropTypes from 'prop-types';
import { PLAY_VIDEO } from '../../utils/tracks';
import './video.scss';

const Video = ({
  idVideo, title, track, slugProgram, bgVideo, setBgVideo, hash
}) => {
  const playVideo = () => {
    setBgVideo(false);
    track(PLAY_VIDEO,
      { event_type: 'play_video', video_id: idVideo, program: slugProgram });
  };
  return (
    <div className="col-12">
      <h5 className="mb-2">{title}</h5>
      {bgVideo ? (
        <div className="stopVideo">
          <button
            data-testid="buton-video-play"
            type="button"
            className="btn__tool"
            onClick={playVideo}
            width="500"
            height="400"
          >
            Reproducir video
          </button>
        </div>
      )
        : <iframe className="embed-video" title="video" src={`https://player.vimeo.com/video/${idVideo}/${hash ? `?h=${hash}` : ''}`} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />}
    </div>
  );
};

Video.propTypes = {
  idVideo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  track: PropTypes.func.isRequired,
  slugProgram: PropTypes.string.isRequired,
  setBgVideo: PropTypes.func.isRequired,
  bgVideo: PropTypes.bool.isRequired,
  hash: PropTypes.string.isRequired
};

export default Video;
