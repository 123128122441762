import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const RemoteGroup = ({
 groupDetail, senseiModule, registerClass, joinUrl 
}) => {
  const newWindow = useRef(window);

  const renderButton = () => {
    if (joinUrl) {
      return (
        <a
          data-testid="link-open-zoom-campus"
          className="btn__ghost-alt p-3"
          style={{ marginLeft: 0 }}
          href={joinUrl}
          target="__blank"
        >
          IR A MI CLASE
        </a>
      );
    }
    if (senseiModule.meet_link) {
      return (
        <button
          type="button"
          data-testid="link-open-zoom-app-campus"
          className="btn__ghost-alt p-3"
          style={{ marginLeft: 0 }}
          onClick={() => registerClass(senseiModule, (err, link) => {
          if (!err) newWindow.current.open(link);
          // eslint-disable-next-line no-alert
          else alert('Parece que no tienes acceso a tu clase');
        })}
        >
          IR A MI CLASE
        </button>
      );
    }
    return null;
  };

  return (
    <section className="container">
      {(groupDetail && (groupDetail.name || groupDetail.group_name)) && (
      <>
        <div className="m_campus-program-card bg__gray3">
          <h6 className="text__white">{groupDetail.name || groupDetail.group_name}</h6>
          <h6 className="text__white">
            Sensei:
            {' '}
            {senseiModule.meet_link ? `${senseiModule.sensei.first_name} ${senseiModule.sensei.last_name}` : groupDetail.sensei_detail?.name }
          </h6>
          {groupDetail.id_zoom && (
          <Link
            data-testid="link-open-zoom-campus"
            className="btn__ghost-alt p-3"
            to="/alumni/zoom"
            style={{ marginLeft: 0 }}
          >
            IR A MI CLASE
          </Link>
      )}
          {renderButton()}
          <p className="text__white">
            <strong>Descarga </strong>
            la aplicación
            <a className="text__hackeranda" href="https://zoom.us/download"> Zoom</a>
            , aquí es donde tomarás tus clases en vivo.
          </p>
        </div>
        <hr />
        {/* <div className="m_campus-program-card bg__gray4">
          <h5 className="text__white">
            Gracias por hackear la educación con DEV.F estudiando
            <strong className="text__hackeranda"> #DesdeCasa </strong>
            <span role="img" aria-label="World">🌎</span>
          </h5>
          <p className="text__white">
            <strong>Descarga </strong>
            la aplicación
            <a className="text__hackeranda" href="https://zoom.us/download"> Zoom</a>
            , aquí es donde tomarás tus clases en vivo.
          </p>
        </div> */}
        <div className="m_campus-program-card bg__gray4">
          <h5 className="text__white">
            ¿Ya hiciste este test de personalidad?
          </h5>
          <p className="text__white">
            Nos encantaría conocer tu perfil de personalidad para seguir
            mejorando tu experiencia en DEV.F
          </p>
          <p className="text__white">
            Solo tienes que
            <a className="text__hackeranda" href="https://www.16personalities.com/es"> hacer este test </a>
            . Después ve a tu
            <strong> perfil </strong>
            y cuéntanos tu resultado.
          </p>
          <p className="text__white">
            <strong>¡Gracias por contribuir a que sigamos mejorando!</strong>
          </p>
        </div>
      </>
  )}
    </section>
); 
};

RemoteGroup.defaultProps = {
  senseiModule: {
    sensei: {}
  },
  groupDetail: {},
  joinUrl: '',
};

RemoteGroup.propTypes = {
  registerClass: PropTypes.func.isRequired,
  senseiModule: PropTypes.shape({
    meet_link: PropTypes.string,
    sensei: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  }), 
  joinUrl: PropTypes.string,
  groupDetail: PropTypes.shape({
    name: PropTypes.string,
    group_name: PropTypes.string,
    time_zone: PropTypes.string,
    meeting_url: PropTypes.string,
    id_zoom: PropTypes.string,
    sensei_detail: PropTypes.shape({
      name: PropTypes.string
    }),
  }),
};
export default RemoteGroup;
