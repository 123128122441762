export default {
  title: 'Ejercicio 40',
  description: `
  Haz una clase llamada <code>Person</code> que siga las siguientes condiciones:
  <ol>
    <li>Sus atributos son: nombre, edad, RFC, sexo, peso y altura.</li>
    <li>Creá método llamado <code>calcularIMC()</code>  (indice de masa corporal)  </li>
    <li>Crear un método llamado <code>esMayorDeEdad()</code> y regrese  un booleano </li>
    <li>El constructor pide nombre, edad,sexo,peso y altura</li>
    <li>Generar el RFC a partir de el nombre, edad y sexo con <code>obtenerRFC()</code> </li>
  </ol>

  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba 1', function (t) {
      const prueba =  new Person("Mauricio", 30, "H", 73.5, 1.75);
      t.equal(prueba.esMayorDeEdad(),true,"Es mayor de edad");
      t.end()
    });`,
    `test('prueba 2', function (t) {
      const prueba =  new Person("Mauricio", 30, "H", 73.5, 1.75);
      t.equal(prueba.obtenerRFC().length,10,"RFC correcto");
      t.end()
    });`,
    `test('prueba 3', function (t) {
      const prueba =  new Person("Mauricio", 30, "H", 73.5, 1.75);
      t.equal(prueba.calcularIMC(), 24,"IMC correcto ");
      t.end()
    });`
  ]
};
