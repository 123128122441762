/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense, useState } from 'react';
import {
  Redirect, Route, Switch
} from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Navbar from '../Common/Navbar';
// import NavbarMobile from '../Common/NavbarMobile';
import EmbedMd from '../views/EmbedMd';
import Career from '../views/Career';
import Lectures from '../views/Lectures';
import SelfLectures from '../views/SelfLectures';
import Profile from '../views/Profile';
// import MyCourse from '../views/MyCourse';
// import MasterPayment from '../views/MasterPayment';
import Sidebar from '../Common/Sidebar';
import Videos from '../views/Videos';
import Workspace from '../views/Workspace';
import Careers from '../views/Careers/private';
import RateProject from '../views/RateProject';
import isAuthenticated from '../utils/isAuthenticated';
import Me from '../views/Me';
import Projects from '../views/Projects';
import { MeProvider } from '../Context/MeContext';
import { LecturesProvider } from '../Context/LecturesContext';
import { ModulesProvider } from '../Context/ModuleContext';
import { LectureProvider } from '../Context/LectureContext';
import { VideoProvider } from '../Context/VideoContext';
import { CareersProvider } from '../Context/CareersContext';
import { CareerProvider } from '../Context/CareerContext';
import { ProjectProvider } from '../Context/ProjectContext';
import { MasterPaymentInfoProvider } from '../Context/MasterPaymentInfoContext';
import { Modal } from '../Common/Modal';
import HelpModal from '../Components/HelpModal';
import ProfessionalProfile from '../views/ProfessionalProfile';
import SidebarProfile from '../Common/SidebarProfile';
import ScrollToTop from '../Common/ScrollToTop';

const Zoom = React.lazy(() => import('../views/Zoom'));
const MasterPayment = React.lazy(() => import('../views/MasterPayment'));
const MyCourse = React.lazy(() => import('../views/MyCourse'));

// eslint-disable-next-line react/prop-types
const Logout = () => {
  localStorage.clear();
  window.location.reload(false);
  return <Redirect to="/" />;
};
// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={
      (props) => (
        isAuthenticated() ? <Component {...props} /> : <Logout />
      )
    }
  />
);

function AlumniRoutes() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
  const [showHelpModal, setShowHelpModal] = useState(false);

  return (

    <MeProvider>
      <Navbar openHelpModal={() => setShowHelpModal(true)} />
      <Modal show={showHelpModal} closeModal={() => setShowHelpModal(false)} dismissible>
        <HelpModal closeModal={() => setShowHelpModal(false)} />
      </Modal>
      <main style={{ paddingTop: '64px' }}>
        <div className="row" style={{ margin: 'auto', minHeight: 'calc(100vh - 64px)' }}>
          <Switch>
            <Suspense fallback={<div>Loading...</div>}>
              <PrivateRoute path="/alumni/course" key={0}>
                <ScrollToTop>
                  <MyCourse />
                </ScrollToTop>
              </PrivateRoute>

              <PrivateRoute exact path="/me" key={1}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <div className="col-lg-3 col-12 bg__gray2">
                  <div className="campus-container">
                    <SidebarProfile />
                  </div>
                </div>
                <div className="col-lg-7 col-12 bg__gray2">
                  <div className="campus-container">
                    <Me />
                  </div>
                </div>
              </PrivateRoute>
              <PrivateRoute exact path="/me/:id" key={2}>
                <ProfessionalProfile />
              </PrivateRoute>
              <PrivateRoute exact path="/alumni/profile" key={3}>
                <>
                  <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                    <Sidebar />
                  </div>
                  <Profile />
                </>
              </PrivateRoute>
              <PrivateRoute exact path="/alumni/lectures/:module/:lecture" key={4}>
                <ModulesProvider>
                  <LecturesProvider>
                    <ScrollToTop>
                      <Lectures />
                    </ScrollToTop>
                  </LecturesProvider>
                </ModulesProvider>
              </PrivateRoute>
              <PrivateRoute exact path="/alumni/self/lectures/:module/:lecture" key={30}>
                <ModulesProvider>
                  <LecturesProvider>
                    <ScrollToTop>
                      <SelfLectures />
                    </ScrollToTop>
                  </LecturesProvider>
                </ModulesProvider>
              </PrivateRoute>
              <PrivateRoute exact path="/alumni/lecture/:lecture" key={5}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <LectureProvider>
                  <EmbedMd />
                </LectureProvider>
              </PrivateRoute>
              <PrivateRoute exact path="/alumni/videos" key={6}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <VideoProvider>
                  <Videos />
                </VideoProvider>
              </PrivateRoute>

              <PrivateRoute exact path="/logout" component={Logout} key={8} />
              <PrivateRoute exact path="/master/payments" key={9}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <Elements stripe={stripePromise}>
                  <MasterPaymentInfoProvider>
                    <MasterPayment />
                  </MasterPaymentInfoProvider>
                </Elements>
              </PrivateRoute>
              <PrivateRoute exact path="/career/:career" key={10}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <CareerProvider>
                  <Career />
                </CareerProvider>
              </PrivateRoute>
              <PrivateRoute exact path="/alumni/lecture/:lecture" key={11}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <LectureProvider>
                  <EmbedMd />
                </LectureProvider>
              </PrivateRoute>
              <PrivateRoute exact path="/alumni/videos" key={12}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <VideoProvider>
                  <Videos />
                </VideoProvider>
              </PrivateRoute>
              <PrivateRoute
                exact
                path="/exercises/js/:cinta/:id"
                key={13}
              >
                <div className="col-12">
                  <Workspace timestamp={new Date().toString()} />
                </div>
              </PrivateRoute>
              <PrivateRoute exact path="/logout" component={Logout} key={14} />
              <PrivateRoute exact path="/careers" key={16}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <CareersProvider>
                  <Careers />
                </CareersProvider>
                
              </PrivateRoute>
              <PrivateRoute exact path="/alumni/projects" key={17}>
                <ProjectProvider>
                  <Projects />
                </ProjectProvider>
              </PrivateRoute>
              <PrivateRoute exact path="/projects/save/:nameProject/:idProject" key={18}>
                <ProjectProvider>
                  <RateProject />
                </ProjectProvider>
              </PrivateRoute>
              <PrivateRoute exact path="/account" key={19}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <div className="col-lg-3 col-12 bg__gray2">
                  <div className="campus-container">
                    <SidebarProfile />
                  </div>
                </div>
                <div className="col-lg-7 col-12 bg__gray2">
                  <div className="campus-container">
                    <Me />
                  </div>
                </div>
              </PrivateRoute>
              <PrivateRoute exact path="/invoice" key={20}>
                <div className="col-lg-2 col-12 bg__gray2 d-flex justify-content-center" style={{ height: 'auto' }}>
                  <Sidebar />
                </div>
                <div className="col-lg-3 col-12 bg__gray2">
                  <div className="campus-container">
                    <SidebarProfile />
                  </div>
                </div>
                <div className="col-lg-7 col-12 bg__gray2">
                  <div className="campus-container">
                    <Me />
                  </div>
                </div>
              </PrivateRoute>
              <PrivateRoute exact path="/alumni/zoom">
                <Zoom />
              </PrivateRoute>
            </Suspense>
            <Route
              exact
              path="/logout"
              key={100}
            >
              <Logout />
            </Route>
          </Switch>
        </div>
      </main>
      {/* <NavbarMobile /> */}
    </MeProvider>
  );
}

export default AlumniRoutes;
