export default {
  title: 'Ejercicio 34',
  description: ` 
    Crea una función llamada <code>determine</code> que reciba como parámetro cualquier valor.
    La función debe retornar <code>"string"</code> <code>"boolean"</code> o <code>"number"</code>
    según el tipo de dato que reciba como parámetro.     
    `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba número', function (t) {
      t.equal(determine(19), "number", "Correcto");
      t.end()
    });`,
    `test('Prueba string', function (t) {
      t.equal(determine("Gerardo"), "string", "Correcto");
      t.end()
    });`,
    `test('Prueba boolean', function (t) {
      t.equal(determine(false), "boolean", "Correcto");
      t.end()
    });`
  ]
};
