/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';

const NotifictaionModal = ({
    notification, setModalNotification, params
}) => {
  const paramsLink = new URLSearchParams(params).toString();
  return (
    <>
      <h3>{notification.short_description}</h3>
      <p>{notification.description}</p>
      <br />
      {notification.link
      && (
        <a
          className="btn__tool"
          target="_blank"
          onClick={() => setModalNotification(false)}
          href={`${notification.link}?${paramsLink}&notification=${notification._id}`}
        >
          Ir a la encuesta
        </a>
      )}
    </>
); 
};

NotifictaionModal.propTypes = {
  setModalNotification: PropTypes.func.isRequired,
  notification: PropTypes.shape({
    _id: PropTypes.string,
    description: PropTypes.string,
    link: PropTypes.string,
    short_description: PropTypes.string
  }).isRequired,

  params: PropTypes.shape().isRequired,
};

export default NotifictaionModal;
