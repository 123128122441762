export default {
  title: 'Ejercicio 38',
  description: `
  Crea una clase llamada <code>Triangle</code> que tenga dos atributos  <code>baseline</code> 
  y <code>height</code>, además crear dos métodos que calculen el <code>perimeter()</code> 
  y el <code>surface()</code>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba base = 2 y altura = 4', function (t) {
      const prueba = new  Triangulo(2,4)
      t.equal(prueba.baseline, 2, "El valor del atributo coincide");
      t.end()
    });`,
    `test('Prueba base = 2 y altura = 4', function (t) {
      const prueba = new  Triangulo(2,4)
      t.equal(prueba.perimeter(), 8, "Perimetro correcto");
      t.end()
    });`,
    `test('Prueba base = 2 y altura = 4', function (t) {
      const prueba = new  Triangulo(2,4)
      t.equal(prueba.surface(), 4, "Área correcta");
      t.end()
    });`
  ]
};
