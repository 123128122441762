export default {

  title: 'Ejercicio 6',
  description: `
  Crear un ciclo <code>for</code> o <code>while</code> que almacene en un array llamado <strong>pares</strong> 
  los  numeros pares  que hay entre el 0 y el 100, empezando desde 0
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Empieza en 0', function (t) {
      t.equal(pares[0], 0 ,"debe empezar en 0");
      t.end()
    });`,
    `test('Numero de elementos', function (t) {
      t.equal(pares.length, 51 ,"debe haber 51 numeros pares");
      t.end()
    });`,
    `test('Mid test', function (t) {
      t.equal(pares[25], 50,"La mitad debe ser 25 ");
      t.end()
    });`,
  ]
};
