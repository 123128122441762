import { devfApi, hueApi } from '../../utils/http';

export const getAlumniForSensei = (batch, groupName, module) => new Promise((resolve, reject) => {
  hueApi.get(`/campus/sensei/alumnis?batch=${batch}&name=${groupName}&module=${module}`)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

// eslint-disable-next-line max-len
export const getAlumniForSenseiSelfpaced = (batch, groupName, module) => new Promise((resolve, reject) => {
  hueApi.get(`/campus/sensei/alumnis/selpaced?batch=${batch}&name=${groupName}&module=${module}`)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addRecommendation = (recommendation) => new Promise((resolve, reject) => {
  devfApi.post('/api/v1/sensei/add/recommendation', recommendation)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});
export const deactivateRecommendation = (recommendation) => new Promise((resolve, reject) => {
  devfApi.post('/api/v1/sensei/deactivate/recommendation', recommendation)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getSenseiProfile = () => new Promise((resolve, reject) => {
  devfApi.get('/api/v1/campus/sensei/profile/me')
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const createLecture = (lecture) => new Promise((resolve, reject) => {
  devfApi.post('/api/v1/lecture', lecture)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const addLectureToProgram = (program, lecture) => new Promise((resolve, reject) => {
  devfApi.put(`/api/v1/program/${program}/lecture`, lecture)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const updateLecture = (id, lecture) => new Promise((resolve) => {
  devfApi.patch(`/api/v1/lecture/${id}`, lecture)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const alumniProfile = (idAlumni, idCampusProgram) => new Promise((resolve) => {
  hueApi.get(`/campus/sensei/alumni/${idAlumni}/${idCampusProgram}`)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const rateAlumniProject = (project) => new Promise((resolve) => {
  devfApi.post('/api/v1/campus/sensei/rate/project', project)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const rateSelfAlumniProject = (project) => new Promise((resolve) => {
  devfApi.post('/api/v1/campus/sensei/rate/self/project', project)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const feedbackSubmit = (project) => new Promise((resolve) => {
  devfApi.post('/api/v1/campus/sensei/module/feedback', project)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const getAllModules = () => new Promise((resolve) => {
  devfApi.get('/api/v1/campus/modules')
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      resolve(err);
    });
});

export const putLectureToModule = (idModule, lectures) => new Promise((resolve, reject) => {
  devfApi.put(`/api/v1/campus/module/${idModule}/lectures`, lectures)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const getAllLecturesOrderByModule = (idModule) => new Promise((resolve, reject) => {
  devfApi.get(`/api/v1/campus/module/${idModule}/lectures`)
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});
