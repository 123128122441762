import React from 'react';
import { NavLink } from 'react-router-dom';
import whiteLogo from '../../assets/svg/logo-devf-white.svg';

const AnonymousNavbar = () => (
  <nav
    role="navigation"
    aria-label="main navigation"
    className="m-navbar-anonymous"
  >
    <div className="container">
      <div className="d-flex align-items-center justify-content-between" style={{ height: `${64}px` }}>
        <NavLink to="/" className="navbar__logo">
          <img src={whiteLogo} alt="DEV.F" />
        </NavLink>
      </div>
    </div>
  </nav>
);

export default AnonymousNavbar;
