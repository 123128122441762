export default {
  title: 'Ejercicio 36',
  description: `
        Crea una función llamada <code>showMessaje()</code>
        que devuelva <code>"Mi mensaje dentro de un callback"</code>
        mediante un callback.
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Recibir mensaje desde un callback', function (t) {
      t.equal(showMessaje(callback), "Este es mi mensaje dentro de un callback", "Prueba correcta");
      t.end()
    });`,
  ]
};
