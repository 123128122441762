import React from 'react';
import PropTypes from 'prop-types';
import ProfileCard from '../../Components/ProfileCard/ProfileCard';
import ProgramCard from '../../Components/ProgramCard/ProgramCard';
import RemoteGroup from '../../Components/RemoteGroup/RemoteGroup';
import ProfileFeedAlumni from '../../Components/ProfileFeedAlumni/ProfileFeedAlumni';
import FirstAlert from '../../Components/Alerts/FirstAlert';
// import HolidaysAlert from '../../Components/Alerts/HolidaysAlert';
import SuspensionAlert from '../../Components/Alerts/SuspensionAlert';
import session from '../../utils/session';
// import dateToLocale from '../../utils/dateToLocale';

const Profile = ({
  profile, suspensionDate, isSuspended,
  changePayment, qrData, signDiploma, senseiModule, registerClass
}) => {
  const validatePercentage = () => {
    const startDate = new Date(profile.activeProgram.start_date);
    const endDate = new Date(profile.activeProgram.end_date);
    const currentDate = new Date();
    const percentage = ((currentDate - startDate) / (endDate - startDate)) * 100;
    return percentage < 0 ? 100 : percentage;
  };

  const validateWeek = () => {
    let week = 4 - Math.round(
      (new Date(profile.activeProgram.end_date) - new Date()) / (7 * 24 * 60 * 60 * 1000)
    );
    week = week > 4 ? 4 : week;
    week = week < 1 ? 1 : week;
    return week;
  };

  return (
    <>
      {isSuspended
        && (
          <div className="col-lg-10 col-12">
            <SuspensionAlert
              date={profile.lastInvoice.created}
              onClick={changePayment}
            />
          </div>
        )}
      {!isSuspended && profile !== null && profile.activeProgram && (
        <>
          <div className="col-lg-7 col-xl-8 col-12 bg__gray2" style={{ height: 'auto' }}>
            <div className="campus-container">
              {/* {
                <HolidaysAlert startDate={dateToLocale('2022-05-22')}
                endDate={dateToLocale('2022-05-30')} />
              } */}
              {(suspensionDate)
                && (
                  <FirstAlert
                    onClick={changePayment}
                    suspensionDate={suspensionDate}
                    date={profile?.lastInvoice?.created}
                  />
                )}
              <ProfileFeedAlumni
                activeProgram={profile.activeProgram}
                percentage={validatePercentage()}
                product={profile.product}
              />
              <ProfileCard
                profile={profile.alumni}
                activeProgram={profile.activeProgram}
                acknowledgement
                qrData={qrData}
                signDiploma={signDiploma}
                program={profile.activeProgram.infoProgram.name}
                diploma={profile.activeProgram.infoProgram.diploma}
              />
            </div>
          </div>
          <div className="col-lg-3 col-xl-2 col-12 bg__gray2">
            <aside className="pt-0 pt-md-3 pb-5 pb-lg-0">
              {profile.activeProgram.product_line === 'CINTAS' && (
                <ProgramCard
                  currentProgram={profile.activeProgram}
                  week={validateWeek()}
                />
              )}
              {
                profile.activeProgram.group_detail && profile.activeProgram.group_detail.is_active
                && (
                  <RemoteGroup
                    joinUrl={profile.activeProgram?.currentModule?.zoomInfo?.join_url}
                    groupDetail={profile.activeProgram.group_detail}
                    senseiModule={senseiModule}
                    registerClass={registerClass}
                  />
                )
              }
            </aside>
          </div>
        </>
      )}
    </>
  );
};

Profile.defaultProps = {
  profile: {},
  suspensionDate: '',
  senseiModule: {},
};

Profile.propTypes = {
  profile: PropTypes.shape(),
  senseiModule: PropTypes.shape(),
  registerClass: PropTypes.func.isRequired,
  changePayment: PropTypes.func.isRequired,
  suspensionDate: PropTypes.string,
  isSuspended: PropTypes.bool.isRequired,
  qrData: PropTypes.shape().isRequired,
  signDiploma: PropTypes.func.isRequired,
};

export default session(Profile);
