// export default {

export default {
  title: 'Ejercicio 39',
  description: `
        Elige un pingüino de la lista de
        <a href="https://en.wikipedia.org/wiki/List_of_fictional_penguins" target="_blank">pingüinos ficticios de wikipedia</a>
        Crea un objeto llamado <code>myPenguin</code> con propiedades que representen
        la información listada en cada columna en esa página de wikipedia (character, origin y notes)
    `,
  seed: '//type your code here ',
  test: [
    `test('Prueba 1', function (t) {
                t.equal(typeof myPenguin.character, "string");
                t.end()
            });`,
    `test('Prueba 2', function (t) {
                t.equal(typeof myPenguin.origin, "string");
                t.end()
            });`,
    `test('Prueba 3', function (t) {
            t.equal(typeof myPenguin.notes, "string");
            t.end()
            });`
  ]
};
