export default {
  title: 'Ejercicio 22',
  description: `
  Crea una función llamada <code>square()</code> que reciba como parámetro un arreglo de números positivos  y que retorne los elementos elevados al cuadrado
  <br>
  <b>NOTA:</b> puedes utilizar map()
  
  `,
  seed: '// Escribe tu código aquí',
  test: [
      `test('Probar [1,2,3,4] ', function (t) {
          t.deepEqual(square([1,2,3,4]), [ 1, 4, 9, 16 ],'Esto debe retornar [ 1, 4, 9, 16 ]');
          t.end()
        });`,
        `test('Probar [40,78,19]', function (t) {
          t.deepEqual(square([40,78,19]), [ 1600, 6084, 361 ],'Esto debe retornar [ 1600, 6084, 361 ]');
          t.end()
        });`,
        `test('Probar [4,6,9,10]', function (t) {
          t.deepEqual(square([4,6,9,10]), [ 16, 36, 81, 100 ],'Esto debe retornar [ 16, 36, 81, 100 ]');
          t.end()
        });`,
  ]
};
