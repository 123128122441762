/* eslint-disable react/self-closing-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TRY_CODE } from '../../utils/tracks';
import './embed.scss';

const Embed = ({
  data, lectureId, lectureProgram, track, type
}) => {
  const [isCode, setIsCode] = useState(data.service === 'codepen');

  const tryCode = () => {
    setIsCode(!isCode);
    track(TRY_CODE,
      { lecture_code_id: data.source, lecture_id: lectureId, program: lectureProgram });
  };

  return (
    <div className={type === 'selfPaced' ? 'selfPaced-shade shade' : 'shade'}>
      {isCode ? (
        <button
          type="button"
          className="btn__default btn-try-code"
          onClick={tryCode}
        >
          Probar
        </button>
      ) : <> </>}
      <div className={`ce-block" ${isCode ? 'no-click' : ''}`} style={{ width: '100%' }}>
        <div className="ce-block__content">
          <div className="cdx-block embed-tool">
            <iframe
              id="iframe"
              title="Campus"
              className={`embed-tool__content" ${isCode ? 'frame-code' : ''}`}
              frameBorder="0"
              allowFullScreen
              src={data.embed}
            />
            <div style={{ textAlign: 'center', marginTop: '5px' }}>{data.caption}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Embed.propTypes = {
  data: PropTypes.shape().isRequired,
  lectureId: PropTypes.string.isRequired,
  lectureProgram: PropTypes.string.isRequired,
  track: PropTypes.func.isRequired,
  type: PropTypes.string,
};

Embed.defaultProps = {
  type: '',
};

export default Embed;
