export default {
  title: 'Ejercicio 17',
  description: `
  Crea una función llamada <code>digits()</code> que recibe como parámetro un número positivo de uno o dos dígitos decimales (3.45), evalúe si el número tiene uno o dos dígitos decimales.
  <ul>
  <li>Retorne <code>Dos dígitos</code>, en caso de que tenga dos dígitos decimales.</li>
  <li>Retorna <code>Un dígito</code>, en caso de que tenga un dígito decimal.</li>
  </ul>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Probar el número 1.24 ', function (t) {
      t.equal(digits(1.24), 'Dos dígitos','Esto debe retornar Dos dígitos');
      t.end()
    });`,
    `test('Probar el número 404.4', function (t) {
      t.equal(digits(404.4), 'Un dígito','Esto debe retornar Un dígito');
      t.end()
    });`,
    `test('Probar el número 98.02', function (t) {
      t.equal(digits(98.02), 'Dos dígitos','Esto debe retornar Dos dígitos');
      t.end()
    });`,
  ]
};
