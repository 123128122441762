import React from 'react';
import PropTypes from 'prop-types';
import { JobDetail } from '../JobDetail';
import { Spinner } from '../../Common/Spinner';

const Career = ({ 
  job, 
  alumni, 
  apllyJobAlumni, 
  isApplySuccess, 
  isErrorApply, 
  isFetchingApply 
}) => {
  const renderContent = () => (
    <div className="col-lg-10 col-10">
      {job
        ? (
          <JobDetail
            apllyJobAlumni={apllyJobAlumni} 
            job={job} 
            alumni={alumni}  
            isFetchingApply={isFetchingApply} 
            isErrorApply={isErrorApply} 
            isApplySuccess={isApplySuccess} 
          />
          )
        : (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '93vh' }}>
            <Spinner />
          </div>
        )}
    </div>
  );

  return (
    renderContent()
  );
};

Career.defaultProps = {
  profile: {},
  alumni: {}
};

Career.propTypes = {
  job: PropTypes.objectOf(PropTypes.object),
  profile: PropTypes.shape(),
  alumni: PropTypes.shape(),
  apllyJobAlumni: PropTypes.func.isRequired,
  isFetchingApply: PropTypes.func.isRequired,
  isErrorApply: PropTypes.func.isRequired,
  isApplySuccess: PropTypes.func.isRequired
};

export default Career;
