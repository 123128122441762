// import LogRocket from 'logrocket';
import { devfApi } from '../../utils/http';
import Mixpanel from '../../utils/Mixpanel';

export const me = () => new Promise((resolve, reject) => devfApi.get('/api/v1/campus/profile/me')
    .then(({ data }) => {
      // eslint-disable-next-line no-underscore-dangle
      // LogRocket.identify(data.alumni._id, {
      //   name: data.alumni.first_name,
      //   email: data.alumni.email,
      //   role: 'ALUMNI'
      // });
      // eslint-disable-next-line no-underscore-dangle
      Mixpanel.identify(data.alumni._id);
      Mixpanel.people.set({
        $first_name: data.alumni.first_name,
        $email: data.alumni.email,
      });
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    }));

export const getCurrentBatch = () => new Promise((resolve, reject) => {
  devfApi.get('/api/v1/batch?campusBatch=true')
  .then(({ data }) => {
      resolve(data);
  }).catch((err) => {
      reject(err);
  });
});

export const getCurrentGeneration = () => new Promise((resolve, reject) => {
  devfApi.get('/api/v1/generation?current=true')
  .then(({ data }) => {
      resolve(data);
  }).catch((err) => {
      reject(err);
  });
});

export const signDiploma = (program) => new Promise((resolve, reject) => {
  devfApi.post('/api/v1/campus/sign', program).then(({ data }) => {
    resolve(data);
  }).catch((err) => {
    reject(err);
  });
});

export const getSenseiAndClass = (idCampusProgram, module) => new Promise((resolve, reject) => {
  const uri = `/api/v1/campus/module-class/${idCampusProgram}`;
  const params = {};
  if (module) params.specificmodule = module;
  devfApi.get(uri, { params })
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  });
});

export const registerClass = (senseiModule, campusProgramId) => new Promise((resolve, reject) => {
  devfApi.post('/api/v1/campus/class/register', { senseiModule, campusProgramId })
  .then(({ data }) => {
    resolve(data);
  }).catch((err) => {
    reject(err);
  });
});

export const getMyResume = (campusProgramId) => new Promise((resolve, reject) => {
  devfApi.get(`/api/v1/campus/myresume/${campusProgramId}`)
  .then((data) => {
    resolve(data);
  }).catch((err) => {
    reject(err);
  });
});

export const attendedNotification = (campusProgram, notification) => new Promise(
  (resolve, reject) => {
  devfApi.post('/api/v1/campus-extra-global-notification/attended/', { campusProgram, notification })
  .then((data) => {
    resolve(data);
  }).catch((err) => {
    reject(err);
  });
}
);
