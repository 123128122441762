export default {
  title: 'Ejercicio 19',
  description: `
  Crea una función llamada <code>numbersBackwards()</code> que reciba un array de números y retorne los números en orden inverso.
  Ejemplo:
  <ul>
  <li>[1,2,3,4,5,6,7,8,9,10] →[10,9,8,7,6,5,4,3,2,1]</li>
  </ul>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Probar [2,5,6,8,3,4,9] ', function (t) {
      t.deepEqual(numbersBackwards([2,5,6,8,3,4,9]), [9,4,3,8,6,5,2],'Esto debe retornar [9,4,3,8,6,5,2]');
      t.end()
    });`,
    `test('Probar [20,56,89,3,4,789]', function (t) {
      t.deepEqual(numbersBackwards([20,56,89,3,4,789]), [789,4,3,89,56,20],'Esto debe retornar [789,4,3,89,56,20]');
      t.end()
    });`,
    `test('Probar [34,56,72,12,678,234] ', function (t) {
      t.deepEqual(numbersBackwards([34,56,72,12,678,234]), [234, 678, 12, 72, 56, 34],'Esto debe retornar [234, 678, 12, 72, 56, 34 ]');
      t.end()
    });`,
  ]
};
