export default {
  title: 'Ejercicio 28',
  description: `
        Crea una función llamada <code>palindrome()</code> que reciba como parametro una oración,
        si la oración es un palíndromo la función deberá devolver <code>true</code> en otro caso,
        devolver <code>false<code>
        `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba 1', function (t) {
                t.equal(palindrome("Aman a Panama"), true);
                t.end()
        });`,
    `test('Prueba 2', function (t) {
                t.equal(palindrome("Anita lava el auto"), false);
                t.end()
        });`,
    `test('Prueba 3', function (t) {
                t.equal(palindrome("Yo de todo te doy"), true);
                t.end()
        });`
  ]
};
