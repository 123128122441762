export default {
    title: 'Ejercicio 23',
    description: `
    Crea una función llamada <code>promedio()</code> que reciba como parámetro un arreglo de números positivos, retorna el promedio de todos los elementos
    <br>
    <b>NOTA:</b> puedes utilizar forOf()
    
    `,
    seed: '// Escribe tu código aquí',
    test: [
        `test('Probar [1,2,3,4] ', function (t) {
            t.equal(promedio([1,2,3,4]), 2.5,'Esto debe retornar 2.5');
            t.end()
          });`,
          `test('Probar [40,7,19]', function (t) {
            t.equal(promedio([40,7,19]), 22,'Esto debe retornar 22');
            t.end()
          });`,
          `test('Probar [4,6,90,10]', function (t) {
            t.equal(promedio([4,6,90,10]), 27.5,'Esto debe retornar 27.5');
            t.end()
          });`,
    ]
  };
