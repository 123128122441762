export default {
    title: 'Ejercicio 44',
    description: `
    Crea una función llamada <code>mayusculas()</code> que reciba como parámetro una cadena de texto y que la retorne en mayúsculas. 
    
    `,
    seed: '// Escribe tu código aquí',
    test: [
        `test('Probar "Hola devf" ', function (t) {
            t.equal(mayusculas('Hola devf'), "HOLA DEVF",'Esto debe retornar HOLA DEVF');
            t.end()
          });`,
          `test('Probar "Felizmente codeando"', function (t) {
            t.equal(mayusculas('Felizmente codeando'), "FELIZMENTE CODEANDO",'Esto debe retornar FELIZMENTE CODEANDO');
            t.end()
          });`,
          `test('Probar "happy place"', function (t) {
            t.equal(mayusculas('happy place'), "HAPPY PLACE",'Esto debe retornar HAPPY PLACE');
            t.end()
          });`,
    ]
  };
