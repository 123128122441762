import React, { useEffect } from 'react';
import { useVideos } from '../../Context/VideoContext';
import { useMe } from '../../Context/MeContext';
import { Spinner } from '../../Common/Spinner';

export default (Videos) => function VideosHoc() {
  const [
    {
      isFetching, isError, videos, activeVideo, bgVideo
    },
    { getVideos, selectVideo, setBgVideo }] = useVideos();
  const [{ profile }, { track }] = useMe();

  useEffect(() => {
    if (profile && profile.activeProgram) {
      getVideos(
        profile.activeProgram.infoProgram.slug,
        profile.activeProgram.batch,
        profile.activeProgram.product_line,
        profile.activeProgram.start_date,
        profile.activeProgram.end_date,
      );
    }
  }, [profile]);

  const renderContent = () => {
    if (isFetching) {
      return (
        <div className="d-flex justify-content-center align-items-center bg__gray2" style={{ height: '93vh', width: '83.3vw' }}>
          <Spinner />
        </div>
      );
    }
    if (isError) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <h3>{isError}</h3>
        </div>
      );
    }
    if (videos && videos.length === 0) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <h3>Videos no disponibles</h3>
        </div>
      );
    }
    if (videos && videos.length > 0) {
      return (
        <Videos
          videos={videos}
          activeProgram={profile.activeProgram}
          track={track}
          activeVideo={activeVideo}
          setActiveVideo={selectVideo}
          bgVideo={bgVideo}
          setBgVideo={setBgVideo}
        />
      );
    }
    return (
      <div className="d-flex justify-content-center align-items-center bg__gray2" style={{ height: '93vh', width: '83.3vw' }}>
        <Spinner />
      </div>
    );
  };

  return (<>{renderContent()}</>);
};
