/* eslint-disable react/prop-types */
import React from 'react';
import { useParams } from 'react-router-dom';
import { useMe } from '../../Context/MeContext';
import { Spinner } from '../../Common/Spinner';

export default (Workspace) => function LecturesHoc({ timestamp }) {
  const { id, cinta } = useParams();
  const [{ profile }] = useMe();
  return (
    <>
      {profile && profile.activeProgram ? (
        <Workspace
          activeProgram={profile.activeProgram.infoProgram.slug}
          id={id}
          program={cinta}
          timestamp={timestamp}
        />
      ) : (
        <div className="d-flex justify-content-center mt-5">
          <Spinner />
        </div>
        )}
    </>
  );
};
