/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
import React, {
    useContext,
    useState,
  } from 'react';
  import PropTypes from 'prop-types';
  import api from './api';
  
  const LecturesContext = React.createContext(undefined);
  
  const LecturesProvider = ({ children }) => {
    const [isFetching, setIsFeatching] = useState(false);
    const [isError, setIsError] = useState(false);
    const [lectures, setLectures] = useState([]);
    const [projects, setProjects] = useState([]);
    const [currentLecture, setCurrentLecture] = useState(null);
    const [examLecture, setExamLecture] = useState([]);
    const [isFetchingGradeExam, setIsFetchingGradeExam] = useState(false);
    const [isErrorGradeExam, setIsErrorGradeExam] = useState(null);
    const [isSuccessGradeExam, setIsSuccessGradeExam] = useState(null);

    // Lo ocupa el sensei
    const getAllLectures = async () => {
      try {
        setIsFeatching(true);
        setIsError(false);
        const response = await api.Lectures.getAllLectures();
        setLectures(response);
      } catch (error) {
        switch (error.response.status) {
          case 500:
            setIsError('Ocurrió un error en el servidor');
            break;
          default:
            setIsError(error.response.data.message);
            break;
        }
      } finally {
        setIsFeatching(false);
      }
    };

    const getProgramLecturesBySlug = async (programSlug) => {
      setIsFeatching(true);
      setIsError(false);
      try {
        const response = await api.Lectures.getProgramLecturesBySlug(programSlug);
        setLectures(response);
      } catch (error) {
        switch (error.response.status) {
          case 500:
            setIsError('Ocurrió un error en el servidor');
            break;
          default:
            setIsError(error.response.data.message);
            break;
        }
      }
      setIsFeatching(false);
    };

    const getLectureOrExam = async (id, lecture = null) => {
      setIsFeatching(true);
      setIsError(false);
      try {
          const response = !id && lecture ? lecture : await api.Lectures.getLectureById(id);
          if (response.questions && response.questions.length > 0) {
            const questions = response.questions.reduce((acc, question) => {
              const answer = question.answers.map((_answer) => ({ ..._answer, selected: null }));
              const questionObj = {
                question: question.question,
                answers: [...answer],
              };
              acc.push(questionObj);
              return acc;
            }, []);            
            setExamLecture(questions);
        }
          setCurrentLecture(response);
      } catch (error) {
        switch (error.response.status) {
          case 500:
            setIsError('Ocurrió un error en el servidor');
            break;
          default:
            setIsError(error.response.data.message);
            break;
        }
      }
      setIsFeatching(false);
    };

    // Solo lo ocupa el sensei hay que hacer el cambio para module
    const getLectureByModules = async (slugModule) => {
      setIsFeatching(true);
      setIsError(false);
      try {
        const response = await api.Lectures.getLectureByModules(slugModule);
        setLectures(response.topics);
        setProjects(response.projects);
      } catch (error) {
        switch (error.response.status) {
          case 500:
            setIsError('Ocurrió un error en el servidor');
            break;
          default:
            setIsError(error.response.data.message);
            break;
        }
      } finally {
        setIsFeatching(false);
      }
    };

    const setResponse = (question, newAnswers) => {
      const questionIndex = examLecture.findIndex((exam) => exam.question === question);
      // eslint-disable-next-line no-multi-assign
      examLecture[questionIndex].answers = newAnswers;
      setExamLecture([...examLecture]);
    };
  
    const nonNullResponseExam = (exam) => exam.some((question) => 
    question.answers.some((answer) => answer.selected === null));

    const submitExam = async (activeProgram, cb) => {
      try {
        if (!nonNullResponseExam(examLecture)) {
          setIsFetchingGradeExam(true);
          setIsErrorGradeExam(null);
          // eslint-disable-next-line no-underscore-dangle
          const { grade, newCampusProgram } = await api.Lectures.gradeExam(currentLecture._id,
          examLecture, activeProgram);  
          setIsSuccessGradeExam(grade);
          setIsFetchingGradeExam(false);
          cb(null, grade, newCampusProgram);
        } else {
          alert('Necesitas completar tu cuestionario');
        }
      } catch (error) {
        setIsFetchingGradeExam(false);
        setIsErrorGradeExam(error);
        cb(error, null);
      }
    };

    const submitSelfExam = async (idActiveProgram, responses, slugModule, topicSlug, cb) => {
      if (!nonNullResponseExam(responses)) {
        // setIsFetchingGradeExam(true);
        setIsErrorGradeExam(null);
        // eslint-disable-next-line no-underscore-dangle
        const { grade, maxGrade } = await api.Lectures.gradeSelfExam(currentLecture._id,
          slugModule, topicSlug,
          responses, idActiveProgram);  
        setIsSuccessGradeExam(grade);
        setIsFetchingGradeExam(false);
        cb(null, { grade, maxGrade });
      } else {
        alert('Necesitas completar tu cuestionario');
      }
    };

    const state = [
      {
        isFetching, 
        isError, 
        lectures, 
        projects,
        currentLecture, 
        isFetchingGradeExam, 
        isErrorGradeExam, 
        isSuccessGradeExam,
        examLecture,
      },
      {
        getProgramLecturesBySlug,
        getLectureOrExam,
        getLectureByModules,
        setResponse,
        submitExam,
        getAllLectures,
        submitSelfExam,
      }
    ];

    return (
      <LecturesContext.Provider value={state}>
        {children}
      </LecturesContext.Provider>
    );
  };

  LecturesProvider.propTypes = {
    children: PropTypes.shape().isRequired
  };

  const useLectures = () => {
    const context = useContext(LecturesContext);
    if (context === undefined) {
      throw new Error('useLectures can only be used inside LecturesProvider');
    }
    return context;
  };

export {
  LecturesProvider,
  useLectures
};
