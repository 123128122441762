/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Video } from '../../Components/Video';
import './videos.scss';

const Videos = ({
  videos, track, activeProgram, activeVideo, setActiveVideo, bgVideo, setBgVideo
}) => (
  <>
    <div className="col-lg-10 bg__gray2">
      <section className="campus-container">
        <Video
          idVideo={videos[activeVideo].idVideo}
          title={videos[activeVideo].name}
          hash={videos[activeVideo].hash}
          slugProgram={activeProgram.slugProgram}
          track={track}
          bgVideo={bgVideo}
          setBgVideo={setBgVideo}
        />
      </section>
    </div>
    <div className="col-lg-2 bg__gray3 videolist-container">
      <div className="d-flex flex-column">
        {videos.map((_video, i) => (
          <a
            key={_video.idVideo}
            type="button"
            className={i === activeVideo ? 'video-link-active' : 'video-link'}
            onClick={() => setActiveVideo(i)}
          >
            {_video.name}
          </a>
          ))}
      </div>
    </div>
  </>
  );

Videos.propTypes = {
  videos: PropTypes.arrayOf(PropTypes.func).isRequired,
  track: PropTypes.func.isRequired,
  activeProgram: PropTypes.shape().isRequired,
  activeVideo: PropTypes.shape().isRequired,
  setActiveVideo: PropTypes.func.isRequired,
  setBgVideo: PropTypes.func.isRequired,
  bgVideo: PropTypes.bool.isRequired,
};

export default Videos;
