/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import parser from 'html-react-parser';
import PropTypes from 'prop-types';

const Paragraph = ({
 data
}) => (
  <div className="ce-block">
    <div className="ce-block__content">
      <div className="ce-paragraph cdx-block" style={{ margin: '0 25px', color: 'white' }}> 
        {' '}
        {data.text.indexOf('<a href=') >= 0 ? null : parser(`${data.text}`)}
        {' '}
      </div>
    </div>
  </div>
  );

Paragraph.propTypes = {
    data: PropTypes.shape().isRequired,
};

export default Paragraph;
