import React, {
    useCallback,
    useContext,
    useState,
  } from 'react';
  import PropTypes from 'prop-types';
  import api from './api';

 const SenseiAdminModuleContext = React.createContext(undefined);

 const SenseiAdminModuleProvider = ({ children }) => {
    const [isFetching, setIsFeatching] = useState(false);
    const [isFetchingAddLectures, setIsFetchingAddLectures] = useState(false);
    const [isFetchingAllLectures, setIsFetchingAllLectures] = useState(false);
    const [isError, setIsError] = useState(false);
    const [lectures, setLectures] = useState([]);
    const [senseiAdminModules, setSenseiAdminModules] = useState([]);

    const AllSenseiAdminModules = useCallback(async () => {
        try {
            const response = await api.Sensei.getAllModules();
            setSenseiAdminModules(response);
            setIsFeatching(true);
            setIsError(false);
        } catch (error) {
            switch (error.response.status) {
                case 500:
                  setIsError('Ocurrió un error en el servidor');
                  break;
                default:
                  setIsError(error.response.data.message);
                  break;
              }
        } finally {
            setIsFeatching(false);
        }
    }, []);

    const updateLectureToModule = async (moduleId, lecturesData) => {
        const lecturesAllData = lecturesData.filter((lecture) => lecture.order)
        // eslint-disable-next-line no-underscore-dangle
        .map((item) => ({ lecture: item._id, order: item.order }));

        try {
            setIsFetchingAddLectures(true);
            // eslint-disable-next-line max-len
            await api.Sensei.putLectureToModule(moduleId, { lectures: lecturesAllData });
            setIsFetchingAddLectures(false);
        } catch (error) {
            switch (error.response.status) {
                case 500:
                  setIsError('Ocurrió un error en el servidor');
                  break;
                default:
                  setIsError(error.response.data.message);
                  break;
              }
        } finally {
            setIsFetchingAddLectures(false);
        }
    };

    const allLectures = async (moduleId) => {
        try {
            setIsFetchingAllLectures(false);
            const response = await api.Sensei.getAllLecturesOrderByModule(moduleId);
            setLectures(response);
            setIsFetchingAllLectures(true);
        } catch (error) {
            switch (error.response.status) {
                case 500:
                  setIsError('Ocurrió un error en el servidor');
                  break;
                default:
                  setIsError(error.response.data.message);
                  break;
              }
        } finally {
            setIsFetchingAllLectures(true);
        }
    };

    const state = [
        {
            isFetching,
            isError,
            lectures,
            isFetchingAddLectures,
            isFetchingAllLectures,
            senseiAdminModules
        },
        {
            updateLectureToModule,
            AllSenseiAdminModules,
            allLectures
        }
    ];

    return (
      <SenseiAdminModuleContext.Provider value={state}>
        {children}
      </SenseiAdminModuleContext.Provider>
    );
 };

const useSenseiAdminModules = () => {
    const context = useContext(SenseiAdminModuleContext);
    if (context === undefined) {
        throw new Error('useSenseiAdminModules can only be used inside SenseiAdminModuleProvider');
    }
    return context;
};

SenseiAdminModuleProvider.propTypes = {
    children: PropTypes.shape().isRequired
};

export {
    SenseiAdminModuleProvider,
    useSenseiAdminModules
  };
