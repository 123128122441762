import React from 'react';
import PropTypes from 'prop-types';

const ProgramCard = ({ currentProgram, week }) => (
  <div className="m_campus-program-card bg__hackeranda">
    <div className="row">
      <div className="col-12">
        <h5 className="a_emoji-figure">
          <span role="img" aria-label="Student">🎓</span>
        </h5>
      </div>
      <div className="col-12">
        <h5 className="text__white">{currentProgram.infoProgram.name}</h5>
        <p className="text__white">{ `Semana ${week} de 4` }</p>
      </div>
    </div>
    <div className="d-flex">
      <progress className="a_progress-rainbow" value={week >= 1 ? 100 : 0} max="100" />
      <progress className="a_progress-rainbow" value={week >= 2 ? 100 : 0} max="100" />
      <progress className="a_progress-rainbow" value={week >= 3 ? 100 : 0} max="100" />
      <progress className="a_progress-rainbow" value={week >= 4 ? 100 : 0} max="100" />
    </div>
  </div>
  );

ProgramCard.defaultProps = {
  week: 0,
};

ProgramCard.propTypes = {
  currentProgram: PropTypes.shape({
    batch: PropTypes.number.isRequired,
    city_name: PropTypes.string.isRequired,
    evaluation: PropTypes.shape().isRequired,
    slugProgram: PropTypes.string.isRequired,
    lectures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    infoProgram: PropTypes.shape().isRequired,
  }).isRequired,
  week: PropTypes.number
};

export default ProgramCard;
