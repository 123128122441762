import React from 'react';
import PropTypes from 'prop-types';
import './rateProjectCard.scss';
import { rewards } from '../../utils/const';

const RateProjectCard = ({
  score, topic, index
}) => {
  const bgColors = [
    'bg--1',
    'bg--2',
    'bg--3',
    'bg--4',
    'bg--5',
  ];

  const textColors = [
    'text--1',
    'text--2',
    'text--3',
    'text--4',
    'text--5',
  ];

  return (
    <div className="col-6 col-lg-3 " style={{ padding: '15px' }} key={index}>
      <div className={`card border-${bgColors[index]}`}>
        {/* <svg className={`${textColors[index]}`}>
          {icon}
        </svg> */}
        <span> 
          {' '}
          {`${rewards[`${score}`]}`}
          {' '}
        </span>
        <p className={`card_topic ${textColors[index]}`}>
          {' '}
          {topic}
          {' '}
        </p>
      </div>
    </div>
  );
};

RateProjectCard.propTypes = {
  score: PropTypes.string.isRequired,
  topic: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default RateProjectCard;
