import farenheitToCelsius from './1_farenheitToCelsius.exercise';
import evaluateLetter from './2_evaluateLetter.exercise';
import decimalToBinary from './3_decimalToBinary.exercise';
import evaluateNumber from './4_evaluateNumber.exercise';
import palindrome from './5_palindrome.exercise';
import longestCountry from './6_longestCountry.exercise';
import time from './7_time.exercise';
import frequent from './8_frequent.exercise';
import cleanArray from './9_cleanArray.exercise';
import books from './10_books.exercise';
import determine from './11_determine.exercise';
import petNames from './12_petNames.exercise';
import showMessaje from './13_showMessaje.exercise';
import callback from './14_callback.exercise';
import triangle from './15_Triangle.exercise';
import myPenguin from './16_myPenguin.exercise';
import Person from './17_Person.exercise';
import map from './18_map.exercise';
import teacher from './19_Teacher.exercise';
import drink from './20_drink.exercise';
import uppercase from './21_uppercase.exercise';
import agregandoUnGuion from './22_agregandoUnGuion.exercise';
import countingVowels from './23_countingVowels.exercise';
import parOImpar from './24_parOImpar.exercise';

export default {
  farenheitToCelsius,
  evaluateLetter,
  decimalToBinary,
  evaluateNumber,
  palindrome,
  longestCountry,
  time,
  frequent,
  cleanArray,
  books,
  determine,
  petNames,
  showMessaje,
  callback,
  triangle,
  myPenguin,
  Person,
  map,
  teacher,
  drink,
  uppercase,
  agregandoUnGuion,
  countingVowels,
  parOImpar
};
