// export default {

export default {
  title: 'Ejercicio 33',
  description: `
        Crea un nuevo método para el siguiente objeto: <br>
        <code>const myCollection = {
          books: [
            {
              title: 'Cien Años de Soledad',
              author: 'Gabriel García Márquez',
              read: true
            },
            {
              title: 'Do Androids Dream of Electric Sheep',
              author: 'Phillip K. Dick',
              read: false
            }
          ]
        };</code>
        <br>
        El método debe llamarse <code>isRead()</code> que recibirá objeto con los atributos
        <code>title</code>, <code>author</code> y <code>read</code>. <br>
        Si el libro ha sido leído debe retornar: <br>
        <code>"Ya has leido Cien Años de Soledad de Gabriel García Márquez"</code> <br>
        Si el libro no ha sido leído debe retornar: <br>
        <code>"Aún necesitas leer Do Androids Dream of Electric Sheep de Phillip K. Dick"</code>
    `,
  seed: `const myCollection = {
    books: [
      {
        title: 'Cien Años de Soledad',
        author: 'Gabriel García Márquez',
        read: true
      },
      {
        title: 'Do Androids Dream of Electric Sheep',
        author: 'Phillip K. Dick',
        read: false
      }
    ]
  };`,
  test: [
    `test('Prueba libro 1', (t) => {
      t.equal(myCollection.isRead(myCollection.books[0]),
        'Ya has leído Cien Años de Soledad de Gabriel García Márquez',
        'Prueba correcta');
      t.end();
    });
    test('Prueba libro 2', (t) => {
      t.equal(myCollection.isRead(myCollection.books[1]),
        'Aún necesitas leer Do Androids Dream of Electric Sheep de Phillip K. Dick',
        'Prueba correcta');
      t.end();
    });`
  ]
};
