export default {
    title: 'Ejercicio 45',
    description: `
    Crea una función llamada <code>modificando()</code> que reciba como parámetro una cadena de texto y la retorna poniendo un guión entre cada letra. 
    <br>
    Ejemplo: “Hola mundo” → “H-o-l-a-m-u-n-d-o”

    
    `,
    seed: '// Escribe tu código aquí',
    test: [
        `test('Probar "Let it be" ', function (t) {
            t.equal(modificando("Let it be"), "L-e-t-i-t-b-e",'Esto debe retornar L-e-t-i-t-b-e');
            t.end()
          });`,
          `test('Probar "Little People"', function (t) {
            t.equal(modificando("Little People"), "L-i-t-t-l-e-P-e-o-p-l-e",'Esto debe retornar L-i-t-t-l-e-P-e-o-p-l-e');
            t.end()
          });`,
          `test('Probar "Harry Potter"', function (t) {
            t.equal(modificando("Harry Potter"), "H-a-r-r-y-P-o-t-t-e-r",'Esto debe retornar H-a-r-r-y-P-o-t-t-e-r');
            t.end()
          });`,
    ]
  };
