import React from 'react';
import { useAuth } from '../../Context/AuthContext';

export default (Login) => function LoginHoc() {
  const [
    {
      isFetching, isError, recoverSuccess
    },
    {
      onLogin, onRegister, onRecover
    }
  ] = useAuth();

  const onLoginHandler = (email, password) => {
    onLogin(email, password);
  };

  const onRegisterHandler = (email, password, confirmPassword, handleAuthOption) => {
    onRegister(email, password, confirmPassword, handleAuthOption);
  };

  const onRecoverHandler = (recoverEmail) => {
    onRecover(recoverEmail);
  };

  return (
    <Login
      onLogin={onLoginHandler}
      onRegister={onRegisterHandler}
      onRecover={onRecoverHandler}
      isFetching={isFetching}
      isError={isError}
      recoverSuccess={recoverSuccess}
    />
  );
};
