import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../../Components/Question/question.scss';
import QuestionExam from './QuestionExam';
import { MIN_EXAM_GRADE } from '../../../utils/const';

function Exam({ exam, submitExam, score }) {
  const [newExam, setNewExam] = useState(exam);  
  const grade = score === -1 ? -1 : score / exam.length;

  const setExam = (question, answers) => {
    const newState = newExam.map((_exam) => {
      if (_exam.question === question) {
        // eslint-disable-next-line no-param-reassign
        _exam.answers = answers;
      }
      return _exam;
    });
    setNewExam(newState);
  };

  const isSuccessExam = () => grade >= MIN_EXAM_GRADE;

  const renderFeedbackScore = () => {
    // TODO: Refactorizar UI
    if (grade === -1) return null;
    if (grade >= 0.70) {
      return (<h4 className="text__white">{`Felicidades tuviste un score de ${grade * 10}`}</h4>);
    }
    return (<h4 className="text__white">{`Tuviste un score de ${Math.trunc(grade * 10)} minimo debes de cumplir con el 70% de opciones correctas, intenta ver nuavamente el video`}</h4>);
  };

  return (
    <section>
      <h5>Cuestionario</h5>
      {renderFeedbackScore()}
      {exam.map(({ question, answers }, i) => 
      (
        <QuestionExam
          key={question}
          index={i}
          question={question}
          answers={answers}
          setExam={setExam}
          isRatedExam={isSuccessExam()}
        />
      ))}
      {!isSuccessExam() && <button type="button" onClick={() => submitExam(newExam)}>Enviar Respuestas</button>}
    </section>
  );  
}
Exam.defaultProps = {
  score: -1
};

Exam.propTypes = {
  submitExam: PropTypes.func.isRequired,
  score: PropTypes.number,
  exam: PropTypes.arrayOf(PropTypes.shape(
    {
      question: PropTypes.string,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string,
          answer: PropTypes.string,
          is_correct: PropTypes.bool,
          selected: PropTypes.bool,
        })
      )
    }
  )).isRequired
};

export default Exam;
