import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckCircle } from 'react-feather';
import { Input } from '../../Common/Input';
import { Spinner } from '../../Common/Spinner';

const RecoverForm = ({
  onRecover, handleAuthOption, isError, isFetching, recoverSuccess
}) => {
  const [recoverEmail, setRecoverEmail] = useState('');

  const sendData = async (e) => {
    e.preventDefault();
    onRecover(recoverEmail);
  };

  const handleChangeRecoverEmail = (event) => setRecoverEmail(event.target.value);

  return (
    <div className="o_login-body">
      <div className="o_login-heading">
        <h3 className="text__white">
          Recupera tu contraseña
        </h3>
      </div>
      <div className={isError ? 'd-inline' : 'd-none'}>
        <p className="bg__error text__white p-3" style={{ margin: 0 }}>
          {isError}
        </p>
      </div>
      {recoverSuccess
        ? (
          <div>
            <div className="d-flex justify-content-center align-items-center">
              <h1 className="text__success" style={{ margin: '2rem 0' }}>
                <CheckCircle color="#52E386" size={55} className="mb-3" />
              </h1>
              <h6 className="text__white" style={{ padding: '0rem 1rem 1rem 3rem' }}>
                Hemos enviado un link de recuperación a tu correo, no olvides revisar en spam.
              </h6>
            </div>
            <button
              className="btn__tool"
              type="button"
              onClick={() => handleAuthOption('login')}
              style={{ margin: '0 1px 3rem 0' }}
            >
              Inicio
            </button>
          </div>
        )
        : (
          <form onSubmit={sendData}>
            <div className="m_form-group">
              <small className="a_form-legend text__gray6">Correo electrónico</small>
              <Input
                data-testid="input-recover-password"
                name="recoverEmail"
                label=""
                type="email"
                placeholder=""
                icon=""
                value={recoverEmail}
                onChange={handleChangeRecoverEmail}
                required
              />
            </div>
            <div className="d-flex flex-row pt-3 pb-5">
              <button data-testid="btn-forgot-password" style={{ marginLeft: 0 }} className="btn__tool" type="submit">
                {isFetching ? <Spinner /> : 'RECUPERAR'}
              </button>
              <button className="btn__ghost-alt" type="button" onClick={() => handleAuthOption('login')}>CANCELAR</button>
            </div>
          </form>
        )}
    </div>
  );
};

RecoverForm.propTypes = {
  handleAuthOption: PropTypes.func.isRequired,
  onRecover: PropTypes.func.isRequired,
  isError: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  recoverSuccess: PropTypes.bool.isRequired
};

export default RecoverForm;
