/* eslint-disable camelcase */
import React from 'react';
import {
  faArrowLeft, faHeart, faShare, faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { faYoutube, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { AnonymousNavbar } from '../../Common/AnonymousNavbar';
import './professional-profile.scss';
import isAuthenticated from '../../utils/isAuthenticated';

const ProfessionalProfile = ({ profile }) => {
  const { tags, alumni, experience } = profile;
  const {
    first_name, last_name, profile_pic, title, city_name, country, about_me
  } = alumni;
  const tagsList = tags.map((tag) => (
    <span
      key={tag.id}
      className={`a-professional-profile__tags -${tag.className}`}
    >
      {tag.name}
    </span>
  ));
  const aboutMe = about_me.split('\n').map((paragraph, i) => (<p key={`${i + 1}`}>{paragraph}</p>));
  const experienceRecord = experience.map((record, i) => (
    <div key={`${i + 1}`}>
      <div className="row">
        <div className="col-3 col-lg-1 col-md-3">
          <img className="a-professional-profile__record -logo" src={record.image} alt={`Logo de ${record.company}`} />
        </div>
        <div className="col-9 col-lg-11 col-md-9">
          <h6 className="a-professional-profile__record -company">{record.position}</h6>
          <ul className="a-professional-profile__record -detail">
            <li>{record.company}</li>
            <li>{`${record.from} - ${record.to}`}</li>
          </ul>
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <div className={`p-professional-profile pt-4 ${!isAuthenticated() && 'mt-64'}`} data-testid="professional-profile">
        { !isAuthenticated() && (<AnonymousNavbar />) }
        <div className="container-fluid">
          <div className="row o-professional-profile__actions">
            <div className="col-lg-2 col-md-12">
              <div className="m-professional-profile__actions">
                <a href="./" className="btn__danger">
                  <FontAwesomeIcon className="a-professional-profile__actions -back" icon={faArrowLeft} />
                </a>
                <div>
                  <a href="./" className="btn__alt-default">
                    <FontAwesomeIcon className="a-professional-profile__actions -like" icon={faHeart} />
                  </a>
                  <a href="./" className="btn__alt-default">
                    <FontAwesomeIcon className="a-professional-profile__actions -share" icon={faShare} />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="m-professional-profile">
                <div className="a-professional-profile__banner" />
                <div className="a-professional-profile__info">
                  <img className="a-professional-profile__info -avatar" src={profile_pic} alt="Imágen de perfil de usuario" />
                  <h5 className="a-professional-profile__info -name">{`${first_name} ${last_name}`}</h5>
                  <h6 className="a-professional-profile__info -description">{title}</h6>
                  <h6 className="a-professional-profile__info -location">{`${country}, ${city_name}`}</h6>
                  <a className="a-professional-profile__info -link" href={alumni.linkedin}>
                    <FontAwesomeIcon icon={faLinkedin} />
                    LinkedIn
                  </a>
                  <a className="a-professional-profile__info -link" href={alumni.github}>
                    <FontAwesomeIcon icon={faGithub} />
                    GitHub
                  </a>
                  <a className="a-professional-profile__info -link" href={alumni.youtube}>
                    <FontAwesomeIcon icon={faYoutube} />
                    YouTube
                  </a>
                </div>
                {tags.length >= 1 && (
                  <div
                    data-testid="professional-profile-tags"
                    className="m-professional-profile__tags"
                  >
                    {tagsList}
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-2 col-md-12">
              <div className="m-professional-profile-connect">
                <button type="button" className="btn__success">
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                  Contactar
                </button>
                <button type="button" className="btn__ghost-alt">Perfiles similares</button>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-lg-2 col-md-12" />
            <div className="col-lg-8 col-md-12">
              <div className="m-professional-profile__description my-4">
                <h5 className="a-professional-profile__description -title">Acerca de mí</h5>
                {aboutMe}
              </div>
              {experience.length >= 1 && (
                <div
                  data-testid="professional-profile-experience"
                  className="m-professional-profile__record my-4"
                >
                  <h5 className="a-professional-profile__record -title">Experiencia</h5>
                  {experienceRecord}
                </div>
              )}
            </div>
            <div className="col-lg-2 col-md-12" />
          </div>
        </div>
      </div>
    </>
  );
};

ProfessionalProfile.propTypes = {
  profile: PropTypes.objectOf(PropTypes.shape).isRequired,
};

export default ProfessionalProfile;
