/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useLectures } from '../../../Context/LecturesContext';
import { Spinner } from '../../../Common/Spinner';
import { useSensei } from '../../../Context/SenseiContext';
import { useModules } from '../../../Context/ModuleContext';

export default (Lectures) => function LecturesHoc() {
  const [{
    isFetching, isError, currentLecture, examLecture,
  }, {  
    getLectureOrExam, 
    setResponse
  }] = useLectures();
  const [{ module: selectedModule }, { getCompleteModule, sendAlumniProyect }] = useModules();
  const [{ profile }, { syncProfile, track }] = useSensei();

  const { module, lecture } = useParams();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  
  const [activeProject, setActiveProject] = useState(null);
  const [alumniModule, setAlumniModule] = useState(null);
  const [alumniProject, setAlumniProject] = useState(null);
  
  const sendProfileAndSync = () => { 
  };

  useEffect(() => {
    if (!selectedModule) {
      getCompleteModule(module);
    }
    if (profile && profile.activeProgram) {
      setAlumniModule(profile.activeProgram.modules.find((m) => m.slug === module));
    }
  }, []);

  useEffect(() => {
    if (query.get('type') === 'project') {
      const activeP = selectedModule && selectedModule.projects.find((_p) => _p.slug === lecture);
      setActiveProject(activeP);
      if (alumniModule) {
        const _alumniProject = alumniModule.projects.find((_p) => _p.slug === activeP.slug);
        setAlumniProject(_alumniProject || null);
      }
    } else {
      getLectureOrExam(lecture);
    }
  }, [lecture, selectedModule]);

  const saveAlumniProject = (links, description) => {
    sendAlumniProyect(selectedModule._id, activeProject._id,
      { links, description, idCampusProgram: profile.activeProgram._id });
    setTimeout(syncProfile, 500);
  };

  return (
    <div className="col-lg-12 col-12 bg__gray2">
      { 
      selectedModule
      && profile 
      && profile.activeProgram ? (
        <Lectures
          isFetchingLecture={isFetching}  
          isError={isError}
          lectures={selectedModule}
          profile={profile}
          currentLecture={currentLecture || {}}
          contentType={query.get('type')}
          module={module}
          setResponse={setResponse}
          submitExam={sendProfileAndSync}
          examLecture={examLecture || []}
          track={track}
          projects={selectedModule?.projects || []}
          activeProject={activeProject}
          alumniProject={alumniProject}
          saveAlumniProject={saveAlumniProject}
          isSensei
        />
      ) : (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '93vh' }}>
          <Spinner />
        </div>
      )
}
    </div>
  );
};
