export default {
  title: 'Ejercicio 21',
  description: `
  Crea una función llamada <code>teamName()</code> que recibirá dos parámetros, 
  <ol>
  <li>El primero será el nombre de equipo (string) y </li>
  <li>El segundo será un array con el nombre de los integrantes. </li>
  </ol>
  Retorna el siguiente mensaje con los parámetros.
  <code>Hay “número de integrantes” personas en el equipo de “Nombre del equipo”.
  Ellos son: “Integrantes del equipo”</code>
  <br>
  Ejemplo:
  <br>
  Datos de entrada:

  <ul>
  <li>teamName: 'formación'</li>
  <li>people: [ 'Amalia', 'Karla', 'Silvana', 'Manu', 'Jonh', 'Mike'], </li>
  </ul>
  Resultado:
  Hay 6 personas en el equipo de formación.
  Ellos son: Amalia,Karla,Silvana,Manu,Jonh,Mike. 
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test("Probar América, [Orive Peralta, Memo Ochoa, Cabañas, Roberto Becerra]", function (t) {
      t.equal(teamName("América", ["Orive Peralta", "Memo Ochoa", "Cabañas", "Roberto Becerra"]), "Hay 4 personas en el equipo de América. Ellos son: Orive Peralta,Memo Ochoa,Cabañas,Roberto Becerra.", "Esto debe retornar Hay 4 personas en el equipo de América. Ellos son: Orive Peralta, Memo Ochoa, Cabañas, Roberto Becerra.");
      t.end()
    });`,
    `test("Probar DevF, [Maui, Edwin, Mali, Karen, Victor]", function (t) {
      t.equal(teamName("DevF", ["Maui", "Edwin", "Mali", "Karen", "Victor"]), "Hay 5 personas en el equipo de DevF. Ellos son: Maui,Edwin,Mali,Karen,Victor.", "Esto debe retornar Hay 5 personas en el equipo de DevF. Ellos son: Maui, Edwin, Mali, Karen, Victor.");
      t.end()
    });`,
    `test("Probar Avengers, [Thor, Spider-Man, Ironman]", function (t) {
      t.equal(teamName( "Avengers", ["Thor", "Spider-Man", "Ironman"]), "Hay 3 personas en el equipo de Avengers. Ellos son: Thor,Spider-Man,Ironman.", "Esto debe retornar Hay 3 personas en el equipo de Avengers. Ellos son: Thor, Spider-Man, Ironman.");
      t.end()
    });`,
  ]
};
