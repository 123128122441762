/* eslint-disable react/prop-types */
import React from 'react';
import { useSensei } from '../../../Context/SenseiContext';
import urlResources from './allUrlResources.json';

export default (Sidebar) => function SidebarHoc() {
  const [{ profile }] = useSensei();

  return (
    <>
      {
        (profile)
        && (
          <Sidebar
            profile={profile} 
            role={localStorage.getItem('ROLE')}
            urlResources={urlResources}
          />
        )
      }
    </>
  );
};
