/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
 useTable, usePagination, useFilters, useAsyncDebounce, useGlobalFilter
} from 'react-table';

const TableLectures = ({
  data, setData
}) => {
  const GlobalFilter = ({
    globalFilter,
    setGlobalFilter,
  }) => {
    const [valueFilter, setValueFilter] = useState(globalFilter);
  
    const onChange = process.env.NODE_ENV === 'test' ? (() => {}) : (useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 1500));
  
    return (
      <span>
        <input
          value={valueFilter || ''}
          onChange={(e) => {
            setValueFilter(e.target.value);
            onChange(e.target.value);
          }}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    );
  };

  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
  }) => {
    const [value, setValue] = useState(initialValue);
  
    const onChange = (e) => {
      setValue(e.target.value);
    };
  
    const onKeyUp = () => {
      updateMyData(index, id, value);
    };
  
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
  
    return <input data-testid="table-modal-lecture-input" value={value || ''} onChange={onChange} onKeyUp={onKeyUp} />;
  };
  
  const CheckCell = ({
      cell: value
      }) => {
        if (value.row.original.order) {
          return '✅';
        } return '';
  };
  const columns = React.useMemo(
    () => [
        {
        Header: 'Check',
        accessor: 'check',
        Cell: CheckCell
        },
        {
        Header: 'Título',
        accessor: 'title',

        },
        {
        Header: 'Orden',
        accessor: 'order',
        Cell: EditableCell
        },
    ],
    []
  );

  const [skipPageReset, setSkipPageReset] = useState(false);
  const [dataAllLecturesToModule, setDataAllLecturesToModule] = useState(data);

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(false);
    setDataAllLecturesToModule((old) =>
    old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return row;
    }));
  };
  
  useEffect(() => {
    setSkipPageReset(false);
  }, [data]);
  
  useEffect(() => {
    setData(dataAllLecturesToModule);
  }, [dataAllLecturesToModule]);
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    setGlobalFilter,
    state,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data: dataAllLecturesToModule,
      autoResetPage: skipPageReset,
      autoResetGlobalFilter: false,
      updateMyData,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  return (
    <>
      <table data-testid="table-modal-lectures" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={`row-${i}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, ii) => (
                <th key={`cell-${ii}`} {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>

              ))}
            </tr>
          ))}
          <tr>
            <th
              data-testid="table-modal-lectures-input-search"
              colSpan={visibleColumns.length}
              style={{
                textAlign: 'left',
              }}
            >
              <GlobalFilter
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr data-testid="table-modal-lectures-rows" key={row.original._id} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td data-testid={cell.column.id === 'check' && 'table-modal-lecture-check'} key={row.original._id} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        {' '}
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        {' '}
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        {' '}
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        {' '}
        <span>
          Page
          <strong>
            {`${pageIndex + 1} of ${pageOptions.length}`}
          </strong>
        </span>
        <span>
          | Go to page:
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageGo = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageGo);
            }}
            style={{ width: '100px' }}
          />
        </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 25].map((pageSizeItem) => (
            <option key={pageSizeItem} value={pageSizeItem}>
              {`Show ${pageSizeItem}`}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

TableLectures.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setData: PropTypes.elementType.isRequired
};

export default TableLectures;
