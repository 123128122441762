import axios from 'axios';
import { devfApi } from '../../utils/http';

const token = process.env.REACT_APP_VIMEO_TOKEN;
const userId = process.env.REACT_APP_VIMEO_USER;

export const getFoldersVimeo = () => new Promise((resolve, reject) => axios.get(`https://api.vimeo.com/users/${userId}/projects?per_page=100`, { headers: { Authorization: `Bearer ${token}` } })
  .then(({ data: { data } }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));

export const getVideosFromFolderByUriAndPage = (uri, page) => new Promise((resolve, reject) => axios.get(`https://api.vimeo.com${uri}/videos?page=${page}&per_page=9&sort=date`, { headers: { Authorization: `Bearer ${token}` } })
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));

  export const getOneVideo = (uri, page) => new Promise((resolve, reject) => axios.get(`https://api.vimeo.com${uri}/videos?page=${page}&per_page=1&sort=date`, { headers: { Authorization: `Bearer ${token}` } })
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));

export const getVideosFromFolderByUri = (uri) => new Promise((resolve, reject) => axios.get(`https://api.vimeo.com${uri}/videos?page=1&per_page=10&sort=date`, { headers: { Authorization: `Bearer ${token}` } })
  .then(({ data: { data } }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));

export const getVideosFromFolderByUriWithUserId = (uri) => new Promise((resolve, reject) => axios.get(`https://api.vimeo.com/users/${userId}/projects${uri}/videos?page=1&per_page=10&sort=date`, { headers: { Authorization: `Bearer ${token}` } })
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));

export const getVideosByModule = (idCampusProgram,
  module, page, perPage, folderLegacy) => new Promise((resolve, reject) => {
  const uri = `/api/v1/campus/module-videos/${idCampusProgram}`;
  const params = {};
  if (module) params.specificmodule = module;
  if (page) params.page = page;
  if (perPage) params.perPage = perPage;
  if (folderLegacy) {
    if (folderLegacy.uri.length > 30) {
      const uriLegacy = folderLegacy.uri.split('/')[4];
      params.uriLegacy = uriLegacy;
    }
  }
  devfApi.get(uri, { params })
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  });
});
