/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoginForm } from '../LoginForm';
import { RegisterForm } from '../RegisterForm';
import { RecoverForm } from '../RecoverForm';
import { AnonymousNavbar } from '../../Common/AnonymousNavbar';
import './login.scss';

const Login = ({
  onLogin,
  onRegister,
  onRecover,
  isFetching,
  isError,
  recoverSuccess
}) => {
  const [register, setRegister] = useState(false);
  const [login, setLogin] = useState(true);
  const [recover, setRecover] = useState(false);

  const handleAuthOption = (option) => {
    switch (option) {
      case 'register':
        setRegister(true);
        setLogin(false);
        setRecover(false);
        break;
      case 'login':
        setRegister(false);
        setLogin(true);
        setRecover(false);
        break;
      case 'recover':
        setRegister(false);
        setLogin(false);
        setRecover(true);
        break;
      default:
        setRegister(true);
        setLogin(false);
        setRecover(false);
        break;
    }
  };

  return (
    <section className="login-container">
      <AnonymousNavbar />
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-12">
            <div className="m_card card-post bg__brand-plasma">
              {register && (
                <RegisterForm
                  handleAuthOption={handleAuthOption}
                  onRegister={onRegister}
                  isError={isError}
                  isFetching={isFetching}
                />
              )}
              {login && (
                <LoginForm
                  handleAuthOption={handleAuthOption}
                  onLogin={onLogin}
                  isError={isError}
                  isFetching={isFetching}
                />
              )}
              {recover && (
                <RecoverForm
                  handleAuthOption={handleAuthOption}
                  onRecover={onRecover}
                  recoverSuccess={recoverSuccess}
                  isError={isError}
                  isFetching={isFetching}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Login.defaultProps = {
  history: { push() { } },
};

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  onLogin: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
  onRecover: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isError: PropTypes.string.isRequired,
  recoverSuccess: PropTypes.bool.isRequired,
};
export default Login;
