export default {
  title: 'Ejercicio 43',
  description: `
  Crear una clase <code>Bebida</code> que herede a dos clases, 
  <code>Cerveza</code> y <code>Refresco</code>. Ambas clases deben tener la propiedad 
    <code>cantidad</code> (ml). La clase <code>Refresco</code> debe tener el atributo
    <code>azucar</code> (g) y la clase <code>Cerveza</code> debe el atributo 
    <code>porcentajeAlcohol</code>. Crear los getters y setters correspondientes.
    `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba 1', function (t) {
      const prueba =  new Cerveza(355, 4.7);
      t.equal(prueba.getPorcentajeAlcohol(),4.7,"Porcentaje correcto");
      t.end()
    });`,
    `test('prueba 2', function (t) {
      const prueba = new Refresco(600, 20);
      t.equal(prueba.getAzucar(),20,"Azucar correcto");
      t.end()
    });`,
  ]
};
