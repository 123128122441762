import sum from './1_sum.exercise';
import subs from './2_subs.exercise';
import variables from './3_var.exercise';
import mayor from './4_max.exercise';
import forloop from './5_for.exercise';
import evaluate from './6_evaluate.excercise';
import letter from './7_letter.exercise';
import multiple from './8_multiple.exercise';
import largest from './9_largest.exercise';
import game from './10_game.exercise';
import isEqual from './11_isEqual.exercise';
import itsCristmas from './12_itsChristmas.exercise';
import isLessThan10 from './13_isLessThan10.exercise';
import whichQuadrant from './14_whichQuadrant.exercise';
import payRise from './15_payRise.exercise';
import evaluateGrade from './16_evaluatingGrades.exercise';
import howManyDigits from './17_howManyDigits.exercise';
import sayHello from './18_sayHello.exercise';
import numbersBackwards from './19_numbersBackwards.exercise';
import alDerechoYAlReves from './20_alDerechoYAlReves.excersice';
import teamName from './21_teamName.exercise';
import squaringANumber from './22_squaringANumber.exercise';
import promedio from './23_promedio.exercise';

export default {
  sum,
  subs,
  variables,
  mayor,
  evaluate,
  forloop,
  letter,
  multiple,
  largest,
  game,
  isEqual,
  itsCristmas,
  isLessThan10,
  whichQuadrant,
  payRise,
  evaluateGrade,
  howManyDigits,
  sayHello,
  numbersBackwards, 
  alDerechoYAlReves,
  teamName,
  squaringANumber,
  promedio
};
