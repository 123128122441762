/* eslint-disable import/prefer-default-export */
import { hueApi } from '../../utils/http';

export const getAlumnis = (batch) => new Promise((resolve, reject) =>
  hueApi.get(`campus/${batch}?batch=${batch}&cities[]=aguascalientes&cities[]=bogota&cities[]=cali&cities[]=cancun&cities[]=cdmx&cities[]=cdmxnorte&cities[]=cdmxponiente&cities[]=chihuahua&cities[]=culiacan&cities[]=elsalvador&cities[]=guadalajara&cities[]=guatemala&cities[]=hermosillo&cities[]=leon&cities[]=medellin&cities[]=merida&cities[]=monterrey&cities[]=oaxaca&cities[]=panama&cities[]=puebla&cities[]=queretaro&cities[]=quito&cities[]=remote&cities[]=sanjose&cities[]=sanluispotosi&cities[]=toluca&cities[]=villahermosa&programs[]=cintablanca&programs[]=cintanegra&programs[]=cintaroja&programs[]=datascience&programs[]=datavisualization&programs[]=ecommerce&programs[]=executive&programs[]=ai&programs[]=machinelearning&programs[]=uininja&programs[]=uxninja&programs[]=marketing&password=false`)
    .then(({ data }) => {
      resolve(data);
    }).catch((err) => {
      reject(err);
    }));

export const getJobs = (search = null) => new Promise((resolve, reject) => {
  const url = search ? `/careers/campus/job?search=${search}` : '/careers/campus/job';
  hueApi.get(url)
  .then(({ data }) => {
    resolve(data);
  }).catch((err) => {
    reject(err);
  });
});
export const getJobByName = (jobName) => new Promise((resolve, reject) => hueApi.get(`/careers/job/${jobName}`)
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));

  export const applyJob = (nameJob) => hueApi.post('/careers/apply/job', {
    name_job: nameJob
  });
