import React, { useEffect, useState } from 'react';
import { Spinner } from '../../Common/Spinner';
import { useLecture } from '../../Context/LectureContext';
import { useMe } from '../../Context/MeContext';

export default (EmbedLecture) => function EmbedLectureHoc() {
  const [{
 isFetching, isError, lecture, examLecture, isFetchingGradeExam,
 isErrorGradeExam,
 isSuccessGradeExam, module
}, { setResponse, submitExam }] = useLecture();
  const [loading, setLoading] = useState(true);
  const [{ profile, activeProgram }, { track }] = useMe();
  useEffect(() => {
    if (profile && profile.activeProgram) {
      setLoading(false);
    }
  }, [profile]);

  const renderContent = () => {
    if (isFetching) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <Spinner />
        </div>
      );
    }
    if (isError) return <h1 style={{ marginTop: '20rem' }}>Error</h1>;
    if (!loading) {
      return (
        <div className="col-lg-10 col-12 bg__gray2">
          <EmbedLecture
            module={module}
            lecture={lecture}
            profile={profile}
            activeProgram={activeProgram}
            track={track}
            examLecture={examLecture}
            setExamLecture={setResponse}
            submitExam={submitExam}
            isFetchingGradeExam={isFetchingGradeExam}
            isErrorGradeExam={isErrorGradeExam}
            isSuccessGradeExam={isSuccessGradeExam}
          />
        </div>
      );
    }
    return (
      <div className="d-flex justify-content-center mt-5">
        <Spinner />
      </div>
    );
  };

  return (
    <>
      {renderContent()}
    </>
  );
};
