const columns = [
  {
    Header: 'Acciones',
    accessor: 'actions'
  },
  {
    Header: 'Nombre',
    accessor: 'first_name',
  },
  {
    Header: 'Asistencias',
    accessor: 'assisted'
  },
  {
    Header: 'Lecturas Leídas',
    accessor: (props) => `${props.complete_lectures} / ${props.total_lectures}`,
  },
  {
    Header: 'Evaluaciones',
    accessor: (props) => `${props.complete_exams} / ${props.total_exams}`,

  },
  {
    Header: 'Katas',
    accessor: (props) => `${props.katas} / ${props.total_katas}`,

  },
  {
    Header: 'Proyectos',
    accessor: (props) => `${props.complete_projects} / ${props.total_projects}`,

  },
  {
    Header: 'Alerta',
    accessor: 'alert_projects'
  }
];

export default columns;
