/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import { faBell, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { ChevronDown, ChevronUp } from 'react-feather';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/svg/logo-devf-white.svg';
import './sensei-navbar.scss';

const SenseiNavbar = ({ profile, setActiveRecordModule, logout }) => {
  const {
    first_name, last_name, records_module: RecordsModule, activeRecordModule
  } = profile;

  const [navOpen, setNavOpen] = useState(false);

  const signout = () => {
    setNavOpen(false);
    logout();
  };

  return (
    <nav
      role="navigation"
      aria-label="main navigation"
      className="m-navbar"
      style={{ top: 0 }}
      data-testid="navbar-sensei"
    >
      <div
        className="d-flex align-items-center justify-content-between"
        style={{
          height: `${64}px`,
        }}
      >
        <NavLink to="/sensei/profile" className="navbar__logo ml-5">
          <img src={logo} alt="DEV.F" />
        </NavLink>
        <div className="navbar__actions pr-5">
          <button
            data-testid="navbar-sensei-notification"
            id="navbar-notifaction"
            type="button"
            className="navbar-button"
          >
            <FontAwesomeIcon icon={faBell} />
          </button>
          <button
            data-testid="navbar-sensei-help"
            id="navbar-help"
            type="button"
            className="navbar-button"
          >
            <FontAwesomeIcon icon={faQuestion} />
          </button>
          <button
            data-testid="navbar-sensei-profile"
            id="my-profile"
            type="button"
            className={
              navOpen
                ? 'a-btn o-profile-btn btn__hackeranda d-flex justify-content-between navbar-button'
                : 'a-btn o-profile-btn d-flex justify-content-between navbar-button'
            }
            onClick={() => setNavOpen(!navOpen)}
          >
            <img
              className="my-profile__avatar"
              src="https://yt3.ggpht.com/a/AATXAJwR5XaNm85QxvFYSnF3Xt5hlrfl-5CjZuH8mADm4Q=s900-c-k-c0x00ffffff-no-rj"
              alt="Perfil"
            />
            <div>
              {first_name && <span data-testid="navbar-sensei-fist-name">{`${first_name} `}</span>}
              {last_name && <span data-testid="navbar-sensei-last-name">{last_name}</span>}
            </div>
            {navOpen ? (
              <ChevronUp
                style={{
                  marginRight: '1rem',
                }}
                color="#E85D97"
              />
            ) : (
              <ChevronDown
                style={{
                  marginRight: '1rem',
                }}
                color="#E85D97"
              />
            )}
          </button>
          {navOpen && (
            <div className="d-flex flex-column bg__white o_nav-programs">
              {RecordsModule?.length > 0 && RecordsModule.map((recordModule) => (
                <button
                  data-testid="navbar-sensei-program"
                  type="button"
                  key={recordModule.slug + recordModule._id}
                  className={
                    activeRecordModule.slug === recordModule.slug
                      && activeRecordModule._id
                      === recordModule._id
                      ? 'btn__default'
                      : 'btn__alt-default'
                  }
                  onClick={() => {
                    setActiveRecordModule(
                      recordModule.slug,
                      null,
                      recordModule._id
                    );
                    setNavOpen(!navOpen);
                  }}
                >
                  {`${recordModule.title} / ${recordModule.group_detail.group_name}`}
                </button>
              ))}
              <button
                data-testid="navbar-sensei-logout"
                type="button"
                className="btn__danger"
                onClick={() => signout()}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

SenseiNavbar.propTypes = {
  profile: PropTypes.shape().isRequired,
  setActiveRecordModule: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

export default SenseiNavbar;
