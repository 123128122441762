import React from 'react';
import PropTypes from 'prop-types';

const ImageEditor = ({ data }) => (
  <div className="ce-block container">
    <div className="ce-block__content">
      <div className="cdx-block image-tool image-tool--filled">
        <div className="image-tool__image">
          <img className="image-tool__image-picture" src={data.file ? data.file.url : data.url} alt="Code" style={{ width: '75%', margin: '24px auto' }} />
          <span className="text__white" style={{ textAlign: 'right', marginBottom: '5px' }}>{data.caption}</span>
        </div>
      </div>
    </div>
  </div>
  );

  ImageEditor.propTypes = {
    data: PropTypes.shape().isRequired,
};

export default ImageEditor;
