export default {
  title: 'Ejercicio 47',
  description: `
  Crea una función llamada <code>evaluate()</code> que recibe como parámetro un número entero y evalúa si el número es par o impar.
  <ul>
  <li>Retorna <code>PAR</code>, si el número es par.</li>
  <li>Retorna <code>IMPAR</code>, si el número es impar.</li>
  </ul>

  `,
  seed: '// Escribe tu código aquí',
  test: [
      `test('Probar 234 ', function (t) {
          t.equal(evaluate(234), 'PAR','Esto debe retornar PAR');
          t.end()
        });`,
        `test('Probar 3', function (t) {
          t.equal(evaluate(3), 'IMPAR','Esto debe retornar IMPAR');
          t.end()
        });`,
        `test('Probar 190', function (t) {
          t.equal(evaluate(190), 'PAR','Esto debe retornar PAR');
          t.end()
        });`,
  ]
};
