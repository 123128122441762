/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './modal.scss';
import { X } from 'react-feather';

const Modal = ({
  show, closeModal, children, dismissible, theme = 'dark'
}) => {
  const handleModalDialogClick = (e) => {
    e.stopPropagation();
  };
  return (
    <div
      data-testid="modal-window"
      className={show ? 'modal-window-fixed' : 'modal-window-hidden'}
      // onClick={() => dismissible && closeModal()}
    >
      <div className={theme !== 'dark' ? 'modal-light' : 'modal-dark'}>
        <div className="container modal-container" onClick={handleModalDialogClick}>
          {dismissible && (
            <span data-testid="span-modal-close" title="Close" className="modal-close" onClick={() => closeModal()}><X color="red" /></span>
          )}
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

Modal.defaultProps = {
  show: false,
  dismissible: false,
  theme: 'dark',
};

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.shape().isRequired,
  closeModal: PropTypes.func.isRequired,
  dismissible: PropTypes.bool,
  theme: PropTypes.string,
};
