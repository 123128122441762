/* eslint-disable func-names */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect } from 'react-router-dom';

export default function (WrappedComponent) {
  return function session(props) {
    // Opera 8.0+
    const isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    // Internet Explorer 6-11
    const isIE = /* @cc_on!@ */false || !!document.documentMode;
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    // Chrome 1 - 79
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    // Edge (based on chromium) detection
    const isEdgeChromium = isChrome && (navigator.userAgent.indexOf('Edg') !== -1);

    // iOS Devices
    const isiOS = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

    const isValid = isChrome || isOpera || isEdge || isEdgeChromium || isiOS;

    return isValid
      ? <WrappedComponent {...props} />
      : <Redirect to="/support" />;
  };
}
