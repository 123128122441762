/* eslint-disable class-methods-use-this */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './testrunner.scss';

class TestRunner extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props;
    this.state = {
      contentRef: '',
      show: false,
      dialogClases: '',
      message: '',
      tap: '',
      id,
    };
  }

  componentDidMount() {
    const vm = this;

    window.document.addEventListener('tapeEvent', handleEvent, false);
    function handleEvent(e) {
      vm.setState(({ tap: '' }));
      e.detail.tap.forEach((element) => {
        vm.setState((prevState) => ({ tap: prevState.tap + element }));
      });
      if (e.detail.results.count > 0 && e.detail.results.count === e.detail.results.pass) {
        vm.setState(({ show: true, dialogClases: 'bg__success text__white p-3', message: 'Correcto' }));
        vm.props.success(vm.props.id);
      } else {
        vm.setState(({ show: true, dialogClases: 'bg__error text__white p-3', message: 'Error' }));
      }
      vm.setState({ results: e.detail.results, tests: e.detail.tests });
      vm.props.buildAssigment(vm.state);
    }
    window.document.addEventListener('catchError', handleCatchError, false);
    function handleCatchError(e) {
      const tap = `${e.detail.message}<br> ${e.detail.stack}`;
      vm.setState(({
        show: true, dialogClases: 'bg__error text__white p-3', tap, message: 'Error'
      }));
    }

    window.document.addEventListener('iframeError', handleIframeError, false);
    function handleIframeError(e) {
      const tap = `${e.detail.msg}\n${e.detail.url}\n${e.detail.err.message}\n${e.detail.err.stack}`;
      vm.setState(({
        show: true, dialogClases: 'bg__error text__white p-3', tap, message: 'Error'
      }));
    }
  }

  setSavedTest = (test) => {
    if (test !== '') {
      this.setState({
        tap: test.tap,
        show: true,
        dialogClases: (test.results.fail === 0) ? 'bg__success text__white p-3' : 'bg__error text__white p-3',
        message: (test.results.fail === 0) ? 'Correcto' : 'Error',
      });
    }
  }

  runTest = (code, save, cinta, id) => {
    const { contentRef } = this.state;
    const { test } = this.props;
    contentRef.open();
    contentRef.writeln(this.buildFrame(code, test));
    contentRef.close();
    const newState = {
      save,
      cinta,
      id,
      code
    };
    this.setState(newState);
  }

  clearOutput = () => {
    this.setState({
      show: false, message: '', dialogClases: '', tap: ''
    });
  }

  setContentRef = (node) => {
    if (node) {
      this.setState(
        { contentRef: node.contentWindow.document }
      );
    }
  }

  getState = () => this.state;

  buildFrame = (code, test) => `
<head>
<base href="/">
</head>
<div>
  <script src='./tape.js'></script>
  <script>
    window.onerror = function (msg, url, ln, col, err) {
      const error = { msg, url, ln, col, err };
      var event = new CustomEvent('iframeError', { detail: error })
      window.parent.document.dispatchEvent(event)
      return true;
    };
  </script>
  <script type="text/javascript">
    var test = tape;
    try {
      ${code}
      ${test.join('\n')}
      var arr = [];
      test.createStream({ objectMode: false })
        .on('data', function (row) {
          arr.push(row)
        })
        .on('close', function () {
          const detail = { results: test.getHarness()._results, tests: test.getHarness()._tests, tap: arr }
          var event = new CustomEvent('tapeEvent', { detail: detail })
          window.parent.document.dispatchEvent(event)
        })
    } catch (e) {
      debugger;
      var event = new CustomEvent('catchError', { detail: e })
      window.parent.document.dispatchEvent(event)
    }
  </script>
</div>
`

  render() {
    const {
      show, dialogClases, message, tap
    } = this.state;
    return (
      <section>
        <p className={show ? dialogClases : 'd-none'} style={{ marginBottom: 0 }}>{message}</p>
        {(tap !== '') ? <pre className="m_campus-card bg__gray6" style={{ height: 'auto', marginTop: 0 }} dangerouslySetInnerHTML={{ __html: tap }} /> : <div />}
        <iframe style={{ display: 'none' }} title="frame" ref={this.setContentRef} />
      </section>
    );
  }
}

TestRunner.propTypes = {
  test: PropTypes.array.isRequired,
  id: PropTypes.array.isRequired,
};

export default TestRunner;
