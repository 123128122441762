import React from 'react';
import PropTypes from 'prop-types';
import RateProjectTable from '../../Components/RateProjectTable';
import './project.scss';

function RateProject({ rate }) {
  return (
    <>
      {rate && (
      <div className="campus-container">
        <h3 className="text__white mb-2">{rate.message}</h3>
        <h4 className="text__white mb-5">{`Projecto: ${rate.results.project}`}</h4>
        <h4 className="text__white mb-2">Tu calificación: </h4>
        <RateProjectTable 
          testPasses={rate.results.testPasses} 
          testTotal={rate.results.testTotal} 
          testFailures={rate.results.testFailures} 
        />
      </div>
      )}
    </>
  );
}

RateProject.propTypes = {
  rate: PropTypes.shape().isRequired
};

export default RateProject;
