import React, { useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useMe } from '../../../Context/MeContext';
import { useCareers } from '../../../Context/CareersContext';
import { Spinner } from '../../../Common/Spinner';

export default (Careers) => function CareersHoc() {
  const [{ profile }] = useMe();
  const [{ isFetching, isError, jobs }, { getCareers, searchJobs }] = useCareers();

  useEffect(() => {
    if (profile) {
      getCareers();
    }
  }, [profile]);

  const debouncedSave = useCallback(
    debounce((nextValue) => searchJobs(nextValue), 1000),
    [], // will be created only once initially
  );
  const render = () => {
    if (jobs) {
      if (jobs.length > 0) {
        return (
          <Careers
            profile={profile}
            isFetching={isFetching}
            isError={isError}
            jobs={jobs}
            search={debouncedSave}
          />
        );
      }
      return (
        <div className="col-10  d-flex justify-content-center align-items-center" style={{ height: '93vh' }}>
          <h2>Por el momento no hay vacantes disponibles.</h2>
        </div>
);
    }

    return (
      <div className="col-10 d-flex justify-content-center align-items-center" style={{ height: '93vh' }}>
        <Spinner />
      </div>
);
  };

  return (
    <>
      {render()}
    </>
  );
};
