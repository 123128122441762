/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Common/Input';
import { Spinner } from '../../Common/Spinner';

const RegisterForm = ({
  handleAuthOption, onRegister, isError, isFetching
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const sendData = async (e) => {
    e.preventDefault();
    onRegister(email, password, confirmPassword, handleAuthOption);
  };

  const handleChangeEmail = (event) => setEmail(event.target.value);
  const handleChangePassword = (event) => setPassword(event.target.value);
  const handleChangecheckPassword = (event) => setConfirmPassword(event.target.value);

  return (
    <div className="o_login-body">
      <div className="o_login-heading">
        <h3 className="text__white">
          Regístrate en Campus
        </h3>
      </div>
      <div className={isError ? 'd-inline' : 'd-none'}>
        <p className="bg__error text__white p-3" style={{ margin: 0 }}>
          {isError}
        </p>
      </div>
      <form onSubmit={sendData}>
        <div className="m_form-group">
          <small className="a_form-legend text__gray6">Correo electrónico</small>
          <Input
            data-testid="input-register-email"
            type="email"
            name="email"
            placeholder="Ingresa el correo electrónico que proporcionaste a DEV.F"
            value={email}
            onChange={handleChangeEmail}
            required
          />
        </div>
        <div className="m_form-group">
          <small className="a_form-legend text__gray6">Contraseña</small>
          <Input
            data-testid="input-register-password"
            type="password"
            name="password"
            placeholder="Ingresa una contraseña segura"
            value={password}
            onChange={handleChangePassword}
            required
          />
        </div>
        <div className="m_form-group">
          <small className="a_form-legend text__gray6">Ingresa nuevamente tu contraseña</small>
          <Input
            data-testid="input-register-repassword"
            type="password"
            name="confirmPassword"
            placeholder=""
            value={confirmPassword}
            onChange={handleChangecheckPassword}
            required
          />
        </div>
        
        <input className="mt-3" type="checkbox" id="terms" required />
        <label className="text__white" style={{ fontSize: 15 }}>
          Acepto
          {' '}
          <a className="text__white" style={{ fontStyle: 'italic' }} target="_blank" without rel="noreferrer" href="https://devf.la/terminos">Término(s) y condiciones</a>
        </label>
        
        <br />
        <input className="mt-3" type="checkbox" id="genre" required />
        <label className="text__white" style={{ fontSize: 15 }}>
          Acepto
          {' '}
          <a className="text__white" target="_blank" style={{ fontStyle: 'italic' }} without rel="noreferrer" href="https://devf.mx/protocolo-genero">Acepto Protocolo de Género, Violentómetro y Reglas de Oro</a>
        </label>

        <div className="d-flex flex-row pt-3 pb-5">
          <button data-testid="button-register" style={{ marginLeft: 0 }} className="btn__tool" type="submit">
            {isFetching ? <Spinner /> : 'REGISTRO'}
          </button>
          <button data-testid="button-register-cancelar" className="btn__ghost-alt" type="button" onClick={() => handleAuthOption('login')}>CANCELAR</button>
        </div>
      </form>
    </div>
  );
};

RegisterForm.propTypes = {
  handleAuthOption: PropTypes.func.isRequired,
  onRegister: PropTypes.func.isRequired,
  isError: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired
};

export default RegisterForm;
