import React from 'react';
import PropTypes from 'prop-types';
import LectureCardAlumni from '../../../Components/Alumni/LectureCard';
import session from '../../../utils/session';
import { AddLectureModal } from '../../../Components/AddLectureModal';

const CreateLecture = ({
 lectures, sensei, createLecture
}) => (
  <div className="container">
    {sensei === 'SENSEI_MASTER' ? (
      <>
        <AddLectureModal
          createLecture={createLecture}
        />
        <div className="container row">

          {lectures.map((lecture) => (
            <LectureCardAlumni
              completed={false}
                // eslint-disable-next-line no-underscore-dangle
              key={lecture._id}
              lecture={lecture}
              senseiMaster
            />
            ))}
        </div>
      </>
      ) : null }
  </div>
  );

CreateLecture.defaultProps = {
  sensei: false,
  createLecture() { },
};

CreateLecture.propTypes = {
  lectures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sensei: PropTypes.bool,
  createLecture: PropTypes.func,
};

export default session(CreateLecture);
