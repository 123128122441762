import React from 'react';
import { useMe } from '../../Context/MeContext';

export default (SidebarProfile) => function NavbarHoc() {
  const [{ profile }] = useMe();
  return (
    <>
      {
        (profile && profile.activeProgram)
        && (
          <SidebarProfile
            profile={profile.alumni}
          />
        )
      }
    </>
  );
};
