/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Selection = ({
  select, 
  answers, 
  setSelect, 
  showModalSoftKills, 
  showModalRecomended,
  showTechnicalSkills, 
}) => {
  const [selected, setSelected] = useState({});

  const handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    setSelected({ ...selected, [name]: value });
  };

  useEffect(() => {
    setSelect({ ...select, ...selected });
  }, [selected]);

  useEffect(() => {
    if (!showModalSoftKills && !showModalRecomended && !showTechnicalSkills) {
      setSelected({});
    }
  }, [showModalSoftKills, showModalRecomended, showTechnicalSkills]);

  return (
    <div className="d-flex flex-column">
      {
        answers.map((answer) => (
          // eslint-disable-next-line no-nested-ternary
          answer.type === 'radio'
            ? (
              <label
                key={answer.id}
                id={answer.id}
                className={`o-modal-content__form -radio ${selected[answer.name] === answer.value ? '-selected' : ''}`}
              >
                <input
                  id={answer.id}
                  data-testid="form-option"
                  name={answer.name}
                  type="radio"
                  value={answer.value}
                  checked={selected[answer.name] === answer.value}
                  onChange={(e) => handleInputChange(e)}
                />
                {answer.label}
              </label>
            ) : answer.type === 'checkbox' ? (
              <>
                {answer.text && <span className="mb-2 mt-4">{answer.text}</span>} 
                <label
                  id={answer.id}
                  key={answer.id}
                  className={`o-modal-content__form -checkbox ${selected[answer.name] ? '-selected' : ''}`}
                >
                  <input
                    id={answer.id}
                    data-testid="form-option"
                    name={answer.name}
                    type="checkbox"
                    checked={selected[answer.name] ? true : answer.checked}
                    onChange={(e) => handleInputChange(e)}
                  />
                  { answer.label}
                </label>
              </>
            )
            : (
              <>
                {answer.text && <span className="mb-2 mt-3">{answer.text}</span>} 
                <label
                  id={answer.id}
                  key={answer.id}
                >
                  <textarea
                    className="o-modal-content__form -input"
                    data-testid="form-option"
                    name={answer.name}
                    value={selected[answer.name] ? selected[answer.name] : answer.value}
                    type="input"
                    onChange={(e) => handleInputChange(e)}
                  />
                  { answer.label}
                </label>
              </>
            )
        ))
      }
    </div>
  );
};

Selection.defaultProps = {
  answers: [], 
};

Selection.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape()),
  setSelect: PropTypes.func.isRequired,
  select: PropTypes.shape().isRequired,
  showModalSoftKills: PropTypes.bool.isRequired,
  showTechnicalSkills: PropTypes.bool.isRequired,
  showModalRecomended: PropTypes.bool.isRequired,
};

export default Selection;
