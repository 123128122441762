import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Pills = ({ pills, children }) => {
  const [active, setActive] = useState(pills[0].ref);

  return (
    <div className="col-lg-12" style={{ overflowX: 'auto' }} data-testid="pills-container">
      <div className="o-nav-pills">
        <ul>
          {pills.map((pill) => (
            <li key={pill.ref}>
              <button
                data-testid="pills-button"
                onClick={() => setActive(pill.ref)}
                className={`a-nav-pills__pill ${active === pill.ref && '-active'}`}
                type="button"
              >
                {pill.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="o-nav-pills__content">
        {children({ active })}
      </div>
    </div>
  );
};

Pills.propTypes = {
  pills: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  children: PropTypes.func.isRequired
};

export default Pills;
