/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Check, User } from 'react-feather';
import { Input } from '../../Common/Input';
import { Spinner } from '../../Common/Spinner';
import detectImage from '../../utils/detectImage';
// import dateToLocale from '../../utils/dateToLocale';
import './alumniForm.scss';
import Mixpanel from '../../utils/Mixpanel';
import { PersonalitiesForm } from '../PersonalitiesForm';

const formatdateForinput = (date) => {
  const dateFormat = `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() + 1 < 10 ? `0${date.getDate() + 1}` : date.getDate() + 1}`;
  return dateFormat;
};

const AlumniForm = ({
  profile, isFetchingUpdateProfile,
  isErrorUpdateProfile, isUpdateProfileSuccess,
  updateProfile, updateAlumni,
}) => {
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState('');
  const [cv, setCv] = useState(null);
  const [cvFile, setCvFile] = useState('');
  const [linkedin, setLinkedin] = useState(profile.linkedin);
  const [linkedinErr, setLinkedinErr] = useState(false);
  const [github, setGithub] = useState(profile.github);
  const [githubErr, setGithubErr] = useState(false);
  const [job, setJob] = useState(profile.interested_in_jobs);
  const [firstName, setFirstName] = useState(profile.first_name || '');
  const [lastName, setLastName] = useState(profile.last_name || '');
  const [phone, setPhone] = useState(profile.phone || '');

  const [birthday, setBirthday] = useState(profile.birthday ? formatdateForinput(new Date(profile.birthday)) : '');
  const [gender, setGender] = useState(profile.gender || '');
  const [studieslvl, setStudieslvl] = useState(profile.studieslvl || '');
  const [career, setCaeer] = useState(profile.career || '');
  const [isWorking, setisWorking] = useState(profile.isWorking);
  const [workplace, setworkplace] = useState(profile.workplace || '');
  const [english, setEnglish] = useState(profile.english || '');
  const [perceivedProfile, setperceivedProfile] = useState(profile.perceivedProfile || '');

  const [personality, setNewPersonality] = useState(profile.personality ? profile.personality.type : 'No registrada');

  const getImage = (e) => {
    e.preventDefault();
    setImageFile(e.target.value);
    const fileData = e.target.files[0];
    setImage(fileData);
  };

  const getCv = (e) => {
    setCvFile(e.target.value);
    const fileData = e.target.files[0];
    setCv(fileData);
  };

  const evaluateIfItsLink = (link) => {
    // eslint-disable-next-line no-useless-escape
    const regexp = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    return regexp.test(link);
  };

  const areLinksCorrect = (linkLinkedin, linkGithub) => {
    setLinkedinErr(false);
    setGithubErr(false);
    if (!evaluateIfItsLink(linkLinkedin) || !linkLinkedin.includes('linkedin')) {
      return setLinkedinErr(true);
    }
    if (!evaluateIfItsLink(linkGithub) || !linkGithub.includes('github')) {
      return setGithubErr(true);
    }
    return true;
  };

  const updateProfileSubmit = (e) => {
    e.preventDefault();
    let dataImage;
    let dataCv;
    if (image) {
      dataImage = new FormData();
      dataImage.append('photo', image);
    }
    if (cv) {
      dataCv = new FormData();
      dataCv.append('cv', cv);
    }

    let personalityObj = {};
    if (profile.personality && profile.personality.type !== personality) {
      personalityObj = {
        type: personality,
        times_updated: profile.personality.times_updated + 1,
      };
    } else {
      personalityObj = {
        type: personality,
        times_updated: 1,
      };
    }

    const update = {
      first_name: firstName,
      last_name: lastName,
      phone,
      personality: personalityObj,
      birthday,
      gender,
      career,
      studieslvl,
      isWorking,
      workplace,
      english,
      perceivedProfile
    };
    Mixpanel.track('CampusAlumniForm-UpdateProfile');
    
    if (areLinksCorrect(linkedin, github)) {
      updateAlumni(update);
      updateProfile(dataImage, dataCv,
        {
          linkedin,
          github,
          interested_in_jobs: job,
          personality: personalityObj,
          birthday,
          gender,
          career,
          studieslvl,
          isWorking,
          workplace,
          english,
          perceivedProfile
        });
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <h4 className="text__white">Información básica</h4>
        <form onSubmit={updateProfileSubmit}>
          <div className="m_campus-program-card">
            <section className="d-flex justify-content-center align-items-center">
              {profile.profile_pic
              ? (
                <label htmlFor="photo-upload" className="m-profile-img__container fas">
                  <div className="img-wrap img-upload">
                    <img
                      className="m-profile_img__img"
                      htmlFor="photo-upload" 
                      src={image ? URL.createObjectURL(image) : detectImage(profile.profile_pic) ? profile.profile_pic : `data:image/png;base64, ${profile.profile_pic}`}
                      alt="Perfil"
                    />
                  </div>
                  <Input
                    name="image"
                    type="file"
                    value={imageFile}
                    placeholder=""
                    accept="image/*"
                    icon="image"
                    required={false}
                    id="photo-upload"
                    onChange={getImage}
                  />  
                </label>
                )
              : <Spinner />}
            </section>
            {/* Datos Personales */}
            <h5 className="text__white">Datos personales</h5>
            <div className="row">
              <div className="m_form-group pt-3 col-12 col-md-6">
                <Input
                  data-testid="input-Alumniform-name"
                  name="first_name"
                  label="Nombre"
                  type="text"
                  value={firstName}
                  placeholder=""
                  required={false}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="m_form-group pt-3 col-12 col-md-6">
                <Input
                  data-testid="input-Alumniform-last_name"
                  name="last_name"
                  label="Apellidos"
                  type="text"
                  value={lastName}
                  placeholder=""
                  required={false}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="m_form-group pt-3 col-12 col-md-6">
                <Input
                  data-testid="input-Alumniform-birthday"
                  name="birthday"
                  label="Fecha de nacimiento"
                  type="date"
                  value={birthday}
                  placeholder=""
                  required
                  onChange={(e) => setBirthday(e.target.value)}
                />
              </div>
              <div className="m_form-group pt-3 col-12 col-md-6">
                <div className="field">
                  <label htmlFor="gender" className="label">Género</label>
                  <div className="control has-icons-right">
                    <select
                      className="a_input"
                      id="gender"
                      name="gender"
                      data-testid="input-Alumniform-gender"
                      value={gender}
                      required
                      style={{
                        background: '#383851', color: 'white', marginTop: '10px', border: 'none' 
                      }}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="" disabled>Género</option>
                      <option value="M">Femenino</option>
                      <option value="H">Masculino</option>
                      <option value="OTHER">Otro</option>
                      <option value="SE">Prefiero no responder</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <span style={{ marginTop: '.3rem', fontSize: '14px' }} className="text__gray4">La información aquí vertida es privada y se utilizará con fines meramente estadísticos</span>
            <h5 className="text__white mt-5">Información de contacto</h5>
            <div className="row">
              <div className="m_form-group pt-3 col-12">
                <Input
                  data-testid="input-Alumniform-phone"
                  name="phone"
                  label="Teléfono"
                  type="text"
                  value={phone}
                  placeholder=""
                  required={false}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="m_form-group pt-3 col-12 col-md-6">
                <div className="field">
                  <label htmlFor="study" className="study">Nivel de estudios tienes completado</label>
                  <div className="control has-icons-right">
                    <select
                      required
                      data-testid="input-Alumniform-study"
                      className="a_input"
                      id="study"
                      name="study"
                      value={studieslvl}
                      style={{
                          background: '#383851', color: 'white', marginTop: '10px', border: 'none' 
                        }}
                      onChange={(e) => setStudieslvl(e.target.value)}
                    >
                      <option value="" disabled>Nivel de estudios</option>
                      <option value="BACHILLERATO">Bachillerato</option>
                      <option value="UNIVERSIDAD">Universidad</option>
                      <option value="POSTGRADO">Post-Grado</option>
                      <option value="OTRO">Otro</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="m_form-group pt-3 col-12 col-md-6">
                <Input
                  data-testid="input-Alumniform-career"
                  name="career"
                  label="Que carrera estudiaste"
                  type="text"
                  value={career}
                  placeholder=""
                  required
                  onChange={(e) => setCaeer(e.target.value)}
                />
              </div>
            </div>
            <PersonalitiesForm
              alumniPersonality={profile.personality}
              personality={personality}
              setNewPersonality={setNewPersonality}
            />
            {/* Datos Profesionales */}
            <h5 className="text__white mt-5">Curriculum</h5>
            <div className="m_form-group pt-3">
              {profile.cv_url
                ? (
                  <>
                    <Input
                      name="cv"
                      label="Actualiza tu CV en formato PDF"
                      type="file"
                      value={cvFile}
                      placeholder=""
                      icon="image"
                      accept="application/pdf"
                      required={false}
                      onChange={getCv}
                    />
                    <span style={{ marginTop: '.3rem', fontSize: '14px' }} className="text__gray4">
                      Ya tenemos tu CV registrado si quieres actualizarlo
                      puedes volver a subir otro.
                    </span>
                  </>
                )
                : (
                  <Input
                    name="cv"
                    label="Adjunta tu CV en formato PDF"
                    type="file"
                    value={cvFile}
                    placeholder=""
                    icon="image"
                    accept="application/pdf"
                    required={false}
                    onChange={getCv}
                  />
                )}
            </div>
            <div className="d-flex align-items-center mt-3">
              <div className="m-profile_toggle">
                <input name="job" id="job" type="checkbox" checked={job} onChange={(e) => setJob(e.target.checked)} />
                <label htmlFor="job" className="m-pofile-slider round" />
              </div>
              <small className="text__gray4 ml-3">Estoy interesado en recibir vacantes a mi correo</small>
            </div>
            <div className="row">
              <div className="m_form-group pt-3 col-12 col-md-6">
                <div className="d-flex align-items-center mt-3">
                  <div className="m-profile_toggle">
                    <input data-testid="input-Alumniform-isWorking" name="isWorking" id="isWorking" type="checkbox" checked={isWorking} onChange={(e) => setisWorking(e.target.checked)} />
                    <label htmlFor="isWorking" className="m-pofile-slider round" />
                  </div>
                  <small className="text__gray4 ml-3">Actualmente trabajas</small>
                </div>
              </div>
              {isWorking && (
              <div className="m_form-group pt-3 col-12 col-md-6">
                <div className="field">
                  <label htmlFor="study" className="study">¿En dónde trabajas?</label>
                  <div className="control has-icons-right">
                    <select
                      data-testid="input-Alumniform-workplace"
                      className="a_input"
                      id="workplace"
                      name="workplace"
                      value={workplace}
                      style={{
                          background: '#383851', color: 'white', marginTop: '10px', border: 'none' 
                        }}
                      onChange={(e) => setworkplace(e.target.value)}
                    >
                      <option value="" disabled>¿En dónde trabajas?</option>
                      <option value="GOB">Gobierno</option>
                      <option value="ONG">ONG</option>
                      <option value="EDUCACION">Trabajo en Educación,</option>
                      <option value="EMPRESA">Trabajo en una empresa / corporativo</option>
                      <option value="STARTUP">Trabajo en una Startup</option>
                      <option value="EMPRENDEDXR">Soy emprendedor/a</option>
                      <option value="FREELANCE">Soy Freelance</option>
                      <option value="OTRO">Otro</option>
                    </select>
                  </div>
                </div>
              </div>
            )}
            </div>

            <div className="row">
              <div className="m_form-group pt-3 col-12 col-md-6">
                <div className="field">
                  <label htmlFor="study" className="study">Nivel de inglés</label>
                  <div className="control has-icons-right">
                    <select
                      data-testid="input-Alumniform-english"
                      className="a_input"
                      id="english"
                      name="english"
                      value={english}
                      style={{
                          background: '#383851', color: 'white', marginTop: '10px', border: 'none' 
                        }}
                      onChange={(e) => setEnglish(e.target.value)}
                    >
                      <option value="" disabled>Inglés</option>
                      <option value="10">Excelente</option>
                      <option value="9">Muy Bueno</option>
                      <option value="8">Bueno</option>
                      <option value="7">No tan bueno</option>
                      <option value="6">Malo</option>
                      <option value="5">Muy malo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="m_form-group pt-3 col-12 col-md-6">
                <div className="field">
                  <label htmlFor="study" className="study">Perfil con el que te identificas más</label>
                  <div className="control has-icons-right">
                    <select
                      className="a_input"
                      data-testid="input-Alumniform-perceivedProfile"
                      id="perceivedProfile"
                      name="perceivedProfile"
                      value={perceivedProfile}
                      style={{
                          background: '#383851', color: 'white', marginTop: '10px', border: 'none' 
                        }}
                      onChange={(e) => setperceivedProfile(e.target.value)}
                    >
                      <option value="" disabled>Perfil</option>
                      <option value="PRINCIPIANTE">Principiante (pocos conocimientos)</option>
                      <option value="BACK">Desarrollador@ Back-End</option>
                      <option value="FRONT">Desarrolador@ Front-End</option>
                      <option value="DISENO">Diseñador@</option>
                      <option value="DB">Data Base Administrator</option>
                      <option value="EMPRENDEDXR">Emprendedor@ / Empresari@</option>
                      <option value="PM">Product Manager</option>
                      <option value="OTRO">Otro</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Redes sociales */}
            <h5 className="text__white mt-5">Redes sociales</h5>
            <p className="text__white" style={{ fontSize: '14px' }}>Conecta a los perfiles públicos de tus redes sociales y deja que las demás personas de nuestra comunidad te puedan contactar</p>
            <div className="row">
              <div className="m_form-group pt-3 col-12">
                <Input
                  data-testid="input-Alumniform-linkedin"
                  name="linkedin"
                  label="LinkedIn"
                  type="text"
                  placeholder=""
                  required={false}
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
                {
                linkedinErr && <small className="text__error mt-1"> Por favor agrega un link válido.</small>
              }
              </div>
              <div className="m_form-group pt-3 col-12">
                <Input
                  data-testid="input-Alumniform-github"
                  name="github"
                  label="Github"
                  type="text"
                  value={github}
                  placeholder=""
                  required={false}
                  onChange={(e) => setGithub(e.target.value)}
                />
                {
                githubErr && <small className="text__error mt-1"> Por favor agrega un link válido.</small>
              }
              </div>
            </div>
            {/* Buton guardad */}
            {isErrorUpdateProfile && <small className="text__error">Tuvimos un error actualizando tu información, intenta mas tarde.</small>}
            <div className="pt-4 d-flex">
              {isFetchingUpdateProfile
                ? (
                  <button type="button" className="a-btn btn__brand-dark" style={{ borderRadius: ' 8px' }} disabled>
                    <Spinner />
                  </button>
                )
                : (
                  <button
                    data-testid="button-AlimniForm-save_personal_data"
                    style={{ marginLeft: 0, borderRadius: ' 8px' }}
                    type="submit"
                    className={isUpdateProfileSuccess ? 'a-btn btn__success' : 'btn__tool'}
                    disabled={isFetchingUpdateProfile || isUpdateProfileSuccess}
                  >
                    {isUpdateProfileSuccess 
                    ? (
                      <>
                        <Check className="mr-3" />
                        Datos actualizados correctamente
                      </>
                      )
                    : (
                      <>
                        <User className="mr-3" />
                        Guardar
                      </>
                    )}
                  </button>
                )}
            </div>
          </div>
        </form>
      </div>

    </div>
  );
};

AlumniForm.propTypes = {
  isErrorUpdateProfile: PropTypes.bool.isRequired,
  isFetchingUpdateProfile: PropTypes.bool.isRequired,
  isUpdateProfileSuccess: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    github: PropTypes.string.isRequired,
    linkedin: PropTypes.string.isRequired,
    profile_pic: PropTypes.string.isRequired,
    interested_in_jobs: PropTypes.bool,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    phone: PropTypes.string,
    birthday: PropTypes.string,
    gender: PropTypes.string,
    career: PropTypes.string,
    studieslvl: PropTypes.string,
    isWorking: PropTypes.string,
    workplace: PropTypes.string,
    english: PropTypes.string,
    perceivedProfile: PropTypes.string,
    cv_url: PropTypes.string,
    personality: PropTypes.shape({
      type: PropTypes.string.isRequired,
      times_updated: PropTypes.number.isRequired,
    })
  }).isRequired,
  updateProfile: PropTypes.func.isRequired,
  updateAlumni: PropTypes.func.isRequired,
};

export default AlumniForm;
