/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import React, { useState, memo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {
 faSort, faSortDown, faSortUp, faFile, faThumbsUp, faCircle
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useSortBy, useTable } from 'react-table';
import { Modal } from '../../../Common/Modal';
import { Form } from '../../../Common/Form';
import { Spinner } from '../../../Common/Spinner';
import './table.scss';

const TableSensei = memo(({
  data, 
  columns, 
  recommendation, 
  addRecommendation, 
  deactivateRecommendation, 
  isFecthingAddRecommendation,
  isFetchingDeleteRecommendation,
  isSelfPaced,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );
  const [showDeleteRecommend, setShowDeleteRecommend] = useState(false);
  const [showModalSoftKills, setShowModalSoftKills] = useState(false);
  const [showTechnicalSkills, setShowTechnicalSkills] = useState(false);
  const [showModalRecomended, setShowModalRecomended] = useState(false);
  const [nameByAlumni, setNameByAlumni] = useState('');
  const [campusProgramIdByAlumni, setCampusProgramIdByAlumni] = useState('');
  const [select, setSelect] = useState({});

  useEffect(() => {
    setSelect({ ...select, campusProgramId: campusProgramIdByAlumni });
  }, [showModalSoftKills]);

  useEffect(() => {
    if (!showModalSoftKills && !showModalRecomended && !showTechnicalSkills) {
      setSelect({});
    }
  }, [showModalSoftKills, showModalRecomended, showTechnicalSkills]);

  useEffect(() => {
    if (isFecthingAddRecommendation) {
      setShowModalSoftKills(false);
      setShowTechnicalSkills(false);
      setShowModalRecomended(false);
    }
  }, [isFecthingAddRecommendation]);

  useEffect(() => {
      setShowDeleteRecommend(false);
  }, [isFetchingDeleteRecommendation]);

  return (
    <>
      <Modal
        show={showDeleteRecommend}
        closeModal={() => setShowDeleteRecommend(false)}
        dismissible
      >
        <>
          <h6>¿Estás seguro que quieres desrecomendar al alumni?</h6>
          <h5> 
            <p>{`${nameByAlumni}`}</p>
          </h5>
          {
            !isFetchingDeleteRecommendation
            ? (
              <button
                data-testid="table-sensei-recommendation"
                type="button"
                className="button--desrecommendation"
                onClick={() => {
                        setShowDeleteRecommend(true);
                        deactivateRecommendation(campusProgramIdByAlumni);
                      }}
              >
                Desrecomendar
              </button>
            ) : (
              <Spinner />
            )
          }
        </>
      </Modal>

      <Modal
        show={showModalSoftKills}
        closeModal={() => setShowModalSoftKills(false)}
        dismissible
      >
        <>
          <p>{`Alumni: ${nameByAlumni}`}</p>
          <Form
            showModalSoftKills={showModalSoftKills}
            showModalRecomended={showModalRecomended}
            showTechnicalSkills={showTechnicalSkills} 
            select={select}
            setSelect={setSelect}
            dataForm={{ ...recommendation.soft_skills }}
            next={setShowTechnicalSkills}
            close={setShowModalSoftKills}
          />
        </>
      </Modal>

      <Modal
        show={showTechnicalSkills}
        closeModal={() => setShowTechnicalSkills(false)}
        dismissible
      >
        <>
          <p>{`Alumni: ${nameByAlumni}`}</p>
          <Form
            showModalSoftKills={showModalSoftKills}
            showModalRecomended={showModalRecomended}
            showTechnicalSkills={showTechnicalSkills} 
            select={select}
            setSelect={setSelect}
            dataForm={{ ...recommendation.technical_skills }}
            back={setShowModalSoftKills}
            next={setShowModalRecomended}
            close={setShowTechnicalSkills}
          />
        </>
      </Modal>

      <Modal
        show={showModalRecomended}
        closeModal={() => setShowModalRecomended(false)}
        dismissible
      >
        <>
          <p>{`Alumni: ${nameByAlumni}`}</p>
          <Form
            showModalSoftKills={showModalSoftKills}
            showModalRecomended={showModalRecomended} 
            showTechnicalSkills={showTechnicalSkills} 
            select={select}
            setSelect={setSelect}
            dataForm={recommendation.recomended}
            back={setShowTechnicalSkills}
            action={addRecommendation}
            close={setShowModalRecomended}
          />
        </>
      </Modal>

      <table {...getTableProps()} id="table-sensei" data-testid="table-sensei">
        <thead>
          {headerGroups.map((headerGroup, ii) => (
            <tr key={`header-${ii}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  data-testid="table-sort"
                  className="a-column__name"
                  key={`th-${i}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div>
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} color="#765CCC" />
                      )}
                    </span>
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} data-testid="table-sensei-body">
          {rows.map((row, iii) => {
            prepareRow(row);
            return (
              <tr data-testid="table-sensei-row" key={`rows-${iii}`} {...row.getRowProps()}>
                {row.cells.map((cell, iv) => (
                  <td
                    className={`-${cell.column.id}`}
                    key={`row-${iv}`}
                    {...cell.getCellProps()}
                  >
                    {
                      cell.column.id === 'actions'
                        ? (
                          <div className="row row-actions">
                            <div className="icon-profile">
                              <div className="icons"> 
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={row.original.github}
                                >
                                  <FontAwesomeIcon 
                                    className={`icon-alumni ${row.original.github ? 'github' : ''}`}
                                    icon={faGithub}
                                  />
                                </a>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={row.original.linkedin}
                                >
                                  <FontAwesomeIcon 
                                    className={`icon-alumni ${row.original.linkedin ? 'linkedin' : ''}`}
                                    icon={faLinkedin}
                                  />
                                </a>
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={row.original.cv_url}
                                >
                                  <FontAwesomeIcon 
                                    className={`icon-alumni ${row.original.cv_url ? 'cv' : ''}`}
                                    icon={faFile}
                                  />
                                </a>
                                <>
                                  {row.original.isRecommended === true
                                ? (
                                  <abbr title="Desrecomendar">
                                    <FontAwesomeIcon
                                      icon={faThumbsUp}
                                      className="icon-desrecommendation icon-alumni"
                                      data-testid="table-sensei-recommendation"
                                      onClick={() => {
                                      setShowDeleteRecommend(true);
                                      setCampusProgramIdByAlumni(row.original.campusProgramId);
                                      setNameByAlumni(`${row.original.first_name} ${row.original.last_name}`);
                                    }}
                                    />
                                  </abbr>
                                )
                                : (
                                  <abbr title="Recomendar">
                                    <FontAwesomeIcon
                                      icon={faThumbsUp}
                                      data-testid="table-sensei-recommendation"
                                      className="icon-recommendation icon-alumni"
                                      onClick={() => {
                                      setShowModalSoftKills(true);
                                      setCampusProgramIdByAlumni(row.original.campusProgramId);
                                      setNameByAlumni(`${row.original.first_name} ${row.original.last_name}`);
                                    }}
                                    />
                                  </abbr>
                                )}
                                </>
                              </div>
                            </div>
                          </div>
                        )
                        : (
                          cell.column.id === 'first_name'
                          ? (
                            isSelfPaced 
                            ? (
                              <Link to={`/sensei/alumni/profile/self/${row.original.alumniId}/${row.original.campusProgramId}`}>
                                <p className="link-profile">{`${row.original.first_name} ${row.original.last_name}`}</p>
                              </Link>
                            )
                            : (
                              <Link to={`/sensei/alumni/profile/${row.original.alumniId}/${row.original.campusProgramId}`}>
                                <p className="link-profile">{`${row.original.first_name} ${row.original.last_name}`}</p>
                              </Link>
                            )
                          )
                          : cell.column.id === 'alert_projects' 
                          ? (
                            row.original.alert_projects === true && <FontAwesomeIcon icon={faCircle} color="#DB4437" />
                          )
                          : cell.render('Cell'))
                    }
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
});

TableSensei.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  addRecommendation: PropTypes.func.isRequired,
  deactivateRecommendation: PropTypes.func.isRequired,
  isFecthingAddRecommendation: PropTypes.bool.isRequired,
  isFetchingDeleteRecommendation: PropTypes.bool.isRequired,
  recommendation: PropTypes.shape({
    soft_skills: PropTypes.shape({
      title: PropTypes.string,
      button: PropTypes.string,
      answers: PropTypes.arrayOf(PropTypes.shape())
    }).isRequired,
    technical_skills: PropTypes.shape({
      title: PropTypes.string,
      button: PropTypes.string,
      answers: PropTypes.arrayOf(PropTypes.shape())
    }).isRequired,
    recomended: PropTypes.shape({
      title: PropTypes.string,
      button: PropTypes.string,
      answers: PropTypes.arrayOf(PropTypes.shape())
    }).isRequired,
    role: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  isSelfPaced: PropTypes.bool.isRequired,
};

export default TableSensei;
