/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Common/Input';
import lastClass from '../LastClassModal/lastClass.png';
import './helpModal.scss';

function HelpModal({
 profile,
 isFetchingUpdateProfile,
 isUpdateProfileSuccess,
 createTicket,
 closeModal,
}) {
  const [ticketSubject, setTicketSubject] = useState('');
  const [ticketDescription, setTicketDescription] = useState('');
  const [ticketError, setTicketError] = useState('');

  const createHelpTicket = async () => {
    if (ticketDescription.length < 5 || ticketSubject.length < 5) {
      return setTicketError('Debes llenar todos los campos');
    }
    setTicketError('');

    const userInfo = `Nombre: ${profile.alumni.first_name} ${profile.alumni.last_name}, Correo: ${profile.alumni.email}`;

    const ticket = {
      subject: ticketSubject,
      content: `${ticketDescription} ${userInfo}`,
      };

    const success = await createTicket(ticket);
    if (!success) {
      return setTicketError('Ocurrió un error. Ponte en contacto con dentro del bot');
    }
    return setTimeout(() => {
      setTicketSubject('');
      setTicketDescription('');
      closeModal();
    }, 1500);
  };

   return (
     <div className="help-container">
       <form
         className="d-flex flex-column align-items-center"
         onSubmit={(e) => {
            e.preventDefault();
            createHelpTicket();
          }}
       >
         <img className="help-top-image" src={lastClass} alt="Last class" />
         <h6 className="mt-3 mb-0">Ponte en contacto con un asesor de DEV.F</h6>
         <p className="mt-3">Después de enviar tus comentarios, un asesor se pondrá en contacto contigo via email</p>
         <label>¿CUÁL ES EL TEMA A TRATAR?</label>
         <Input
           data-testid="input-ticketSubject"
           type="text"
           name="help-subject"
           value={ticketSubject}
           placeholder="ejemplo: No puedo conectarme a mi clase"
           required
           onChange={(e) => setTicketSubject(e.target.value)}
           style={{ marginBottom: '16px' }}
         />
         <label>DESCRIBE LA SITUACIÓN A DETALLE</label>
         <textarea
           data-testid="textarea-ticketDescription"
           className="a_textarea"
           name="help-description"
           rows="8"
           cols="60"
           type="text"
           value={ticketDescription}
           placeholder='ejemplo: Al hacer click en el botón "ir a mi clase" no ocurre nada'
           required
           onChange={(e) => setTicketDescription(e.target.value)}
         />
         <span className="text__error">{ ticketError }</span>
         <button
           data-testid="button-sendForm"
           type="submit"
           style={{ margin: 0 }}
           className={isUpdateProfileSuccess ? 'a-btn btn__success mt-4' : 'btn__tool mt-4'}
           disabled={isFetchingUpdateProfile || isUpdateProfileSuccess}
           onClick={createHelpTicket}
         >
           { isUpdateProfileSuccess
                    ? 'Mensaje enviado exitosamente'
                    : 'Enviar mensaje' }
         </button>
       </form>
     </div>
    );
}

HelpModal.propTypes = {
  profile: PropTypes.shape().isRequired,
  isFetchingUpdateProfile: PropTypes.bool.isRequired,
  isUpdateProfileSuccess: PropTypes.bool.isRequired,
  createTicket: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default HelpModal;
