export default {
    title: 'Ejercicio 12',
    description: `
    Crea una función llamada <code>isChristmas()</code> que reciba como parámetro una fecha cualquiera (“dia-mes-año”), 
    y verifica si dicha fecha corresponde a Navidad. 
    <ul>
    <li>Retorna <code>true</code>. En caso de que sea Navidad.</li>
    <li>Retorna <code>false</code>. En caso de no sea Navidad.</li>
    </ul>
    `,
    seed: '// Escribe tu código aquí',
    test: [
      `test('Probar si 25-11-12', function (t) {
        t.equal(isChristmas("25-11-12"), false,'Esto debe retornar false');
        t.end()
      });`,
      `test('Probar si 12-12-37', function (t) {
        t.equal(isChristmas("12-12-37"), false,'Esto debe retornar false');
        t.end()
      });`,
      `test('Probar si 25-12-94', function (t) {
        t.equal(isChristmas("25-12-94"), true,'Esto debe retornar true');
        t.end()
      });`,
    ]
  };
