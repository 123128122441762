/* eslint-disable no-underscore-dangle */
import React, {
  useContext,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import api from './api';
import { OPEN_PROFILE } from '../utils/tracks';
// import { CONTROL_GROUP } from '../utils/const';

const MeContext = React.createContext(undefined);

const MeProvider = withRouter(({ children, history }) => {
  const [profile, setProfile] = useState(null);
  const [qrData, setqrData] = useState(null);
  const [suspensionDate, setSuspensionDate] = useState(null);
  const [isSuspended, setIsSuspended] = useState(false);
  const [isFetchingUpdateProfile, setIsFetchingUpdateProfile] = useState(false);
  const [isErrorUpdateProfile, setIsErrorUpdateProfile] = useState(false);
  const [isUpdateProfileSuccess, setIsUpdateProfileSuccess] = useState(false);
  const [moduleSensei, setModuleSensei] = useState({});

  const [ratingClass, setRatingClass] = useState(null);

  const getModuleClass = async (idCampusProgram) => {
    const module = await api.Me.getSenseiAndClass(idCampusProgram);
    return module?.moduleSensei;
  };

  const getCurrentModule = (modules, isActiveCampusProgram) => {
    if (!isActiveCampusProgram) return modules[0];
    
    // Find current module
    const now = new Date();
    const currentModule = modules.find(
      (module) => now > new Date(module.startDate) && now < new Date(module.finishDate)
    );
    return currentModule || modules[0];
  };

  const setActiveProgram = async (slugProgram, anteProfile = null, toMyCourse = false) => {
    const profilee = anteProfile || profile;
    const activeProgram = profilee.activePrograms
      .find((program) => slugProgram === program.infoProgram.slug);

    const moduleSenseiClass = await getModuleClass(activeProgram._id);
    if (moduleSenseiClass) setModuleSensei(moduleSenseiClass);

    activeProgram.currentModule = getCurrentModule(activeProgram.modules, activeProgram.active);

    setProfile({
      ...profilee,
      activeProgram,
    });
    if (toMyCourse) {
      history.push('/alumni/course');
    } else if (history.location.pathname.search('/projects/save/') !== 0) {
      // if (CONTROL_GROUP.indexOf(activeProgram.batch) !== -1) {
      setTimeout(() => history.push('/alumni/course'), 500);
      // }
      // history.push('/alumni/profile');
    }
    api.Track.trackAliumni(OPEN_PROFILE, { program: activeProgram.infoProgram.slug });
  };

  const logout = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload(false);
    }, 400);
  };

  const suspensionDateCalculator = (lastInvoice) => {
    if (lastInvoice && lastInvoice.status !== 'success') {
      const date = new Date(lastInvoice.created);
      date.setDate(date.getDate() + 2 * 7);
      setSuspensionDate(date);
      setIsSuspended(new Date() > date);
    }
  };

  const getProfile = async () => {
    try {
      const data = await api.Me.me();
      setProfile(data);
      setActiveProgram(data.activePrograms[data.activePrograms.length - 1].infoProgram.slug,
        data);
      suspensionDateCalculator(data.lastInvoice);
    } catch (error) {
      // eslint-disable-next-line no-console
      if (process.env.REACT_APP_DEBUG === 'false' || process.env.REACT_APP_DEBUG === undefined) {
        logout();
      }
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    if (profile === null) {
      getProfile();
    }
  }, [profile]);

  const updateProfile = async (photo, cv, data) => {
    setIsErrorUpdateProfile(false);
    setIsFetchingUpdateProfile(true);
    try {
      if (photo) {
        await api.Profile.updatePhotoProfile(photo);
      }
      if (cv) {
        await api.Profile.updateCvProfile(cv);
      }
      if (data) {
        await api.Profile.updateProfile(data);
      }
      setTimeout(() => {
        getProfile();
        setIsUpdateProfileSuccess(false);
      }, 1000);
      setIsUpdateProfileSuccess(true);
    } catch (error) {
      switch (error.response.status) {
        case 500:
          setIsErrorUpdateProfile('Ocurrió un error en el servidor');
          break;
        default:
          setIsErrorUpdateProfile(error.response.data.message);
          break;
      }
    }
    setIsFetchingUpdateProfile(false);
  };

  const updateAlumni = async (body) => {
    setIsErrorUpdateProfile(false);
    setIsFetchingUpdateProfile(true);
    try {
      await api.Profile.updateAlumni(body);
      setTimeout(() => {
        getProfile();
        setIsUpdateProfileSuccess(false);
      }, 1000);
      setIsUpdateProfileSuccess(true);
    } catch (error) {
      switch (error.response.status) {
        case 500:
          setIsErrorUpdateProfile('Ocurrió un error en el servidor');
          break;
        default:
          setIsErrorUpdateProfile(error.response.data.message);
          break;
      }
    }
    setIsFetchingUpdateProfile(false);
  };

  const updateBillingData = (body) => {
    setIsErrorUpdateProfile(false);
    setIsFetchingUpdateProfile(true);
    try {
      api.Profile.updateBilling(body);
      setTimeout(() => {
        getProfile();
        setIsUpdateProfileSuccess(false);
      }, 1000);
      setIsUpdateProfileSuccess(true);
    } catch (error) {
      switch (error.response.status) {
        case 500:
          setIsErrorUpdateProfile('Ocurrió un error en el servidor');
          break;
        default:
          setIsErrorUpdateProfile(error.response.data.message);
          break;
      }
    }
    setIsFetchingUpdateProfile(false);
  };

  const updatePassword = async (body) => {
    setIsErrorUpdateProfile(false);
    setIsFetchingUpdateProfile(true);
    try {
      await api.Profile.updatePassword(body);
      setTimeout(() => {
        getProfile();
        setIsUpdateProfileSuccess(false);
      }, 1000);
      setIsUpdateProfileSuccess(true);
    } catch (error) {
      setIsUpdateProfileSuccess(false);
      switch (error.response.status) {
        case 500:
          setIsErrorUpdateProfile('Ocurrió un error en el servidor');
          break;
        default:
          setIsErrorUpdateProfile(error.response.data.message);
          break;
      }
    }
    setIsFetchingUpdateProfile(false);
  };

  const sendEmail = (body) => {
    setIsErrorUpdateProfile(false);
    setIsFetchingUpdateProfile(true);
    try {
      api.Profile.sendEmail(body);
      setTimeout(() => {
        getProfile();
        setIsUpdateProfileSuccess(false);
      }, 1000);
      setIsUpdateProfileSuccess(true);
    } catch (error) {
      setIsUpdateProfileSuccess(false);
      switch (error.response.status) {
        case 500:
          setIsErrorUpdateProfile('Ocurrió un error en el servidor');
          break;
        default:
          setIsErrorUpdateProfile(error.response.data.message);
          break;
      }
    }
    setIsFetchingUpdateProfile(false);
  };

  const createTicket = async (body) => {
    setIsErrorUpdateProfile(false);
    setIsFetchingUpdateProfile(true);
    try {
      await api.Profile.createTicket(body);
      setTimeout(() => {
        setIsUpdateProfileSuccess(false);
        setIsFetchingUpdateProfile(false);
      }, 1500);
      setIsUpdateProfileSuccess(true);
      return true;
    } catch (error) {
      setIsUpdateProfileSuccess(false);
      switch (error.response.status) {
        case 500:
          setIsErrorUpdateProfile('Ocurrió un error en el servidor');
          break;
        default:
          setIsErrorUpdateProfile(error.response.data.message);
          break;
      }
    }
    setIsFetchingUpdateProfile(false);
    return false;
  };

  const track = async (cosnt, body = {}) => {
    try {
      const data = await api.Track.trackAliumni(cosnt,
          { ...body, campusProgram: profile.activeProgram._id });
      // console.log(data);
      if (!data.selfpaced) {
        const prof = {
          ...profile,
          activeProgram: {
            ...profile.activeProgram,
            tracking: {
              ...data.trackking
            }
          }
        };
        setProfile(prof);
      } else {
        const indexModule = profile.activeProgram.modules
          .map(({ slug }) => slug)
          .indexOf(data.trackking.slug);
        const newActiveProgram = {
          ...profile.activeProgram,
        };
        newActiveProgram.modules[indexModule] = data.trackking;
        const prof = {
          ...profile,
          activeProgram: {
            ...newActiveProgram,
          }
        };
        setProfile(prof);
    }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error');
    }
  };

  const syncProfile = async () => {
    try {
      const data = await api.Me.me();
      const prof = {
        ...profile,
        activeProgram: {
          ...profile.activeProgram,
          tracking: {
            ...data.activePrograms.find(
              (pro) => pro.program === profile.activeProgram.program
            ).tracking
          }
        }
      };
      setProfile(prof);
      // setActiveProgram('frontend', data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  
  const syncProfileTracking = async (data) => {
    try {
      const prof = {
        ...profile,
        activeProgram: {
          ...profile.activeProgram,
          tracking: {
            ...data.tracking
          }
        }
      };
      setProfile(prof);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const signDiploma = async (program) => {
    try {
      const data = await api.Me.signDiploma(program);
      if (data) setqrData(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const createRatingClass = async (body, options) => {
    const ratingClassBody = {
      options: options.filter((opt) => opt.value).map((opt) => opt.key),
      evaluation: body ? 'LIKE' : 'DISLIKE',
      sensei_id: profile.activeProgram.group_detail.sensei_detail.id,
      sensei: profile.activeProgram.group_detail.sensei_detail.name,
      batch: profile.activeProgram.batch,
      program: profile.activeProgram.infoProgram.slug,
      email: profile.alumni.email,
      campus_program: profile.activeProgram._id,
      lectures: [],
    };
    try {
      if (ratingClass && ratingClass._id) {
        await api.Profile.updateRatingClass(ratingClassBody, ratingClass._id);
        setRatingClass(null);
        localStorage.removeItem('MEET');
        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      } else {
        const { data: newRattingClass } = await api.Profile.createRatingClass(ratingClassBody);
        setRatingClass(newRattingClass);
      }
    } catch (error) {
      // TODO: Mandar un update para saber que existio un error
    }
  };

  const registerClass = async (senseiModule, cb) => {
    const registeredClass = await api.Me.registerClass(senseiModule, profile.activeProgram._id);
    if (registeredClass.status === 204) cb(true);
    else cb(null, registeredClass.join_url);
  };

  const sendFinalProject = async (idCampusProgram, update, cb) => {
    setIsErrorUpdateProfile(false);
    setIsFetchingUpdateProfile(true);
    try {
      const project = await api.Profile.sendFinalProject(idCampusProgram, update);
      setIsUpdateProfileSuccess(true);
      cb(null, project);
   } catch (e) {
     cb(e?.response?.data?.message, null);
     setIsErrorUpdateProfile('Ocurrió un error en el servidor');
   }
    setIsFetchingUpdateProfile(false);
  };
  const getMyResume = async (activeProgramId, cb) => {
    const resume = await api.Me.getMyResume(activeProgramId);
    if (resume.status === 200) cb(null, resume.data);
    else cb('Error');
  };

  const attendedNotification = (activeProgramId, notificationId) => {
    api.Me.attendedNotification(activeProgramId, notificationId);
  };

  const onRatingClass = async (
    stars, selectedTags, comments, campusProgram, module, assistenceToRate
  ) => {
    try {
    //  console.log(stars, selectedTags, comments, campusProgram);
     const data = {
      evaluation_number: stars,
      options: selectedTags,
      comments,
      campus_program: campusProgram,
      module,
      date_to_rate: assistenceToRate,
     };
     await api.Profile.createRatingClass(data);
    } catch (error) {
      console.log(error);
    }
  };

  const state = [
    {
      profile,
      moduleSensei,
      qrData,
      suspensionDate,
      isSuspended,
      isFetchingUpdateProfile,
      isErrorUpdateProfile,
      isUpdateProfileSuccess,
    },
    {
      setActiveProgram,
      sendFinalProject,
      getProfile,
      track,
      updateProfile,
      updateAlumni,
      updateBillingData,
      updatePassword,
      logout,
      syncProfile,
      syncProfileTracking,
      signDiploma,
      sendEmail,
      createRatingClass,
      onRatingClass,
      createTicket,
      registerClass,
      getMyResume,
      attendedNotification,
    }
  ];

  return (
    <MeContext.Provider value={state}>
      {children}
    </MeContext.Provider>
  );
});

MeProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape().isRequired
};

MeProvider.defaultProps = {
  children: [],
  history: { push() { } },
  location: {}
};

const useMe = () => {
  const context = useContext(MeContext);
  if (context === undefined) {
    throw new Error('useMe can only be used inside MeProvider');
  }
  return context;
};

export {
  MeProvider,
  useMe
};
