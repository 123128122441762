/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Footer } from '../../Common/Footer';
import Login from '../../Components/Login';
import checkBrowser from '../../utils/checkBrowser';

const Home = (props) => (
  <>
    <Login {...props} />
    <Footer />
  </>
);
export default checkBrowser(Home);
