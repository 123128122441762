/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReadOnly from '../../Common/Content/ReadOnly';
import Questions from '../../Components/Question';
import './lecture.scss';

const Lecture = ({
  lecture, activeProgram,
  track, examLecture,
  setExamLecture, submitExam,
  isFetchingGradeExam, isErrorGradeExam,
  isSuccessGradeExam,
  module,
}) => {
  const [reviewExam, setReviewExam] = useState(false);
  const lectureTrack = activeProgram.lectures.find(((_l) => _l.lecture_id === lecture._id));
  const examResponse = lectureTrack && lectureTrack.exam && lectureTrack.exam.length > 0
    ? lectureTrack.exam[0] : null;

  const onPreSubmit = () => {
    setReviewExam(!reviewExam);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    submitExam(activeProgram);
  };

  return (
    <>
      <div className="markdown" id="lectureContainer">
        <ReadOnly
          module={module}
          data={lecture}
          activeProgram={activeProgram}
          track={track}
          program={activeProgram.slugProgram}
        />
      </div>
      { examLecture && !examResponse ? (
        <form className="bg__neutral90 container pt-3 pb-3 mb-5" onSubmit={onSubmit}>
          <h4 className="a_campus-card-title text__white mb-0 mt-0">Pon a prueba lo aprendido</h4>
          <hr />
          {examLecture && examLecture.map(
            (question) => (
              <Questions
                key={question.question}
                question={question.question}
                answers={question.answers}
                setExamLecture={setExamLecture}
                submitExam={submitExam}
                disabled={reviewExam}
              />
            )
          )}
          {isSuccessGradeExam !== null
            ? (
              <div className="bg__hackeranda container pt-1 pb-1 mt-3">
                <h4 className="text__white">{`Respuestas correctas: ${isSuccessGradeExam} / ${examLecture.length}`}</h4>
              </div>
            )
            : (
              <div className="d-flex justify-content-end pt-4 mr-1">
                <button
                  type="button"
                  className={!reviewExam ? 'btn__tool' : 'btn__danger'}
                  disabled={isFetchingGradeExam || isErrorGradeExam}
                  onClick={onPreSubmit}
                >
                  {!reviewExam ? 'Enviar' : 'No estoy seguro'}
                </button>
                {reviewExam && (
                  <button
                    type="submit"
                    className="btn__tool"
                    disabled={isFetchingGradeExam || isErrorGradeExam}
                  >
                    {isErrorGradeExam ? 'Parece que tuvimos un problema mandando tu examen.' : 'Estoy seguro'}
                  </button>
                )}
              </div>
            )}
        </form>
      ) : (
          examResponse && (
            <div className="bg__hackeranda container pt-1 pb-1 mb-5">
              <h4 className="text__white">{`Respuestas correctas: ${examResponse.score}/${examResponse.responses.length}`}</h4>
            </div>
          )
        )}
    </>
  );
};

Lecture.propTypes = {
  lecture: PropTypes.shape().isRequired,
  examLecture: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  activeProgram: PropTypes.shape({
    lectures: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    slugProgram: PropTypes.string.isRequired,
  }).isRequired,
  track: PropTypes.func.isRequired,
  setExamLecture: PropTypes.func.isRequired,
  submitExam: PropTypes.func.isRequired,
  isFetchingGradeExam: PropTypes.bool.isRequired,
  isErrorGradeExam: PropTypes.bool.isRequired,
  isSuccessGradeExam: PropTypes.bool.isRequired,
  module: PropTypes.string.isRequired,
};

export default Lecture;
