/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './careers.scss';
import { Job } from '../../Components/Job';
import { AnonymousNavbar } from '../../Common/AnonymousNavbar';

const Careers = ({ jobs, search }) => {
  const [value, setValue] = useState('');
  
  return (
    <>
      <section className={`${!localStorage.getItem('TOKEN') ? 'col-lg-12' : 'col-lg-10'} col-12 carrers`}>
        {
          !localStorage.getItem('TOKEN') && (<AnonymousNavbar />)
        }
        <div className="mt-5 mb-4 col-12 d-flex justify-content-between">
          <input
            data-testid="jobsearch"
            className="jobs__input jobs__input_text"
            placeholder="Busca empleos para ti"
            onChange={(event) => {
              const { value: nextValue } = event.target;
              setValue(nextValue);
              search(nextValue);
            }}
            value={value}
          />
          <a className="btn__tool" href="https://devf.la/careers" target="_blank" rel="noreferrer">@ publica una vacante</a>
        </div>
        <div className="d-flex flex-column">
          <div className="mb-2">
            <h4 className="jobs__number" data-testid="jobs">{`${jobs.length} posiciones abiertas`}</h4>
          </div>
          <div className="d-flex flex-wrap">
            {jobs.map(({ result: _job }) => (
              <Job
                key={_job.name}
                company={_job.company}
                logo={_job.logo}
                name={_job.name}
                nameContact={_job.name_contact}
                profile={_job.profile}
                location={_job.location}
                date={_job.created_at}
                applied={_job.applied}
              />
          ))}
          </div>
        </div>
      </section>
    </>
  );
};

Careers.propTypes = {
  jobs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  search: PropTypes.func.isRequired
};

export default Careers;
