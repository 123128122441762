import cintablanca from './cinta_blanca';
import cintaroja from './cinta_roja';

export default {
  cintablanca,
  cintaroja,
  frontend: {
    ...cintablanca,
    ...cintaroja
  },
  code: {
    ...cintablanca,
    ...cintaroja
  },
  course_code: {
    ...cintablanca,
    ...cintaroja
  },
  code_web: {
    ...cintablanca,
    ...cintaroja
  },
  professional_becas: {
    ...cintablanca,
    ...cintaroja
  },
  professional_code: {
    ...cintablanca,
    ...cintaroja
  }
};
