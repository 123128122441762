/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import './answer.scss';
import classNames from 'classnames';

const Answer = ({
  answer, selected, setSelected, i, disabled, done, correct, type
}) => {
  const answerId = i + answer[0].toLowerCase() + Math.round(Math.random() * 10);
  return (
    <div className={type === 'selfPaced' ? 'selfPaced-answer' : 'pt-1'}>
      <input
        data-testid="answer-input"
        className={type === 'selfPaced' ? 'a-quiz-input-selfPaced' : 'a-quiz-input'}
        name={i}
        id={answerId}
        type="checkbox"
        checked={selected}
        onChange={setSelected}
        disabled={disabled}
      />
      <label
        data-testid="answer-label-text"
        className={type === 'selfPaced' ? classNames('a-quiz-label-selfPaced', {
          'a-quiz-label-sp-correct': done && correct && selected,
          'a-quiz-label-sp-wrong': done && !correct && selected
        }) : classNames('a-quiz-label', {
          'a-quiz-label-correct': done && correct && selected,
          'a-quiz-label-wrong': done && !correct && selected
        })}
        htmlFor={answerId}
      >
        {answer}
      </label>
    </div>
  );
};

Answer.propTypes = {
  answer: PropTypes.string.isRequired,  
  done: PropTypes.bool.isRequired,
  correct: PropTypes.bool.isRequired,
  i: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default Answer;
