import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Input } from '../../Common/Input';
import { Spinner } from '../../Common/Spinner';
import { AnonymousNavbar } from '../../Common/AnonymousNavbar';

const Recovery = ({ onSetNewPassword, isFetching, isError }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { code } = useParams();
  const history = useHistory();

  const handleNewPassword = (event) => setNewPassword(event.target.value);
  const handleConfirmPassword = (event) => setConfirmPassword(event.target.value);

  const sendData = async (e) => {
    e.preventDefault();
    onSetNewPassword(newPassword, confirmPassword, code);
  };

  useEffect(() => {
    if (!code) {
      history.push('/');
    }
  }, []);

  return (
    <section className="login-container">
      <AnonymousNavbar />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="m_card card-post bg__brand-plasma">
              <div className="o_login-body">
                <div className="o_login-heading">
                  <h3 className="text__white">
                    Recupera tu contraseña
                  </h3>
                  <h6 className="text__brand-light">Asegúrate de escribir tu contraseña en un lugar seguro.</h6>
                </div>
                <div className={isError ? 'd-inline' : 'd-none'}>
                  <p className="bg__error text__white p-3" style={{ margin: 0 }}>
                    {isError}
                  </p>
                </div>
                <form onSubmit={sendData}>
                  <div className="m_form-group">
                    <small className="a_form-legend text__brand-light">Escribe una nueva contraseña</small>
                    <Input
                      type="password"
                      name="password"
                      placeholder=""
                      label=""
                      value={newPassword}
                      onChange={handleNewPassword}
                      required
                    />
                  </div>
                  <div className="m_form-group">
                    <small className="a_form-legend text__brand-light">Confirma tu contraseña</small>
                    <Input
                      type="password"
                      name="confirmPassword"
                      placeholder=""
                      label=""
                      value={confirmPassword}
                      onChange={handleConfirmPassword}
                      required
                    />
                  </div>
                  <button data-testid="btn-recovery" style={{ marginLeft: 0 }} className="btn__tool" type="submit">
                    {isFetching ? <Spinner /> : 'RECUPERAR'}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Recovery.propTypes = {
  onSetNewPassword: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isError: PropTypes.string.isRequired,
};

export default Recovery;
