export default {
  title: 'Ejercicio 29',
  description: `
  Crea una función llamada <code>longestCountry()</code>, esta función recibirá de parámetro
  un arreglo con nombres de distintos países, la función deberá retornar el país con 
  mayor número de letras.<br>
  Por ejemplo, del siguiente arreglo de países:<br>
  <code>const paises = ["México", "Panamá", "Guatemala", "El Salvador"]</code> <br>
  El Salvador es el país con mayor número de letras.
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba 1', function (t) {
      t.equal(longestCountry(["México", "Panamá", "Guatemala", "El Salvador"]), "El Salvador");
      t.end()
    });`,
    `test('Prueba 2', function (t) {
      t.equal(longestCountry(["Colombia", "Chile", "Argentina"]), "Argentina");
      t.end()
    });`,
    `test('Prueba 3', function (t) {
      t.equal(longestCountry(["Ecuador", "Perú"]), "Ecuador");
      t.end()
    });`
  ]
};
