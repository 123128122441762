import React from 'react';
import { useAuth } from '../../Context/AuthContext';

export default (Recovery) => function RecoveryHoc() {
  const [{ isFetching, isError }, { onSetNewPassword }] = useAuth();

  const onSetNewPasswordHandler = (newPassword, confirmPassword, code) => {
    onSetNewPassword(newPassword, confirmPassword, code);
  };

  return (
    <Recovery
      onSetNewPassword={onSetNewPasswordHandler}
      isFetching={isFetching}
      isError={isError}
    />
  );
};
