export default {

  title: 'Ejercicio 2',
  description: `
  Crea una función llamada <code>subs</code> que reste dos parametros "a" y "b" y regrese su resultado.
  Recuerda que para devolver el valor de una función se utiliza la palabra reservada <code>return</code>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('0 test', function (t) {
      t.equal(subs(2,2), 0,"'subs' debe regresar 0");
      t.end()
    });`,
    `test('negative test', function (t) {
      t.notEqual(subs(2,2), 4,"'subs' no debe regresar 4");
      t.end()
    });`
  ]
};
