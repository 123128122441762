import React from 'react';
import Proptypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog, faBrain, faCheckCircle, faRainbow
} from '@fortawesome/free-solid-svg-icons';
import RateProjectCard from '../RateProjectCard';
import { rewards } from '../../utils/const';

// const generateRate = (testPasses, testTotal) => {
//   const number = testPasses / testTotal;
//   if (number) {
//     if (number < 0.3) {
//       return 'F';
//     }
//     if (number < 0.5) {
//       return 'C';
//     }
//     if (number < 0.7) {
//       return 'B';
//     }
//     if (number < 0.8) {
//       return 'A';
//     }
//     return 'A+';
//   }
//   return '';
// };

function RateProjectTable({
  alumniProject, testPasses, testTotal, testFailures
}) {
  const topics2 = {
    comprehension: ['Comprensión', <FontAwesomeIcon icon={faBrain} key="comprension-icon" />],
    functionality: ['Funcionalidad', <FontAwesomeIcon icon={faCog} key="funcionalidad-icon" />],
    goodPractices: ['Buenas Prácticas', <FontAwesomeIcon icon={faCheckCircle} key="buenas-icon" />],
    creativity: ['Creatividad', <FontAwesomeIcon icon={faRainbow} key="creatividad-icon" />],
  };

  return (
    <div className="row">
      <section className="col-12 rate-project__resume">
        {alumniProject?.points >= -1 ? (
          <>
            {alumniProject?.points === -1
              ? (
                <div className="rate-project__standby bg__brand-plasma">
                  <p className="text__white" data-testid="rateproject-p-standby">Tu sensei está en proceso de calificar tu proyecto</p>
                </div>
              ) : (
                <div className="container">
                  {/* <h1 className="text__white ">
                    {alumniProject?.points ?? generateRate(testPasses, testTotal)}
                    Puntos
                  </h1> */}
                  <div className="rate-project__reward">
                    <div>
                      <span role="img" aria-label="Reward">{`${rewards[alumniProject?.points]}`}</span>
                    </div>
                  </div>
                  <p className="text__white text-center" data-testid="rateproject-p-comments">{alumniProject?.comments || 'Tu proyecto ya se calificó'}</p>
                  <div className="row rate_project__cards">

                    {Object.keys(alumniProject?.rubric ?? {}).map((value, index) => (
                      <RateProjectCard
                        score={alumniProject?.rubric[value]}
                        topic={topics2[value][0]}
                        index={index}
                        key={`card-${value}`}
                      // icon={topics2[value][1]}
                      />
                    ))}
                  </div>

                </div>
              )}
          </>
        ) : (
          <>
            <p className="text__white">
              Pruebas pasadas:
              {' '}
              <strong data-testid="passes">{testPasses}</strong>
            </p>
            <p className="text__white">
              Pruebas falladas:
              {' '}
              <strong data-testid="fails">{testFailures}</strong>
            </p>
            <p className="text__white">
              Pruebas totales:
              {' '}
              <strong data-testid="total">{testTotal}</strong>
            </p>
          </>
        )}
      </section>
    </div>
  );
}

RateProjectTable.defaultProps = {
  alumniProject: {}
};

RateProjectTable.propTypes = {
  testPasses: Proptypes.number.isRequired,
  testTotal: Proptypes.number.isRequired,
  testFailures: Proptypes.number.isRequired,
  alumniProject: Proptypes.shape(),
};

export default RateProjectTable;
