import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Answer from './Answer';
import './question.scss';

function Questions({
  question, answers, setExamLecture, disabled, numQuestion, total, doneExam, type
}) {
  const setSelected = (e) => {
    const response = answers.map(
      // eslint-disable-next-line eqeqeq
      (answer, i) => ({ ...answer, selected: e.target.name == i })
    );
    setExamLecture(question, response);
  };

  return (
    <section data-testid="section-question" className={type === 'selfPaced' && 'selfPaced-question'}>
      {
        type === 'selfPaced'
          ? (
            <h6 className="text__white mt-4 mb-2" data-testid="question-selfpaced">
              {`Pregunta ${numQuestion}. ${question}`}
            </h6>
          )
          : (
            <>
              <h6 className="text__white mt-4 mb-2" data-testid="question-question">
                {`Pregunta ${numQuestion} de ${total}`}
              </h6>
              <p className="text__white mb-2">{question}</p>
            </>
          )
      }

      {answers.map((answer, i) => (
        <Answer
          key={answer.answer}
          answer={answer.answer}
          selected={answer.selected}
          i={i}
          setSelected={setSelected}
          disabled={disabled}
          done={!_.isEmpty(doneExam)}
          correct={answer.is_correct}
          type={type}
        />
      ))}
    </section>
  );
}

Questions.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  question: PropTypes.string.isRequired,
  setExamLecture: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  numQuestion: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  doneExam: PropTypes.shape().isRequired,
  type: PropTypes.string,
};

Questions.defaultProps = {
  type: '',
};

export default Questions;
