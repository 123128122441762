/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSortBy, useTable } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort, faSortDown, faSortUp, faPen
} from '@fortawesome/free-solid-svg-icons';
import { Modal } from '../../../Common/Modal';
import ModalNewProjectRate from '../../../Components/ModalNewProjectRate';

const TableProjects = memo(({
  data,
  columns,
  rateProjectSubmit
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const [showModal, setShowModal] = useState(false);
  const [projectToRate, setProjectToRate] = useState({});

  return (
    <section>
      <Modal
        show={showModal}
        closeModal={() => setShowModal(false)}
        dismissible
      >

        <ModalNewProjectRate
          project={projectToRate}
          submit={(response) => {
            const body = {
              campusProgram: projectToRate.campusProgram,
              slugModule: projectToRate.slugModule,
              slugProject: projectToRate.slug,
              ...response,
            };
            rateProjectSubmit(body);
            setShowModal(false);
          }}
        />
      </Modal>
      <table {...getTableProps()} id="table-sensei" data-testid="table-sensei">
        <thead>
          {headerGroups.map((headerGroup, ii) => (
            <tr key={`header-${ii}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  data-testid="table-sort"
                  className="a-column__name"
                  key={`th-${i}`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div>
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortDown} />
                        ) : (
                          <FontAwesomeIcon icon={faSortUp} />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} color="#765CCC" />
                      )}
                    </span>
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} data-testid="table-sensei-body">
          {rows.map((row, iii) => {
            prepareRow(row);
            return (
              <tr data-testid="table-sensei-row" key={`rows-${iii}`} {...row.getRowProps()}>
                {row.cells.map((cell, iv) => (
                  <td
                    className={`-${cell.column.id}`}
                    key={`row-${iv}`}
                    {...cell.getCellProps()}
                  >
                    {
                      cell.column.id === 'actions'
                        ? (
                          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                          <div
                            style={{
                              cursor: 'pointer',
                            }}
                            className="row row-actions"
                            onClick={() => {
                              const obj = {
                                ...row.original.project,
                                campusProgram: row.original.campusProgramId,
                                slugModule: row.original.slugModule
                              };

                              setProjectToRate(obj);
                              setShowModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} color="#DB4437" />
                          </div>
                        )
                        : (
                          cell.column.id === 'first_name'
                            ? (
                              <Link to={`/sensei/alumni/profile/${row.original.alumniId}/${row.original.campusProgramId}`}>
                                <p className="link-profile">{`${row.original.first_name} ${row.original.last_name}`}</p>
                              </Link>
                            )
                            : cell.render('Cell'))
                    }
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
});

TableProjects.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  rateProjectSubmit: PropTypes.func.isRequired,
  campusProgram: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TableProjects;
