import React from 'react';
import PropTypes from 'prop-types';
import Metrics from '../Metrics';
import './profile-sensei.scss';

const ProfileSensei = ({
  profile,
  history,
  recommendation,
  alumnisBySensei,
  alumnisBySenseiProjects,
  addRecommendation,
  deactivateRecommendation,
  isFecthingAddRecommendation,
  isFetchingDeleteRecommendation,
  onRateProject,
  isSelfPaced,
}) =>
(
  <>
    {profile !== null ? (
      <section id="sensei__home" className="col-12 campus-container bg__sensei-dark">
        <div className="col-12 bg__gray6 mt-3" style={{ height: 'auto' }}>
          <div className="row">
            <div className="col-12 col-md-2 d-flex align-items-center">
              <img className="sensei-avatar m-2" src="https://yt3.ggpht.com/a/AATXAJwR5XaNm85QxvFYSnF3Xt5hlrfl-5CjZuH8mADm4Q=s900-c-k-c0x00ffffff-no-rj" alt="Imagen de perfil" />
            </div>
            <div className="col-12 col-md-10 d-flex flex-column justify-content-center">
              <h5 className="a_campus-card-title text__dark75">{`${profile.first_name} ${profile.last_name}`}</h5>
              <p className="text__dark50">{profile.email}</p>
            </div>
          </div>
        </div>
        <Metrics
          profile={profile}
          isFecthingAddRecommendation={isFecthingAddRecommendation}
          isFetchingDeleteRecommendation={isFetchingDeleteRecommendation}
          history={history}
          alumnisBySensei={alumnisBySensei}
          alumnisBySenseiProjects={alumnisBySenseiProjects}
          recommendation={recommendation}
          addRecommendation={addRecommendation}
          deactivateRecommendation={deactivateRecommendation}
          onRateProject={onRateProject}
          isSelfPaced={isSelfPaced}
        />
      </section>
    ) : (
      <></>
    )}
  </>
);

ProfileSensei.propTypes = {
  addRecommendation: PropTypes.func.isRequired,
  deactivateRecommendation: PropTypes.func.isRequired,
  isFecthingAddRecommendation: PropTypes.bool.isRequired,
  isFetchingDeleteRecommendation: PropTypes.bool.isRequired,
  recommendation: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
  alumnisBySensei: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  alumnisBySenseiProjects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  profile: PropTypes.shape({
    activeProgram: PropTypes.shape().isRequired,
    campusPrograms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onRateProject: PropTypes.func.isRequired,
  isSelfPaced: PropTypes.bool.isRequired,
};

export default ProfileSensei;
