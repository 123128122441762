/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import '../../../Components/Question/answer.scss';
import classNames from 'classnames';

function AnswerExam({ answers, setSelected, isRatedExam }) {
  return (
    <>
      {answers.map((answer) => (
        <div className="selfPaced-answer" key={answer._id}>
          <input
            data-testid="answer-input"
            className="a-quiz-input-selfPaced"
            name={answer._id}
            id={answer._id}
            type="checkbox"
            checked={answer.selected || false}
            onChange={(e) => {
              if (!isRatedExam) setSelected(e);
            }}
            required
          />
          <label
            data-testid="answer-label-text"
            className={classNames('a-quiz-label-selfPaced', {
                      'a-quiz-label-sp-correct': isRatedExam && answer.is_correct && answer.selected,
                      'a-quiz-label-sp-wrong': isRatedExam && !answer.is_correct && answer.selected,
                    })}
            htmlFor={answer._id}
          >
            {answer.answer}
          </label>
        </div>
              ))}
    </>
  );  
}

AnswerExam.propTypes = {
  setSelected: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      answer: PropTypes.string,
      is_correct: PropTypes.bool,
      selected: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  isRatedExam: PropTypes.bool.isRequired,
};

export default AnswerExam;
