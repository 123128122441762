export default {
  title: 'Ejercicio 31',
  description: `
    Crea una funcion llamada <code>frequent()</code> que recibirá
    de parámetro un arreglo con distintos números, retornar el elemento más frecuente
    dentro del arreglo.
    `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba 1', function (t) {
      t.equal(frequent([1, 1, 0, 1, 9, 8, 3, 8]), 1, "Prueba correcta");
      t.end()
    });`,
    `test('Prueba 2', function (t) {
      t.equal(frequent([8, 1, 0, 8, 9, 8, 3, 8]), 8, "Prueba correcta");
      t.end()
    });`,
    `test('Prueba 3', function (t) {
      t.equal(frequent([3, 5, 3, 1, 0, 8, 3, 7]), 3, "Prueba correcta");
      t.end()
    });`
  ]
};
