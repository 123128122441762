/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

function Answer({
  answer, selected, i, onChangeAnswer
}) {
  const onChangeInput = (e) => {
    onChangeAnswer(i, e);
  };

  return (
    <div data-testid="input-answer-question" className="d-flex align-items-center pt-3">
      <input className="a_input" name="answer" placeholder="Respuesta" value={answer} onChange={onChangeInput} />
      <input name="isCorrect" id="job" type="checkbox" checked={selected} onChange={onChangeInput} />
    </div>
  );
}

Answer.propTypes = {
  answer: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  onChangeAnswer: PropTypes.func.isRequired,
};

export default Answer;
