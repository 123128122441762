/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './modalNewProjectRate.scss';

function ModalNewProjectRate({ submit, project }) {
  const basicRubric = {
    functionality: 0,
    comprehension: 0,
    goodPractices: 0,
    creativity: 0
  };

  const gradeTopics = {
    functionality: 'Funcionalidad',
    comprehension: 'Comprensión',
    goodPractices: 'Buenas prácticas de código',
    creativity: 'Creatividad'
  };

  const [points, setPoints] = useState(0);
  const [comments, setComments] = useState('');
  const [rubric, setRubric] = useState(basicRubric);

  useEffect(() => {
    setPoints(project?.points || '');
    setComments(project?.comments || '');
    setRubric(project?.rubric || {});
  }, [project]);

  const onSubmit = (e) => {
    e.preventDefault();
    submit({
      points, rubric, comments, project 
    });
    setRubric(basicRubric);
    setComments('');
  };

  const onChange = (_rubric, value) => {
    setRubric({ ...rubric, ...{ [_rubric]: Number(value) } });
  };

  return (
    <form onSubmit={onSubmit} data-testid="rate-form" className="modal_rate__form">
      <h4 className="text__white text-center">Nueva Calificación</h4>
      <h6 className="text__white">
        Califica este Proyecto:
        {' '}
        {project?.title}
      </h6>
      {
        Object.keys(gradeTopics).map((topic) => (
          <>
            <label htmlFor="points" className="modal_rate__label">{`${gradeTopics[`${topic}`]} (1-4)`}</label>
            <div className="modal_rate__options">
             
              <div>
                <input
                  data-testid="modalnewproject-input-points"
                  className=""
                  name={`${gradeTopics[`${topic}`]}`}
                  type="radio"
                  value="1"
                  checked={rubric[topic] === 1}
                  onChange={(e) => {
                    onChange(topic, e.target.value);
                }}
                  required
                />
                <label htmlFor="regular">Regular</label>
              </div>
              <div>
                <input
                  data-testid="modalnewproject-input-points"
                  className=""
                  name={`${gradeTopics[`${topic}`]}`}
                  type="radio"
                  checked={rubric[topic] === 2}
                  value="2"
                  onChange={(e) => {
                    onChange(topic, e.target.value);
                }}
                  required
                />
                <label htmlFor="bueno">Bueno</label>
              </div>
              <div>
                <input
                  data-testid="modalnewproject-input-points"
                  className="VGood"
                  name={`${gradeTopics[`${topic}`]}`}
                  type="radio"
                  value="3"
                  checked={rubric[topic] === 3}
                  onChange={(e) => {
                    onChange(topic, e.target.value);
                }}
                  required
                />
                <label htmlFor="VGood">Muy Bueno</label>
              </div>
              <div>
                <input
                  data-testid="modalnewproject-input-points"
                  className=""
                  name={`${gradeTopics[`${topic}`]}`}
                  type="radio"
                  value="4"
                  checked={rubric[topic] === 4}
                  onChange={(e) => {
                    onChange(topic, e.target.value);
                }}
                  required
                />
                <label htmlFor="excelent">Excelente</label>
              </div>
            </div>
          </>
        ))
      }
      <label htmlFor="comments" className="modal_rate__label">Comentarios adicionales</label>
      <textarea
        data-testid="modalnewproject-textarea-comments"
        className="a_input"
        style={{ height: 'auto' }}
        name="comments"
        type="text"
        rows="8"
        cols="76"
        onChange={(e) => setComments(e.target.value)}
        value={comments}
        required
      />
      <button className="a-btn btn__tool mt-4" type="submit" data-testid="modalnewproject-btn-submit">Calificar proyecto</button>
    </form>
  );
}

ModalNewProjectRate.propTypes = {
  submit: PropTypes.func.isRequired,
  project: PropTypes.shape().isRequired,
};

export default ModalNewProjectRate;
