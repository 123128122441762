/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, {
  useContext,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import tracking from '../views/MyCourse/sensei/tracking.json';
import api from './api';

const SenseiContext = React.createContext(undefined);

const SenseiProvider = withRouter(({ children, history }) => {
  const [isFecthingAddRecommendation, setIsFecthingAddRecommendation] = useState(false);
  const [isFetchingDeleteRecommendation, setIsFetchingDeleteRecommendation] = useState(false);
  const [profile, setProfile] = useState(null);
  const [alumnisBySensei, setAlumnisBySensei] = useState([]);
  const [lectureModal, setLectureModal] = useState(false);
  const [programs, setPrograms] = useState([]);

  const logout = () => {
    localStorage.clear();
    setTimeout(() => {
      window.location.reload(false);
    }, 400);
  };

  const setActiveRecordModule = (slugRecordModule, anteProfile = null, idRecordModule) => {
    const profilee = anteProfile || profile;
    const activeRecordModule = profilee.records_module.find(
      (recordModule) => (
        slugRecordModule === recordModule.slug && idRecordModule === recordModule._id
      )
    );
    activeRecordModule.tracking = tracking;
    setProfile({
      ...profilee,
      activeRecordModule,
    });
    // history.push('/sensei/profile');
  };

  const getProfileSensei = async () => {
    try {
      const data = await api.Sensei.getSenseiProfile();
      setProfile(data);
      if (data.records_module?.slice(-1)[0].slug) {
        setActiveRecordModule(
          data.records_module.slice(-1)[0].slug,
          data,
          data.records_module.slice(-1)[0]._id
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      if (process.env.REACT_APP_DEBUG === 'false' || process.env.REACT_APP_DEBUG === undefined) {
        logout();
      }
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  const addRecommendation = async (data) => {
    try {
      setIsFecthingAddRecommendation(true);
      let recommendation = { abilities: [] };
      // eslint-disable-next-line guard-for-in
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data) {
        if (key === 'role' || key === 'possible_sensei' || key === 'campusProgramId' || key === 'technical' || key === 'observation') {
          recommendation = { ...recommendation, [key]: data[key] };
        } else if (key) {
          recommendation.abilities.push(key);
        }
      }
      await api.Sensei.addRecommendation(recommendation);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setIsFecthingAddRecommendation(false);
    }
  };
  const deactivateRecommendation = async (data) => {
    try {
      setIsFetchingDeleteRecommendation(true);
      await api.Sensei.deactivateRecommendation({ campusProgramId: data });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setIsFetchingDeleteRecommendation(false);
      }
  };

  const createLecture = async (lecture) => {
    try {
      const newLecture = await api.Sensei.createLecture(lecture);
      // eslint-disable-next-line no-underscore-dangle
      history.push(`/sensei/lecture/${newLecture._id}`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const updateContent = async (id, content, codes, read_more, reading_time) => {
    try {
      await api.Sensei.updateLecture(id, {
        ...content, codes, read_more, reading_time, time: new Date().getTime()
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const getAlumniBySensei = async (batch, groupName, module, isSelfPaced) => {
      if (isSelfPaced) {
        try {
          // eslint-disable-next-line max-len
          const dataSelfpaced = await api.Sensei.getAlumniForSenseiSelfpaced(batch, groupName, module);
          setAlumnisBySensei(dataSelfpaced);
          return;
        } catch (error) {
          if (process.env.REACT_APP_DEBUG === 'false' || process.env.REACT_APP_DEBUG === undefined) {
            logout();
          }
        }
      } else {
        try {
          const data = await api.Sensei.getAlumniForSensei(batch, groupName, module);
          setAlumnisBySensei(data);
        } catch (error) {
          // eslint-disable-next-line no-console
          if (process.env.REACT_APP_DEBUG === 'false' || process.env.REACT_APP_DEBUG === undefined) {
            logout();
          }
        }
      }
  };

  const getProfileAlumni = async (idAlumni, idCampusProgram, cb) => {
    try {
      const alumniProfile = await api.Sensei.alumniProfile(idAlumni, idCampusProgram);
      cb(alumniProfile);
    } catch (error) {
      cb(error);
    }
  };

  const rateProject = async (project, cb) => {
    try {
      const projectResponse = await api.Sensei.rateAlumniProject(project);
      cb(projectResponse.data);
    } catch (error) {
      cb('error');
    }
  };

  const rateSelfProject = async (project, cb) => {
    try {
      const projectResponse = await api.Sensei.rateSelfAlumniProject(project);
      cb(null, projectResponse.data);
    } catch (error) {
      cb('error', null);
    }
  };

  const feedbackSubmit = async (feedback, cb) => {
    try {
      const feedbackResponse = await api.Sensei.feedbackSubmit(feedback);
      cb(feedbackResponse);
    } catch (error) {
      cb('error');
    }
  };

  // getPrograms is not necessary
  const getPrograms = async (cb) => {
    try {
      if (programs.length === 0) {
        const prs = await api.Lectures.getAllPrograms();
        setPrograms(prs);
        cb(prs);
      } else {
        cb(programs);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const track = () => {
    // to do: validar con el equipo de data que se va a trackear, y como? 
  };

  const getExercisesRepo = (cb) => {
    api.Github.getAllRepo()
      .then((result) => {
        const onlyMd = result.tree.filter((archive) => archive.path.search(/.md/i) > 0).sort((a, b) => a.path.localeCompare(b.path));
        cb(null, onlyMd);
      }).catch((err) => {
          cb(err);
      });
  };

  const getExercise = (path, cb) => {
    api.Github.getMdFromPath(path)
    .then((result) => {
      cb(null, result);
    }).catch((err) => {
      cb(err);
    });
  };

  useEffect(() => {
    if (profile === null || alumnisBySensei === null) {
      getProfileSensei();
    }
  }, [profile]);

  const state = [
    {
      profile,
      lectureModal,
      programs,
      alumnisBySensei,
      isFecthingAddRecommendation,
      isFetchingDeleteRecommendation,
    },
    {
      feedbackSubmit,
      getAlumniBySensei,
      setActiveRecordModule,
      getProfileSensei,
      logout,
      setLectureModal,
      createLecture,
      updateContent,
      getPrograms,
      addRecommendation,
      deactivateRecommendation,
      getProfileAlumni,
      rateProject,
      rateSelfProject,
      track,
      getExercisesRepo,
      getExercise,
    }
  ];

  return (
    <SenseiContext.Provider value={state}>
      {children}
    </SenseiContext.Provider>
  );
});

SenseiProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape().isRequired
};

SenseiProvider.defaultProps = {
  children: [],
  history: { push() { } },
  location: {}
};

const useSensei = () => {
  const context = useContext(SenseiContext);
  if (context === undefined) {
    throw new Error('useSensei can only be used inside SenseiProvider');
  }
  return context;
};

export {
  SenseiProvider,
  useSensei
};
