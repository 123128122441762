export default {
  title: 'Ejercicio 27',
  description: `
        Crea una función llamada <code>evaluateNumber()</code> que reciba como parámetro 
        un número para evaluar si un número es divisible entre 5 y 11 o no lo es.
        <ul>
            <li>En caso de ser divisible entre 5 y 11, retornar <code>true</code></li>
            <li>En caso de no ser divisible entre 5 y 11, retornar <code>false</code></li>
        </ul>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba de la función con el número 55', function (t) {
      t.equal(evaluateNumber(55), true, "Número múltiplo de 5 y 11");
      t.end()
        });`,
    `test('Prueba de la función con el número 2', function (t) {
      t.equal(evaluateNumber(30), false, "Número no múltiplo de 5 y 11");
      t.end()
        });`,
    `test('Prueba de la función con el número 3', function (t) {
      t.equal(evaluateNumber(110), true, "Número múltiplo de 5 y 11");
      t.end()
    });`
  ]
};
