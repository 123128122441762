import React from 'react';
import PropTypes from 'prop-types';
import Answer from './Answer';

function QuestionsEdit({
  question, answers, i, addAnswer, editAnswer, editQuestion
}) {
  const onClickNewAnswer = () => {
    addAnswer(question, {
      answer: '',
      is_correct: false
    });
  };

  const onChangeAnswer = (indexAnswer, answer) => {
    if (answer.target.name === 'isCorrect') {
      editAnswer(i, indexAnswer, { is_correct: answer.target.checked });
    } else {
      editAnswer(i, indexAnswer, { answer: answer.target.value });
    }
  };

  const setQuestionTxt = (e) => {
    editQuestion(i, e.target.value);
  };

  return (
    <>
      <input data-testid="input-name-question" className="a_input bg__neutral90" style={{ marginTop: '2rem', marginBottom: '0' }} value={question} onChange={setQuestionTxt} />
      {answers.map((answer, index) => (
        <Answer
          // eslint-disable-next-line no-underscore-dangle
          key={answer._id || (index + 1) * 34}
          answer={answer.answer}
          selected={answer.is_correct}
          i={index}
          onChangeAnswer={onChangeAnswer}
        />
      ))}
      <button className="a-btn btn__brand-alt" type="button" onClick={onClickNewAnswer}>Agregar respuesta</button>
      <br />
    </>
  );
}

QuestionsEdit.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  question: PropTypes.string.isRequired,
  i: PropTypes.number.isRequired,
  addAnswer: PropTypes.func.isRequired,
  editAnswer: PropTypes.func.isRequired,
  editQuestion: PropTypes.func.isRequired,
};

export default QuestionsEdit;
