/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MapPin } from 'react-feather';
import Mixpanel from '../../utils/Mixpanel';
import './job.scss';

const JobDetail = ({
  job,
  alumni,
  apllyJobAlumni,
}) => {
  const [isApplied, setIsApplied] = useState(job.applied);

  useEffect(() => {
    setIsApplied(job.applied);
    Mixpanel.track('CampusCareers-openJob');
    Mixpanel.track(`CampusCareers-JobDetail-${job.name}`);
  }, [job]);
  const applyJobSubmit = () => {
    apllyJobAlumni(job.name);
    Mixpanel.track('CampusCareers-ApplyJob');
    setIsApplied(true);
  };
  const renderCareer = () => (
    <>
      <div className="row d-flex flex-column">
        <div className="job__detail-header d-flex align-items-center justify-content-center">

          <h3>{job.name}</h3>
        </div>
      </div>
      <div className="campus-container">
        <div className="mt-3 d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center justify-content-between">
            {job.has_skills.map((_skill) => (
              <span className="bg__skills d-flex align-items-center justify-content-center" key={_skill}>{_skill}</span>
                ))}
            <MapPin color="#7A7689" className="ml-5" />
            <p className="ml-2">{job.location}</p>
          </div>
          {alumni.cv_url && alumni.github && alumni.linkedin 
                  ? (
                    <button type="button" onClick={() => !isApplied && applyJobSubmit()} className={isApplied ? 'btn__tool btn__tool-disabled' : 'btn__tool'}>
                      {isApplied ? 'YA APLICASTE' : 'APLICA AHORA'}
                    </button>
)
                  : (
                    <div className="d-flex flex-column justify-content-between align-items-center">
                      <p className="text__error">Para aplicar a este puesto es necesario que completes tu perfil</p>
                      <Link type="button" to="/me" className="a-btn btn__tool">COMPLETA TU PERFIL</Link>
                    </div>
                  )}
        </div>
        <hr />
        {isApplied && (
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <h4>Datos de contacto</h4>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <ul>
              <li>
                Nombr/e de contacto:
                {job.name_contact} 
              </li>
              <li>
                Contacto:
                {job.email_contact}
              </li>
              <li>
                Numero: 
                {job.phone}
              </li>
            </ul>
          </div>
        </div>
              )}
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <h4>General</h4>
            {job.document && <a download type="button" className="btn__default" href={job.document}>PDF de la vacante</a>}
            {job.link && <a download type="button" className="btn__default" href={job.link}>Link de la vacante</a>}
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div>
              {job.name}
              <br />
              <br />
              <h4>Requisitos</h4>
              <br />
              {job.requierments}
              <br />
              <h4>Responsabilidades</h4>
              <br />
              {job.responsabilities}
              <br />
              <h4>Información extra</h4>
              <br />
              {job.description}

            </div>
          </div>
        </div>
      </div>
    </>
  );
  return (
    renderCareer()
  );
};

JobDetail.propTypes = {
  job: PropTypes.shape().isRequired,
  apllyJobAlumni: PropTypes.func.isRequired,
  isFetchingApply: PropTypes.func.isRequired,
  isErrorApply: PropTypes.func.isRequired,
  isApplySuccess: PropTypes.func.isRequired,
  alumni: PropTypes.shape().isRequired
};

export default JobDetail;
