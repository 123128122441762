export default {
  title: 'Ejercicio 20',
  description: `
  Crea una función llamada <code>numbers()</code> que retorne un array donde se visualicen los números del 1 al 10, 
  y a la vez, una cuenta inversa de 10 a 1
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Probar numeros del 1 al 10 y al mismo tiempo del 10 al 1 ', function (t) {
      t.deepEqual(numbers(), [ 1, 10, 2, 9, 3, 8, 4, 7, 5, 6, 6, 5, 7, 4, 8, 3, 9, 2, 10, 1
        ],'Esto debe retornar un array [1, 10, 2, 9,  3, 8, 4, 7, 5, 6, 6, 5, 7, 4, 8, 3, 9, 2, 10, 1]');
      t.end()
    });`
  ]
};
