export default {
    title: 'Ejercicio 18',
    description: `
    Crea una variable llamada <code>idioma</code>, que contenga un array con tres elementos, ‘Español’, ’Inglés’, ’Francés’. 
    Posteriormente crea una función llamada <code>saludo()</code>, que reciba como parámetros un idioma ‘Español’, ’Inglés’, ’Francés’. Y que retorne:
    <ul>
    <li>Retorne <code>Hola hacker</code>, en caso de elegir Español
    <li>Retorne <code>Hello hacker</code>, en caso de elegir Inglés
    <li>Retorne <code>Bonjour hacker</code>, en caso de elegir Francés
    </ul>
    `,
    seed: '// Escribe tu código aquí',
    test: [
      `test('Probar Español ', function (t) {
        t.equal(saludo('Español'), 'Hola hacker','Esto debe retornar Hola hacker');
        t.end()
      });`,
      `test('Probar Inglés', function (t) {
        t.equal(saludo('Inglés'), 'Hello hacker','Esto debe retornar Hello hacker');
        t.end()
      });`,
      `test('Probar Francés', function (t) {
        t.equal(saludo('Francés'), 'Bonjour hacker','Esto debe retornar Bonjour hacker');
        t.end()
      });`,
    ]
  };
