/* eslint-disable max-len */
import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import PropTypes from 'prop-types';

const Code = ({ data }) => {
  const getLanguage = (code) => {
    if (code.search('from \'react\';') >= 0) return 'jsx';
    const codeMatch = code.match(/<[^>]*>/g) ? 'html' : 'javascript';
    return codeMatch;
  };

  return (
    <div className="ce-block">
      <div className="ce-block__content">
        <div className="cdx-block ce-code">
          <SyntaxHighlighter language={getLanguage(data.code)} style={okaidia} showLineNumbers>
            {data.code}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>
  );
};

Code.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Code;
