/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTable, useExpanded } from 'react-table';
import './table.scss';
import './button.scss';

const SenseiAdminModules = (
  { 
    setModal,
    data,
  }
) => {
  const columns = React.useMemo(
    () => [
        {
            id: 'expander', // Make sure it has an ID
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
              <span {...getToggleAllRowsExpandedProps()}>
                {isAllRowsExpanded ? '👇' : '👉'}
              </span>
            ),
            // eslint-disable-next-line react/display-name
            Cell: ({ row }) =>
                (row.canExpand ? (
                  <span
                    data-testid="table-button-expand"
                    {...row.getToggleRowExpandedProps({
                      })}
                  >
                    {row.isExpanded ? '👇' : '👉'}
                  </span>
                ) : null),
        },
          {
            Header: 'Módulo',
            accessor: 'title',
          },
          {
            Header: 'Orden',
            accessor: 'order',
          },
          {
            Header: 'Acción',
            accessor: 'action',
          }
    ],
    []
);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data, getSubRows: (row) => row.lectures || [] }, useExpanded); 
  return (
    <>
      <table {...getTableProps()} id="table-sensei-modules" data-testid="table-sensei-modules">
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={`row-${i}`} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, ii) => (
                <th
                  key={`cell-${ii}`}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
             ))}
            </tr>
         ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
           prepareRow(row);
           return (
             <tr data-testid={row.depth === 0 ? 'table-row-module' : 'table-row-lecture'} key={row.original._id} {...row.getRowProps()}>
               {row.cells.map((cell) => (
                 <td
                   key={row.original._id}
                   {...cell.getCellProps()}
                 >
                   {
                    cell.column.id === 'action' ? (
                      <>
                        {row.depth === 0 && (
                          <button type="button" data-testid="table-button-update-lecture" className="button" onClick={() => { setModal({ status: true, idModule: row.original._id, title: row.original.title }); }}> Actualizar Módulo</button>
                        )} 
                        {row.depth === 1 && (
                          <Link data-testid="table-button-edit-lecture-link" to={`/sensei/lecture/${row.original._id}`}>
                            <button type="button" data-testid="table-button-edit-lecture" className="button--edit"> Editar Lectura</button>
                          </Link>
                        )} 
                      </>
                    )
                   : cell.render('Cell')
                 }
                 </td>
                 ))}
             </tr>
           );
         })}
        </tbody>
      </table>
    </>
  ); 
};

SenseiAdminModules.propTypes = {
  setModal: PropTypes.elementType.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default SenseiAdminModules;
