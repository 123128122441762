import axios from 'axios';

const getAuth = () => (localStorage.getItem('TOKEN') ? localStorage.getItem('TOKEN') : null);

const devfApi = axios.create({
  baseURL:
    process.env.REACT_APP_API_BASE_URL
    || 'https://devf-api-staging.herokuapp.com/',
});

devfApi.interceptors.response.use((response) => response, (error) => {
 if (error?.response?.status === 403) {
  localStorage.clear();
  window.location.reload(false);
 }
 return Promise.reject(error);
});

const hueApi = axios.create({
  baseURL:
    process.env.REACT_APP_HUE_API_BASE_URL
    || 'https://staging-dot-hue-ai.appspot.com/',
});

if (getAuth()) {  
  devfApi.defaults.headers.common.Authorization = `Bearer ${getAuth()}`;
  hueApi.defaults.headers.common.Authorization = `Bearer ${getAuth()}`;
}

devfApi.interceptors.response.use((response) => response, (error) => {
  if (!error || !error.response) {
    // eslint-disable-next-line no-param-reassign
    error.response = {
      status: 1000,
      data: {
        message: 'No pudimos conectar con el servidor'
      }
    };
  }
  return Promise.reject(error);
});
export { devfApi, hueApi };
