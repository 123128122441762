export default {
    title: 'Ejercicio 11',
    description: `
    Crea una función llamada <code>isEqual()</code> que reciba dos parámetros y evalúe si estos son iguales.
    <ul>
    <li>Retorna <code>true</code>. Si los dos parámetros son iguales retorna true.</li>
    <li>Retorna <code>false</code>. Si los dos parámetros son diferentes retorna false.</li>
    </ul>
    
    Ten en cuenta que deben de ser iguales tanto en valor como tipo de dato.
    `,
    seed: '// Escribe tu código aquí',
    test: [
      `test('Probar si 2 y "2" son iguales', function (t) {
        t.equal(isEqual(2,"2"), false,'Esto debe retornar false');
        t.end()
      });`,
      `test('Probar si 9 y 9 son iguales', function (t) {
        t.equal(isEqual(9,9), true,'Esto debe retornar true');
        t.end()
      });`,
      `test('Probar si "" y null son iguales', function (t) {
        t.equal(isEqual("", null), false,'Esto debe retornar false');
        t.end()
      });`,
    ]
  };
