/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dateToLocale from '../../utils/dateToLocale';
import './job.scss';

const Job = ({
  name, location, date, applied, profile, company, logo
}) => {
  const dateBreak = date.split(' ');
  const dateJob = dateToLocale(dateBreak[0]);

  return (
    <div className={`${!localStorage.getItem('TOKEN') && 'login-required'} col-12 col-md-6 col-lg-4 mb-4`}>
      <Link
        data-testid="link-job"
        to={`/career/${name}`}
        className="m_card bg__gray3 job__row-container"
      >
        {
          !localStorage.getItem('TOKEN') && (
            <div className="job__message">
              <p className="btn__alt-default">Iniciar sesión</p>
            </div>
          )
        }
        <div className="row job__content-descripcion -specific">
          {logo && <img className="col-3 img job__img" src={logo} alt="Logo company" />}
          <div className="col-9 job__information">
            <h5 className="job__title">{name.length > 35 ? `${name.substr(0, 35)}...` : name}</h5>
            <p className="job__company">
              {company || 'Company'}
            </p>
            <span className="text__gray5 job__location">{location}</span>
          </div>
          <div className="row col-12">
            <span className="job-badge___applied col-3">{applied ? 'APLICASTE' : '' }</span>
            <span className="job__date col-9">{dateJob}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

Job.propTypes = {
  name: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  company: PropTypes.string,
  logo: PropTypes.string,
  date: PropTypes.string.isRequired,
  profile: PropTypes.string.isRequired,
  applied: PropTypes.bool,
};

Job.defaultProps = {
  applied: false,
  company: '',
  logo: '',
};

export default Job;
