export default {
    title: 'Ejercicio 16',
    description: `
    Crea una función llamada <code>evaluateGrade()</code> que reciba como parámetro una nota (número)  y evalúe la calificación según la nota, retornando:
    <ul>
    <li>Si la nota se encuentra entre 0-3: Retorna <code>Muy deficiente </code></li>
    <li>Si la nota se encuentra entre 4-5: Retorna <code>Insuficiente</code></li>
    <li>Si la nota se encuentra entre 6-7: Retorna <code>Bien</code></li>
    <li>Si la nota se encuentra entre 8-9: Retorna <code>Notable</code></li>
    <li>Si la nota se encuentra entre  10: Retorna <code>Sobresaliente</code></li>
    </ul>
    `,
    seed: '// Escribe tu código aquí',
    test: [
      `test('Probar la nota 0 ', function (t) {
        t.equal(evaluateGrade(0), 'Muy deficiente','Esto debe retornar Muy deficiente');
        t.end()
      });`,
      `test('Probar la nota 6', function (t) {
        t.equal(evaluateGrade(6), 'Bien','Esto debe retornar Bien');
        t.end()
      });`,
      `test('Probar la nota 9', function (t) {
        t.equal(evaluateGrade(9), 'Notable','Esto debe retornar Notable');
        t.end()
      });`,
    ]
  };
