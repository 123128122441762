/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AnswerExam from './AnswerExam';
import '../../../Components/Question/answer.scss';

function QuestionExam({
 question, answers, index, isRatedExam, setExam
}) {
  const [localAnswers, setLocalAnsers] = useState(answers);
  
  const setSelected = (e) => {
    const newAnswers = answers.map((answer) => (
      { ...answer, selected: e.target.name === answer._id }
    ));
    setLocalAnsers(newAnswers);
    setExam(question, newAnswers);
  };

  return (
    <section className="selfPaced-question" key={question}>
      <h6 className="text__white mt-4 mb-2" data-testid="question-selfpaced">
        {`Pregunta ${index + 1}. ${question}`}
      </h6>
      <AnswerExam answers={localAnswers} setSelected={setSelected} isRatedExam={isRatedExam} />
    </section>
    
  );  
}

QuestionExam.propTypes = {
  question: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      answer: PropTypes.string,
      is_correct: PropTypes.bool,
      selected: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  isRatedExam: PropTypes.bool.isRequired,
  setExam: PropTypes.func.isRequired,
};

export default QuestionExam;
