import React, { useEffect } from 'react';
import { useProjects } from '../../Context/ProjectContext';
import { useMe } from '../../Context/MeContext';
import { Spinner } from '../../Common/Spinner';

export default (Projects) => function ProjectHOC() {
  const [{
    isError, isFetching, projects, activeProject 
  }, { setActiveProject, getProjects }] = useProjects();
  const [{ profile }] = useMe();

  useEffect(() => {
    getProjects();
  }, []);

  const renderContent = () => {
    if (isFetching) {
      return (
        <div className="d-flex justify-content-center align-items-center bg__gray2" style={{ height: '93vh', width: '83.3vw' }}>
          <Spinner />
        </div>
      );
    }
    if (isError) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <h3>{isError}</h3>
        </div>
      );
    }
    if (projects) {
      return (
        <Projects
          projects={projects}
          activeProject={activeProject}
          setActiveProject={setActiveProject}
          activeProgram={profile.activeProgram}
          projectsResults={profile.alumni.projects_results}
        />
    ); 
  }
    return null;
  };

  return renderContent();
};
