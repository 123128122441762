/* eslint-disable max-len */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Spinner } from '../../Common/Spinner';
import { AlumniForm } from '../AlumniForm';
import { AlumniFormSecurity } from '../AlumniFormSecurity';
import { AlumniFormInvoice } from '../AlumniFormInvoice';
import AknowledgmentDevf from '../../libs/diplomas-library';
import detectImage from '../../utils/detectImage';
import dateToLocale from '../../utils/dateToLocale';
import { CONTENT_HOURS } from '../../utils/const';
import './profileCard.scss';

const ProfileCard = ({
  acknowledgement, profile, isMe, activeProgram,
  isFetchingUpdateProfile, isErrorUpdateProfile, isUpdateProfileSuccess,
  updateProfile, updateAlumni, updateBillingData, sendEmail,
  updatePassword, program, diploma, qrData, signDiploma
}) => {
  const {
    profile_pic, first_name, last_name, email, cv_url, github, linkedin
  } = profile;
  const {
    city_name, end_date, path, product_line,
  } = activeProgram;
  const [showDiploma, setShowDiploma] = useState(false);
  
  const [dataDiploma, setDataDiploma] = useState({});

  const { pathname } = useLocation();
  const currentPath = pathname.split('/').pop();
  const endDateMonthParser = new Date(end_date).getMonth() + 1;
  const endMonthFixed = endDateMonthParser > 9 ? endDateMonthParser : `0${endDateMonthParser}`;

  const showAknowledgment = () => {
    const bodyDiploma = {
      totalHours: CONTENT_HOURS[product_line]?.[path],
      totalHoursEN: CONTENT_HOURS[product_line]?.EN?.[path],
      name: `${first_name} ${last_name}`,
      program,
      place: city_name,
      date: dateToLocale(end_date),
      dateEN: dateToLocale(end_date, {
        year: 'numeric', month: 'long', day: 'numeric'
      }, 'en-MX'), 
      info: diploma,
    };
    const currentDate = new Date();
    const endDate = new Date(end_date);
    if (acknowledgement && (currentDate >= endDate || activeProgram.extended?.needBaseDiploma)) {
      const { _id, batch, program: currentProgram } = activeProgram;
      const body = { batch, program: currentProgram, campus_program_id: _id };
      if (activeProgram.extended?.needBaseDiploma && currentDate < endDate) {
        body.extended = true;
        bodyDiploma.program = activeProgram.extended?.infoProgram.name;
        bodyDiploma.diploma = activeProgram.extended?.infoProgram.diploma;
        bodyDiploma.totalHours = CONTENT_HOURS[activeProgram.extended?.baseProductLine]?.[path];
        bodyDiploma.totalHoursEN = CONTENT_HOURS[activeProgram.extended
          ?.baseProductLine]?.EN?.[path];
        bodyDiploma.date = null;
      }
      if (qrData && Object.keys(qrData).length > 0) {
        setDataDiploma({ ...bodyDiploma, qr: qrData.qr, hash: qrData.block_hash });
        setShowDiploma(true);
      } else {
        signDiploma(body);
      }
    } else {
      setShowDiploma(false);
    }
  };

  useEffect(() => {
    showAknowledgment();
  }, [activeProgram, qrData]);

  return (
    <>
      { !isMe
      && (
      <div className="m_campus-card bg__white d-flex flex-column justify-content-center" style={{ height: 'auto', padding: '1.5rem 2rem' }}>
        <div className="row">
          <div className="o_campus-card__img col-12 col-sm-3 d-flex align-items-center">
            {profile_pic ? <img src={detectImage(profile_pic) ? profile_pic : `data:image/png;base64, ${profile_pic}`} style={{ width: 'inherit' }} alt="Imagen de perfil" /> : <Spinner />}
          </div>
          <div className="col-12 col-sm-9 d-flex flex-column justify-content-center">
            <h4>{`${first_name} ${last_name}`}</h4>
            <p className="text__gray4">{email}</p>
            <div className="profile-buttons">
              <>
                {!cv_url && !github && !linkedin
                    && (
                      <p className="d-flex align-items-center text__error" role="img" aria-label="Warning">
                        <b>
                          <span role="img" aria-label="Warning">⚠️</span>
                          Completa tu perfil
                        </b>
                      </p>
                    )}
              </>
              <br />
              {showDiploma && (
                <>
                  <AknowledgmentDevf
                    style={{ marginLeft: '1rem' }}
                    name={dataDiploma.name}
                    program={dataDiploma.program}
                    place={dataDiploma.city_name}
                    date={dataDiploma.date}
                    dateES={new Date(dataDiploma.date)}
                    info={dataDiploma.diploma}
                    qr={dataDiploma.qr}
                    hash={dataDiploma.hash}
                    totalHours={dataDiploma.totalHours}
                    totalHoursEN={dataDiploma.totalHoursEN}
                    dateEN={dataDiploma.dateEN}
                  />
                  <a 
                    href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${program}&organizationId=3787571&issueYear=${new Date(end_date).getFullYear()}&issueMonth=${endMonthFixed}&certId=${qrData?.block_hash}&credentialDoesNotExpire=1&certUrl=https://devf.la`}
                    title="LinkedIn"
                    target="_blank"
                    rel="noreferrer"
                    className="btn__tool"
                    style={{ background: '#084461', color: 'white', maxWidth: '40%' }}
                  >
                    Agregar a linkedin
                  </a>
                </>
              )}
            </div>
          </div>
          {!activeProgram.active && (
          <div>
            <p className="d-flex align-items-center text__error" role="img" aria-label="Warning">
              <b>
                Tu perfil en campus por el momento está suspendido
              </b>
            </p>
          </div>
                )}
        </div>
      </div>
)}
      {isMe && currentPath === 'me' && (
        <>
          <AlumniForm
            activeProgram={activeProgram.infoProgram.slug}
            profile={profile}
            updateProfile={updateProfile}
            updateAlumni={updateAlumni}
            updateBillingData={updateBillingData}
            updatePassword={updatePassword}
            sendEmail={sendEmail}
            isFetchingUpdateProfile={isFetchingUpdateProfile}
            isErrorUpdateProfile={isErrorUpdateProfile}
            isUpdateProfileSuccess={isUpdateProfileSuccess}
          />
        </>
      )}

      {isMe && currentPath === 'account' && (
      <>
        <AlumniFormSecurity
          updatePassword={updatePassword}
          sendEmail={sendEmail}
          isFetchingUpdateProfile={isFetchingUpdateProfile}
          isErrorUpdateProfile={isErrorUpdateProfile}
          isUpdateProfileSuccess={isUpdateProfileSuccess}
        />
      </>
      )}

      {isMe && currentPath === 'invoice' && (
      <>
        <AlumniFormInvoice
          updatePassword={updatePassword}
          activeProgram={activeProgram.infoProgram.slug}
          profile={profile}
          sendEmail={sendEmail}
          updateBillingData={updateBillingData}
          isFetchingUpdateProfile={isFetchingUpdateProfile}
          isErrorUpdateProfile={isErrorUpdateProfile}
          isUpdateProfileSuccess={isUpdateProfileSuccess}
        />
      </>
      )}
    </>
  );
};

ProfileCard.propTypes = {
  acknowledgement: PropTypes.bool,
  profile: PropTypes.shape().isRequired,
  activeProgram: PropTypes.shape().isRequired,
  isMe: PropTypes.bool,
  isFetchingUpdateProfile: PropTypes.bool,
  isErrorUpdateProfile: PropTypes.bool,
  isUpdateProfileSuccess: PropTypes.bool,
  updateAlumni: PropTypes.func,
  updateProfile: PropTypes.func,
  updateBillingData: PropTypes.func,
  updatePassword: PropTypes.func,
  sendEmail: PropTypes.func,
  program: PropTypes.string.isRequired,
  diploma: PropTypes.string.isRequired,
  qrData: PropTypes.shape(),
  signDiploma: PropTypes.func.isRequired
};

ProfileCard.defaultProps = {
  acknowledgement: false,
  isMe: false,
  isFetchingUpdateProfile: null,
  isErrorUpdateProfile: null,
  isUpdateProfileSuccess: null,
  updateProfile() { },
  updateAlumni() { },
  updateBillingData() { },
  updatePassword() { },
  sendEmail() { },
  qrData: {},
};

export default ProfileCard;
