import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ data }) => {
  const element = React.createElement(
    `h${data.level}`,
    {
      className: 'ce-header text__white',
    },
    data.text
  );  
  return (
    <div className="ce-block">
      <div className="ce-block__content">
        <div style={{ height: 'fit-content', margin: '32px' }}>
          {element}
        </div>
      </div>
    </div> 
  );
};

Header.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default Header;
