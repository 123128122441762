export default {

  title: 'Ejercicio 1',
  description: `
  Crea una función llamada <code>sum</code> que reciba dos parametros "a" y "b" y regrese la suma de ambos números.
  Recuerda que para devolver el valor de una función se utiliza la palabra reservada <code>return</code>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('4 test', function (t) {
      t.equal(sum(2,2), 4,"'sum' debe regresar 4");
      t.end()
    });`,
    `test('negative test', function (t) {
      t.notEqual(sum(2,2), 0,"'sum' no debe regresar 0");
      t.end()
    });`
  ]
};
