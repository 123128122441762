export default {
  title: 'Ejercicio 5',
  description: `
  Escribe una función llamada <code>evaluate</code> que reciba como parámetro un número
  retorne lo siguiente:
  <ul>
    <li><code>"Positivo"</code> si el número es positivo.</li>
    <li><code>"Negativo"</code> si el número es negativo.</li>
    <li><code>"Cero"</code> si el número es cero.</li>
  <ul>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('evaluate(4)', function (t) {
      t.equal(evaluate(4), "Positivo","'evaluate' debe regresar 'Positivo'");
      t.end()
    });`,
    `test('evaluate(-9)', function (t) {
      t.equal(evaluate(-9), "Negativo","'evaluate' debe regresar 'Negativo'");
      t.end()
    });`,
    `test('evaluate(0)', function (t) {
      t.equal(evaluate(0), "Cero","'evaluate' debe regresar 'Cero'");
      t.end()
    });`
  ]
};
