import React from 'react';
import PropTypes from 'prop-types';

const List = ({ data }) => (
  <div className="ce-block">
    <div className="ce-block__content">
      <ul className={`"cdx-block" "cdx-list" "cdx-list--${data.style}"`}>
        {data.items.map((item) => <li key={item} className="cdx-list__item text__white">{item}</li>)}
      </ul>
    </div>
  </div>
  );

List.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default List;
