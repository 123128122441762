import Embed from '@editorjs/embed';
import Table from '@editorjs/table';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import Code from '@editorjs/code';
import LinkTool from '@editorjs/link';
// import Image from '@editorjs/image';
import Raw from '@editorjs/raw';
import Header from '@editorjs/header';
import Quote from '@editorjs/quote';
import Marker from '@editorjs/marker';
import CheckList from '@editorjs/checklist';
import Delimiter from '@editorjs/delimiter';
import InlineCode from '@editorjs/inline-code';
import SimpleImage from '@editorjs/simple-image';

// eslint-disable-next-line import/prefer-default-export
export const EDITOR_JS_TOOLS = {
  inlineCode: {
    class: InlineCode,
    inlineToolbar: true,
  },
  embed: {
    class: Embed,
    config: {
      services: {
        youtube: true,
        coub: true,
        vimeo: {
          // eslint-disable-next-line no-useless-escape
          regex: /https?:\/\/vimeo.com\/([^\/\?\&]*)\/([^\/\\&]*)/,
          embedUrl: 'https://player.vimeo.com/video/<%= remote_id %>',
          html: "<iframe className='embed-video' title='video' frameBorder='0' fullscreen' allowFullScreen />",
          height: 400,
          width: 800,
          id: (groups) => groups.join('/')
        },
        codepen: {
          // eslint-disable-next-line no-useless-escape
          regex: /https?:\/\/repl.it\/([^\/\?\&]*)\/([^\/\?\&]*)/,
          embedUrl: 'https://repl.it/<%= remote_id %>?lite=true',
          html: "<iframe height='300' scrolling='no' frameborder='no' allowtransparency='true' allowfullscreen='true' style='width: 100%; sandbox='allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-modals'></iframe>",
          height: 400,
          width: 800,
          id: (groups) => groups.join('/')
        }
      }
    }
  },
  table: Table,
  list: {
    class: List,
    inlineToolbar: true,
  },
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  raw: Raw,
  header: {
    class: Header,
    inlineToolbar: true,
  },
  quote: {
    class: Quote,
    inlineToolbar: true,
  },
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  image: {
    class: SimpleImage,
    inlineToolbar: true,
    config: {
      placeholder: 'Inserta la imagen'
    },
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
  },
};
