import React from 'react';
import PropTypes from 'prop-types';
import Selection from '../Selection';

const Form = ({
  back,
  next, 
  close, 
  action, 
  select, 
  dataForm, 
  setSelect, 
  showModalSoftKills, 
  showModalRecomended,
  showTechnicalSkills
}) => {
  const {
      title, subtitle, answers, buttons 
  } = dataForm;

  const actionsOnClick = (actions) => {
    if (actions[0] === 'close' && actions[1] === 'next') {
      return (close(false), next(true));
    } 
    if (actions[0] === 'close' && actions[1] === 'back') {
      return (close(false), back(true));
    }
    if (actions.includes('action')) {
      return (action(select));
    }
    return null;
  };

  return (
    <div className="o-modal-content">
      <h5>{title}</h5>
      { subtitle && <span>{subtitle}</span> }
      <form className="o-modal-content__form" data-testid="form-container">   
        <Selection
          showModalSoftKills={showModalSoftKills}
          showModalRecomended={showModalRecomended} 
          showTechnicalSkills={showTechnicalSkills} 
          answers={answers} 
          select={select} 
          setSelect={setSelect}
        />
        <div className="d-flex">
          {buttons && buttons.map((button) => (
            <>
              <button
                key={button.index}
                data-testid="form-recommended-button"
                type="button"
                className="btn__tool ml-0 mt-4"
                onClick={() => { actionsOnClick(button.actions); }}
              >
                {button.name}
              </button>
            </>
            ))}
        </div>
      </form>
    </div>
  );
};

Form.defaultProps = {
  previus: {},
  dataForm: {
    buttons: [],
    subtitle: ''
  },
  action: () => { },
  next: () => { },
  back: () => { }
};

Form.propTypes = {
  action: PropTypes.func,
  next: PropTypes.func,
  back: PropTypes.func,
  setSelect: PropTypes.func.isRequired,
  previus: PropTypes.shape({
    email: PropTypes.string
  }),
  select: PropTypes.shape().isRequired,
  showModalSoftKills: PropTypes.bool.isRequired,
  showModalRecomended: PropTypes.bool.isRequired,
  showTechnicalSkills: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  dataForm: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttons: PropTypes.arrayOf(PropTypes.shape()),
    answers: PropTypes.arrayOf(PropTypes.shape())
  }),
};

export default Form;
