import React, { useEffect, useState } from 'react';
import { Spinner } from '../../Common/Spinner';
import { useCareer } from '../../Context/CareerContext';
import { useMe } from '../../Context/MeContext';

export default (Career) => function CareerHoc() {
  const [{ 
          isFetching, 
          isError, 
          job, 
          isFetchingApply, 
          isErrorApply, 
          isApplySuccess 
        }, 
        { 
          apllyJobAlumni 
        }
        ] = useCareer();
  const [loading, setLoading] = useState(true);
  const [{ profile }] = useMe();

  useEffect(() => {
    if (profile && profile.activeProgram) {
      setLoading(false);
    }
  }, [profile]);

  const renderContent = () => {
    if (isFetching || loading) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '93vh' }}>
          <Spinner />
        </div>
      );
    }
    if (isError) return <h1 style={{ marginTop: '20rem' }}>Error</h1>;
    if (job) {
      return (
        <Career 
          isFetchingApply={isFetchingApply} 
          isErrorApply={isErrorApply} 
          isApplySuccess={isApplySuccess} 
          apllyJobAlumni={apllyJobAlumni} 
          job={job.result} 
          alumni={profile.alumni} 
          profile={profile} 
        />
            );
    }
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '93vh' }}>
        <Spinner />
      </div>
    );
  };

  return (
    <>
      {renderContent()}
    </>
  );
};
