/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { ArrowLeft, Send, ChevronLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import session from '../../utils/session';
import Mixpanel from '../../utils/Mixpanel';
import ReadOnly from '../../Common/Content/ReadOnly';
import Questions from '../../Components/Question';
import RateProjectTable from '../../Components/RateProjectTable';
import './lectures.scss';
import tick from './tick.svg';
import book from './book.svg';
import flag from './flag.svg';
import { Spinner } from '../../Common/Spinner';
import { ProjectRateForm } from '../../Components/ProjectRateForm';

const Lectures = ({
  isFetchingGradeExam,
  lectures,
  isFetchingLecture, 
  currentLecture, 
  profile, 
  contentType, 
  module, 
  setResponse, 
  submitExam, 
  examLecture, 
  track,
  projects,
  activeProject,
  alumniProject,
  saveAlumniProject,
  isSensei
}) => {

  useEffect(() => {
    Mixpanel.track(`CampusLecture-Open${contentType}`);
    ReactGA.event({
      category: contentType,
      action: `Open ${contentType} `,
      label: `${contentType}: ${lectures.name} `
    });
  }, []);


  const doneLecturesIDS = profile.activeProgram.tracking.lectures.map((lecture) => ({ 
      id: lecture.lecture_id, 
      date_open: lecture.done.date_open, 
      date_read: lecture.done.date_read 
}));

  const doneExamsIDS = profile.activeProgram.tracking.exams 
    ? profile.activeProgram.tracking.exams.map(
      (exam) => exam.id_exam
    ) : [];

  const renderTick = (key, doneIDS, topic, typeTracking1, typeTracking2) => {
    const idLecture = topic.id || topic._id;
    if (key === 'lecture') {
      const found = doneIDS.find((lecture) => lecture.id === idLecture);
      if (found) {
        if ((typeTracking1 === 'open' && found.date_open) && (typeTracking2 === 'read' && found.date_read)) {
          return (<div className="lecture-donut" style={{ background: '#14C7D0' }}><div className="lecture-donut-center" /></div>);
        } if (typeTracking1 === 'open' && found.date_open) {
            return (<div className="lecture-donut"><div className="lecture-donut-center" /></div>);
        } if (typeTracking2 === 'read' && found.date_read) {
          return (<img className="mr-2" src={tick} alt="done" />);
        }
      }
    }
    return null;
  };

  const sendProject = contentType === 'project' && profile.activeProgram.tracking.projects.find(
   (p) => activeProject && p.project === activeProject.slug && (alumniProject ? alumniProject.points <= 0 : true)
  );

  const doneProject = contentType === 'project' && profile.activeProgram.tracking.projects.find(
    (p) => activeProject && p.project === activeProject.slug && (alumniProject && alumniProject.points > 0)
   );

  const doneExam = profile.activeProgram.tracking.exams ? profile.activeProgram.tracking.exams.find(
      (exam) => exam.id_exam === currentLecture._id
    ) : false;

  const questions = doneExam ? doneExam.responses : examLecture;

  const getMinutes = (milliseconds) => parseInt(milliseconds / 60000, 10);

  const isCurrentLink = ({ mod, cont, lID }) => 
    // eslint-disable-next-line no-underscore-dangle
    mod === module && cont === contentType && lID === currentLecture._id;

  const sideBarElements = () => lectures.topics.map((topic) => (
    <>
      <div className="sidebar-topic-main">
        <strong className="text__white">{topic.tema}</strong>
      </div>
      {
        Object.keys(topic).filter((key) => key === 'exam' || key === 'lecture').map((key) => (
          <div 
            key={`${key}-${topic[key].id}`}
            className={`sidebar-topic-basic d-flex flex-row align-items-center justify-content-between ${isCurrentLink({ mod: module, cont: key, lID: topic[key].id }) && 'sidebar-topic-basic__selected'}`}
          >
            <Link
              key={`${key}-${topic[key].id}`}
              className="text__white"
              // className={`${isCurrentLink({ mod: module, cont: key, lID: topic[key].id }) ? 'text__gray1' : 'text__white'}`} 
              to={isSensei ? `/sensei/lectures/${module}/${topic.id}?type=${key}` : `/alumni/lectures/${module}/${topic.id}?type=${key}`}
            >
              {topic[key].tema}
            </Link>
            <div>
              { renderTick(key, doneLecturesIDS, topic, 'open', 'read') }
              {key === 'exam' && doneExamsIDS.indexOf(topic.id) !== -1 && <div className="lecture-donut" style={{ background: '#14C7D0' }}><div className="lecture-donut-center" /></div>}
            </div>
          </div>
        ))
      }
    </>
    )); 

  return (    
    <div className="row">
      <div className="col-md-3 pb-5 px-0 d-none d-sm-block" style={{ backgroundColor: '#11111E' }}>
        <div className="sidebar-header-lecture">
          <h5 className="text__white">Contenido adicional </h5>
          <div className="d-flex align-items-center ">
            <Link to="/alumni/course" className="btn__alt-default px-0 ml-0 mr-2"> 
              {' '}
              <ArrowLeft width="15" height="15" />
              {' '}
            </Link>
            <h5 className="text__brand-pluto">{lectures.name}</h5>
          </div>
        </div>
        {sideBarElements()}
        {projects && projects.length > 0 && (<h5 className="text__white" style={{ margin: '18px' }}>Proyectos del módulo</h5>)}
        {projects && projects.map((project) => (
          <div 
            key={project.slug} 
            className={`sidebar-topic-basic d-flex flex-row align-items-center justify-content-between ${isCurrentLink({ mod: module, cont: project.slug, lID: project.slug }) && 'sidebar-topic-basic__selected'}`}
          >
            <Link 
              key={project.slug} 
              className={`${isCurrentLink({ mod: module, cont: project.slug, lID: project.slug }) ? 'text__gray1' : 'text__white'}`} 
              to={isSensei ? `/sensei/lectures/${module}/${project.slug}?type=project` : `/alumni/lectures/${module}/${project.slug}?type=project`}
            >
              {project.title}
            </Link>
          </div>
      ))}
      </div>
      <div className="col-md-9 pb-5 pb-lg-0">
        { currentLecture._id && contentType === 'lecture' && (
        <>
          <div className="lecture__time row mt-0 mt-sm-4">
            <div className="col-9 col-md-8 col-lg-6 d-flex flex-row">
              <div className="ribbon__lecture bg__brand-plasma d-none d-sm-flex">
                <img src={book} alt="lecture ribbon" />
                <p className="text__white">Lectura</p>
              </div>
              <p className="text__gray4">
                Tiempo aproximado
                {' '}
                {getMinutes(currentLecture.reading_time)}
                {' '}
                minutos
              </p>
            </div>
            <>
              { renderTick('lecture', doneLecturesIDS, currentLecture, 'open', 'read') }
            </>
          </div>
          <Link 
            className="d-flex d-block d-md-none text__white pt-3"
            to={isSensei ? '/sensei/course' : '/alumni/course'}
          >
            <ChevronLeft />
            {' '}
            VOLVER
          </Link>
          <div className="markdown lectures-scroll" id="lectureContainer">
            {isFetchingLecture ? <Spinner /> : (
              <ReadOnly
                module={lectures.name}
                data={currentLecture}
                activeProgram="Master en Code"
                track={track}
                program={profile.activeProgram.program}
                lectures={profile.activeProgram.tracking.lectures}
              />
              )}
          </div>
        </>
       )}
        { currentLecture && contentType === 'project' && (
        <>
          <div className="row mt-4">
            <div className="col-6 d-flex flex-row justify-content-between">
              <div className="ribbon__lecture bg__brand-plasma">
                <img src={book} alt="lecture ribbon" />
                <p className="text__white">Proyecto</p>
              </div>
            </div>
          </div>
          {sendProject && (
            <div className="m-5 project_send text__white">
              <h5>¡Felicidades por tu esfuerzo!</h5>
              <p>Tú senseí calificará tu proyecto y una vez calificado podrás visualizar en esta sección tu revisión.</p>
              {alumniProject ? (
                <>
                  <p>{`Título: ${alumniProject?.title}`}</p>
                  <p>{`Descripción: ${alumniProject?.description}`}</p>
                  <p>Links: </p>
                  <ul>
                    {alumniProject?.links.length > 0 ? alumniProject?.links.map((e,i) => <li key={`${i}+${e}`}><a href={e} target="_blanck">{e}</a></li>) : null}
                  </ul>
                </>
                ) : <></>}
            </div>
          )}
          {doneProject && (
          <>
            <h4 className="my-5 text__white">
              Tu calificación:
            </h4>
            <RateProjectTable
              alumniProject={alumniProject} 
              testPasses={doneProject.testPasses} 
              testTotal={doneProject.testTotal} 
              testFailures={doneProject.testFailures}
            />
          </>
            )}
          <div className="markdown lectures-scroll" id="lectureContainer">
            {activeProject && (
              <>
                <ReadOnly
                  module={lectures.name}
                  data={activeProject}
                  activeProgram="Master en Code"
                  track={() => {}}
                  program={profile.activeProgram.program}
                  lectures={profile.activeProgram.tracking.lectures}
                />
                <ProjectRateForm submitProject={saveAlumniProject} alumniProject={alumniProject} />
              </>
            )}
          </div>
        </>
       )}
        {
         currentLecture && contentType === 'exam' && (
           <>
             <div className="row mt-4">
               <div className="col-12 d-flex flex-row justify-content-between mt-3 mb-1">
                 <h4 className="text__white">Auto-Evaluación</h4>
               </div>
               <div className="col-12 d-flex flex-row justify-content-between">
                 <div className="ribbon__exam bg__brand-nebula">
                   <img src={flag} alt="exam ribbon" />
                   <p className="text__white">Evaluación</p>
                 </div>
               </div>
             </div>
             <div>
               {questions.map((question, i) => (
                 <Questions
                   key={question.question}
                   question={question.question}
                   answers={question.answers}
                   setExamLecture={setResponse}
                   disabled={false}
                   numQuestion={i + 1}
                   total={currentLecture?.questions?.length}
                   doneExam={doneExam}
                 />
           ))}
             </div>
             <div className="row my-4 ">
               <div className="col-12 d-flex flew-row">
                 {
                  !doneExam && (
                    <button 
                      type="button" 
                      className="btn__tool bg__warning text__gray1"
                      onClick={submitExam}
                      disabled={isFetchingGradeExam}
                    > 
                      {
                        isFetchingGradeExam ? (
                          <Spinner />
                        ) : (
                          <>
                            ENVIAR RESPUESTAS
                            {' '}
                            <Send />
                            {' '}
                          </>
                          )
                      }
                    </button>
                  )
                }
               </div>
             </div>
             
           </>
         )
       }
      </div>
    </div>
  );
};

Lectures.defaultProps = {
  alumniProject: {
    links: [''],
    points: -1,
    description: '',
  }
};

Lectures.propTypes = {
 lectures: PropTypes.shape().isRequired,
 currentLecture: PropTypes.shape().isRequired,
 contentType: PropTypes.string.isRequired,
 module: PropTypes.string.isRequired,
 setResponse: PropTypes.func.isRequired,
 submitExam: PropTypes.func.isRequired,
 examLecture: PropTypes.array.isRequired,
 track: PropTypes.func.isRequired,
 isFetchingLecture: PropTypes.bool.isRequired,
 isFetchingGradeExam: PropTypes.bool.isRequired,
 profile: PropTypes.shape({
   activeProgram: PropTypes.shape({
     program: PropTypes.string.isRequired,
     tracking: PropTypes.shape({
       lectures: PropTypes.array.isRequired,
       exams: PropTypes.array.isRequired,
       projects: PropTypes.array.isRequired,
      }).isRequired
    }).isRequired
  }).isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // projectsResults: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  activeProject: PropTypes.shape().isRequired,
  activeProgram: PropTypes.shape().isRequired,
  alumniProject: PropTypes.shape({
    title: PropTypes.string,
    points: PropTypes.number,
    links: PropTypes.arrayOf(PropTypes.string).isRequired,
    description: PropTypes.string,
  }),
  saveAlumniProject: PropTypes.func.isRequired,
  isSensei: PropTypes.bool.isRequired,
};

export default session(Lectures);
