export default {
  title: 'Ejercicio 15',
  description: `
  Necesitamos saber si un empleado recibirá un aumento.
  Crea una función llamada <code>payRise()</code> que reciba como parámetros sueldo, años de antigüedad y nombre del empleado. Con esos datos evalúe si: 
  <ul>
  <li>Si el sueldo es menor a 500 y su antigüedad es igual o mayor a 10 años, retorna <code> “El empleado ‘nombre’, recibirá un aumento del 20%” </code> </li>
  <li>Si el sueldo es menor a 500 y su antigüedad es menor a 10 años, retorna <code> “El empleado ‘nombre’, recibirá un aumento del 5%”</code> </li>
  <li>Si el sueldo es mayor o igual a 500, retorna <code> “El empleado ‘nombre’, no recibirá aumento”</code> </li>
  </ul>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Probar si sueldo de 400, 11 años de antigüedad, Juan', function (t) {
      t.equal(payRise(400,11,"Juan"), 'El empleado Juan, recibirá un aumento del 20%','Esto debe retornar El empleado Juan, recibirá un aumento del 20%');
      t.end()
    });`,
    `test('Probar si sueldo de 300, 9 años de antigüedad, Roberto', function (t) {
      t.equal(payRise(300,9,"Roberto"), 'El empleado Roberto, recibirá un aumento del 5%','Esto debe retornar El empleado Roberto, recibirá un aumento del 5%');
      t.end()
    });`,
    `test('Probar si sueldo de 900, 11 alos de antigüedad, Maria', function (t) {
      t.equal(payRise(900,11,"Maria"), 'El empleado Maria, no recibirá aumento','Esto debe retornar El empleado Maria, no recibirá aumento');
      t.end()
    });`
  ]
};
