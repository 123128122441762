export default {
  title: 'Ejercicio 35',
  description: ` Crea una funcion llamada <code>petNames()</code>
  A partir del siguiente arreglo de mascotas:
    const mascotas = [
        { nombre: "Bimbo",    especie: "Hamster", comidasFavoritas: ["Semillas", "Nueces"] },
        { nombre: "Ludovico", especie: "Perro",   comidasFavoritas: ["Huesos", "Jamon"] },
        { nombre: "Pavlov",   especie: "Perro",   comidasFavoritas: ["Croquetas", "Pollo"] },
        { nombre: "Chancla",  especie: "Gato",    comidasFavoritas: ["Atun", "Pescado"] },
        { nombre: "Don Pepe", especie: "Perico",  comidasFavoritas: ["Semillas"] }
    ];
    Genera un nuevo arreglo que contenga los nombres de todas 
    las mascotas. 
    `,
  seed: `const mascotas = [
        { nombre: "Bimbo",    especie: "Hamster", comidasFavoritas: ["Semillas", "Nueces"] },
        { nombre: "Ludovico", especie: "Perro",   comidasFavoritas: ["Huesos", "Jamon"] },
        { nombre: "Pavlov",   especie: "Perro",   comidasFavoritas: ["Croquetas", "Pollo"] },
        { nombre: "Chancla",  especie: "Gato",    comidasFavoritas: ["Atun", "Pescado"] },
        { nombre: "Don Pepe", especie: "Perico",  comidasFavoritas: ["Semillas"] }
    ];`,
  test: [
    `test('Prueba 1', function (t) {
      const prueba =  petNames();
      t.equal(prueba.length,5,"Correcto el tamaño");
      t.end()
    });`,
    `test('prueba 2', function (t) {
      const prueba = petNames();
      t.equal(prueba[3],'Chancla',"Nombre correcto");
      t.end()
    });`,
  ]
};
