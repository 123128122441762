import React, { useEffect } from 'react';
import { useVideos } from '../../../Context/VideoContext';
import { useSensei } from '../../../Context/SenseiContext';
import { Spinner } from '../../../Common/Spinner';

export default (VideosSensei) => function VideosHoc() {
  const [
    {
      isFetching, isError, videosOnboarding, activeVideo, bgVideo
    },
    { getVideosOnboarding, selectVideo, setBgVideo }] = useVideos();
  const [{ profile }] = useSensei();

  useEffect(() => {
        if (profile && profile.activeRecordModule) {
          getVideosOnboarding('/3194550');
    }
  }, [profile]);

  const renderContent = () => {
    if (isFetching) {
      return (
        <div className="d-flex justify-content-center align-items-center bg__gray2" style={{ height: '93vh', width: '83.3vw' }}>
          <Spinner />
        </div>
      );
    }
    if (isError) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <h3>{isError}</h3>
        </div>
      );
    }
    if (videosOnboarding && videosOnboarding.length === 0) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <h3>Videos no disponibles</h3>
        </div>
      );
    }
    if (videosOnboarding && videosOnboarding.length > 0) {
      return (
        <VideosSensei
          videosOnboarding={videosOnboarding}
          track={() => {}}
          activeVideo={activeVideo}
          setActiveVideo={selectVideo}
          bgVideo={bgVideo}
          setBgVideo={setBgVideo}
        />
      );
    }
    return (
      <div className="d-flex justify-content-center align-items-center bg__gray2" style={{ height: '93vh', width: '83.3vw' }}>
        <Spinner />
      </div>
    );
  };

  return (<>{renderContent()}</>);
};
