/* eslint-disable consistent-return */
import React, {
  useContext,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
// import { ElementsConsumer } from '@stripe/react-stripe-js'; 
import {
  useStripe,
} from '@stripe/react-stripe-js';
import api from './api';

const MasterPaymentInfoContext = React.createContext(undefined);

const MasterPaymentInfoProvider = ({ children }) => {
  const [infoPayment, setInfoPayment] = useState(null);
  const [isFetchingInfoPayment, setIsFeatchingInfoPayment] = useState(true);
  const [isErrorInfoPayment, setIsErrorInfoPayment] = useState(null);
  
  const [infoSubscriptions, setInfoSubscriptions] = useState(null);
  const [isFetchingSubscriptions, setIsFeatchingSubscriptions] = useState(true);
  const [isErrorInfoSubscriptions, setIsErrorInfoSubscriptions] = useState(null);

  const [isFetchingChangeDefaultCard, setIsFeatchingChangeDefaultCard] = useState(false);

  const [viewHelpModal, setViewModalHelp] = useState(false);
  const [viewPaymentModal, setViewPaymentModal] = useState(false);
  const stripe = useStripe();
  
  const getInfoPayment = async () => {
    setIsErrorInfoPayment(false);
    try {
      const { data: arrayPayments } = await api.Profile.masterPaymentInfo();
      setInfoPayment(arrayPayments[0]);
    } catch (error) {
      if (error && error.response) {
        switch (error.response.status) {
          case 500:
            setIsErrorInfoPayment('Ocurrió un error en el servidor');
            break;
          default:
            setIsErrorInfoPayment(error.response.data.message);
            break;
        }
      } else {
        setIsErrorInfoPayment('Ocurrió un error en el servidor');
      }
    } finally {
      setIsFeatchingInfoPayment(false);
    }
  };

  const getInfoSubscriptions = async () => {
    setIsErrorInfoSubscriptions(false);
    try {
      const { data: arraySuscriptions } = await api.Profile.masterSybscriptionsInfo();
      setInfoSubscriptions(arraySuscriptions);
    } catch (error) {
      if (error && error.response) {
        switch (error.response.status) {
          case 500:
            setIsErrorInfoSubscriptions('Ocurrió un error en el servidor');
            break;
          default:
            setIsErrorInfoSubscriptions(error.response.data.message);
            break;
        }
      } else {
        setIsErrorInfoSubscriptions('Ocurrió un error en el servidor');
      }
    } finally {
      setIsFeatchingSubscriptions(false);
    }
  };

  const changeDefaultCard = async (idSource, cb) => {
    try {
      setIsFeatchingChangeDefaultCard(true);
      const newDefaultCard = await api.Profile.setDefaultCard(idSource);
      cb(null, newDefaultCard);
    } catch (error) {
      if (error && error.response) {
        switch (error.response.status) {
          case 500:
            cb('Ocurrió un error en el servidor');
            break;
          default:
            cb(error.response.data.message);
            break;
        }
      } else {
        cb('Ocurrió un error en el servidor');
      }
    } finally {
      setIsFeatchingChangeDefaultCard(false);
    }
  };

  const addCard = async (card, cb) => {
    try {
      const { token, error } = await stripe.createToken(card);
      if (error) return cb(error.message, null);
      await api.Profile.addCardCustumer(token.id);
      await getInfoSubscriptions();
      return cb(null, true);
    } catch (error) {
      return cb(error?.response?.data?.message ?? 'Se declinó la tarjeta', null);
    }
  };

  const deletedCard = async (card, cb) => {
    try {
       await api.Profile.deletedCard(card);
       await getInfoSubscriptions();
       cb(null, true);
    } catch (error) {
      cb(error);
    }
  };

  const payInvoice = async (idInvoice, cb) => {
    try {
      await api.Profile.payInvoice(idInvoice);
      await getInfoPayment();
      await getInfoSubscriptions();
      return cb(null, true);
    } catch (error) {
      if (error && error.response) {
        if (error.response.data && error.response.data.message) {
          return cb(error.response.data.message);
        } 
          return cb('Su pago fue declinado, puedes ponerte en contacto desde el bot', null);
      } 
        cb('Ocurrió un error inesperado, puedes ponerte en contacto desde el bot', null);
    }
  };

  const requestHelp = async (message) => {
    await api.Profile.helpPayments(message);
  };

  useEffect(() => {
    getInfoSubscriptions();
    getInfoPayment();
  }, []);

  const state = [
    {
      isFetchingInfoPayment,
      isErrorInfoPayment,
      infoPayment,
      viewHelpModal,
      viewPaymentModal,
      isFetchingSubscriptions,
      isErrorInfoSubscriptions,
      infoSubscriptions,

      isFetchingChangeDefaultCard,
    },

    {
    setViewModalHelp,
    setViewPaymentModal,
    requestHelp, 
    changeDefaultCard,
    addCard,
    deletedCard,
    payInvoice,
    stripe, 
}
  ];
  
  return (
    <MasterPaymentInfoContext.Provider value={state}>
      {children}
    </MasterPaymentInfoContext.Provider>
  );
};

MasterPaymentInfoProvider.propTypes = {
  children: PropTypes.shape(),
};

MasterPaymentInfoProvider.defaultProps = {
  children: {},
};

const useMasterPaymentInfo = () => {
  const context = useContext(MasterPaymentInfoContext);
  if (context === undefined) {
    throw new Error('useMasterPaymentInfo can only be used inside MasterPaymentInfoProvider');
  }
  return context;
};

export { MasterPaymentInfoProvider, useMasterPaymentInfo };
