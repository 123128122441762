import React, { useContext, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import api from './api';

const CareersContext = createContext(undefined);

const CareersProvider = ({ children }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [jobs, setJobs] = useState(null);

  const getCareers = async () => {
    setIsFetching(true);
    setIsError(false);
    try {
      const data = await api.Hue.getJobs();
      if (data) {
        setJobs(data);
      } else if (!data) {
        setIsFetching(false);
        setIsError('No hay vacantes por el momento.');
      } else {
        setIsFetching(false);
        setIsError('Las vacantes han caducado.');
      }
    } catch (error) {
      if (error && error.response) {
      switch (error.response.status) {
        case 500:
          setIsError('Ocurrió un error en el servidor.');
          break;
        default:
          setIsError(error.data.data.message);
          break;
      }
    } else {
      setIsError('Ocurrió un error en el servidor.');
    }
    }
    setIsFetching(false);
  };
  const searchJobs = async (search) => {
    setIsFetching(true);
    setIsError(false);
    try {
      const data = await api.Hue.getJobs(search);
      if (data) {
        setJobs(data);
      } else if (!data) {
        setIsFetching(false);
        setIsError('No hay vacantes por el momento.');
      } else {
        setIsFetching(false);
        setIsError('Las vacantes han caducado.');
      }
    } catch (error) {
      if (error && error.response) {
      switch (error.response.status) {
        case 500:
          setIsError('Ocurrió un error en el servidor.');
          break;
        default:
          setIsError(error.data.data.message);
          break;
      }
    } else {
      setIsError('Ocurrió un error en el servidor.');
    }
    }
    setIsFetching(false);
  };
  
  const state = [
    { isFetching, isError, jobs },
    { getCareers, searchJobs }
  ];
  return (
    <CareersContext.Provider value={state}>
      {children}
    </CareersContext.Provider>
  );
};

CareersProvider.propTypes = {
  children: PropTypes.shape(),
};

CareersProvider.defaultProps = {
  children: [],
};

const useCareers = () => {
  const context = useContext(CareersContext);
  if (context === undefined) {
    throw new Error('useCareers can only be used inside CareersProvider');
  }
  return context;
};

export {
  CareersProvider,
  useCareers
};
