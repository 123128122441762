export default {
  title: 'Ejercicio 26',
  description: `
    Crea una función llamada <code>decimalToBinary()</code> que reciba como parámetro un 
    número entero decimal y lo convierta en un número binario retornando su resultado.
    `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Número 5 a binario', function (t) {
      t.equal(decimalToBinary(5),"101","5 en binario");
      t.end()
    });`,
    `test('Número 67 a binario', function (t) {
      t.equal(decimalToBinary(67),"1000011","67 en binario ");
      t.end()
    });`,
    `test('Número 181 a binario', function (t) {
      t.equal(decimalToBinary(181), "10110101","181 en binario");
      t.end()
    });`
  ]
};
