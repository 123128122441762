import { devfApi } from '../../utils/http';

export const trackAliumni = (tracking, obj) => new Promise((resolve, reject) => {
    devfApi.post('/api/v1/campus/tracking', { event_type: tracking, ...obj }).then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
});

export const trackSensei = (tracking) => devfApi.post('/api/v1/campus/tracking', { tracking });
