/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import ReadOnly from '../../Common/Content/ReadOnly';

function Projects({
 projects, activeProject, setActiveProject, activeProgram, projectsResults
}) {  
  const createTextProject = (project) => {
    // eslint-disable-next-line max-len
    const projectRated = projectsResults ? projectsResults.find((r) => r.project === project.slug) : false;
    return projectRated
        ? (
          <>
            <span style={{ color: 'green' }}>
              {projectRated.testPasses}
            </span> 
            /
            <span style={{ color: 'red' }}>{projectRated.testFailures}</span>
            /
            <span style={{ color: '#9272FF' }}>{projectRated.testTotal}</span>
            {'  '}
          </>
      ) : '';
  };

  return (
    <>
      <div className="col-lg-8 bg__gray2">
        <section className="campus-container">
          <ReadOnly data={activeProject} activeProgram={activeProgram} track={() => {}} program="" module="" lectures={[]} />
        </section>
      </div>
      <div className="col-lg-2 bg__gray3 videolist-container">
        <div className="d-flex flex-column" style={{ height: 'auto' }}>
          {projects.map((project, i) => (
            <a
              key={project.slug}
              type="button"
              className={i === activeProject ? 'video-link-active' : 'video-link'}
              onClick={() => setActiveProject(i)}
            >
              {createTextProject(project)}
              {project.blocks[0].data.text}
            </a>
          ))}
        </div>
      </div>
    </>
  );
}

Projects.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  projectsResults: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  activeProject: PropTypes.shape().isRequired,
  setActiveProject: PropTypes.func.isRequired,
  activeProgram: PropTypes.shape().isRequired,
};

export default Projects;
