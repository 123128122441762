import React, {
  useContext,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { paths } from '../utils/const';
import videoNameBuilder from '../utils/videoNameBuilder';
import supportVideos from '../views/MyCourse/sensei/supportVideos.json';
import api from './api';

const VideosContext = React.createContext(undefined);

const VideoProvider = withRouter(({ children }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [videos, setVideos] = useState(null);
  const [lastVideo, setLastVideo] = useState(null);
  const [videosSupport, setVideosSupport] = useState(null);
  const [videosOnboarding, setVideosOnboarding] = useState(null);
  const [activeVideo, setActiveVideo] = useState(0);
  const [bgVideo, setBgVideo] = useState(true);
  // Video Normal
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState([]);
  // Video Support
  const [pageSupport, setPageSupport] = useState(1);
  const [totalPagesSupport, setTotalPagesSupport] = useState([]);

  const nextPage = () => setPage(page + 1);
  const previousPage = () => setPage(page - 1);

  // Video Support
  const nextPageSupport = () => setPageSupport(pageSupport + 1);
  const previousPageSupport = () => setPageSupport(pageSupport - 1);

  const getVideosOnboarding = async (id) => {
    setIsFetching(true);
    setIsError(false); 
    try {
        const folder = await api.Videos.getVideosFromFolderByUriWithUserId(id);
          if (folder) {
            setVideosOnboarding(folder.data.map((video) => ({
                duration: video.duration,
                name: videoNameBuilder(video.name),
                idVideo: video.link.split('/')[3],
                title: video.name,
                hash: video.link.split('/')[4] || null,
                image: video.pictures.sizes.find((img) => img.width === 295)
              })));
            setIsError(false);
          } else if (!folder) {
            setIsFetching(false);
            setIsError('No existen videos del sensei por el momento.');
          }
    } catch (error) {
      if (error && error.response) {
        switch (error.response.status) {
          case 500:
            setIsError('Ocurrió un error desconocido.');
            break;
          default:
            setIsError(error.response.data.message);
            break;
        }
      } else {
        setIsError('Ocurrió un error desconocido.');
      }
    }
    setIsFetching(false);
  };

  const getVideos = async (slugProgram, batch, startDate,
    endDate, senseiGroup, module, idCampusProgram) => {
    setIsFetching(true);
    setIsError(false);
    try {
      const now = new Date();
      const diffdays = Math.ceil((new Date(endDate) - now) / (1000 * 60 * 60 * 24)); 
      if (diffdays > -90) {
        const path = paths[slugProgram];
        if (path) { // Programas Actuales
          const folderLegacy = senseiGroup.video_folders.find(
            (_folder) => _folder.module_slug === module
          );
          const { data, paging } = await api.Videos.getVideosByModule(
            idCampusProgram, module, page, 9, folderLegacy
            );
          if (data && paging) {
            const lastPageUri = paging.last;
            const folderPagesInt = parseInt(lastPageUri.split('page=')[1].split('&')[0], 10);
            // // Se genera una lista a partir del número de páginas, para poder iterar
            const folderPagesList = Array.from(new Array(folderPagesInt), (_, i) => i + 1);
            setTotalPages(folderPagesList);
            const detailedVideos = data.map((_video) => ({
              duration: _video.duration,
              name: videoNameBuilder(_video.name),
              idVideo: _video.link.split('/')[3],
              hash: _video.link.split('/')[4] || null,
              image: _video.pictures.sizes.find((img) => img.width === 295)
            }));
            setVideos(detailedVideos);
          } else if (!folderLegacy) {
            setVideos([]);
            setTotalPages([]);
            setIsFetching(false);
            setIsError('No existen videos por el momento para el módulo seleccionado.');
            return;
          }
        }
      } else {
        setIsFetching(false);
        setIsError('Los videos han expirado.');
      }
    } catch (error) {
      if (error && error.response) {
        switch (error.response.status) {
          case 500:
            setIsError('Ocurrió un error desconocido.');
            break;
          default:
            setIsError(error.response.data.message);
            break;
        }
      } else {
        setIsError('Ocurrió un error desconocido.');
      }
    }
    setIsFetching(false);
  };

  const getOneVideo = async (slugProgram, batch, startDate,
    endDate, senseiGroup, module, idCampusProgram,) => {
    setIsFetching(true);
    setIsError(false);
    try {
      const now = new Date();
      const diffdays = Math.ceil((new Date(endDate) - now) / (1000 * 60 * 60 * 24)); 
      if (diffdays > -90) {
        const path = paths[slugProgram];
        if (path) { // Programas Actuales
          const folder = senseiGroup.video_folders.find(
            (_folder) => _folder.module_slug === module
          );
          const { moduleSensei } = await api.Me.getSenseiAndClass(idCampusProgram, module);
          if (folder || moduleSensei) {
            const {
              data
            } = await api.Videos.getOneVideo(
              moduleSensei ? moduleSensei.videos_folder : folder.uri, page
            );
            if (data.length > 0) {
              const detailedVideo = [{
                duration: data[0].duration,
                name: videoNameBuilder(data[0].name),
                idVideo: data[0].link.split('/')[3],
                hash: data[0].link.split('/')[4] || null,
                image: data[0].pictures.sizes.find((img) => img.width === 295)
              }];
              setLastVideo(detailedVideo);
            }
          } else if (!folder) {
            setIsFetching(false);
            setLastVideo([]);
            setIsError('No existen videos por el momento para el módulo seleccionado.');
            return;
          }
        }
      } else {
        setIsFetching(false);
        setIsError('Los videos han expirado.');
      }
    } catch (error) {
      if (error && error.response) {
        switch (error.response.status) {
          case 500:
            setIsError('Ocurrió un error desconocido.');
            break;
          default:
            setIsError(error.response.data.message);
            break;
        }
      } else {
        setIsError('Ocurrió un error desconocido.');
      }
    }
    setIsFetching(false);
  };
  
  const getVideosSupport = async (slugProgram, batch, startDate, endDate, senseiGroup, module) => {
    setIsFetching(true);
    setIsError(false);
    try {
      const now = new Date();
      const diffdays = Math.ceil((new Date(endDate) - now) / (1000 * 60 * 60 * 24));
      if (diffdays > -90) {
        const slugProgramUpperCase = slugProgram.toUpperCase();
        const path = supportVideos.filter((e) => e.path === slugProgramUpperCase);
        if (path) { // Programas Actuales
          const folder = path[0].video_folders.find(
            (_folder) => _folder.module_slug === module
          );
          if (folder) {
            const {
              data,
              paging,
            } = await api.Videos.getVideosFromFolderByUriAndPage(folder.uri, pageSupport);
            // "/users/110646974/projects/4833644/videos?page=11&per_page=9&sort=date";
            const lastPageUri = paging.last;
            const folderPagesInt = parseInt(lastPageUri.split('page=')[1].split('&')[0], 10);
            // // Se genera una lista a partir del número de páginas, para poder iterar
            const folderPagesList = Array.from(new Array(folderPagesInt), (_, i) => i + 1);
            setTotalPagesSupport(folderPagesList);
            const detailedVideos = data.map((_video) => ({
              duration: _video.duration,
              name: videoNameBuilder(_video.name),
              idVideo: _video.link.split('/')[3],
              hash: _video.link.split('/')[4] || null,
              image: _video.pictures.sizes.find((img) => img.width === 295)
            }));
            setVideosSupport(detailedVideos);
          } else if (!folder) {
            setVideosSupport([]);
            setTotalPagesSupport([]);
            setIsFetching(false);
            setIsError('No existen videos por el momento para el módulo seleccionado.');
            return;
          }
        }
      } else {
        setIsFetching(false);
        setIsError('Los videos han expirado.');
      }
    } catch (error) {
      if (error && error.response) {
        switch (error.response.status) {
          case 500:
            setIsError('Ocurrió un error desconocido.');
            break;
          default:
            setIsError(error.response.data.message);
            break;
        }
      } else {
        setIsError('Ocurrió un error desconocido.');
      }
    }
    setIsFetching(false);
  };

  const selectVideo = (index) => {
    setActiveVideo(index);
    setBgVideo(true);
  };

  const state = [
    {
      isFetching,
      isError,
      activeVideo,
      bgVideo,
      // Videos Onboarding
      videosOnboarding,
      // Videos Normal    
      videos,
      lastVideo,
      page,
      // Videos Support
      videosSupport, 
      pageSupport
    },
    {
      selectVideo,
      setBgVideo,
      getVideosOnboarding,
      // Videos Normal
      getVideos,
      getOneVideo,
      previousPage,
      nextPage,
      setPage,
      totalPages,
      // Videos Support
      getVideosSupport,
      previousPageSupport,
      nextPageSupport,
      setPageSupport,
      totalPagesSupport
    }
  ];
  return (
    <VideosContext.Provider value={state}>
      {children}
    </VideosContext.Provider>
  );
});

VideoProvider.propTypes = {
  children: PropTypes.shape(),
};

VideoProvider.defaultProps = {
  children: [],
};

const useVideos = () => {
  const context = useContext(VideosContext);
  if (context === undefined) {
    throw new Error('useVideos can only be used inside VideoProvider');
  }
  return context;
};

export {
  VideoProvider,
  useVideos
};
