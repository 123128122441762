export default {
  title: 'Ejercicio 8',
  description: `
    Crea una función llamada <code>multiple</code> que reciba como parámetro un número para evaluar si un número es divisible entre 5 y 11 o no lo es.
    En caso de ser divisible entre 5 y 11, retornar <code>true</code>
    En caso de no ser divisible entre 5 y 11, retornar <code>false</code>
    `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('multiple(55)', function (t) {
      t.equal(multiple(55), true,"Número es múltiplo de 5 y 11");
      t.end()
    });`,
    `test('multiple(3)', function (t) {
      t.equal(multiple(3), false,"Número no es múltiplo de 5 y 11");
      t.end()
    });`,
  ]
};
