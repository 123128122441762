import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useMe } from './MeContext';
import api from './api';

const CareerContext = React.createContext(undefined);

const CareerProvider = withRouter(({ children, location }) => {
  const [profile] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [job, setJob] = useState(null);
  const [isFetchingApply, setIsFeatchingApply] = useState(false);
  const [isErrorApply, setIsErrorApply] = useState(false);
  const [isApplySuccess, setIsApplySuccess] = useState(false);
  const [{ getProfile }] = useMe();

  const getCareer = async (nameJob) => {
    setIsFetching(true);
    setIsError(false);
    try {
      const response = await api.Hue.getJobByName(nameJob);
      if (response) {
        setJob(response);
        setIsError(null);
      } else if (!response) {
        setIsError('No hay vacantes por el momento.');
      } else {
        setIsError('Las vacantes han caducado.');
      }
      setIsFetching(false);
    } catch (error) {
      switch (error.response.status) {
        case 500:
          setIsError('Ocurrió un error en el servidor');
          break;
        default:
          setIsError(error.response.data.message);
          break;
      }
    } finally {
      setIsFeatchingApply(false);
    }
  };

  const apllyJobAlumni = async (dataAlumni) => {
    setIsErrorApply(false);
    setIsFeatchingApply(true);
    try {
      if (dataAlumni) {
        await api.Hue.applyJob(dataAlumni);
        setIsApplySuccess(true);
      }
      getProfile();
    } catch (error) {
      switch (error.response) {
        case 500:
          setIsErrorApply('Ocurrió un error en el servidor');
          break;
        default:
          setIsErrorApply(error.response);
          break;
      }
    } finally {
      setIsFeatchingApply(false);
    }
  };
  
  useEffect(() => {
    const [, , nameJob] = location.pathname.split('/');
    if (nameJob) {
      getCareer(nameJob);
    }
  }, []);
  const state = [
    { 
      isFetching, isError, job, profile, isFetchingApply, isErrorApply, isApplySuccess 
    },
    { getCareer, apllyJobAlumni }
  ];
  return (
    <CareerContext.Provider value={state}>
      {children}
    </CareerContext.Provider>
  );
});

CareerProvider.propTypes = {
  children: PropTypes.shape(),
  location: PropTypes.shape()
};

CareerProvider.defaultProps = {
  children: [],
  location: {}
};

const useCareer = () => {
  const context = useContext(CareerContext);
  if (context === undefined) {
    throw new Error('useCareers can only be used inside CareerProvider');
  }
  return context;
};

export {
  CareerProvider,
  useCareer
};
