import React from 'react';
import PropTypes from 'prop-types';
import dateToLocale from '../../utils/dateToLocale';

const SuspensionAlert = ({ date, onClick }) => (
  <div className="campus-container">
    <div className="bg__error pt-5 pb-5 pl-5">
      <h1 className="m-0 text__white">:’(</h1>
      <h4 className="m-1 mt-3 text__white">
        Tu cuenta ha sido suspendida
      </h4>
      <p className="m-1 mt-3 text__white">
        El pago correspondiente al
        {' '}
        {dateToLocale(date)}
        {' '}
        presenta un retraso por más de dos semanas.
      </p>
      <p className="m-1 mt-3 text__white">
        Para poder seguir disfrutando de los beneficios de tu cuenta en
        Campus y tus clases en DEV.F es indispensable realizar el pago.
      </p>
      <button className="btn__default ml-0 mt-4 p-3 mb-5" type="button" onClick={onClick}>REALIZAR EL PAGO AHORA</button>
    </div>
  </div>
);

SuspensionAlert.propTypes = {
  date: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SuspensionAlert;
