/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Common/Input';
import { Spinner } from '../../Common/Spinner';
import '../AlumniForm/alumniForm.scss';
import Mixpanel from '../../utils/Mixpanel';

const AlumniFormInvoice = ({
 isFetchingUpdateProfile, profile, activeProgram,
isUpdateProfileSuccess, updateBillingData,
}) => {
  const [billName, setBillName] = useState(profile.bill_name || '');
  const [billRFC, setBillRFC] = useState(profile.bill_rfc || '');
  const [billEmail, setBillEmail] = useState(profile.bill_email || '');
  const [billZip, setBillZip] = useState(profile.bill_zip_code || '');
  const [billCFDI, setbillCFDI] = useState(profile.bill_use_cdfi);
  const [invoice, setInvoice] = useState(!!profile.bill_rfc || false);
  const [invoiceErr, setInvoiceErr] = useState(false);

  const updateBillingSubmit = (e) => {
    e.preventDefault();
    setInvoiceErr(false);
    if (billEmail && billName && billRFC && billCFDI && billZip) {
      const body = {
        approach: {
          program: activeProgram,
          is_invoice: !!invoice,
        },
        billing: {
          bill_use_cdfi: billCFDI,
          bill_email: billEmail,
          bill_name: billName,
          bill_rfc: billRFC,
          bill_zip_code: billZip,
        }
      };
      Mixpanel.track('CampusAlumniForm-UpdateBilling');
      updateBillingData(body);
    } else {
      setInvoiceErr(true);
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="col-12 d-flex flex-column">
          <h4 className="text__white">Datos de facturación</h4>
          <form onSubmit={updateBillingSubmit}>
            <div className="m_campus-program-card">
              <span className="text__white">(Solo aplica para México)</span>
              <div className="m_form-group pt-3">
                <Input
                  data-testid="input-Alumniform-bill_name"
                  name="bill_name"
                  label="Nombre o razón social"
                  type="text"
                  placeholder=""
                  required={false}
                  value={billName}
                  onChange={(e) => setBillName(e.target.value)}
                />
              </div>
              <div className="m_form-group pt-3">
                <Input
                  data-testid="input-Alumniform-bill_rfc"
                  name="bill_rfc"
                  label="RFC"
                  type="text"
                  placeholder=""
                  required={false}
                  value={billRFC}
                  onChange={(e) => setBillRFC(e.target.value)}
                />
              </div>
              <div className="m_form-group pt-3">
                <Input
                  data-testid="input-Alumniform-bill_email"
                  name="bill_email"
                  label="Correo electrónico para facturación"
                  type="email"
                  placeholder=""
                  required={false}
                  value={billEmail}
                  onChange={(e) => setBillEmail(e.target.value)}
                />
              </div>
              <div className="m_form-group pt3">
                <div className="field">
                  <label htmlFor="bill_use_cdfi" className="label">Concepto</label>
                  <select data-testid="input-Alumniform-bill_concept" className="a_input" name="bill_use_cdfi" id="bill_use_cdfi" value={billCFDI} onChange={(e) => setbillCFDI(e.target.value)}>
                    <option value="" disabled defaultValue>Seleccionar</option>
                    <option value="G02">Devoluciones, descuentos o bonificaciones</option>
                    <option value="G03" data-testid="input-Alumniform-bill_concept_G03">Gastos en General</option>
                    <option value="D10">Pagos por servicios educativos</option>
                    <option value="P01">Por definir</option>
                  </select>
                </div>
              </div>
              <div className="m_form-group pt-3">
                <Input
                  data-testid="input-Alumniform-bill_postal_code"
                  name="bill_zip_code"
                  label="Código postal"
                  type="tel"
                  placeholder=""
                  required={false}
                  value={billZip}
                  onChange={(e) => setBillZip(e.target.value)}
                />
              </div>
              <div className="d-flex align-items-center mt-3">
                <div className="m-profile_toggle">
                  <input
                    data-testid="input-Alumniform-bill_invoice"
                    name="invoice"
                    id="invoice"
                    type="checkbox"
                    checked={invoice}
                    onChange={(e) => setInvoice(e.target.checked)}
                  />
                  <label htmlFor="invoice" className="m-pofile-slider round" />
                </div>
                <small className="text__gray4 ml-3">Deseo recibir facturas</small>
              </div>
              {invoiceErr && <small className="text__error">Debes completar todos los campos.</small>}
              <div className="pt-4 d-flex">
                {isFetchingUpdateProfile
                ? (
                  <button type="button" className="a-btn btn__brand-dark" disabled>
                    <Spinner />
                  </button>
                )
                : (
                  <button
                    data-testid="button-AlumniForm-save_billing_data"
                    style={{ marginLeft: 0 }}
                    type="submit"
                    className={isUpdateProfileSuccess ? 'a-btn btn__success' : 'btn__tool'}
                    disabled={isFetchingUpdateProfile || isUpdateProfileSuccess || !invoice}
                  >
                    {profile.bill_rfc ? 'Actualizar' : 'Guardar'}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

AlumniFormInvoice.propTypes = {
  isFetchingUpdateProfile: PropTypes.bool.isRequired,
  isUpdateProfileSuccess: PropTypes.bool.isRequired,
};

export default AlumniFormInvoice;
