/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { User } from 'react-feather';
import { Input } from '../../Common/Input';
import shield from '../../assets/svg/shield.svg';
import watch from '../../assets/svg/watch.svg';
import '../AlumniForm/alumniForm.scss';
import Mixpanel from '../../utils/Mixpanel';
import { Modal } from '../../Common/Modal';

const AlumniFormSecurity = ({
 isFetchingUpdateProfile,
  isErrorUpdateProfile, isUpdateProfileSuccess,
  updatePassword, sendEmail
}) => {
  const [viewPasswordModal, setViewPasswordModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [viewEmailModal, setViewEmailModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');

  const updatePasswordSubmit = (e) => {
    e.preventDefault();
    if (currentPassword === newPassword) {
      return setPasswordError('Tu nueva contraseña debe ser distinta a la actual');
    }

    if (newPassword !== newPasswordConfirm) {
      return setPasswordError('Tu nueva contraseña y la confirmación deben ser iguales');
    }

    const body = { new_password: newPassword, old_password: currentPassword };
    setPasswordError(isErrorUpdateProfile);
    Mixpanel.track('CampusAlumniForm-UpdatePassword');
    return updatePassword(body);
  };

  const sendEmailSubmit = (e) => {
    e.preventDefault();

    const body = {
      subject: emailSubject,
      body: emailBody,
    };

    setTimeout(() => {
      setViewEmailModal(false);
    }, 1500);
    Mixpanel.track('CampusAlumniForm-RequestHelp');
    return sendEmail(body);
  };

  const closePasswordModal = () => {
    setPasswordError('');
    setCurrentPassword('');
    setNewPassword('');
    setNewPasswordConfirm('');
    setViewPasswordModal(false);
  };

  const closeEmailModal = () => {
    setEmailBody('');
    setEmailSubject('');
    setViewEmailModal(false);
  };
  return (
    <div className="row pb-5 pb-sm-0">
      <div className="col-12">
        <div className="col-12 d-flex flex-column">
          <h4 className="text__white">Cuenta y seguridad</h4>
          <div className="m-profile__banner col-12 col-sm-5">
            <User />
            <p>Tu cuenta tiene permisos de alumno</p>
          </div>
          <h5 className="text__white mt-4">Datos de la cuenta</h5>
          <section>
            <button type="button" className=" btn__alt-default mt-4" onClick={() => setViewPasswordModal(true)}>
              <img className="action__icon" src={shield} alt="Cambiar contraseña" />
              <span>Cambiar Contraseña</span>
            </button>
            <button type="button" className=" btn__alt-default mt-4" onClick={() => setViewEmailModal(true)}>
              <img className="action__icon" src={watch} alt="Ayuda en pagos" />
              <span>Solicitar Ayuda de Pagos</span>
            </button>
          </section>
          <Modal show={viewPasswordModal} closeModal={closePasswordModal} className="m-security-modal" dismissible>
            <h5 className="text__white">Cambiar contraseña</h5>
            <form onSubmit={updatePasswordSubmit}>
              <Input
                data-testid="input-Alumniform-current_password"
                name="current_password"
                label="Escribe tu contraseña actual"
                type="password"
                value={currentPassword}
                placeholder=""
                required
                onChange={(e) => setCurrentPassword(e.target.value)}
                style={{ width: '90%' }}
              />
              <hr />
              <Input
                data-testid="input-Alumniform-new_password"
                name="new_password"
                label="Escribe tu nueva contraseña"
                type="password"
                value={newPassword}
                placeholder=""
                required
                onChange={(e) => setNewPassword(e.target.value)}
                style={{ marginBottom: '16px', width: '90%' }}
              />
              <Input
                data-testid="input-Alumniform-confirm_new_password"
                name="new_password_confirm"
                label="Confirma tu nueva contraseña"
                type="password"
                value={newPasswordConfirm}
                placeholder=""
                required
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                style={{ marginBottom: '16px', width: '90%' }}
              />
              <span data-testid="span-alumniForm-error_change_password" className="text__error">{ passwordError }</span>
              <button
                data-testid="button-Alumniform-change_password"
                type="submit"
                style={{ margin: 0 }}
                className={isUpdateProfileSuccess ? 'a-btn btn__success mt-4' : 'btn__tool mt-4'}
                disabled={isFetchingUpdateProfile || isUpdateProfileSuccess}
              >
                { isUpdateProfileSuccess
                    ? 'Contraseña actualizada correctamente'
                    : 'Actualizar contraseña' }
              </button>
            </form>
          </Modal>
          <Modal show={viewEmailModal} closeModal={closeEmailModal} dismissible>
            <h5>Solicita ayuda al equipo de DEV.F</h5>
            <form onSubmit={sendEmailSubmit}>
              <Input
                data-testid="input-alumniForm-modal-email_subject"
                name="email_subject"
                label="¿Con qué tema necesitas ayuda?"
                type="text"
                value={emailSubject}
                placeholder=""
                required
                onChange={(e) => setEmailSubject(e.target.value)}
                style={{ marginBottom: '16px', width: '90%' }}
              />
              <textarea
                data-testid="input-alumniForm-modal-help_area"
                className="a_textarea"
                name="help"
                rows="8"
                cols="74"
                type="text"
                value={emailBody}
                placeholder="¿En qué te podemos ayudar?"
                required
                onChange={(e) => setEmailBody(e.target.value)}
              />
              <span className="text__error">{ isErrorUpdateProfile || '' }</span>
              <button
                data-testid="button-alumniForm-modal-submit_emiail"
                type="submit"
                style={{ margin: 0 }}
                className={isUpdateProfileSuccess ? 'a-btn btn__success mt-4' : 'btn__tool mt-4'}
                disabled={isFetchingUpdateProfile || isUpdateProfileSuccess}
              >
                <span className="text__error">{ passwordError }</span>
                { isUpdateProfileSuccess
                    ? 'Mensaje enviado exitosamente'
                    : 'Enviar mensaje' }
              </button>
            </form>
          </Modal>
        </div>
      </div>

    </div>
  );
};

AlumniFormSecurity.propTypes = {
  isErrorUpdateProfile: PropTypes.bool.isRequired,
  isFetchingUpdateProfile: PropTypes.bool.isRequired,
  isUpdateProfileSuccess: PropTypes.bool.isRequired,
};

export default AlumniFormSecurity;
