import React, { useEffect } from 'react';
import { Spinner } from '../../../Common/Spinner';
import { useLectures } from '../../../Context/LecturesContext';
import { useSensei } from '../../../Context/SenseiContext';

export default (CreateLecture) => function CreateLectureHoc() {
  const [
    { profile },
    { createLecture },
  ] = useSensei();
  const [
    { isFetching, isError, lectures },
    { getAllLectures }
  ] = useLectures();

  useEffect(() => {
    if (profile && profile.activeRecordModule && localStorage.getItem('ROLE') === 'SENSEI_MASTER') {
      getAllLectures();
    }
  }, [profile]);

  return (
    <div className="col-lg-12 col-12 bg__gray2">
      {lectures ? (
        <CreateLecture
          sensei={localStorage.getItem('ROLE')}
          createLecture={createLecture}
          profile={profile}
          isFetching={isFetching}
          isError={isError}
          lectures={lectures}
        />
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <Spinner />
          </div>
      )}
    </div>
  );
};
