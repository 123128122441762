import React from 'react';

const columns = [
    {
      Header: 'Acciones',
      accessor: 'actions'
    },
    {
      Header: 'Nombre',
      accessor: 'fullName',
    },
    {
      Header: 'Proyecto',
      accessor: 'project.title',
    },
    {
      Header: 'Descripción',
      accessor: 'project.description',
    },
    {
      Header: 'Repo',
      Cell: ({ row }) => row.original
        .project.links.map((link) => (
          link && (
          <section key={link}>
            <a className="text__white" target="__blank" href={link}>{link}</a>
            <br />
          </section>
          )
        ))
    },
    {
      Header: 'Puntos',
      accessor: 'project.points',
    },

  ];
  
export default columns;
