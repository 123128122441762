import payload from './payload';

export default () => {
  const token = localStorage.getItem('TOKEN') !== null;
  if (!token) return false;
  const now = Math.floor(new Date().getTime() / 1000);
  const sessionExp = payload().exp;

  return sessionExp < now;
};
