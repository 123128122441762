import { devfApi } from '../../utils/http';

export const getLecturesByModule = (slug) => new Promise((resolve, reject) => devfApi.get(`api/v1/module/lectures/${slug}`)
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
}));

export const getProgramLecturesBySlug = (slug) => new Promise((resolve, reject) =>
  devfApi.get(`api/v1/program/${slug}`)
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
}));

export const getAllLecturesWithProgram = () => new Promise((resolve, reject) =>
  devfApi.get('api/v1/program/lectures')
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
}));

export const getLectureById = (idLecture) => new Promise((resolve, reject) => devfApi.get(`/api/v1/lecture/${idLecture}`)
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));

export const gradeExam = (idLecture, responses, activeProgram) => new Promise((resolve, reject) => devfApi.post(`/api/v1/campus/exam/lecture/${idLecture}`, { responses, activeProgram })
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));

export const gradeSelfExam = (idLecture, slugModule, topicSlug, responses, activeProgram) => new Promise((resolve, reject) => devfApi.post(`/api/v1/campus/self/exam/lecture/${idLecture}`, {
  responses, activeProgram, topicSlug, slugModule, date: new Date() 
  })
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));
  
export const getAllPrograms = () => new Promise((resolve, reject) => devfApi.get('/api/v1/program')
.then(({ data }) => {
  resolve(data);
})
.catch((err) => {
  reject(err);
}));

export const addLectureToProgram = (idProgram, body) => new Promise((resolve, reject) => devfApi.put(`/api/v1/program/${idProgram}/lecture`, body)
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));

export const removeLectureToProgram = (idProgram, lectureId) => new Promise((resolve, reject) => devfApi.delete(`/api/v1/program/${idProgram}/lecture/${lectureId}`)
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));  

export const getLectureByModules = (slugModule) => new Promise((resolve, reject) => devfApi.get(`/api/v1/module/lectures/${slugModule}`)
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));    

export const getLectureByModulesSelfPaced = (slugModule) => new Promise((resolve, reject) => devfApi.get(`/api/v1/module/self/lectures/${slugModule}`)
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));    
  
export const getAllLectures = () => new Promise((resolve, reject) => devfApi.get('/api/v1/lecture/title')
.then(({ data }) => {
  resolve(data);
})
.catch((err) => {
  reject(err);
}));    
