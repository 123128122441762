/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Video } from '../../../Components/Video';

const VideosOnboarding = ({
  videosOnboarding, track, activeVideo, setActiveVideo, bgVideo, setBgVideo
}) => {
  const [existVideo, setExistVideo] = useState(false);
  useEffect(() => {
    if (activeVideo >= 0) {
      setExistVideo(true);
    }
  }, []); 
  return (
    <>
      <div className="col-lg-10 bg__gray2">
        <section className="campus-container">
          {existVideo && (
          <Video
            key={videosOnboarding[activeVideo].idVideo}
            image={videosOnboarding[activeVideo].image}
            hash={videosOnboarding[activeVideo].hash}
            idVideo={videosOnboarding[activeVideo].idVideo}
            track={track}
            bgVideo={bgVideo}
            setBgVideo={setBgVideo}
          />
          )}
        </section>
      </div>
      <div className="col-lg-2 bg__gray3 videolist-container">
        <div className="d-flex flex-column">
          {videosOnboarding.map((_video, i) => (
            <a
              key={_video.idVideo}
              type="button"
              className={i === activeVideo ? 'video-link-active' : 'video-link'}
              onClick={() => setActiveVideo(i)}
            >
              {_video.title}
            </a>
            ))}
        </div>
      </div>
    </>
    );
};

VideosOnboarding.propTypes = {
  videosOnboarding: PropTypes.arrayOf(PropTypes.shape).isRequired,
  track: PropTypes.func.isRequired,
  activeVideo: PropTypes.number.isRequired,
  setActiveVideo: PropTypes.func.isRequired,
  setBgVideo: PropTypes.func.isRequired,
  bgVideo: PropTypes.bool.isRequired,
};

export default VideosOnboarding;
