import { devfApi, hueApi } from '../../utils/http';

export const login = (email, password) => new Promise((resolve, reject) => {
  devfApi.post('/api/v1/campus/login', { email, password })
    .then(({ data }) => {
      devfApi.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      hueApi.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      // devfApi.post('/api/v1/campus/tracking', { event_type: 'login' });
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const logout = (state) => new Promise((resolve, reject) => {
  devfApi.post('/api/v1/campus/tracking', {
    event_type: 'logout',
  })
    .then(() => {
      resolve({
        ...state, profile: {}, currentProgram: {}, formProject: {}, progress: {}
      });
    })
    .catch((err) => {
      reject(err);
    });
});

export const register = (email, password) => new Promise((resolve, reject) => {
  devfApi.post('/api/v1/campus/register', { email, password })
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const recover = (recoverEmail) => new Promise((resolve, reject) => {
  devfApi.post('/api/v1/campus/recover', { email: recoverEmail })
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});

export const setNewPassword = (newPassword, code) => new Promise((resolve, reject) => {
  devfApi.post('/api/v1/campus/recover/find', { password: newPassword, code })
    .then(({ data }) => {
      resolve(data);
    })
    .catch((err) => {
      reject(err);
    });
});
