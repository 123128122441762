import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Terminal, Play
} from 'react-feather';
import Home from '../../assets/svg/home.svg';
import Perfil from '../../assets/svg/perfil.svg';
import Courses from '../../assets/svg/courses.svg';
import Payments from '../../assets/svg/payments.svg';
import './sidebar.scss';

const Sidebar = ({ profile, isSuspended }) => {
  const activeProgram = profile.activeProgram.infoProgram.slug;

  const isMaster = () => profile.activeProgram.product_line === 'MASTER' || profile.activeProgram.product_line === 'PROFESSIONAL' || profile.activeProgram.product_line === 'COURSE' || localStorage.getItem('ROLE') === 'SENSEI_MASTER';

  const hasKatas = () => profile.activeProgram.path && profile.activeProgram.path === 'WEB' && profile.activeProgram.product_line !== 'B2B_PROTALENTO';

  return (
    <aside className="m-sidebar campus-container">
      {!isSuspended && (
        <>
          <NavLink className="btn__sidebar " activeClassName="btn__sidebar-active" to="/alumni/profile">
            <img src={Home} className="mr-3" alt="Home" />
            Inicio
          </NavLink>
          <NavLink className="btn__sidebar " activeClassName="btn__sidebar-active" to="/me">
            <img src={Perfil} className="mr-3" alt="Perfil" />
            Perfil
          </NavLink>
          {/* <NavLink className="btn__sidebar d-flex
           align-items-center" id="clases" activeClassName="btn__sidebar-active
           " to="/alumni/lectures/kata_intro_web/5ec2fc0b3103d431a98ae9b2?type=lecture">
            <img src={Courses} className="mr-3" alt="Courses" />
            Clases
          </NavLink> */}
          <NavLink className="btn__sidebar " id="clases" activeClassName="btn__sidebar-active" to="/alumni/course">
            <img src={Courses} className="mr-3" alt="Courses" />
            Mi Curso
          </NavLink>
        </>
      )}
      {hasKatas() && (
      <NavLink
        className="btn__sidebar d-none d-lg-block"
        activeClassName="btn__sidebar-active"
        to={`/exercises/js/${activeProgram}/sum`}
      >
        <Terminal color="#5BFF66" size={20} />
        Katas
      </NavLink>
      )}
      {isMaster()
        && (
          <NavLink className="btn__sidebar d-none d-lg-block" id="pagos" activeClassName="btn__sidebar-active" to="/master/payments">
            <img src={Payments} className="mr-3" alt="Payments" />
            Pagos
          </NavLink>
        )}     
      {/* <NavLink className="btn__sidebar d-flex
       align-items-center" activeClassName="btn__sidebar_active" to="/careers">
        <Star size={16} color="#FFAC66" />
        Careers
      </NavLink> */}
      <a
        className="btn__sidebar d-flex align-items-center"
        id="videos"
        activeClassName="btn__sidebar-active"
        target="_blank"
        rel="noreferrer"
        href="https://youtube.com/playlist?list=PLmLvP-LEy9yyh0pbrE3NVM_F126qYA-VI"
      >
        <Play color="#FF5959" size={20} />
        Master Clases
      </a>
      <hr />
      <footer className="sidebar-footer pb-5">
        <small style={{ margin: 0 }} className="d-flex justify-content-around">
          <a style={{ color: '#A2A2B4', textDecoration: 'None', fontSize: '12px' }} href="https://devf.la/remote">Cursos</a>
          {' '}
          <a style={{ color: '#A2A2B4', textDecoration: 'None', fontSize: '12px' }} href="https://devf.la/becadevf">Becas</a>
        </small>
        <small style={{ margin: 0 }} className="d-flex justify-content-around mt-2">
          {/* <a style={{ color: '#A2A2B4', textDecoration: 'None' }} href="https://devf.la/">Paquetes</a> */}
          {' '}
          <a style={{ color: '#A2A2B4', textDecoration: 'None', fontSize: '12px' }} href="https://devf.la/terminos">TyC</a>
          {' '}
        </small>
        <small className="d-flex justify-content-around mt-2">
          <a style={{ color: '#A2A2B4', textDecoration: 'None', fontSize: '12px' }} href="https://devf.la/privacidad">Aviso de privacidad</a>
        </small>
        <br />
        <small style={{ color: '#A2A2B4', textAlign: 'center' }} className="mt-2">
          DEV.F todos los derechos reservados 2020
        </small>
      </footer>
    </aside>
  );
};

Sidebar.propTypes = {
  profile: PropTypes.shape().isRequired,
  isSuspended: PropTypes.bool.isRequired
};

export default Sidebar;
