import { devfApi } from '../../utils/http';

export const updatePhotoProfile = (data) => devfApi.post('/api/v1/campus/profile/photo', data, {
  headers: {
    'content-type': 'multipart/form-data',
  }
});

export const updateCvProfile = (data) => devfApi.post('/api/v1/campus/profile/cv', data, {
  headers: {
    'content-type': 'multipart/form-data',
  }
});

export const updateProfile = (data) => devfApi.patch('/api/v1/campus/profile', data);

export const sendFinalProject = (idCampusProgram, project) => new Promise((resolve, reject) => {
  devfApi.patch(`/api/v1/campus/finalproject/${idCampusProgram}`, project)
  .then(({ data }) => {
    resolve(data);
  }).catch((err) => {
    reject(err);
  });
});

export const masterPaymentInfo = () => devfApi.get('/api/v1/campus/master/payments');
export const masterSybscriptionsInfo = () => devfApi.get('/api/v1/campus/master/subscriptions');

export const addCardCustumer = (tokenStripe) => devfApi.post('/api/v1/campus/master/source/create', {
  source: tokenStripe,
});

export const deletedCard = (tokenStripe) => devfApi.post('/api/v1/campus/master/source/delete', {
  source: tokenStripe,
});

export const setDefaultCard = (source) => devfApi.post('/api/v1/campus/master/source/change', { source });

export const payInvoice = (idInvoice) => devfApi.get(`/api/v1/campus/master/payments/pay/invoices/${idInvoice}`);

export const helpPayments = (message) => devfApi.post('/api/v1/campus/help/payments', { message });

export const updateBilling = (data) => devfApi.patch('/api/v1/campus/billing', data);

export const signatureZoom = (meetingNumber) => devfApi.post('/api/v1/zoom/signature', { meetingNumber });

export const updateAlumni = (body) => devfApi.patch('/api/v1/campus/alumni', body);

export const updatePassword = (body) => devfApi.patch('/api/v1/campus/password', body);

export const sendEmail = (body) => devfApi.post('/api/v1/campus/email', body);

export const createRatingClass = (body) => devfApi.post('/api/v1/classratings', body);

export const updateRatingClass = (body, idRatingClass) => devfApi.patch(`/api/v1/classratings/${idRatingClass}`, body);

export const createTicket = (body) => devfApi.post('/api/v1/campus/tickets', body);
