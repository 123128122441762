/* eslint-disable react/prop-types */
import React from 'react';
import { useMe } from '../../Context/MeContext';

export default (HelpModal) => function HelpModalHOC({ closeModal }) {
  const [{ 
    profile,
    isFetchingUpdateProfile,
    isUpdateProfileSuccess,
  }, {
    createTicket,
  }] = useMe();

  return (
    <HelpModal
      profile={profile}
      isFetchingUpdateProfile={isFetchingUpdateProfile}
      isUpdateProfileSuccess={isUpdateProfileSuccess}
      createTicket={createTicket}
      closeModal={closeModal}
    />
  );
};
