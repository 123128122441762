/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import session from '../../utils/session';
// import { ChallengeCard } from '../ChallengeCard';
// import { LectureCounter } from '../LectureCounter';
// import { KataCounter } from '../KataCounter';

// eslint-disable-next-line arrow-body-style
const ProfileFeedAlumni = ({ activeProgram }) => {
  // const lecturesModules = activeProgram.infoProgram.lectures.reduce((obj, lecture) => {
  //   if (typeof obj[lecture.module] === 'object') {
  //     obj[lecture.module].push(lecture);
  //   } else {
  //     // eslint-disable-next-line no-param-reassign
  //     obj[lecture.module] = [lecture];
  //   }
  //   return obj;
  // }, {});

  return (
    <section>
      <section className="o-profile-sensei__program">
        <h2 className="text__white">{activeProgram.infoProgram.name}</h2>
        <p className="text__white">{`Batch ${activeProgram.batch} - ${activeProgram.city_name} `}</p>
      </section>
      {/* {
      activeProgram.product_line === 'CINTAS' && (
        <ChallengeCard
          emoji="🤓"
          title="Asiste al 90% de las sesiones"
          progress={percentage}
          content={`Toma precisamente al menos ${activeProgram.program.includes('cinta') ? 18 : 7} clases de ${activeProgram.infoProgram.name}.`}
        />
      )} */}

      <div className="d-flex flex-wrap flex-fill justify-content-start">
        {/* {Object.keys(lecturesModules).map((key) => (
          <summary key={key}>
            <h4 className="text__white">{key}</h4>
            <LectureCounter
              key={key}
              currentLectures={activeProgram.lectures}
              lectures={lecturesModules[key]}
              lectureModule={key}
            />
          </summary>
        ))} */}
        {/* {(activeProgram.program === 'cintablanca' || activeProgram.program === 'cintaroja') && activeProgram.product_line === 'CINTAS'
        ? <KataCounter currentKatas={activeProgram.katas} /> : null} */}
      </div>
    </section>
  );
};

ProfileFeedAlumni.propTypes = {
  activeProgram: PropTypes.shape({
    batch: PropTypes.number.isRequired,
    city_name: PropTypes.string.isRequired,
    infoProgram: PropTypes.shape().isRequired,
    product_line: PropTypes.string.isRequired,
    modules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
};

export default session(ProfileFeedAlumni);
