/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import refresh from '../../assets/svg/refresh.svg';
import './personalitiesForm.scss';
import { Modal } from '../../Common/Modal';

const PersonalitiesForm = ({
  alumniPersonality,
  personality,
  setNewPersonality,
}) => {
  const [viewPersonalityModal, setViewPersonalityModal] = useState(false);

  const closePersonalityModal = () => {
    setViewPersonalityModal(false);
  };

  const handleSetPersonality = async (e) => {
    e.preventDefault();
    setNewPersonality(e.target.id);
    closePersonalityModal();
  };

  return (
    <div>
      <section
        data-testid="form-PersonalitiesForm"
        className="d-flex justify-content-between align-items-center row mx-0"
        style={{ marginTop: '12px' }}
      >
        <span data-testid="span-personality-name" className="text__white col-6 col-md-8">{personality}</span>
        <button
          data-testid="button-Personalitymodal"
          type="button"
          className="aditional__action btn__success col-6 col-md-4 mx-0"
          style={{ borderRadius: ' 8px' }}
          onClick={() => setViewPersonalityModal(true)}
          disabled={alumniPersonality && alumniPersonality.times_updated === 2}
        >
          <img className="action__icon" src={refresh} alt="Seleccionar tipo de personalidad" />
          <span>{alumniPersonality && alumniPersonality.times_updated >= 2 ? 'Límite de cambios alcanzado' : 'Cambiar tipo de personalidad'}</span>
        </button>
      </section>
      <Modal show={viewPersonalityModal} closeModal={closePersonalityModal} dismissible>
        <h5>Selecciona tu personalidad</h5>
        <p className="text__white">
          Descubre tu perfil de personalidad haciendo este
          <a className="text__hackeranda" href="https://www.16personalities.com/es/test-de-personalidad"> test</a>
          .
        </p>
        <p className="text__white">
          O consulta la descripción de cada personalidad
          <a className="text__hackeranda" href="https://www.16personalities.com/es/descripcion-de-los-tipos"> aquí</a>
          .
        </p>
        <div className="text__error__background">
          <span className="text__white">
            {alumniPersonality && alumniPersonality.times_updated === 1 ? 'Solo puedes cambiar tu personalidad 1 vez más' : ''}
          </span>
        </div>
        <h5>Analistas</h5>
        <div className="m_form-group pt3">
          <div className="field">
            <button
              type="button"
              id="Arquitecto (INTJ-A/INTJ-T)"
              className="personality__analyst"
              data-testid="button-Personalitiesform-arquitecto"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Arquitecto (INTJ-A/INTJ-T)
            </button>
            <button
              type="button"
              id="Lógico (INTP-A/INTP-T)"
              className="personality__analyst"
              data-testid="button-Personalitiesform-logico"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Lógico (INTP-A/INTP-T)
            </button>
            <button
              type="button"
              id="Comandante (ENTJ-A/ENTJ-T)"
              className="personality__analyst"
              data-testid="button-Personalitiesform-comandante"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Comandante (ENTJ-A/ENTJ-T)
            </button>
            <button
              type="button"
              id="Innovador (ENTP-A/ENTP-T)"
              className="personality__analyst"
              data-testid="button-Personalitiesform-innovador"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Innovador (ENTP-A/ENTP-T)
            </button>
          </div>
        </div>
        <hr style={{ marginTop: '16px' }} />
        <h5>Diplomáticos</h5>
        <div className="m_form-group pt3">
          <div className="field">
            <button
              type="button"
              id="Abogado (INFJ-A/INFJ-T)"
              className="personality__diplomat"
              data-testid="button-Personalitiesform-abogado"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Abogado (INFJ-A/INFJ-T)
            </button>
            <button
              type="button"
              id="Mediador (INFP-A/INFP-T)"
              className="personality__diplomat"
              data-testid="button-Personalitiesform-mediador"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Mediador (INFP-A/INFP-T)
            </button>
            <button
              type="button"
              id="Protagonista (ENFJ-A/ENFJ-T)"
              className="personality__diplomat"
              data-testid="button-Personalitiesform-protagonista"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Protagonista (ENFJ-A/ENFJ-T)
            </button>
            <button
              type="button"
              id="Activista (ENFP-A/ENFP-T)"
              className="personality__diplomat"
              data-testid="button-Personalitiesform-activista"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Activista (ENFP-A/ENFP-T)
            </button>
          </div>
        </div>
        <hr style={{ marginTop: '16px' }} />
        <h5>Centinelas</h5>
        <div className="m_form-group pt3">
          <div className="field">
            <button
              type="button"
              id="Logista (ISTJ-A/ISTJ-T)"
              className="personality__sentinel"
              data-testid="button-Personalitiesform-logista"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Logista (ISTJ-A/ISTJ-T)
            </button>
            <button
              type="button"
              id="Defensor (ISFJ-A/ISFJ-T)"
              className="personality__sentinel"
              data-testid="button-Personalitiesform-defensor"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Defensor (ISFJ-A/ISFJ-T)
            </button>
            <button
              type="button"
              id="Ejecutivo (ESTJ-A/ESTJ-T)"
              className="personality__sentinel"
              data-testid="button-Personalitiesform-ejecutivo"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Ejecutivo (ESTJ-A/ESTJ-T)
            </button>
            <button
              type="button"
              id="Cónsul (ESFJ-A/ESFJ-T)"
              className="personality__sentinel"
              data-testid="button-Personalitiesform-consul"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Cónsul (ESFJ-A/ESFJ-T)
            </button>
          </div>
        </div>
        <hr style={{ marginTop: '16px' }} />
        <h5>Exploradores</h5>
        <div className="m_form-group pt3">
          <div className="field">
            <button
              type="button"
              id="Virtuoso (ISTP-A/ISTP-T)"
              className="personality__explorator"
              data-testid="button-Personalitiesform-virtuoso"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Virtuoso (ISTP-A/ISTP-T)
            </button>
            <button
              type="button"
              id="Aventurero (ISFP-A/ISFP-T)"
              className="personality__explorator"
              data-testid="button-Personalitiesform-aventurero"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Aventurero (ISFP-A/ISFP-T)
            </button>
            <button
              type="button"
              id="Emprendedor (ESTP-A/ESTP-T)"
              className="personality__explorator"
              data-testid="button-Personalitiesform-emprendedor"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Emprendedor (ESTP-A/ESTP-T)
            </button>
            <button
              type="button"
              id="Animador (ESFP-A/ESFP-T)"
              className="personality__explorator"
              data-testid="button-Personalitiesform-animador"
              onClick={(e) => {
                handleSetPersonality(e);
              }}
            >
              Animador (ESFP-A/ESFP-T)
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

PersonalitiesForm.propTypes = {
  alumniPersonality: PropTypes.shape({
    type: PropTypes.string.isRequired,
    times_updated: PropTypes.number.isRequired,
  }).isRequired,
  personality: PropTypes.string.isRequired,
  setNewPersonality: PropTypes.func.isRequired,
};

export default PersonalitiesForm;
