/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
  label, name, type, icon, placeholder, onChange, required, value, accept, ...props
}) => (
  <div className="field">
    {label && <label htmlFor={name} className="label">{label}</label>}
    <div className="control has-icons-right">
      {type === 'file'
          ? (
            <input
              className="a_input"
              style={{ width: '100%' }}
              name={name}
              accept={accept}
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              required={required}
              {...props}
            />
          )
          : (
            <input
              className="a_input"
              name={name}
              type={type}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              required={required}
              {...props}
            />
          )}
      {
          (icon) ? (
            <span className="icon is-small is-right">
              <i className={`fa fa-${icon}`} />
            </span>
          ) : <div />
      }
    </div>
  </div>
  );

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.string,
  required: PropTypes.bool.isRequired,
  accept: PropTypes.string,
};

Input.defaultProps = {
  accept: 'image/*',
  icon: null,
  label: ''
};

export default Input;
