import * as Auth from './auth';
import * as Me from './me';
import * as Lectures from './lectures';
import * as Videos from './videos';
import * as Track from './tracking';
import * as Profile from './profile';
import * as Sensei from './sensei';
import * as Hue from './hue';
import * as Project from './projects';
import * as Github from './github';

export default {
  Auth, Me, Lectures, Videos, Track, Profile, Sensei, Hue, Project, Github
};
