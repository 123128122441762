/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import EditorJS from '@editorjs/editorjs';
import { getLectureReadingTime } from '../../../utils/dataUtils';
import { urlFinder } from '../../../utils/stringUtils';
import QuestionEdit from '../../../Components/QuestionEdit';

function EditLecture({
  data, tools, updateContent,
  createQuestion, examLecture,
  addAnswer, editAnswer,
  editQuestion, saveResponses
}) {
  const [editor, setEditor] = useState(null);

  const update = async () => {
    if (typeof editor.save === 'function') {
      const newContent = await editor.save();
      const codes = newContent.blocks
        .filter((block) => block.type === 'embed' && block.data.service === 'codepen')
        .map((embed) => embed.data.source);
      const read_more = newContent.blocks
        .filter((block) => block.type === 'paragraph')
        .map((paragraph) => urlFinder(paragraph.data.text))
        .filter((text) => text != null);
      const reading_time = getLectureReadingTime(newContent);
      // eslint-disable-next-line no-underscore-dangle
      updateContent(data._id, newContent, codes, read_more, reading_time);
      // Workaround for sensei profile !!!
    }
  };

  useEffect(() => {
    setEditor(new EditorJS({
      holder: 'codex-campus-editor',
      tools,
      data,
    }));
  }, []);

  return (
    <section style={{ backgroundColor: 'white', width: '100%' }}>
      <div id="codex-campus-editor" />
      <div className="container">
        <div className="m_card card-post">
          <form className="container">
            {examLecture && examLecture.map((question, i) => (
              <QuestionEdit
                i={i}
                // eslint-disable-next-line react/no-array-index-key
                key={(i + 1) * 22}
                question={question.question}
                answers={question.answers}
                addAnswer={addAnswer}
                editAnswer={editAnswer}
                editQuestion={editQuestion}
                saveResponses={saveResponses}
              />
            ))}
            <footer className="d-flex justify-content-around">
              <button type="button" id="save-lecture" className="a-btn btn__hackeranda" onClick={createQuestion}>
                Crear pregunta
              </button>
              <button type="button" id="save-lecture" className="a-btn btn__success" onClick={update}>
                Guardar
              </button>
            </footer>
          </form>
        </div>
      </div>
    </section>
  );
}

EditLecture.propTypes = {
  data: PropTypes.shape().isRequired,
  tools: PropTypes.shape().isRequired,
  updateContent: PropTypes.func.isRequired,
  createQuestion: PropTypes.func.isRequired,
  examLecture: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  addAnswer: PropTypes.func.isRequired,
  editAnswer: PropTypes.func.isRequired,
  editQuestion: PropTypes.func.isRequired,
  saveResponses: PropTypes.func.isRequired,
};

export default EditLecture;
