import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  Code, PenTool, List, Monitor, BookOpen
} from 'react-feather'; // BookOpen

const Sidebar = ({ profile, role, urlResources }) => {
  const findUrlResources = () => {
    if (profile.activeRecordModule && urlResources.length > 0) {
    const findUrl = urlResources.filter((urlResource) => 
    urlResource.module === `${profile.activeRecordModule.path}`);
      if (findUrl.length > 0) {
        return (
          <a data-testid="sidebar-item-recursos" className="btn__sidebar d-flex align-items-center" target="_blank" rel="noopener noreferrer" href={findUrl[0].url}>
            <List color="#5BFF66" size={20} />
            RECURSOS
          </a>
        );
      }
    } 
    return null;
  };
        return (
          <aside className="m-sidebar pt-5">
            <NavLink data-testid="sidebar-item-inicio" className="btn__sidebar d-flex align-items-center" activeClassName="btn__sidebar-active" exact to="/sensei/profile">
              <Code color="#E85D97" size={20} />
              INICIO
            </NavLink>
            <NavLink data-testid="sidebar-item-curso" className="btn__sidebar d-flex align-items-center" activeClassName="btn__sidebar-active" exact to="/sensei/course">
              <Monitor color="#59B1FF" size={20} />
              CURSO
            </NavLink>
            <NavLink data-testid="sidebar-item-onboarding" className="btn__sidebar d-flex align-items-center" activeClassName="btn__sidebar-active" exact to="/sensei/onboarding">
              <PenTool color="#52E386" size={20} />
              ONBOARDING
            </NavLink>
            {
                    role === 'SENSEI_MASTER' && (
                      <NavLink data-testid="sidebar-item-modulos" className="btn__sidebar d-flex align-items-center" activeClassName="btn__sidebar-active" exact to="/sensei/modules">
                        <BookOpen color="#52E386" size={20} />
                        MÓDULOS
                      </NavLink>
                    )
            } 
            {
                findUrlResources()
            }
            <hr />
            <footer className="pb-5">
              <small style={{ margin: 0 }} className="d-flex justify-content-around">
                <a style={{ color: '#A2A2B4', textDecoration: 'None', fontSize: '12px' }} href="https://devf.la/remote">Cursos</a>
                {' '}
                <a style={{ color: '#A2A2B4', textDecoration: 'None', fontSize: '12px' }} href="https://devf.la/becadevf">Becas</a>
              </small>
              <small style={{ margin: 0 }} className="d-flex justify-content-around mt-2">
                {/* <a style={{ color: '#A2A2B4', textDecoration: 'None' }} href="https://devf.la/">Paquetes</a> */}
                {' '}
                <a style={{ color: '#A2A2B4', textDecoration: 'None', fontSize: '12px' }} href="https://devf.la/terminos">TyC</a>
                {' '}
              </small>
              <small className="d-flex justify-content-around mt-2">
                <a style={{ color: '#A2A2B4', textDecoration: 'None', fontSize: '12px' }} href="https://devf.la/privacidad">Aviso de privacidad</a>
              </small>
              <br />
              <small style={{ color: '#A2A2B4', textAlign: 'center' }} className="mt-2">
                DEV.F todos los derechos reservados 2020
              </small>
            </footer>
          </aside>
   );
};

Sidebar.propTypes = {
  role: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    activeRecordModule: PropTypes.shape()
  }).isRequired,
  urlResources: PropTypes.instanceOf(Array).isRequired
};

export default Sidebar;
