import React from 'react';
import logo from '../../assets/svg/logo-devf-white.svg';
import ar from '../../assets/flags/ar.svg';
import co from '../../assets/flags/co.svg';
import cr from '../../assets/flags/cr.svg';
import dm from '../../assets/flags/dm.svg';
import ec from '../../assets/flags/ec.svg';
import gu from '../../assets/flags/gu.svg';
import hn from '../../assets/flags/hn.svg';
import mx from '../../assets/flags/mx.svg';
import pn from '../../assets/flags/pn.svg';
import pu from '../../assets/flags/pu.svg';
import us from '../../assets/flags/us.svg';
import fb from '../../assets/social/facebook-icon.svg';
import gh from '../../assets/social/github-icon.svg';
import ig from '../../assets/social/instagram-icon.svg';
import tw from '../../assets/social/twitter-icon.svg';
import yt from '../../assets/social/youtube-icon.svg';

const Footer = () => (
  <footer className="o-footer">
    <section className="brand-container">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5 logo">
            <img src={logo} alt="DEV.F" />
          </div>
        </div>
      </div>
    </section>
    <section className="main-container">
      <div className="container info">
        <div className="row">
          <div className="col-12 col-lg-8 mx-0 px-0">
            <div className="main-menu">
              <a href="https://devf.la/cdmx" className="option">Cursos</a>
              <a href="https://devf.la/becadevf" className="option">Becas</a>
              <a href="https://devf.la/becadevf" className="option">Paquetes</a>
              <a href="https://devf.la/terminos" className="text-gray">Términos y condiciones</a>
              <a href="https://devf.la/privacidad" className="text-gray">Aviso de privacidad</a>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <p className="copyright">Hacking LatAm since 2014</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-7 content">
            <div className="icon-menu">
              <a href="https://www.facebook.com/devfla">
                <img className="icon" src={fb} alt="Facebook" />
              </a>
              <a href="https://twitter.com/devflatam">
                <img className="icon" src={tw} alt="Twitter" />
              </a>
              <a href="https://www.instagram.com/devfla/">
                <img className="icon" src={ig} alt="Instagram" />
              </a>
              <a href="https://github.com/devfmx">
                <img className="icon" src={gh} alt="GitHub" />
              </a>
              <a href="https://www.youtube.com/channel/UCsdosmDCPT4GOxTcCltAfyA">
                <img className="icon" src={yt} alt="Youtube" />
              </a>
            </div>
            <div className="address">
              <h6 className="title">Headquarters</h6>
              <p className="subtitle">
                Av. Álvaro Obregón 168, Roma Norte,
                <br />
                C.P. 06700, Ciudad de México
                <br />
                55-3670-1339
              </p>
            </div>
          </div>
          <div className="col-12 col-lg-5 content">
            <div className="icon-flags-menu">
              <img className="icon" src={mx} alt="México" />
              <img className="icon" src={ar} alt="Argentina" />
              <img className="icon" src={co} alt="Colombia" />
              <img className="icon" src={cr} alt="Costa Rica" />
              <img className="icon" src={dm} alt="República Dominicana" />
              <img className="icon" src={ec} alt="Ecuador" />
              <img className="icon" src={gu} alt="Guatemala" />
              <img className="icon" src={hn} alt="El Salvador" />
              <img className="icon" src={pn} alt="Panamá" />
              <img className="icon" src={pu} alt="Perú" />
              <img className="icon" src={us} alt="Estados Unidos" />
            </div>
          </div>
        </div>
        <div className="row">
          <p className="resume">DEV.F ofrece una solución innovadora a la inclusión educativa con programas EdTech que ayudan a las personas a convertirse en desarrolladores de software y a obtener habilidades tecnológicas para así conseguir oportunidades laborales de alta calidad de manera ágil y accesible.</p>
        </div>
      </div>
    </section>
  </footer>
);

export default Footer;
