import React from 'react';
import { useMe } from '../../Context/MeContext';

export default (Me) => function MeHoc() {
  const [{
    profile,
    isFetchingUpdateProfile,
    isErrorUpdateProfile,
    isUpdateProfileSuccess,
  }, {
    updateProfile,
    updateAlumni,
    updateBillingData,
    updatePassword,
    sendEmail,
  }] = useMe();

  const renderContent = () => (
    profile && profile.activeProgram
      ? (
        <Me
          profile={profile}
          updateProfile={updateProfile}
          updateAlumni={updateAlumni}
          updateBillingData={updateBillingData}
          updatePassword={updatePassword}
          sendEmail={sendEmail}
          isFetchingUpdateProfile={isFetchingUpdateProfile}
          isErrorUpdateProfile={isErrorUpdateProfile}
          isUpdateProfileSuccess={isUpdateProfileSuccess}
        />
      ) : null
  );

  return (
    renderContent()
  );
};
