/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Video } from 'react-feather';
import { NavLink, Link, useLocation } from 'react-router-dom';
import Mixpanel from '../../utils/Mixpanel';
import { Spinner } from '../Spinner';
import logo from '../../assets/svg/logo-devf-white.svg';
import questionMark from '../../assets/svg/question-mark.svg';
import bell from '../../assets/svg/bell.svg';
import detectImage from '../../utils/detectImage';
import arrow from '../../assets/svg/arrow.svg';
import chat from '../../assets/svg/chat.svg';
import creditCard from '../../assets/svg/credit-card.svg';
import './navbar.scss';
import { Modal } from '../Modal';
import dateToLocale from '../../utils/dateToLocale';
import datesAreOnSameDay from '../../utils/datesAreOnSameDay';
import TypeformNotificationModal from '../../Components/TypeformNotificationModal';
import RateClass from '../../Components/RateClass';
import { START_HOURS_PRODUCT_LINE } from '../../utils/const';

const Navbar = ({
 profile, setActiveProgram, logout, openHelpModal, track, registerClass,
 senseiModule, needRatingClass, onRatingClass
}) => {
  const newWindow = useRef(window);

  const openLink = (url) => {
    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  };
  
  const {
    alumni,
    activePrograms,
    activeProgram,
  } = profile;

  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [modalTypeformNotification, setTypeformModalNotification] = useState(false);
  const [modalDay0, setModalDay0] = useState(false);
  const [bodyTypeformNotification, setBodyTypeformNotification] = useState({});
  const [params, setParams] = useState({});
  const [modalRateClass, setModalRateClass] = useState(needRatingClass?.assisted);

  const { pathname } = useLocation();
  const currentPath = pathname.split('/').pop();

  const signout = () => {
    setNavOpen(false);
    logout();
  };

  useEffect(() => {
    if (
      activeProgram
      && datesAreOnSameDay(new Date(), new Date(activeProgram.start_date.replace('Z', '')))
      && activeProgram.linkDay0
      && activeProgram.product_line !== 'B2B_PROTALENTO' // TODO: buscar la manera de definir que programas NO tienen día 0 (Normalmente son los especiales)
      ) {
      setModalDay0(true);
    }
  }, []);

  useEffect(() => {
    if (activeProgram && activeProgram.typeformNotifications.length > 0) {
      // Solo funciona para notificaciones de typeform obligatorias
      const mandatoryNotification = activeProgram.typeformNotifications
        .find((not) => not.is_mandatory);
      setBodyTypeformNotification(mandatoryNotification);
      const paramsMapped = Object.keys(mandatoryNotification.hiddens).reduce((obj, hiddenKey) => {
        if (hiddenKey === 'notification') {
          return obj;
        } 
          const newObj = {
            ...obj,
            [hiddenKey]: get(profile, mandatoryNotification.hiddens[hiddenKey])
          };
          return newObj;
      }, {});

      setParams(paramsMapped);

      if (mandatoryNotification) {
        setTypeformModalNotification(true);
      }
    }
  }, []);

  const renderButtonsClass = () => {
    if (activeProgram.batch === 14) {
      return (
        <Link
          to="/alumni/zoom"
          target="_self"
          onClick={() => {
                  track('assistance', { program: profile.activeProgram.infoProgram.slug });
                }}
          className="navbar__button-zoom"
        > 
          {' '}
          <Video size={18} />
          {' '}
          IR A TU CLASE
        </Link>
      );
    }
    if (activeProgram.group_detail) {
     if (activeProgram.group_detail.meeting_url) {
      return (
        <Link
          to={{ pathname: activeProgram.group_detail.meeting_url }}
          target="_blank"
          onClick={() => {
              track('assistance', { program: profile.activeProgram.infoProgram.slug });
            }}
          className="navbar__button-zoom"
        > 
          {' '}
          <Video size={18} />
          {' '}
          IR A TU CLASE
        </Link>
      );
     } 
    
     if (activeProgram.group_detail.group_name && senseiModule.meet_link) {
      return (
        <button
          type="button"
          style={{ border: 'none', cursor: 'pointer' }}
          className="btn navbar__button-zoom"
          onClick={() => registerClass(senseiModule, (err, link) => {
            if (!err) newWindow.current.open(link);
            // eslint-disable-next-line no-alert
            else alert('Parece que no tienes acceso a tu clase');
          })}
        > 
          {' '}
          <Video size={18} />
          {' '}
          IR A TU CLASE
        </button>
      );
     }    
    }
    return null;
  };

  return (
    <>
      <Modal
        key="rate-class"
        dismissible
        show={modalRateClass}
        closeModal={() => setModalRateClass(false)}
      >
        <section>
          <h2>Ayúdanos a mejorar</h2>
          <RateClass
            needRatingClass={needRatingClass}
            onRatingClass={(stars, selectedTags, comments) => {
              onRatingClass(stars, selectedTags, comments, activeProgram);
              setModalRateClass(false);
            }}
          />
        </section>
      </Modal>
      <Modal
        dismissible
        show={modalTypeformNotification}
        closeModal={() => setTypeformModalNotification(false)}
      >
        <TypeformNotificationModal
          setModalNotification={setTypeformModalNotification}
          notification={bodyTypeformNotification}
          params={params}
        />
      </Modal>
      <Modal
        dismissible
        show={modalDay0}
        closeModal={() => setModalDay0(false)}
      >

        <h3>Mi primer día de clase (Día 0)</h3>
        <h4>{dateToLocale(activeProgram.start_date.replace('Z', ''))}</h4>
        <p>{START_HOURS_PRODUCT_LINE[activeProgram?.product_line] || 'De 18:30 a 19:30 pm hora Ciudad de México'}</p>
        <button type="button" onClick={() => openLink(activeProgram.linkDay0)} className="btn__tool">Ir</button>
      </Modal>
      <nav
        style={{ display: currentPath === 'zoom' ? 'none' : 'block' }}
        role="navigation"
        aria-label="main navigation"
        className="new-navbar"
      >
        <div className="d-flex align-items-center" style={{ height: `${64}px` }}>
          <div className="d-flex pr-md-5 pr-1" style={{ height: `${100}%` }}>
            <NavLink
              to="/alumni/profile"
              className="navbar__logo__container"
              onClick={() => { Mixpanel.track('Profile-OpenMainProfile'); }}
            >
              <img src={logo} alt="DEV.F" className="navbar__logo" />
            </NavLink>
            {/* <button type="button" className="navbar__search__container">
            <span className="search__text"> Buscar en Campus </span>
            <img className="search__icon" src={search} alt="Buscar" />
          </button> */}
          </div>
          <div className="navbar__sections__container d-none d-lg-flex">
            <NavLink
              to="/alumni/course"
              className={currentPath === 'profile' ? 'sections__btn-active' : 'sections__btn'}
              onClick={() => Mixpanel.track('Profile-OpenMainProfile')}
            >
              COURSES
            </NavLink>
            <a
              href="https://community.devf.la"
              className="sections__btn"
              target="_blank"
              rel="noreferrer"
            >
              COMUNIDAD
            </a>
            <a
              href="https://jobs.devf.la/"
              className="sections__btn"
              target="_blank"
              rel="noreferrer"
            >
              CAREERS
            </a>
            {/* <NavLink
              to="/careers"
              className={currentPath === 'careers' ? 'sections__btn-active' : 'sections__btn'}
            >
              CAREERS
            </NavLink> */}
            { activeProgram?.product_line !== 'COURSE' && (
            <a
              href="https://youtube.com/playlist?list=PLmLvP-LEy9yzuhrITFW7Lo0qHh3dPU7NM"
              className="sections__btn"
              target="_blank"
              rel="noreferrer"
            >
              POWER SKILLS
            </a>
)}
          </div>
          <div className="navbar__actions pr-2 pr-sm-4" style={{ marginLeft: 'auto' }}>
            <a
              rel="noreferrer"
              className="navbar__button-zoom"
              href="https://discord.gg/WAeBXvYzM7"
              target="_blank"
              style={{
                margin: 0, marginRight: '5px', backgroundColor: '#5865F2', color: 'white' 
              }}
            >
              Discord
            </a>
            {renderButtonsClass()}
            <button
              type="button"
              onClick={() => {
              setNotificationsOpen(!notificationsOpen);
              setHelpOpen(false);
              setNavOpen(false);
            }}
              className={
              notificationsOpen
                ? 'actions__circle-active'
                : 'actions__circle'
            }
            >
              <img src={bell} alt="Notificaciones" />
            </button>
            <button
              type="button"
              onClick={() => {
              setNotificationsOpen(false);
              setHelpOpen(!helpOpen);
              setNavOpen(false);
            }}
              className={
              helpOpen
                ? 'actions__circle-active'
                : 'actions__circle'
            }
            >
              <img src={questionMark} alt="Ayuda" />
            </button>
            <button
              data-testid="navbar-button-open_programs"
              type="button"
              onClick={() => {
              setNotificationsOpen(false);
              setHelpOpen(false);
              setNavOpen(!navOpen);
            }}
              className={
              (navOpen || currentPath === 'me')
                ? 'actions__profile-active'
                : 'actions__profile'
            }
            >
              {alumni.profile_pic
              ? (
                <img
                  className="profile__img"
                  src={detectImage(alumni.profile_pic) ? alumni.profile_pic : `data:image/png;base64, ${alumni.profile_pic}`}
                  alt="Perfil"
                />
              )
              : <Spinner />}
              {alumni.first_name
              ? <span className="profile__text d-none d-sm-flex">{alumni.first_name.toUpperCase()}</span>
              : <Spinner />}
            </button>
            {notificationsOpen
            && (
              <div className="d-flex flex-column o_nav-notifications">
                <span className="help-title">Notificaciones</span>
                <span className="text__white">No tienes notificaciones</span>
              </div>
            )}
            {helpOpen
            && (
              <div className="d-flex flex-column o_nav-help">
                <span className="help-title">FAQ</span>
                <NavLink
                  to="/master/payments"
                  className="help-btn-active"
                  onClick={() => setHelpOpen(!helpOpen)}
                >
                  <div className="d-flex align-items-center">
                    <div className="icon-container">
                      <img src={creditCard} alt="Pagos" />
                    </div>
                    <span className="pl-2">¿Dónde puedo ver mis pagos?</span>
                  </div>
                  <img src={arrow} alt="Flecha" />
                </NavLink>
                <div className="help-division" />
                <button
                  type="button"
                  className="help-btn-active"
                  onClick={() => {
                    setHelpOpen(!helpOpen);
                    openHelpModal();
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div className="icon-container">
                      <img src={chat} alt="Asesor" />
                    </div>
                    <span className="pl-2">Contacta a un asesor</span>
                  </div>
                  <img src={arrow} alt="Flecha" />
                </button>
                {/* <NavLink
                  to="/me"
                  className="help-btn"
                  onClick={() => setHelpOpen(!helpOpen)}
                >
                  <div className="d-flex align-items-center">
                    <div className="icon-container">
                      <img src={calendar} alt="Calendario" />
                    </div>
                    <span className="pl-2">¿Dónde está el calendario?</span>
                  </div>
                  <img src={arrow} alt="Flecha" />
                </NavLink> */}
              </div>
            )}
            {navOpen
            && (
              <div className="d-flex flex-column bg__white o_nav-programs">
                {/* <NavLink
                  type="button"
                  className="btn__alt-default"
                  to="/me/2032"
                  onClick={() => setNavOpen(!navOpen)}
                >
                  Mi perfil profesional
                </NavLink> */}
                <NavLink
                  type="button"
                  className="btn__alt-default"
                  to="/alumni/profile"
                  onClick={() => {
                    Mixpanel.track('Profile-OpenMainProfile');
                    setNavOpen(!navOpen);
                  }}
                >
                  Mi perfil
                </NavLink>
                {activePrograms.map((program) => (
                  <button
                    type="button"
                    key={program.infoProgram.slug}
                    className={profile.activeProgram.infoProgram.slug === program.infoProgram.slug
                      ? 'btn__default'
                      : 'btn__alt-default'}
                    onClick={() => {
                      setActiveProgram(program.infoProgram.slug, null, false);
                      setNavOpen(!navOpen);
                    }}
                  >
                    {program.infoProgram.name}
                  </button>
                ))}
                <NavLink type="button" className="btn__danger" to="/" onClick={() => signout()}>Cerrar sesión</NavLink>
              </div>
            )}
          </div>
          {/* <div className="d-flex d-sm-none mr-3">
            <List className="text__white" />
          </div> */}
        </div>
      </nav>
    </>
  );
};

Navbar.defaultProps = {
  senseiModule: {},
  needRatingClass: {},
};

Navbar.propTypes = {
  profile: PropTypes.shape().isRequired,
  onRatingClass: PropTypes.func.isRequired,
  setActiveProgram: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  openHelpModal: PropTypes.func.isRequired,
  track: PropTypes.func.isRequired,
  registerClass: PropTypes.func.isRequired,
  needRatingClass: PropTypes.shape(),
  senseiModule: PropTypes.shape({
    meet_link: PropTypes.string,
    sensei: PropTypes.string,
  }), 
};

export default Navbar;
