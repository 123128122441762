export default {
  title: 'Ejercicio 42',
  description: `
  Hacer superclase <code>Maestro</code> y subclases <code>MaestroDeFísica</code> y 
  Maestro de Música y a cada uno asignarle su materia y 
  calcular su promedio de grupo a partir de calificaciones 
  (usar arreglos). El maestro de física tiene un 
  atributo "antiguedad" que guarda un valor numerico, 
  mientras que el maestro de música tiene un atributo "edad"
  también guardando un valor numérico.
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba 1', function (t) {
      const prueba =  new MaestroDeFisica('fisica', [7,8,8,10,5,6,9,9,10], 8);
      t.equal(prueba.calcularPromedio(),8,"Promedio correcto");
      t.end()
    });`,
    `test('prueba 2', function (t) {
      const prueba = new MaestroDeMusica('musica', [10,10,10,10,10,10,10,10,10], 2);
      t.equal(prueba.calcularPromedio(),10,"IMC correcto");
      t.end()
    });`,
  ]
};
