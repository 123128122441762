/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSenseiAdminModules } from '../../../Context/SenseiAdminModuleContext';
import { Spinner } from '../../../Common/Spinner';
import { Modal } from '../../../Common/Modal';
import Table from './TableLectures';
import './button.scss';

export default (SenseiAdminModules) => function SenseiAdminModulesHoc() {
    const [{
        isFetching, 
        isError,
        isFetchingAddLectures,
        isFetchingAllLectures,
        lectures,
        senseiAdminModules,
    }, { 
      AllSenseiAdminModules, 
      updateLectureToModule,
      allLectures 
    }] = useSenseiAdminModules();

    const [showModalIdModule, setShowModalIdModule] = useState({ status: false, idModule: '', title: '' });
    const [dataCopyLectures, setDataCopyLectures] = useState([]);

    useEffect(() => {
      if (showModalIdModule.status === true) {
        allLectures(showModalIdModule.idModule);
      } else {
        AllSenseiAdminModules();
      }
    }, [showModalIdModule]);
    
    const dataLectures = React.useMemo(() => lectures, [lectures]);
    const dataModules = React.useMemo(() => senseiAdminModules, [senseiAdminModules]);

    useEffect(() => {
      setDataCopyLectures(dataLectures);
    }, [lectures]);

    const renderContent = () => {
        if (isFetching) {
            return (
              <div className="d-flex justify-content-center align-items-center bg__gray2" style={{ height: '93vh', width: '83.3vw' }}>
                <Spinner />
              </div>
            );
          }
        if (isError) {
        return (
          <div className="d-flex justify-content-center mt-5">
            <h3>{isError}</h3>
          </div>
        );
        }
        if (senseiAdminModules && senseiAdminModules.length > 0) {
        return (
          <div
            id="button-module-admin-sensei"
            className="d-flex justify-content-center"
            style={{
            alignItems: 'flex-start', width: '100%', backgroundColor: 'var(--base-gray-3)', borderRadius: '5px' 
            }}
          >
            <>
              <Modal
                show={showModalIdModule.status}
                closeModal={() => setShowModalIdModule({ status: false, idModule: '', title: '' })}
                dismissible
              >
                { isFetchingAllLectures 
                ? (
                  <>
                    <p>{`Módulo: ${showModalIdModule.title}`}</p>
                    <Table 
                      data={dataCopyLectures}
                      setData={setDataCopyLectures}
                    />
                    <button className="button" disabled={isFetchingAddLectures} type="button" onClick={() => { updateLectureToModule(showModalIdModule.idModule, dataCopyLectures); }}>{ isFetchingAddLectures ? (<Spinner />) : ('Agregar') }</button>
                  </>
                ) : (<Spinner />)}
              </Modal>
              <div style={{ width: '90%', textAlign: 'end' }}>
                <div style={{ width: '90%', marginTop: '40px' }}>
                  <Link to="/sensei/create/lecture">
                    <button className="button--green" type="button">CREAR LECTURA</button>   
                  </Link>

                </div>
                <SenseiAdminModules
                  setModal={setShowModalIdModule}
                  data={dataModules}
                />
              </div>
            </>
          </div>

        );
        }
          return (
            <div className="d-flex justify-content-center align-items-center bg__gray2" style={{ height: '93vh', width: '83.3vw' }}>
              <Spinner />
            </div>
          );
    };
    return (<>{renderContent()}</>);
};
