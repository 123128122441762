/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Common/Input';
import './addLectureModal.scss';

const AddLectureModal = ({ createLecture }) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    title: '',
  });

  const handleChange = (event) =>
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

  const newLecture = (e) => {
    e.preventDefault();
    setLoading(true);
    const lecture = {
      time: Date.now(),
      version: '2.17.0',
      blocks: [
        {
          type: 'header',
          data: {
            text: formValues.title,
            level: 1
          }
        }
      ],
    };
    return createLecture(lecture);
  };

  return (
    <form className="m_campus-program-card bg__gray6 mt-5" onSubmit={newLecture}>
      <header className="modal-card-head">
        <p className="text__gray1"><b>Agregar nueva lectura</b></p>
      </header>
      <div className="row">
        <div className="m_form-group col-12">
          <small className="a_form-legend text__brand-light">Título</small>
          <Input
            name="title"
            label=""
            required
            type="text"
            icon="heading"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="d-flex justify-content-end">
        {loading
          ? <progress className="progress is-success" max="100">30%</progress>
          : (
            <button
              type="submit"
              className="btn__success mt-4"
            >
              Crear Lectura
            </button>
          )}
      </div>
    </form>
  );
};

AddLectureModal.propTypes = {
  createLecture: PropTypes.func.isRequired,
};

export default AddLectureModal;
