/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useMe } from '../../Context/MeContext';
import AnonymousNavbar from '../AnonymousNavbar/AnonymousNavbar';

export default (Navbar) => function NavbarHoc({ openHelpModal }) {
  const [{ profile, moduleSensei }, {
   setActiveProgram, logout, track, registerClass, onRatingClass,
  }] = useMe();
  
  const ratingClass = (stars, selectedTags, comments, activeProgram) => {
    onRatingClass(stars, selectedTags, comments,
      activeProgram._id, activeProgram.currentModule.slug,
      activeProgram?.assistenceToRate?.date);
  };

  return (
    <>
      {
        (profile !== null && profile.activeProgram)
        ? (
          <Navbar
            openHelpModal={openHelpModal}
            track={track}
            profile={profile}
            setActiveProgram={setActiveProgram}
            logout={logout}
            registerClass={registerClass}
            needRatingClass={profile.activeProgram?.assistenceToRate}
            senseiModule={moduleSensei}
            onRatingClass={ratingClass}
          />
        )
        : <AnonymousNavbar />
      }
    </>
  );
};
