export const programs = {
  cintablanca: {
    name: 'Cinta Blanca', slug: 'cintablanca', color: '#7A7689', fontColor: '#000000', acknowledgment: 'Con una duración de 60 horas prácticas en tecnologías web como HTML, CSS y JavaScript.'
  },
  cintaroja: {
    name: 'Cinta Roja', slug: 'cintaroja', color: '#D73232', fontColor: '#FFFFFF', acknowledgment: 'Con una duración de 60 horas prácticas en JavaScript y fundamentos de ciencias de la computación.'
  },
  cintanegra: {
    name: 'Cinta Negra', slug: 'cintanegra', color: '#333333', fontColor: '#FFFFFF', acknowledgment: 'Con una duración de 60 horas prácticas en diseño de arquitecturas para crear aplicaciones web.'
  },
  datascience: {
    name: 'Intro a Data Science', slug: 'datascience', color: '#2F80ED', fontColor: '#FFFFFF', acknowledgment: 'Con duración de 24 horas prácticas de introducción a la exploración, análisis y visualización de datos utilizando R como lenguaje de programación.'
  },
  uxninja: {
    name: 'UX Ninja', slug: 'uxninja', color: '#5DCFE8', fontColor: '#FFFFFF', acknowledgment: 'Con una duración de 24 horas prácticas de investigación, arquitectura de información, creatividad, prototipado, diseño y validación de productos digitales con enfoque centrado en el usuario.'
  },
  uininja: {
    name: 'UI Ninja', slug: 'uininja', color: '#D5C0FB', fontColor: '#FFFFFF', acknowledgment: 'Con una duración de 24 horas prácticas de investigación, arquitectura de información, creatividad, prototipado, diseño y validación de productos digitales con enfoque centrado en el usuario.'
  },
  code: {
    name: 'Master en Coding', slug: 'code', color: '#1D1033', fontColor: '#FFFFFF', acknowledgment: 'Por definir'
  },
  frontend: {
    name: 'Professional en Coding', slug: 'frontend', color: 'white', fontColor: '#FFFFFF', acknowledgment: 'Por definir'
  },
  ai: {
    name: 'Inteligencia Artificial para tod*s', slug: 'ai', color: '#52E386', fontColor: '#FFFFFF', acknowledgment: 'Con una duración de 24 horas prácticas de aprendizaje sobre fundamentos de Aprendizaje Máquina (Machine Learning) utilizando las herramientas proporcionadas en Scikit-Learn.'
  },
  marketing: {
    name: 'Marketing Digital', slug: 'marketing', color: '#FFE059', fontColor: '#000000', acknowledgment: 'Con una duración de 24 horas donde se obtuvo los conocimientos, principios y técnicas para ejecutar, desarrollar y medir campañas en Google y Facebook.'
  },
  ecommerce: {
    name: 'eCommerce', slug: 'ecommerce', color: '#59B1FF', fontColor: '#FFFFFF', acknowledgment: 'Con una duración de 24 horas donde se obtuvo los conocimientos, principios y técninas para configurar y lanzar su propia tienda en línea con Shopify.'
  },
  course_code: {
    name: 'Course Web'
  },
  course_data: { name: 'Course Data Science / AI' },
  prof_data: { name: 'Professional Data Science / AI' },
  master_data: { name: 'Master Data Science / AI' },
  course_uxui: { name: 'Course UX/UI' },
  prof_uxui: { name: 'Professional UX/UI' },
  master_uxui: { name: 'Master UX/UI' },
};

export default programs;
