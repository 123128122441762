import React from 'react';
import PropTypes from 'prop-types';
import dateToLocale from '../../utils/dateToLocale';

const FirstAlert = ({ date, suspensionDate, onClick }) => (
  <div className="bg__warning pt-3 pb-3">
    <div className="container">
      <h3 className="m-0">[ ! ]</h3>
      <h5 className="m-1 mt-3">
        Tu cuenta será suspendida el
        {' '}
        {dateToLocale(suspensionDate)}
      </h5>
      <p className="m-1 mt-3">
        El cobro del día
        {' '}
        {dateToLocale(date * 1000)}
        {' '}
        fue declinado. Modifica tu información de pago antes de la fecha indicada
        para evitar interrupciones en tu suscripción.
      </p>
      <button className="btn__default ml-0 mt-4 p-3" type="button" onClick={onClick}>CAMBIAR MÉTODO DE PAGO</button>
    </div>
  </div>
);

FirstAlert.propTypes = {
  date: PropTypes.number.isRequired,
  suspensionDate: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
};

export default FirstAlert;
