const dateToLocale = (date, options = {
  year: 'numeric', month: 'long', day: 'numeric'
}, region) => {
  let dateFix = date;
  if (typeof date === 'string') {
    dateFix = date.replace('Z', '');
  }
  // const event = new Date(date.replace(/-/g, '/').replace(/T.+/, ''));
  const event = new Date(dateFix);
  return event.toLocaleDateString(region || 'es-MX', options);
};

export default dateToLocale;
