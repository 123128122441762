/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ProjectRateForm({ submitProject, alumniProject }) {
  const [links, setLinks] = useState([]);
  const [description, setDescription] = useState('');
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!alumniProject) {
      setLinks(['', '']);
      setDescription('');
      setDisabled(false);
    } else {
      if (alumniProject._id) setDisabled(true);
      setLinks(alumniProject ? alumniProject.links : ['', '']);
      setDescription(alumniProject ? alumniProject.description : '');
    }
  }, [alumniProject]);

  const onSubmitProject = (e) => {
      e.preventDefault();
      submitProject(links, description);
      setDisabled(true);
  };

  const addLink = () => setLinks([...links, '']);
  const removeLink = (index) => {
    if (links.length > 1) {
      setLinks((currentLinks) => currentLinks
        .filter((_, i) => i !== index));
    } else {
      alert('Tienes que enviar al menos un link');
    }
  };

  return (
    <form className="mt-5" onSubmit={onSubmitProject}>
      <div className="title-links">
        <h6 className="text__white">Links o Repositorios de tu proyecto </h6>
        {!disabled
        && (
        <button type="button" className="btn-add" onClick={addLink}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        )}
      </div>
      <div className="row">
        <p className="text-project"> En esta sección añade los recursos que se te pidieron entregar, repositorios, links de tu proyecto desplegado, tu libreta de python, el link de tus archivos en alguna plataforma.</p>
        <p className="text-project"><b>Una vez enviados no podrás modificar los links.</b></p>
      </div>
      <div className="row" data-testid="projectrate-form-input-link">
        {links && links.map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${i}link`} className="col-8 title-links">
            <input
              className="a_input_rate"
              type="url"
              required
              data-testid={`projectrate-form-input-link-${i}`}
              placeholder="Link de tu repositorio"
              onChange={(e) => {
                const copyLink = [...links];
                copyLink[i] = e.target.value;
                setLinks(copyLink);
              }}
              value={links[i]}
              disabled={disabled}
            />
            {!disabled 
            && (
            <button type="button" className="btn-minus" onClick={() => removeLink(i)}>
              <FontAwesomeIcon icon={faMinus} />
            </button>
            )}
          </div>
      ))}
      </div>
      <div className="mt-3">
        <h6 htmlFor="project-describe" className="text__white">
          Describe tu Proyecto
        </h6>
        <p className="text-project">
          {' '}
          (Si es necesario explícale a tu senseí alguna
          situación extra que tengas que comunicarle con respecto al funcionamiento)
        </p>
        <textarea 
          data-testid="projectrate-form-textarea-comments"
          id="project-describe" 
          required
          className="textarea_rate col-12"
          rows="3"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          disabled={disabled}
        />
        <p className="text-project"><b>Una vez enviados no podrás modificar la descripción.</b></p>
      </div>
      {disabled ? (
        <h4 className="text-project">
          Gracias por enviar tu proyecto, ve arriba para más información.
        </h4>
        ) : (
          <button
            data-testid="projectrate-btn-save"
            type="submit"
            className="btn__tool ml-0 mt-4"
          >
            Enviar mi proyecto
          </button>
        )}
    </form>
  );
}

ProjectRateForm.defaultProps = {
  alumniProject: {
    links: [''],
    points: 0,
    description: '',
  }
};

ProjectRateForm.propTypes = {
  submitProject: PropTypes.func.isRequired,
  alumniProject: PropTypes.shape({
    _id: PropTypes.string,
    points: PropTypes.number,
    links: PropTypes.arrayOf(PropTypes.string).isRequired,
    description: PropTypes.string,
  }),
};

export default ProjectRateForm;
