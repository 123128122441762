import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
// import { hotjar } from 'react-hotjar';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import { CookiesProvider } from 'react-cookie';
import Routes from './routes/Routes';

const App = () => {
/*   const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Campaña para afiliados de GrowSurf 
    if (localStorage.getItem('ROLE') === 'ALUMNI' && loading) {
      const script = document.createElement('script');
      script.src = 'https://app.growsurf.com/growsurf.js?v=2.0.0';
      script.setAttribute('grsf-campaign', '87ukmb');
      script.async = true;
      document.head.appendChild(script);
      setLoading(false);
    }
  }, [loading]); */

  useEffect(() => {
    // eslint-disable-next-line 
    if (process.env.REACT_APP_DEBUG == 'false') {
      Sentry.init({ dsn: 'https://6785dda401da479cb45d3e5b7a79e642@sentry.io/2016984', release: 'campus@2.0.0' });
      // LogRocket.init('nwsvrl/campus');
      // setupLogRocketReact(LogRocket);
      // LogRocket.getSessionURL((sessionURL) => {
      //   Sentry.configureScope((scope) => {
      //     scope.setExtra('sessionURL', sessionURL);
      //   });
      // });

      // hotjar.initialize(2261348, 6);
    }

    ReactGA.initialize(process.env.REACT_APP_GA);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="app">
      <CookiesProvider>
        <Router>
          <Routes />
        </Router>
      </CookiesProvider>
    </div>
  );
};

export default App;
