import React, {
    useContext,
    useState,
  } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import projectsJSON from '../views/Projects/projects.json';
import api from './api';

const ProjectContext = React.createContext(undefined);

const ProjectProvider = withRouter(({ children, match }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [rate, setRate] = useState(null);
  const [projects, setProjects] = useState(null);
  const [activeProject, setactiveProject] = useState({});

  const rateProject = async (profile) => {
    setIsFetching(true);
    try {
      const { module } = projectsJSON.find((project) => project.slug === match.params.nameProject);
      const result = await api.Project.saveRateProject({ 
        // eslint-disable-next-line no-underscore-dangle
        results: match.params.idProject, module, activeProgram: profile.activeProgram._id
      });
      setRate(result);
      setIsError(false);
    } catch (error) {
      if (error.response.status === 400) {
        setIsError(error.response.data.message);
      } else {
        setIsError('Ocurrió un error al procesar tus resultados');
      }
    } finally {
      setIsFetching(false);
    }
  };

  const getProjects = (module = null) => {
    setIsFetching(true);
    setTimeout(() => {
        const projectsByModule = module ? projectsJSON.filter((project) => 
        project.module === module) : projectsJSON;
        setProjects(projectsByModule);
        setactiveProject(projectsJSON[0]);
        setIsError(false);
        setIsFetching(false);
    }, 1000);
  };

  const setActiveProject = (project) => {
    setactiveProject(project);
  };

  const state = [
    {
      isFetching, isError, rate, projects, activeProject
    }, 
    { rateProject, getProjects, setActiveProject }
  ];
  return (
    <ProjectContext.Provider value={state}>
      {children}
    </ProjectContext.Provider>
  );
});

ProjectProvider.propTypes = {
  children: PropTypes.shape(),
};

ProjectProvider.defaultProps = {
  children: [],
};

const useProjects = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error('useProjects can only be used inside ProjectContext');
  }
  return context;
};

export {
  ProjectProvider,
  useProjects
};
