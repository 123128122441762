/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './rateclass.scss';
import dateToLocale from '../../utils/dateToLocale';
import rateTags from './rateTags.json';

function RateClass({ needRatingClass, onRatingClass }) {
  const [stars, setStars] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [comments, setComments] = useState('');
  const [showComments, setShowComments] = useState(false);

  const handleRatingClass = (number) => {
    setStars(number);
    setSelectedTags([]);
    if (number <= 3 && number > 0) {
      setShowComments(true);
    }
  };

  const onRatingClassBtn = () => {
    onRatingClass(stars, selectedTags, comments);
  };

  return (
    <div className="my-course__rating-class">
      <>
        <h4 className="text__white">Califica la experiencia de tu clase anterior</h4>
        <p className="text__white">
          Cuéntanos como te fue en tu clase del
          {' '}
          <strong>
            {dateToLocale(needRatingClass.date)}
          </strong>
          , tus comentarios nos ayudan a mejorar.
        </p>
        <div className="rating">
          <input type="radio" name="rating" id="rating-5" onClick={() => handleRatingClass(5)} />
          <label htmlFor="rating-5" />
          <input type="radio" name="rating" id="rating-4" onClick={() => handleRatingClass(4)} />
          <label htmlFor="rating-4" />
          <input type="radio" name="rating" id="rating-3" onClick={() => handleRatingClass(3)} />
          <label htmlFor="rating-3" />
          <input type="radio" name="rating" id="rating-2" onClick={() => handleRatingClass(2)} />
          <label htmlFor="rating-2" />
          <input type="radio" name="rating" id="rating-1" onClick={() => handleRatingClass(1)} />
          <label htmlFor="rating-1" />
        </div>
        {stars !== 0 && (
          <>
            <h5 className="text__white mt-1 mb-0">Cuéntanos más de tu opinión</h5>
            <div className="rate-class__tags-content">
              {rateTags
            .filter((tag) => tag.stars.find((s) => s === stars))
            .map((tag) => (
              <div className="rate-class__tag" key={tag.slug}> 
                <input
                  key={tag.slug}
                  className="rate-class-checkbox"
                  data-testid="input-lastclass-modal-options"
                  type="checkbox"
                  checked={selectedTags.find((st) => st === tag.slug)}
                  id={tag.slug}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedTags([...selectedTags, e.target.id]);
                    } else {
                      const newSelectedChecked = selectedTags.filter((s) => s !== e.target.id);
                      setSelectedTags(newSelectedChecked);
                    }
                }}
                />
                <label htmlFor={tag.slug}>{tag.name}</label>
              </div>
            ))}
            </div>
            <p 
              className="text__white mt-3"
              onClick={() => setShowComments(!showComments)}
            >
              {showComments ? 'Cancelar' : 'Agregar comentario' }
            </p>
            {showComments && (<textarea placeholder="Puedes agregar más comentarios" onChange={(e) => setComments(e.target.value)} />)}
            <br />
            <button className="btn btn__black mt-2" type="button" onClick={() => onRatingClassBtn()}>Enviar comentarios</button>
          </>
      )} 
      </>
    </div>
  );
}

RateClass.propTypes = {
  needRatingClass: PropTypes.shape().isRequired,
  onRatingClass: PropTypes.func.isRequired,
};

export default RateClass;
