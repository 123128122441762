export default {
    title: 'Ejercicio 46',
    description: `
    Crea una función llamada <code>vocales()</code> que reciba como parámetro una 
    cadena de texto y retorne el número de vocales que tiene la cadena de texto.

    
    `,
    seed: '// Escribe tu código aquí',
    test: [
        `test('Probar "Slipknot Rocks" ', function (t) {
            t.equal(vocales("Slipknot Rocks"), 3,'Esto debe retornar 3');
            t.end()
          });`,
          `test('Probar "Compila y no se porque"', function (t) {
            t.equal(vocales("Compila y no se porque"), 8,'Esto debe retornar 8');
            t.end()
          });`,
          `test('Probar "Aaaamoo seeeeer proooograaaamaaaaadoooor"', function (t) {
            t.equal(vocales("Aaaamoo seeeeer proooograaaamaaaaadoooor"), 28,'Esto debe retornar 28');
            t.end()
          });`,
    ]
  };
