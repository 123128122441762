import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { useProjects } from '../../Context/ProjectContext';
import { Spinner } from '../../Common/Spinner';
import { useMe } from '../../Context/MeContext';

export default (RateProject) => function RateProjectHOC() {
  const [{ isError, isFetching, rate }, { rateProject }] = useProjects();
  const [{ profile }] = useMe();
  const [,, removeCookie] = useCookies();

  useEffect(() => {
   if (profile && profile.activeProgram) {
    rateProject(profile);
    removeCookie('idProject', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
    removeCookie('slugProject', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
   } 
  }, [profile]);

  const renderContent = () => {
    if (isFetching) {
      return (
        <div className="d-flex justify-content-center align-items-center bg__gray2">
          <Spinner />
        </div>
      );
    }
    if (isError) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <h3>{isError}</h3>
        </div>
      );
    }
    return (
      <div className="col-12 d-flex justify-content-center align-items-center bg__gray2">
        <RateProject rate={rate} />
      </div>
    );
  };

  return renderContent();
};
