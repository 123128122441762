import React from 'react';
import { Loader } from 'react-feather';
import './spinner.scss';
// import loading from './loader.gif';

const Spinner = () => (
  <Loader data-testid="svg-spinner" className="a-spinner" size={28} />
  // <img id="a-loading__gif" src={loading} alt="Cargando" />
);

export default Spinner;
