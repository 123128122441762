import React from 'react';
import PropTypes from 'prop-types';
import ProfileCard from '../../Components/ProfileCard/ProfileCard';

const Me = ({
  profile, isFetchingUpdateProfile, isErrorUpdateProfile,
  isUpdateProfileSuccess, updateProfile, updateAlumni,
  updateBillingData, updatePassword, sendEmail,
}) => (
  <ProfileCard
    profile={profile.alumni}
    activeProgram={profile.activeProgram}
    updateProfile={updateProfile}
    updateAlumni={updateAlumni}
    updateBillingData={updateBillingData}
    updatePassword={updatePassword}
    sendEmail={sendEmail}
    isFetchingUpdateProfile={isFetchingUpdateProfile}
    isErrorUpdateProfile={isErrorUpdateProfile}
    isUpdateProfileSuccess={isUpdateProfileSuccess}
    isMe
  />
  );

Me.propTypes = {
  profile: PropTypes.shape().isRequired,
  updateProfile: PropTypes.func.isRequired,
  updateAlumni: PropTypes.func.isRequired,
  updateBillingData: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  isFetchingUpdateProfile: PropTypes.bool.isRequired,
  isErrorUpdateProfile: PropTypes.bool.isRequired,
  isUpdateProfileSuccess: PropTypes.bool.isRequired,
};

export default Me;
