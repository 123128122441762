/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { BookOpen, Flag } from 'react-feather';
import './lectureCard.scss';
// import Cover from '../../../assets/jpg/login.jpg';

function LectureCard({
 lecture, tag, slugModule, completed, sensei, senseiMaster, completedRead
}) {
    const bgColors = [
      'bg_one',
      'bg_two',
      'bg_three',
      'bg_four',
      'bg_six',
      'bg_seven',  
    ];
    
    const styleRandomBackground = () => {
      const random = Math.floor(Math.random() * bgColors.length);
      return `${bgColors[random]} lecture-card`;
    };

    const generateURL = () => {
      if (senseiMaster) {
        return `/sensei/lecture/${lecture.id}`;
      } if (sensei) {
        return `/sensei/lectures/${slugModule}/${lecture.id}?type=${tag}`;
      }
        return `/alumni/lectures/${slugModule}/${lecture.id}?type=${tag}`;
    };

    const renderTick = () => {
      if (completed && completedRead) {
        return (<div className="lecture-donut" data-testid="img-lecturecard-complet_lecture" style={{ background: '#14C7D0' }}><div className="lecture-donut-center" /></div>);
        } if (completed || completedRead) {
          return (<div className="lecture-donut" data-testid="img-lecturecard-complet_lecture"><div className="lecture-donut-center" /></div>);
        } 
        return null;
    };

    return (
      <Link
        data-testid="link-lecturecard" 
        to={generateURL()}
        key={lecture.id}
        className={styleRandomBackground()}
      >
        <div className="lecture-card-content">
          <span className="video_click card-lecture" />
          {/* <div className="lecture-card__footer">
              <div className={` lecture-card__tag ${tag === 'lecture' ? 'bg__brand-plasma' : 'bg__brand-nebula'}`}>
              {tag === 'lecture' ? <BookOpen color="#ffff" size={16} /> : <Flag color="#ffff" size={16} />}
                <p className="text__white ml-1">
                { 
                  (tag === 'lecture' && 'Lectura')
                  || (tag === 'exam' && 'Evaluación')
                  || (tag === 'project' && 'Proyecto')
                }
                </p>
                </div> 
              <div>                 */}
          {/* </div> */}
          <div className="lecture-card-footer">
            <h6>
              {lecture.tema}
            </h6>
            <span>
              {renderTick()}
            </span>
          </div>
        </div>
      </Link>
    );
}

LectureCard.defaultProps = {
  sensei: false,
  completedRead: false,
};

LectureCard.propTypes = {
  completedRead: PropTypes.bool,
  lecture: PropTypes.shape({
    cover: PropTypes.string,
    id: PropTypes.string.isRequired,
    tema: PropTypes.string.isRequired,
  }).isRequired,
  tag: PropTypes.string.isRequired,
  slugModule: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  sensei: PropTypes.bool,
  senseiMaster: PropTypes.bool.isRequired,
};

export default LectureCard;
