export default {
  title: 'Ejercicio 9',
  description: `
    Crea una función llamada <code>largest</code> que reciba como parámetro un arreglo de números,
    esta función debe retornar el número más grande del arreglo.
    `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('largest([5,7,10,9,8,6,2,3,7,1])', function (t) {
    t.equal(largest([5,7,10,9,8,6,2,3,7,1]), 10,"10 es el número más grande");
    t.end()
  });`,
    `test('largest([22,13,55,98,24,1,4])', function (t) {
      t.equal(largest([22,13,55,98,24,1,4]), 98,"98 es el número más grande");
      t.end()
    });`,
  ]
};
