export default {
  title: 'Ejercicio 3',
  description: `
  Crea una variable llamada <code>cont</code> que sea del tipo <strong>entero</strong>, después crea otra variable 
  llamada <code>bool</code> que sea del tipo <strong>booleano</strong> y por último una variable llamada
  <code>str</code> que sea del tipo string.
  Recuerda utilizar la palabra reservada <code>var</code> para este ejercicio.
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Int test', function (t) {
      t.equal(typeof cont, "number","cont debe ser un numero");
      t.end()
    });`,
    `test('Bool test', function (t) {
      t.equal(typeof bool, "boolean","bool debe ser boleano ");
      t.end()
    });`,
    `test('String test', function (t) {
      t.equal(typeof str,"string","str debe ser string");
      t.end()
    });`
  ]
};
