export const OPEN_PROFILE = 'open_profile';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const OPEN_LECTURE = 'open_lecture';
export const RESET_KATA = 'reset_kata';
export const RUN_KATA = 'run_kata';
export const SAVE_KATA = 'save_kata';
export const SIGNUP = 'sign_up';
export const TRY_CODE = 'try_code';
export const URL_REPLIT = 'url_replit';
export const REGISTER_FINAL_PROYECT = 'register_final_proyect';
export const FINAL_PROYECT = 'final_proyect';
export const READ_MORE_CLICKED = 'readmore_clicked';
export const LECTURE_FINISHED = 'lecture_finished';
export const PLAY_VIDEO = 'play_video'; 
export const SELF_OPEN_LESSON = 'self_open_lesson';
export const SELF_FINISH_LESSON = 'self_finish_lesson';
