import React from 'react';

const Support = () => (
  <section>
    <div className="container d-flex flex-column justify-content-center align-items-center">
      <h5>Campus no está disponible para tu navegador</h5>
      <br />
      <p>
        Por favor utiliza alguno de estos navegadores en su
        {' '}
        <b>última versión</b>
      </p>
      <div className="d-flex justify-content-center pt-3">
        <a className="a-btn btn__error" href="https://www.opera.com/es">DESCARGAR OPERA</a>
        <a className="a-btn btn__success" href="https://www.google.com/chrome">DESCARGAR CHROME</a>
        <a className="a-btn btn__info" href="https://www.microsoft.com/es-es/edge">DESCARGAR EDGE</a>
      </div>
    </div>
  </section>
);

export default Support;
