import React from 'react';
import { useSensei } from '../../Context/SenseiContext';

export default (SenseiNavbar) => function SenseiNavbarHoc() {
  const [{ profile }, { setActiveRecordModule, logout }] = useSensei();

  return (
    <>
      {profile
        ? (
          <SenseiNavbar 
            profile={profile} 
            setActiveRecordModule={setActiveRecordModule} 
            logout={logout}
          />
        )
        : null}
    </>
  );
};
