export default {
  title: 'Ejercicio 4',
  description: `
  Crea una función que se llame <code>mayorDeEdad</code>, reciba una  edad como parametro y
  regrese si el usuario es <code>"Mayor de edad"</code> o es <code>"Menor de edad"</code>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Mayor test', function (t) {
      t.equal(mayorDeEdad(25),"Mayor de edad","El usuario es mayor de edad");
      t.end()
    });`,
    `test('Bool test', function (t) {
      t.equal(mayorDeEdad(12), "Menor de edad","El usuario es menor de edad ");
      t.end()
    });`
  ]
};
