/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useLectures } from '../../Context/LecturesContext';
import { Spinner } from '../../Common/Spinner';
import { useMe } from '../../Context/MeContext';
import { SELF_FINISH_LESSON } from '../../utils/tracks';
import { useModules } from '../../Context/ModuleContext';

export default (Lectures) => function LecturesHoc() {
  const [{
    isFetching, isError, currentLecture, examLecture, isFetchingGradeExam
  }, { getLectureOrExam, setResponse, submitSelfExam }] = useLectures();

  const [{ profile }, { track, syncProfile }] = useMe();
  const [{
    selfPaced,
  }, { getCompleteModuleSelfPaced, sendAlumniProyectSelfPaced }] = useModules();

  const { lecture, module } = useParams();
  const history = useHistory();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const [alumniModule, setAlumniModule] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [alumniProject, setAlumniProject] = useState(null);
  const [nextLesson, setNextLesson] = useState({});
  const [lastLesson, setLastLesson] = useState({});
  const [nextProject, setNextProject] = useState({});
  const [loader, setLoader] = useState(true);

  const selectModule = (slugModule) => {
    getCompleteModuleSelfPaced(slugModule);
  };

  useEffect(() => {
    if (lecture !== currentLecture?._id) {
      if (currentProject && currentProject._id === lecture) {
        setLoader(false);  
      } else {
        setLoader(true);
      }
    } else {
      setLoader(false);
    }
  }, [lecture, currentLecture]);

  useEffect(() => {
    if (profile && profile.activeProgram) {
      selectModule(module);
      setAlumniModule(profile.activeProgram.modules.find((m) => m.slug === module));
    }
  }, [lecture]);

  const classTopic = query.get('topic');

  useEffect(() => {
    if (selfPaced) {
      if (query.get('type') === 'selfProject') {
        const activeC = selfPaced?.topics.find((_c) => _c.topic === classTopic);
        const activeP = activeC?.projects.find((_p) => _p.slug === lecture);
        setCurrentProject(activeP);
        setLoader(false);
        if (alumniModule) {
          const currentClass = alumniModule.selfpaced_tracker
            .find((_c) => _c.topic_slug === classTopic);
          const _alumniProject = currentClass.projects.find((_p) => _p.slug === activeP.slug);
          setAlumniProject(_alumniProject || null);
        }
      }
    }
  }, [selfPaced, lecture]);

  useEffect(() => {
    if (query.get('type') === 'self') {
      getLectureOrExam(lecture);
    }
  }, [lecture]);

  useEffect(() => {
    // Obetener la siguiente lección para poner el btn
    if (selfPaced) {
      const activeTopic = selfPaced?.topics.find((_c) => _c.topic === classTopic);
      const activeLectureIndex = activeTopic.lectures
        .findIndex(({ lecture: _l }) => _l.id === lecture);
      const totalLectures = activeTopic.lectures.length;
      if (activeLectureIndex === (totalLectures - 1)) {
        setNextProject(activeTopic.projects[0]);
        setLastLesson(activeTopic.lectures[activeLectureIndex - 1]?.lecture);
        setNextLesson({});
      } else {
        setNextProject({});
        setNextLesson(activeTopic.lectures[activeLectureIndex + 1]?.lecture);
        if (activeLectureIndex !== 0) {
          setLastLesson(activeTopic.lectures[activeLectureIndex - 1]?.lecture);
        } else {
          setLastLesson(null);
        }
      }
    }
  }, [selfPaced, lecture]);

  const onSubmitExam = (responses) => {
    submitSelfExam(profile.activeProgram._id, responses, module, query.get('topic'), (err, { grade, maxGrade }) => {
      if (!err) {
        const score = (grade / maxGrade);
        track(SELF_FINISH_LESSON, { lecture_id: currentLecture._id, module, examScore: score });
      }
    });
  };

  const saveAlumniProject = (links, description) => {
    sendAlumniProyectSelfPaced(selfPaced.module_id, currentProject._id, classTopic,
      { links, description, idCampusProgram: profile.activeProgram._id }, (error) => {
        if (!error) {
          syncProfile();
        }
      });
  };

  return (
    <div className="col-lg-12 col-12 bg__gray2">
      {
        (
          selfPaced && profile?.activeProgram && currentLecture && !loader
        ) || (selfPaced && profile?.activeProgram && currentProject && !loader) ? (
          <>
            <Lectures
              isFetchingLecture={isFetching}
              isError={isError}
              lecture={currentLecture || []}
              activeProgram={profile.activeProgram}
              track={track}
              activeModule={module}
              isFetchingGradeExam={isFetchingGradeExam}
              setResponse={setResponse}
              submitExam={onSubmitExam}
              exam={examLecture || []}
              topic={query.get('topic')}
              history={history}
              activeProject={currentProject}
              contentType={query.get('type')}
              alumniProject={alumniProject}
              saveAlumniProject={saveAlumniProject}
              nextProject={nextProject}
              nextLesson={nextLesson}
              lastLesson={lastLesson}
            />
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '93vh' }}>
            <Spinner />
          </div>
        )
      }
    </div>
  );
};
