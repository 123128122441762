export default {
    title: 'Ejercicio 13',
    description: `
    Crea una función llamada <code>isMinor()</code> que recibe tres parámetros numéricos y evalúe:
 
    <ul>
    <li> En caso de que alguno de los números sea menor a 10 retorna <code>true</code>.</li>
    <li> En caso de que ninguno de los números sean menores a 10 retorna <code>false</code>.</li>
    </ul>
    `,
    seed: '// Escribe tu código aquí',
    test: [
      `test('Probar si 1, 5, 9', function (t) {
        t.equal(isMinor(1, 5, 9), false,'Esto debe retornar false');
        t.end()
      });`,
      `test('Probar si 10, 3, 4', function (t) {
        t.equal(isMinor(10, 3, 4), false,'Esto debe retornar false');
        t.end()
      });`,
      `test('Probar si 1, 30, 4', function (t) {
        t.equal(isMinor(1, 30, 4), true,'Esto debe retornar true');
        t.end()
      });`,
      `test('Probar si 12, 39, 48', function (t) {
        t.equal(isMinor(12, 39, 48), true,'Esto debe retornar true');
        t.end()
      });`,
    ]
  };
