import React from 'react';
import { useSenseiLecture } from '../../../Context/SenseiLectureContext';
import { Spinner } from '../../../Common/Spinner';
import { EDITOR_JS_TOOLS } from '../../../utils/tools';

export default (Lecture) => function EditLectureHoc() {
  const [{
    isFetching, isError, lecture, examLecture 
  }, {
    createQuestion, addAnswer, editAnswer, editQuestion, saveResponses,
  }] = useSenseiLecture();

  const renderContent = () => {
    if (isFetching) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <Spinner />
        </div>
      );
    }
    if (isError) return <h1 style={{ marginTop: '20rem' }}>Error</h1>;
    if (lecture) {
      return (
        <Lecture
          data={lecture}
          tools={EDITOR_JS_TOOLS}
          updateContent={saveResponses}
          createQuestion={createQuestion}
          examLecture={examLecture}
          addAnswer={addAnswer}
          editAnswer={editAnswer}
          editQuestion={editQuestion}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderContent()}
    </>
  );
};
