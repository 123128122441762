import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { useMe } from '../../Context/MeContext';

export default (Profile) => function ProfileHoc() {
  const [{
 profile, qrData, suspensionDate, isSuspended, moduleSensei
}, { signDiploma, registerClass }] = useMe();
  const history = useHistory();
  const [cookies] = useCookies();
  useEffect(() => {
    if (profile) {
      const { idProject, slugProject } = cookies;
      if (idProject && slugProject) {
        setTimeout(() => history.push(`/projects/save/${slugProject}/${idProject}`), 500);
      }
    }
  }, [profile]);
  return (
    <Profile
      senseiModule={moduleSensei}
      profile={profile}
      suspensionDate={suspensionDate}
      isSuspended={isSuspended}
      qrData={qrData}
      signDiploma={signDiploma}
      registerClass={registerClass}
      changePayment={() => {
        history.push('/master/payments');
      }}
    />
  );
};
