import React, {
  useContext,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import api from './api';

const LectureContext = React.createContext(undefined);

const SenseiLectureProvider = withRouter(({ children, location }) => {
  const [isFetching, setIsFeatching] = useState(true);
  const [isError, setIsError] = useState(false);
  const [lecture, setLecture] = useState(null);
  const [examLecture, setExamLecture] = useState(null);

  const getLecture = async (idLecture) => {
    setIsError(false);
    try {
      const response = await api.Lectures.getLectureById(idLecture);
      if (response.questions && response.questions.length > 0) {
        setExamLecture(response.questions);
      }
      setLecture(response);
      localStorage.removeItem('tracked');
    } catch (error) {
      switch (error.response.status) {
        case 500:
          setIsError('Ocurrió un error en el servidor');
          break;
        default:
          setIsError(error.response.data.message);
          break;
      }
    }
  setIsFeatching(false);
  };

  const createQuestion = () => {
    const newQuestion = {
      question: '',
      answers: [
        { answer: '', is_correct: false }
      ]
    };
    if (examLecture) {
      examLecture.push(newQuestion);
      setExamLecture([...examLecture]);
    } else {
      setExamLecture([newQuestion]);
    }
  };

  const editQuestion = (indexQuestion, newQuestion) => {
    examLecture[indexQuestion].question = newQuestion;
    setExamLecture([...examLecture]);
  };

  const addAnswer = (question, newAnswer) => {
    const indexToEdit = examLecture.findIndex((_q) => _q.question === question);
    examLecture[indexToEdit].answers.push(newAnswer);
    setExamLecture([...examLecture]);
  };

  const editAnswer = (indexQuestion, indexAnswer, newAnswer) => {
    examLecture[indexQuestion].answers[indexAnswer] = {
      ...examLecture[indexQuestion].answers[indexAnswer],
      ...newAnswer 
    };
    setExamLecture([...examLecture]);
  };

  // eslint-disable-next-line camelcase
  const saveResponses = async (id, content, codes, read_more, reading_time) => {
    try {
      if (examLecture) {
        await api.Sensei.updateLecture(id, {
          ...content,
          codes,
          read_more,
          reading_time,
          time: new Date().getTime(),
          questions: examLecture
        });
        // eslint-disable-next-line no-alert
        alert('Lectura Guardada ✅');
      } else {
        // eslint-disable-next-line no-alert
        alert('Se necesita crear al menos una pregunta para guardar la lectura ❌');
      }
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert('Error guardando la lectura, se hizo CLG del error');
    }
  };

  useEffect(() => {
    const [, , , idLecture] = location.pathname.split('/');
    if (idLecture) {
      getLecture(idLecture);
    }
  }, []);

  const state = [
    {
      isFetching,
      isError,
      lecture,
      examLecture,
    },
    {
     getLecture, createQuestion, addAnswer, editAnswer, editQuestion, saveResponses
    }
  ];
  
  return (
    <LectureContext.Provider value={state}>
      {children}
    </LectureContext.Provider>
  );
});

SenseiLectureProvider.propTypes = {
  children: PropTypes.shape(),
  location: PropTypes.shape()
};

SenseiLectureProvider.defaultProps = {
  children: [],
  location: {}
};

const useSenseiLecture = () => {
  const context = useContext(LectureContext);
  if (context === undefined) {
    throw new Error('useLecture can only be used inside SenseiLectureProvider');
  }
  return context;
};

export {
  SenseiLectureProvider,
  useSenseiLecture
};
