import axios from 'axios';

const REPO = process.env.REACT_APP_REPO || 'code-web';

export const getAllRepo = () => new Promise((resolve, reject) => axios.get(`https://api.github.com/repos/devfmx/${REPO}/git/trees/main?recursive=1`)
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));

  export const getMdFromPath = (path) => new Promise((resolve, reject) => axios.get(`https://raw.githubusercontent.com/devfmx/code-web/main/${path}`)
  .then(({ data }) => {
    resolve(data);
  })
  .catch((err) => {
    reject(err);
  }));
