// Batches para AB Testing
export const CONTROL_GROUP = [5, 6, 5, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];

export const paths = {
    code_olami: 'WEB',
    professional_code: 'WEB',
    professional_becas: 'WEB',
    code_protalento: 'WEB',
    code: 'WEB',
    frontend: 'WEB',
    course_code: 'WEB',
    course_data: 'DATA', 
    prof_data: 'DATA', 
    master_data: 'DATA',
    course_uxui: 'PRODUCT',
    prof_uxui: 'PRODUCT',
    master_uxui: 'PRODUCT',
    course_mkt: 'MARKETING',
    prof_mkt: 'MARKETING',
    master_mkt: 'MARKETING',
    code_web: 'WEB',
    data_ds: 'DATA',
    data_ai: 'DATA',
    product_ux: 'PRODUCT',
    product_ui: 'PRODUCT',
    self_paced: 'WEB',
};
export const rewards = {
    0: '0',
    1: '🥉',
    2: '🥈',
    3: '🥇',
    4: '💎'
};

export const MIN_EXAM_GRADE = 0.70;

export const CONTENT_HOURS = {
    MASTER: {
        WEB: 'Con 480 horas en desarrollo web',
        DATA: 'Con 240 horas en data science',
        PRODUCT: 'Con 240 horas en ux/ui',
        MARKETING: 'Con 240 horas en marketing digital',
        EN: {
            WEB: '480 hours in web development',
            DATA: '240 hours in data science',
            PRODUCT: '240 hours in ux/ui',
        }
    },
    PROFESSIONAL: {
        WEB: 'Con 240 horas en desarrollo web',
        DATA: 'Con 117 horas en data science',
        PRODUCT: 'Con 117 horas en ux/ui',
        MARKETING: 'Con 117 horas en marketing digital',
        EN: {
            WEB: '240 hours in web development',
            DATA: '117 hours in data science',
            PRODUCT: '117 hours in ux/ui',
        }
    },
    COURSE: {
        WEB: 'Con 96 horas en desarrollo web',
        DATA: 'Con 48 horas en data science',
        PRODUCT: 'Con 48 horas en ux/ui',
        MARKETING: 'Con 48 horas en marketing digital',
    },
    MASTER_2D: {
        WEB: 'Con 240 horas en desarrollo web',
        DATA: 'Con 117 horas en data science',
        PRODUCT: 'Con 117 horas en ux/ui',
        MARKETING: 'Con 117 horas en marketing digital',
    },
    MASTER_2D6M: {
        WEB: 'Con 240 horas en desarrollo web',
        DATA: 'Con 117 horas en data science',
        DS: 'Con 117 horas en data science',
        AI: 'Con 117 horas en inteligencia artificial',
        PRODUCT: 'Con 117 horas en ux/ui',
        UX: 'Con 117 horas en experiencia de usuario',
        UI: 'Con 117 horas en creación de interfaces',
        MARKETING: 'Con 117 horas en marketing digital',
        EN: {
            WEB: '240 hours in web development',
            DS: '117 hours in data science',
            AI: '117 hours in artificial intelligence',
            UX: '117 hours in user experience',
            UI: '117 hours in user interface',
        }
    },
    B2B_PROTALENTO: {
        WEB: 'Con 240 horas en desarrollo web en conjunto con PROTALENTO',
        EN: {
            WEB: '240 hours in web development along PROTALENTO',
        }
    },
    CYC: {
        WEB: 'Con 288 horas en desarrollo web',
        EN: {
            WEB: '288 hours in web development',
        }
    },
    PROFESSIONAL_2D: {
        WEB: 'Con 117 horas en desarrollo web',
        EN: {
            WEB: '117 hours in web development',
        }
    }
};

export const START_HOURS_PRODUCT_LINE = {
    MASTER_2D6M: 'De 18:30 a 19:30 pm hora Ciudad de México',
    B2B_PROTALENTO: 'De 18:30 a 19:30 pm hora Colombia',
};
