/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../Common/Input';
import { Spinner } from '../../Common/Spinner';

const LoginForm = ({
  onLogin, handleAuthOption, isError, isFetching, company
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const sendData = (e) => {
    e.preventDefault();
    onLogin(email, password);
  };

  const handleChangeEmail = (event) => setEmail(event.target.value);
  const handleChangePassword = (event) => setPassword(event.target.value);

  return (
    <div className="o_login-body">
      <div className="o_login-heading">
        <h3 className="text__white">
          { company ? 'Campus para empresas' : 'Bienvenido a Campus' }
        </h3>
        <h6 className="text__gray6">
          { company
          ? 'Encuentra al mejor talento para tu empresa.'
          : 'Campus es la plataforma educativa desarrollada por DEV.F para nuestra comunidad de estudiantes.' }
        </h6>
      </div>
      <div className={isError ? 'd-inline' : 'd-none'}>
        <p className="bg__error text__white p-3" style={{ margin: 0 }}>
          {isError}
        </p>
      </div>
      <form onSubmit={sendData}>
        <div className="m_form-group">
          <small className="a_form-legend text__gray6">Correo electrónico</small>
          <Input
            data-testid="input-email"
            type="email"
            name="email"
            placeholder="Escribe tu correo"
            value={email}
            label=""
            icon=""
            onChange={handleChangeEmail}
            required
          />
        </div>
        <div className="m_form-group">
          <small className="a_form-legend text__gray6">Contraseña</small>
          <Input
            data-testid="input-password"
            type="password"
            name="password"
            placeholder="Ingresa tu contraseña"
            value={password}
            icon=""
            label=""
            onChange={handleChangePassword}
            required
          />
        </div>
        <p
          data-testid="button-forgot-password"
          className="text__brand-nebula"
          style={{ cursor: 'pointer' }}
          onClick={() => handleAuthOption('recover')}
        >
          ¿Olvidaste tu contraseña?
        </p>
        <div className="d-flex flex-row pb-5">
          <button data-testid="buttton-login" style={{ marginLeft: 0 }} className="btn__tool" type="submit" label="login">
            {isFetching && !isError ? <Spinner /> : 'INICIAR SESIÓN'}
          </button>
          <button data-testid="buttton-register" style={{ marginLeft: 0 }} className="btn__ghost-alt" type="button" onClick={() => handleAuthOption('register')}>REGÍSTRATE</button>
        </div>
      </form>
    </div>
  );
};

LoginForm.defaultProps = {
  company: false
};

LoginForm.propTypes = {
  handleAuthOption: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  isError: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  company: PropTypes.bool,
};

export default LoginForm;
