export default {
  title: 'Ejercicio 25',
  description: `
        Crea una función llamada <code>evaluateLetter()</code> que reciba como parámetro 
        cualquier letra del alfabeto y verifique si es vocal o consonante.
        <ul>
            <li>En caso de ser vocal, devolver <code>"Vocal"</code></li>
            <li>En caso de ser consonante, devolver <code>"Consonante"</code></li>
        </ul>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba 1', function (t) {
      t.equal(evaluateLetter("a"), "Vocal", "Prueba correcta");
      t.end()
        });`,
    `test('Prueba 2', function (t) {
      t.equal(evaluateLetter("g"), "Consonante", "Prueba correcta");
      t.end()
        });`,
    `test('Prueba 3', function (t) {
      t.equal(evaluateLetter("e"), "Vocal", "Prueba correcta");
      t.end()
    });`
  ]
};
