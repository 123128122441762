/* eslint-disable func-names */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect } from 'react-router-dom';
import payload from './payload';

export default function (WrappedComponent) {
  return function session(props) {
    const now = Math.floor(new Date().getTime() / 1000);
    const userInfo = payload();
    if (!userInfo) return <Redirect to="/logout" />;
    const sessionExp = userInfo.exp;
    return now > sessionExp
      ? <Redirect to="/logout" />
      : <WrappedComponent {...props} />;
  };
}
