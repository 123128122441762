export default {
  title: 'Ejercicio 7',
  description: `
    Crea una función llamada <code>letter</code> ingresar cualquier letra del alfabeto y verifique si es vocal o consonante
    <ul>
        <li><code>"Vocal"</code> si la letra es una vocal.</li>
        <li><code>"Consonante"</code> si la letra es una consonante.</li>
    <ul>
    `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('4 test', function (t) {
      t.equal(letter("a"), "Vocal","'letter' debe regresar 'Vocal'");
      t.end()
    });`,
    `test('4 test', function (t) {
      t.equal(letter("b"), "Consonante","'letter' debe regresar 'Consonante'");
      t.end()
    });`,
  ]
};
