import { devfApi } from '../../utils/http';

export const sendAlumniProyect = (idModule, idProject, project) => 
  new Promise((resolve, reject) => {
    devfApi.post(`api/v1/campus/module/${idModule}/${idProject}`, project)
      .then(({ data }) => {
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
});

export const sendAlumniProyectSelfPaced = (idModule, idProject, topic, project) => 
  new Promise((resolve, reject) => {
    devfApi.post(`api/v1/campus/self/module/${idModule}/${idProject}/${topic}`, project)
      .then(({ data }) => {
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
});

export const saveRateProject = (body) => new Promise((resolve, reject) => {
  devfApi.patch('/api/v1/campus/projects/results', body)
    .then(({ data }) => {
      resolve(data);
    }).catch((err) => {
      reject(err);
    });
});
