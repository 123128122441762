/* eslint-disable react/state-in-constructor */
/* eslint-disable react/require-default-props */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-undef */
/* eslint-disable react/no-string-refs */
import React, { Component } from 'react';
import MonacoEditor from 'react-monaco-editor';
import PropTypes from 'prop-types';

let monacoThemesDefined = false;
const defineMonacoThemes = (monaco) => {
  if (monacoThemesDefined) {
    return;
  }
  monacoThemesDefined = true;
  const yellowCollor = 'FFFF00';
  const lightBlueColor = '9CDCFE';
  const darkBlueColor = '00107E';
  monaco.editor.defineTheme('vs-dark-custom', {
    base: 'vs-dark',
    inherit: true,
    rules: [
      { token: 'delimiter.js', foreground: lightBlueColor },
      { token: 'delimiter.parenthesis.js', foreground: yellowCollor },
      { token: 'delimiter.array.js', foreground: yellowCollor },
      { token: 'delimiter.bracket.js', foreground: yellowCollor },
    ]
  });
  monaco.editor.defineTheme('vs-custom', {
    base: 'vs',
    inherit: true,
    rules: [{ token: 'identifier.js', foreground: darkBlueColor }]
  });
};

class Editor extends Component {
  state = {
    contentRef: '',
    code: this.props.code
  };

  componentDidUpdate(prevProps) {
    const { code } = this.props;
    if (prevProps.code !== code) {
      this.setState({ code });
    }
  }

  getValue = () => this.refs.monaco.editor.getModel().getValue()

  setValue = () => {
    this.setState({ code: '' });
  }

  editorDidMount(editor,) {
    editor.focus();
  }

  editorWillMount = (monaco) => {
    defineMonacoThemes(monaco);
  };

  setContentRef = (node) => {
    this.setState(
      { contentRef: node.contentWindow.document }
    );
  }

  render() {
    const options = {
      minimap: {
        enabled: false
      },
      fontSize: '15px',
      selectOnLineNumbers: true,
      wordWrap: 'on',
      scrollbar: {
        horizontal: 'hidden',
        vertical: 'visible',
        verticalHasArrows: true
      }
    };
    const { code } = this.state;
    const { height } = this.props;
    return (
      <MonacoEditor
        height={height}     
        language="javascript"
        value={code}
        options={options}
        theme="vs-dark-custom"
        editorWillMount={this.editorWillMount}
        editorDidMount={this.editorDidMount}
        ref="monaco"
      />
    );
  }
}

Editor.propTypes = {
  code: PropTypes.string,
  height: PropTypes.string
};

Editor.defaultPropTypes = {
  height: '500'
};

export default Editor;
