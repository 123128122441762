export default {
  title: 'Ejercicio 10',
  description: `
  Crea una función llamada <code>game</code> que simule el juego "Piedra, papel y tijeras", la función recibirá como parámetros como se muestra a continuación:
  <br/>
  <code>game("Piedra", "Papel")</code> Jugador 1 ha seleccionado <code>"Piedra"</code> y jugador 2 ha seleccionado <code>"Papel"</code>, por lo tanto el ganador es el jugador 2.
  <br/>
  La función unicamente recibirá como parámetro <code>"Piedra"</code>, <code>"Papel"</code> o <code>"Tijeras"</code>, retornar lo siguiente:
  <br/>
  <code>"J1"</code> Si ha ganado el jugador 1. <br/>
  <code>"J2"</code> Si ha ganado el jugador 2. <br/>
  <code>"Empate"</code> Si hay un empate. <br/>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('game("Piedra", "Papel")', function (t) {
      t.equal(game("Piedra", "Papel"), "J2","Gana J2");
      t.end()
    });`,
    `test('game("Papel", "Papel")', function (t) {
      t.equal(game("Papel", "Papel"), "Empate","Empate");
      t.end()
    });`,
    `test('game("Tijeras", "Papel")', function (t) {
      t.equal(game("Tijeras", "Papel"), "J1","Gana J1");
      t.end()
    });`,
  ]
};
