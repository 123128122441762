/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSensei } from '../../../Context/SenseiContext';
import { Spinner } from '../../../Common/Spinner';
import recommendationData from '../../../Common/Form/data/recommendations.json';

export default (ProfileCard) => function ProfileSenseiHoc() {
  const history = useHistory();
  const [{
    profile, alumnisBySensei, isFecthingAddRecommendation,
    isFetchingDeleteRecommendation
  }, {
    getAlumniBySensei,
    addRecommendation,
    deactivateRecommendation,
    rateProject,
    rateSelfProject,
  }] = useSensei();

  const [alumnisBySenseiClean, setAlumnisBySenseiClean] = useState([]);
  const [alumnisBySenseiProjects, setAlumnisBySenseiProjects] = useState([]);
  const [recommendation, setRecommendation] = useState({});
  const [batch, setBatch] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);
  const [isSelfPaced, setIsSelfPaced] = useState(false);

const ifSelfPaced = (currentGroupName) => {
  if (currentGroupName.includes('SELF')) {
    setIsSelfPaced(true);
    return true;
  } 
    setIsSelfPaced(false);
    return false;
};
  useEffect(() => {
    if (alumnisBySensei.length === 0 && batch !== null) {
      getAlumniBySensei(batch, groupName, currentModule, ifSelfPaced(groupName));
    }
    setRecommendation(recommendationData);
  }, []);

  useEffect(() => {
    if (profile !== null) {
      if (profile.records_module?.length > 0) {
        setBatch(profile.records_module.slice(-1)[0].batch);
        setGroupName(profile.records_module.slice(-1)[0].group_detail.group_name);
        setCurrentModule(profile.records_module.slice(-1)[0].slug);
        ifSelfPaced(profile.records_module.slice(-1)[0].group_detail.group_name);
        if (profile.activeRecordModule !== undefined) {
          getAlumniBySensei(
            profile.activeRecordModule.batch,
            profile.activeRecordModule.group_detail.group_name,
            profile.activeRecordModule.slug,
            ifSelfPaced(profile.activeRecordModule.group_detail.group_name),
          );
        }
      }
    }
  }, [profile, isFecthingAddRecommendation, isFetchingDeleteRecommendation]);

  useEffect(() => {
    const alumnis = alumnisBySensei.map((alumni) => {
      const {
        // eslint-disable-next-line camelcase
        _id: campusProgramId,
        campus: {
          // eslint-disable-next-line camelcase 
          _id: alumniId, first_name, last_name, cv_url,
          github, linkedin,
        }
      } = alumni;
      // eslint-disable-next-line max-len
      const isRecommended = alumni.recommendation?.is_active !== undefined && alumni.recommendation.is_active;
      const { assistance } = alumni.tracking;

      const assisted = assistance.reduce((acc, isAssited) => {
        const assist = isAssited.assisted ? 1 : 0;
        let accu = acc;
        // eslint-disable-next-line no-return-assign
        return accu += assist;
      }, 0);

      return {
        alumniId: alumniId.$oid,
        campusProgramId: campusProgramId.$oid,
        first_name,
        last_name,
        isRecommended,
        assisted,
        github,
        linkedin,
        cv_url,
        logins: alumni.tracking.stats.logins,
        lectures: alumni.tracking.stats.lectures,
        exams: alumni.tracking.exams.length,
        videos: alumni.tracking.stats.videos,
        katas: alumni.tracking.stats.katas,
        total_katas: alumni.tracking_module.total_katas,
        complete_lectures: alumni.tracking_module.complete_lectures,
        total_lectures: alumni.tracking_module.total_lectures,
        complete_exams: alumni.tracking_module.complete_exams,
        total_exams: alumni.tracking_module.total_exams,
        total_projects: alumni.tracking_module.total_projects,
        complete_projects: alumni.tracking_module.complete_projects,
        alert_projects: alumni.tracking_module.alert_projects,
      };
    });
    let projectsSelfForModule;
    let projectsForModule;

    if (!isSelfPaced) {
      projectsSelfForModule = alumnisBySensei.reduce((projects, campus) => {
        // eslint-disable-next-line max-len
        const actualModule = campus.modules.find((_mod) => _mod.slug === currentModule)?.selfpaced_tracker;
        const moduleSelfPacedProjects = actualModule?.map((item) => {
          const msp = item.projects.map((project) => ({
            ...project,
            topic_slug: item.topic_slug
          }));
          return msp;
        }).flat();

        if (moduleSelfPacedProjects && moduleSelfPacedProjects.length > 0) {
          const alumniSelfPacedProjects = moduleSelfPacedProjects.map(((_pro) => ({
            // eslint-disable-next-line no-underscore-dangle
            campusProgramId: campus._id.$oid,
            fullName: `${campus.alumnis.first_name} ${campus.alumnis.last_name}`,
            slugModule: currentModule,
            type: 'selfPaced',
            project: _pro,
          })));
          // eslint-disable-next-line no-param-reassign
          projects = [...projects, ...alumniSelfPacedProjects];
        }
        return projects;
      }, []);
      setAlumnisBySenseiProjects(projectsSelfForModule);
    } else {
      projectsForModule = alumnisBySensei.reduce((projects, campus) => {
        const moduleProjects = campus.modules.find((_mod) => _mod.slug === currentModule)?.projects;
        if (moduleProjects && moduleProjects.length > 0) {
          const alumniProjects = moduleProjects.map(((_pro) => ({
            // eslint-disable-next-line no-underscore-dangle
            campusProgramId: campus._id.$oid,
            fullName: `${campus.alumnis.first_name} ${campus.alumnis.last_name}`,
            slugModule: currentModule,
            project: _pro,
          })));
          // eslint-disable-next-line no-param-reassign
          projects = [...projects, ...alumniProjects];
        }
        return projects;
      }, []);
      setAlumnisBySenseiProjects(projectsForModule);
    }
    setAlumnisBySenseiClean(alumnis);
  }, [alumnisBySensei]);

  const onRateProject = (project) => {
    if (isSelfPaced) {
      rateSelfProject(project, () => { });
    } else {
      rateProject(project, () => { });
    }
  };

  return (
    <>
      {
        profile
          ? alumnisBySensei.length > 0 ? (
            <ProfileCard
              isFecthingAddRecommendation={isFecthingAddRecommendation}
              isFetchingDeleteRecommendation={isFetchingDeleteRecommendation}
              history={history}
              profile={profile}
              alumnisBySensei={alumnisBySenseiClean}
              alumnisBySenseiProjects={alumnisBySenseiProjects}
              recommendation={recommendation}
              addRecommendation={addRecommendation}
              deactivateRecommendation={deactivateRecommendation}
              onRateProject={onRateProject}
              isSelfPaced={isSelfPaced}
            />
          ) : <h2 className="text__white">Aun no tienes alumnis para este modulos</h2>
          : (
            <>
              <Spinner />
            </>
          )
      }
    </>
  );
};
