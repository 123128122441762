export default {
  title: 'Ejercicio 32',
  description: `
        Crea una función llamada <code>cleanArray()</code>, que elimine de un arreglo los siguientes elementos: <br>
        <code>null</code>, <code>0</code>, <code>""</code>, <code>false</code> y <code>undefined</code> <br>
        La función debe retornar el arreglo sin los elementos anteriormente mencionados.
    `,
  test: [
    `test('Prueba 1', function (t) {
      t.deepEqual(cleanArray([1, 1, 0, 1, 9, null, 3, undefined]), [1, 1 ,1 ,9 ,3]);
      t.end()
    });`,
    `test('Prueba 2', function (t) {
      t.deepEqual(cleanArray([null, 1, 0, undefined, 9, 8, "", false]), [1, 9, 8]);
      t.end()
    });`,
    `test('Prueba 3', function (t) {
      t.deepEqual(cleanArray([null, 0, undefined, ""]), []);
      t.end()
    });`
  ]
};
