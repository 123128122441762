// export default {

export default {
  title: 'Ejercicio 30',
  description: `
        Crea una función llamada <code>time()</code> que calcule el tiempo necesario para que 
        un automóvil que se mueve con una velocidad de 73000 m/h recorra una distancia de 120 km.
        (Tiempo = distancia/velocidad)
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('Prueba de función tiempo()', function (t) {
      t.equal(time(), 1.64, "Prueba correcta");
      t.end()
    });`
  ]
};
