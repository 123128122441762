import styled from 'styled-components';

const ReadingProgressBar = styled.div`
  position:  fixed;
  background:  linear-gradient(
    to right,
    #9272FF ${(props) => props.scroll},
    transparent  0);
  width:  100%;
  height:  5px;
  z-index:  3;
  top: 65px;
  left: 0;
`;

export default ReadingProgressBar;
