/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Redirect, Route, Switch, useParams
} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { AuthProvider } from '../Context/AuthContext';
import { Home } from '../views/Home';
import { RecoverPassword } from '../views/RecoverPassword';
import { Support } from '../views/Support';
import AlumniRoutes from './AlumniRoutes';
import SenseiRoutes from './SenseiRoutes';
import isAuthenticated from '../utils/isAuthenticated';
import ProfessionalProfile from '../views/ProfessionalProfile';
import { CareersProvider } from '../Context/CareersContext';
import Careers from '../views/Careers/public';

const AnonymousRoute = ({ component: Component, ...rest }) => {
  const role = localStorage.getItem('ROLE') === 'SENSEI_MASTER' ? 'sensei' : 'alumni';
  return (
    isAuthenticated()
      ? (<Redirect to={`/${role}/profile`} />)
      : (
        <Route
          {...rest}
          render={
            (props) => (
              <Component {...props} />
            )
          }
        /> 
      ));
};

const NotFound = () => {
  const role = localStorage.getItem('ROLE') === 'SENSEI_MASTER' ? 'sensei' : 'alumni';
  return isAuthenticated() ? <Redirect to={`/${role}/profile`} /> : <Redirect to="/" />;
};

const Routes = () => {
  const [role, setRole] = useState(null);

  const renderByRole = () => {
    if (((role === 'SENSEI_MASTER') || localStorage.getItem('ROLE') === 'SENSEI_MASTER') || (role === 'SENSEI') || localStorage.getItem('ROLE') === 'SENSEI') {
      return <SenseiRoutes />;
    }
    if (role === 'ALUMNI' || localStorage.getItem('ROLE') === 'ALUMNI') {
      return <AlumniRoutes />;
    }

    return (
      <Switch>
        <AnonymousRoute exact path="/" key={0}>
          <AuthProvider setRole={setRole}>
            <Home />
          </AuthProvider>
        </AnonymousRoute>
        <AnonymousRoute exact path="/me/:id" key={1}>
          <AuthProvider setRole={setRole}>
            <ProfessionalProfile />
          </AuthProvider>
        </AnonymousRoute>
        <AnonymousRoute exact path="/recovery/:code" key={2}>
          <AuthProvider>
            <RecoverPassword />
          </AuthProvider>
        </AnonymousRoute>
        <AnonymousRoute exact path="/support" component={Support} key={3} />
        {/* Agregó este link duplicado porqeu se mando en correos ete link de campus */}
        <AnonymousRoute exact path="/register" key={4}>
          <AuthProvider setRole={setRole}>
            <Home />
          </AuthProvider>
        </AnonymousRoute>
        <AnonymousRoute
          exact
          path="/logout"
          component={() => {
            localStorage.clear();
            setTimeout(() => {
              window.location.reload(false);
            }, 400);
          }}
          key={100}
        >
          <Redirect to="/" />
        </AnonymousRoute>
        <AnonymousRoute
          exact
          path="/projects/save/:project/:idProject"
          component={() => {
            const { project, idProject } = useParams();
            const [, setCookie] = useCookies();
            setCookie('idProject', idProject, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
            setCookie('slugProject', project, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
            return <Redirect to="/" />;
          }}
          key={100}
        />
        <AnonymousRoute exact path="/careers" key={20}>
          <CareersProvider>
            <Careers />
          </CareersProvider>
        </AnonymousRoute>
        <AnonymousRoute path="*" key={7}>
          <NotFound />
        </AnonymousRoute>
      </Switch>
    );
  };

  return (
    <Switch>
      {renderByRole()}
    </Switch>
  );
};

export default Routes;

AnonymousRoute.defaultProps = {
  component() { },
};

AnonymousRoute.propTypes = {
  component: PropTypes.func
};
