export default {
  title: 'Ejercicio 37',
  description: `
  Escribe una función de orden superior llamada <code>superior()</code> 
  que reciba una cadena de caracteres, debe devolver mediante un callback
  la cadena de caracteres en mayúsculas o en minúsculas. <br>
  Por ejemplo:
  <code>superior("PejeLagarto", minus);</code> <br>
        -> pejelagarto <br>
        <code>superior("PejeLagarto", mayus);</code> <br>
        -> PEJELAGARTO <br>
  `,
  seed: '// Escribe tu código aquí',
  test: [
    `test('PEJELAGARTO a minúsculas', function (t) {
                t.equal(superior("PEJELAGARTO", minus), "pejelagarto");
                t.end()
            });`,
    `test('sardina a mayúsculas', function (t) {
                t.equal(superior("sardina", mayus), "SARDINA");
                t.end()
            });`,
    `test('Paloma a mayúsculas', function (t) {
            t.equal(superior("Paloma", mayus), "PALOMA");
            t.end()
            });`
  ]
};
