const getLectureReadingTime = (lecture) => {
  const wordAmounts = lecture.blocks.map((block) => {
    let wordsArray;
    if (block.type === 'paragraph' || block.type === 'header') {
      wordsArray = block.data.text.replace(/<[^>]+>/g, '')
        .split(' ');
    }
    return wordsArray ? wordsArray.length : 0;
  });
  // Total reading time: Assumes 100 words per minute.
  return Math.round((wordAmounts.reduce((a, b) => a + b) / 100) * 60 * 1000);
};

module.exports = {
    getLectureReadingTime,
};
