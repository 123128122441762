/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';
import exercises from '../../exercises';

const KataCard = ({
  program
}) => {
  const {
    slug, color, name
  } = program;
  const showKatas = () => Object.keys(exercises[slug]).map((exercise, index) => (
    <NavLink
      to={`/exercises/js/${slug}/${exercise}`}
      style={{ marginLeft: 0 }}
      className="btn__tool"
      activeClassName="btn__action"
      key={exercise}
    >
      {`${index + 1}: ${exercise}`}
    </NavLink>
  ));

  return (
    <section>
      <h5 style={{ marginBottom: 'auto', marginTop: `${1}rem`, color }}>
        { `#${name.replace(' ', '')}` }
      </h5>
      <div className="d-flex flex-column justify-content-between mt-3 ml-2 mr-2">
        {showKatas()}
      </div>
    </section>
  );
};

KataCard.propTypes = {
  program: PropTypes.object.isRequired,
  slug: PropTypes.string,
  color: PropTypes.string,
};

export default KataCard;
