/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import columns from './ColumnsSenseiTable';
import columnsProjects from './ColumnsProjects';
import { TableSensei, TableProjects } from '../Table';
import Pills from '../../../Common/Pills';

function Metrics({
  profile,
  history,
  recommendation,
  alumnisBySensei,
  alumnisBySenseiProjects,
  addRecommendation,
  deactivateRecommendation,
  isFecthingAddRecommendation,
  isFetchingDeleteRecommendation,
  onRateProject,
  isSelfPaced,
}) {
  return (
    <>
      <div className="m_campus-program-card bg__hackeranda">
        <header className="modal-card-head">
          <h5 className="text__white">{`${profile.activeRecordModule.group_detail.group_name}`}</h5>
          <h6 className="text__white">{`Tienes: ${alumnisBySensei.length} alumnos`}</h6>
        </header>
      </div>
      <div className="row">
        <Pills pills={
          [
            {
              name: 'Alumnis',
              ref: 'alumnos'
            },
            {
              name: 'Proyectos',
              ref: 'proyects'
            }
          ]
        }
        >
          {({ active }) => (
            <>
              {active === 'alumnos'
                && (
                  <div
                    className="o-profile-sensei__table"
                  >
                    <TableSensei
                      isFecthingAddRecommendation={isFecthingAddRecommendation}
                      isFetchingDeleteRecommendation={isFetchingDeleteRecommendation}
                      history={history}
                      columns={columns}
                      data={alumnisBySensei}
                      recommendation={recommendation}
                      addRecommendation={addRecommendation}
                      deactivateRecommendation={deactivateRecommendation}
                      isSelfPaced={isSelfPaced}
                    />
                  </div>
                )}
              {active === 'proyects' && (
                <div className="o-profile-sensei__table">
                  <TableProjects
                    data={alumnisBySenseiProjects}
                    columns={columnsProjects}
                    rateProjectSubmit={onRateProject}
                    isSelfPaced={isSelfPaced}
                  />
                </div>
              )}

            </>
          )}
        </Pills>
      </div>
    </>
  );
}

Metrics.defaultProps = {
  profile: {
    activeRecordModule: {
      group_detail: {
        group_name: ''
      }
    }
  },
};

Metrics.propTypes = {
  isFecthingAddRecommendation: PropTypes.bool.isRequired,
  isFetchingDeleteRecommendation: PropTypes.bool.isRequired,
  addRecommendation: PropTypes.func.isRequired,
  deactivateRecommendation: PropTypes.func.isRequired,
  alumnisBySensei: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  alumnisBySenseiProjects: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  recommendation: PropTypes.shape({
    role: PropTypes.string.isRequired,
  }).isRequired,
  profile: PropTypes.shape({
    activeRecordModule: PropTypes.shape({
      group_detail: PropTypes.shape({
        group_name: PropTypes.string
      }),
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onRateProject: PropTypes.func.isRequired,
  isSelfPaced: PropTypes.bool.isRequired,
};

export default Metrics;
